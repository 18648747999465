import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import './GenericErrorModal.scss'
import DOMPurify from 'dompurify'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'

interface IError {
  title?: string
  body: string
}

interface IGenericErrorModalProps {
  error: IError
}

const GenericErrorModal: React.FC<IGenericErrorModalProps> = ({ error }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal closeable={false} size="fixed">
      <ModalBody id="error-modal">
        {error.title && <div id="error-title">{error.title}</div>}

        {error.body && (
          <div
            id="error-body"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(error.body),
            }}
          />
        )}
      </ModalBody>

      <ModalFooter
        stacked={true}
        onAccept={hideModal}
        onAcceptText={t('close')}
      />
    </Modal>
  )
}

export default GenericErrorModal
