import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import './InviteSuccessModal.scss'
import CheckIcon from '@web/common/img/CheckIcon'
import CheckSmall from '@web/common/img/CheckSmall.svg'
import _ from 'lodash'

interface InviteSuccessModalProps {
  email: string
  setEmail: (email: string) => void
  title: string
  invites: {
    status: string
    userRef: {
      type: 'HCP' | 'REP'
      id: string
    }
  }[]
}

const InviteSuccessModal: React.FC<InviteSuccessModalProps> = ({
  email,
  title,
  setEmail,
  invites,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  const handleClose = () => {
    setEmail('')
    hideModal()
  }

  return (
    <Modal title={title} closeable={true} onClose={handleClose}>
      <ModalBody id="invite-success-modal">
        <div id="invite-success--icon">
          <CheckIcon />
        </div>

        {invites.length > 1 ? (
          <div id="invites">
            {_.map(invites, (invite) => (
              <div className="invite" key={invite.userRef.id}>
                <div className="invite-user">
                  <div className="invite-user-type">
                    {invite.userRef.type === 'HCP'
                      ? t('hcpDemoUser')
                      : t('crmUser')}
                  </div>
                  <div className="invite-status">
                    <img src={CheckSmall} />
                    <span>{t(invite.status.toLowerCase())}</span>
                  </div>
                </div>

                <p className="invite-user-email">{email}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-default">{email}</p>
        )}
      </ModalBody>

      <ModalFooter onAcceptText={t('ok')} onAccept={handleClose} />
    </Modal>
  )
}

export default InviteSuccessModal
