import React, { useState, useEffect, useContext, useRef } from 'react'
import './TimePicker.scss'
import { timeSelectStyles } from '@web/_config/select'
import Select, { components } from 'react-select'
import { AppContext } from '@web/js/context/AppContext'
import dayjs from 'dayjs'
import { PartitionedHours } from '@web/_types'
import {
  AM_PM_SELECT,
  HOURS_12_SELECT,
  HOURS_24_SELECT,
  MINUTES_SELECT,
} from '@web/_constants'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Input = (props: any) => <components.Input {...props} maxLength={2} />

interface ITime {
  hours: string
  minutes: string
  ampm: string | null
}

interface ITimeSelect {
  value: string
  label: string
}

interface TimePickerProps {
  onChange: (day: number, index: number, property: string, time: string) => void
  officeHours: PartitionedHours
  day: number
  index: number
  property: string
}

const TimePicker: React.FC<TimePickerProps> = ({
  onChange,
  day,
  index,
  officeHours,
  property,
}) => {
  const edgeRef = useRef(officeHours[property as keyof PartitionedHours])

  const setInitialTime = () => {
    //turn 00 to 24
    const is24 =
      isLocale24h &&
      property === 'endTime' &&
      !dayjs.utc(officeHours.startTime).isSame(officeHours.endTime, 'day') &&
      dayjs(edgeRef.current)
        .utc()
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm[Z]') === edgeRef.current

    return {
      hours: is24
        ? '24'
        : dayjs.utc(edgeRef.current).format(isLocale24h ? 'H' : 'h'),
      minutes: dayjs.utc(edgeRef.current).format('mm'),
      ampm: isLocale24h ? null : dayjs.utc(edgeRef.current).format('A'),
    }
  }

  const { isLocale24h } = useContext(AppContext)
  const [init, setInit] = useState(true)
  const [time, setTime] = useState<ITime>(setInitialTime())

  useEffect(() => {
    setInit(false)
  }, [])

  useEffect(() => {
    if (!init) {
      const parseTime = `${time.hours}:${time.minutes}`
      const addDay =
        property === 'endTime' &&
        ((isLocale24h && parseTime === '24:00') ||
          (!isLocale24h && parseTime === '12:00' && time.ampm === 'AM'))

      const dateBase = dayjs('2000-01-03')
        .isoWeekday(day + 1)
        .add(addDay ? 1 : 0, 'day')
        .format('YYYY-MM-DD')

      //safari specific conversion to exclude AM/PM
      const hours =
        time.ampm && time.ampm === 'PM'
          ? (parseInt(time.hours) + 12).toString()
          : time.hours
      const hourMinSec = `${hours}:${time.minutes}:00`
      const dateTime = `${dateBase} ${hourMinSec}`
      const edge = dayjs(dateTime).utc(true).format('YYYY-MM-DDTHH:mm[Z]')
      onChange(day, index, property, edge)
    }
  }, [time])

  return (
    <div className="time-picker">
      <div className="time-picker--hours">
        <Select
          tabIndex={1}
          tabSelectsValue={false}
          className="react-select"
          options={
            !isLocale24h
              ? HOURS_12_SELECT
              : property === 'startTime'
              ? HOURS_24_SELECT.slice(0, -1)
              : HOURS_24_SELECT
          }
          value={HOURS_24_SELECT.find((hour) => hour.value === time.hours)}
          styles={timeSelectStyles}
          onChange={(m: ITimeSelect) => {
            const newTime = {
              ...time,
              hours: m.value,
              ...(m.value === '24' && { minutes: '00' }),
            }
            setTime(newTime)
          }}
          menuPlacement="auto"
          menuPosition="fixed"
          closeMenuOnScroll={(e) => {
            return (e.target as HTMLDivElement).className === 'modal-body'
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Input,
          }}
        />
      </div>
      <div className="time-picker--colon">:</div>
      <div
        className={`time-picker--minutes ${
          time.hours === '24' ? 'disabled' : ''
        }`}
      >
        <Select
          tabIndex={1}
          tabSelectsValue={false}
          className="react-select"
          options={MINUTES_SELECT}
          value={MINUTES_SELECT.find((minute) => minute.value === time.minutes)}
          styles={timeSelectStyles}
          onChange={(h: ITimeSelect) => setTime({ ...time, minutes: h.value })}
          menuPlacement="auto"
          isDisabled={time.hours === '24'}
          menuPosition="fixed"
          closeMenuOnScroll={(e) => {
            return (e.target as HTMLDivElement).className === 'modal-body'
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Input,
          }}
        />
      </div>
      {!isLocale24h && (
        <div className="time-picker--ampm">
          <Select
            tabIndex={1}
            tabSelectsValue={false}
            className="react-select"
            options={AM_PM_SELECT}
            value={AM_PM_SELECT.find((ampm) => ampm.value === time.ampm)}
            styles={timeSelectStyles}
            onChange={(a: ITimeSelect) => setTime({ ...time, ampm: a.value })}
            menuPlacement="auto"
            menuPosition="fixed"
            closeMenuOnScroll={(e) => {
              return (e.target as HTMLDivElement).className === 'modal-body'
            }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Input,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default TimePicker
