import * as React from 'react'
import { IconProps } from '@web/_types'

const SamplesIcon: React.FC<IconProps> = ({
  height = '16px',
  width = '16px',
  color = '#ffffff',
  className = 'icon-samples',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3573 3.64271C13.3722 4.65762 13.3722 6.30316 12.3573 7.31806L9.50842 10.1669L5.83307 6.49153L8.68188 3.64271C9.69684 2.62781 11.3423 2.62781 12.3573 3.64271ZM12.9806 7.94138C14.3398 6.58224 14.3398 4.37854 12.9806 3.0194C11.6214 1.6602 9.41776 1.6602 8.05857 3.0194L3.0194 8.05857C1.6602 9.41776 1.6602 11.6214 3.0194 12.9806C4.37859 14.3397 6.58224 14.3397 7.94143 12.9806L12.9806 7.94138Z"
      fill={color}
    />
  </svg>
)

export default SamplesIcon
