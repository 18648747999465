import * as React from 'react'
import { IconProps } from '@web/_types'

const CheckIcon: React.FC<IconProps> = ({
  height = '33px',
  width = '33px',
  color = '#ffffff',
  className = 'icon-check',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7373 0.510037C33.0486 1.43084 33.3946 3.28398 32.5101 4.64913L14.9939 31.6847C14.5551 32.362 13.896 32.7919 13.1829 32.9408C13.166 32.9436 13.1496 32.9469 13.1332 32.9501C13.0924 32.9585 13.0507 32.9654 13.0089 32.9714C12.9881 32.9741 12.968 32.9768 12.9478 32.9793C12.9146 32.9835 12.8808 32.9869 12.847 32.9897C12.8163 32.9925 12.7856 32.9946 12.755 32.9962C12.7321 32.997 12.7092 32.9979 12.6863 32.9985C12.6486 33 12.6107 33.0003 12.5728 32.9998C12.554 32.9987 12.5356 32.9982 12.5172 32.9975C11.8156 32.9751 11.1203 32.6845 10.5846 32.1267L0.83882 21.9806C-0.279607 20.8163 -0.279607 18.9284 0.83882 17.7641C1.95725 16.5997 3.77057 16.5997 4.889 17.7641L12.1832 25.3579L27.7615 1.3146C28.646 -0.0505557 30.426 -0.41077 31.7373 0.510037Z"
      fill={color}
    />
  </svg>
)

export default CheckIcon
