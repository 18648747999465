import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import CheckIcon from '@web/common/img/CheckIcon'
import DOMPurify from 'dompurify'
import './InviteSentModal.scss'

interface InviteSentModalProps {
  displayName: string
}

const InviteSentModal: React.FC<InviteSentModalProps> = ({ displayName }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal
      closeable={true}
      title={t('connectionInviteSentCap')}
      onClose={hideModal}
    >
      <ModalBody id="invite-sent-modal">
        <div id="invite-success--icon">
          <CheckIcon />
        </div>
        <p
          className="text-default"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('youWillBeNotifiedAcceptInvite', {
                name: displayName,
              })
            ),
          }}
        />
      </ModalBody>

      <ModalFooter
        onAcceptText={t('ok')}
        cancellable={false}
        onAccept={hideModal}
      />
    </Modal>
  )
}

export default InviteSentModal
