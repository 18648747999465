import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N, ROUTES } from '@web/_constants'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import './NoConnectionModal.scss'
import { AuthContext } from '@web/js/context/AuthContext'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useNavigate } from 'react-router-dom'

// cannot import icon when no network connection
const CachedPresentationIcon = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.54097 4.25218C7.54097 3.33895 8.28129 2.59863 9.19452 2.59863H32.3441C33.2574 2.59863 33.9977 3.33895 33.9977 4.25218H7.54097ZM37.3048 9.21281V23.6813C37.3048 25.9642 35.4538 27.8152 33.1709 27.8152H25.6617L30.0281 37.4192C30.1005 37.5793 30.087 37.7654 29.992 37.9132C29.8969 38.0609 29.7346 38.1498 29.5589 38.1498H28.4232C28.2206 38.1498 28.0366 38.031 27.9529 37.847L23.3933 27.8152H18.1454L13.5857 37.847C13.502 38.031 13.318 38.1498 13.1155 38.1498H11.9797C11.804 38.1498 11.6407 38.0609 11.5456 37.9132C11.4506 37.7654 11.4371 37.5793 11.5095 37.4192L15.8769 27.8152H8.36774C6.08482 27.8152 4.23389 25.9642 4.23389 23.6813V9.21281C4.23389 6.92988 6.08482 5.07895 8.36774 5.07895H33.1709C35.4538 5.07895 37.3048 6.92988 37.3048 9.21281ZM25.5249 15.9659C26.0158 16.2932 26.0158 17.0145 25.5249 17.3418L17.9209 22.4111C17.3714 22.7774 16.6355 22.3835 16.6355 21.7232L16.6355 11.5845C16.6355 10.9242 17.3714 10.5303 17.9209 10.8966L25.5249 15.9659Z"
      fill="white"
    />
  </svg>
)

const NoConnectionModal: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)
  const { authenticated } = useContext(AuthContext)
  const { lessThan } = useResponsive()

  const leaveRejoinPresentation = () => {
    hideModal()
    const url = authenticated && lessThan.md ? ROUTES.joinMeeting : ROUTES.join
    navigate(url)
  }

  return (
    <Modal closeable={false} size="sm">
      <ModalBody id="presentation-no-network-connection-modal">
        <div id="presentation-icon">
          <CachedPresentationIcon />
        </div>

        <div className="text-headline">{t('noNetworkConnection')}</div>
        <p className="text-small">{t('pleaseCheckConnection')}</p>
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onAcceptText={t('rejoinPresentation')}
        onAccept={() => location.reload()}
        onCancelText={t('leave')}
        onCancel={leaveRejoinPresentation}
        stacked={true}
      />
    </Modal>
  )
}

export default NoConnectionModal
