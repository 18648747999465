import * as React from 'react'
import { IconProps } from '@web/_types'

const PatientHubIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-patient-hub',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.51282 7.81395C8.51282 5.84885 10.0741 4.25581 12 4.25581C13.9259 4.25581 15.4872 5.84885 15.4872 7.81395C15.4872 9.77906 13.9259 11.3721 12 11.3721C10.0741 11.3721 8.51282 9.77906 8.51282 7.81395ZM12 3C9.39435 3 7.28205 5.15528 7.28205 7.81395C7.28205 10.4726 9.39435 12.6279 12 12.6279C14.6057 12.6279 16.7179 10.4726 16.7179 7.81395C16.7179 5.15528 14.6057 3 12 3ZM8.30769 13.8837C7.15785 13.8837 6.05984 14.3673 5.25386 15.2201C4.44861 16.0722 4 17.2231 4 18.4186V20.3721C4 20.7189 4.27552 21 4.61538 21C4.95525 21 5.23077 20.7189 5.23077 20.3721V18.4186C5.23077 17.5417 5.56018 16.7051 6.13982 16.0918C6.71872 15.4792 7.49901 15.1395 8.30769 15.1395H15.6923C16.501 15.1395 17.2813 15.4792 17.8602 16.0918C18.4398 16.7051 18.7692 17.5417 18.7692 18.4186V20.3721C18.7692 20.7189 19.0447 21 19.3846 21C19.7245 21 20 20.7189 20 20.3721V18.4186C20 17.2231 19.5514 16.0722 18.7461 15.2201C17.9402 14.3673 16.8421 13.8837 15.6923 13.8837H8.30769Z"
      fill={color}
    />
  </svg>
)

export default PatientHubIcon
