import React, { useRef, useEffect, useState } from 'react'
import './Image.scss'

const CachedImageIcon = () => {
  return (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path
        fill="#fff"
        d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"
      ></path>
    </svg>
  )
}

interface ImageProps {
  src: string
  alt?: string | null
  showPlaceholder?: boolean
}

const Image: React.FC<ImageProps> = ({ src, alt, showPlaceholder = false }) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const [error, setError] = useState(false)

  const handleError = () => {
    setError(true)
  }

  useEffect(() => {
    if (imgRef.current) imgRef.current.addEventListener('error', handleError)

    return () => {
      if (imgRef.current)
        imgRef.current.removeEventListener('error', handleError)
    }
  }, [imgRef])

  if (error && showPlaceholder)
    return (
      <div className="img-placeholder">
        <CachedImageIcon />
      </div>
    )

  if (error && !showPlaceholder) return null

  return <img ref={imgRef} src={src} alt={alt || ''} />
}

export default Image
