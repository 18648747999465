import React, { ReactNode, useEffect, useState } from 'react'
import { classNames } from '@web/_utils'
import './Sidebar.scss'
import Button from '@web/js/components/Button'
import CloseIcon from '@mui/icons-material/Close'

type SidebarSide = 'left' | 'right'

interface SidebarProps {
  id?: string
  classes?: string
  overlay?: boolean
  open?: boolean
  side?: SidebarSide
  width?: string
  closeFunc?: () => void
  onClosedFunc?: () => void
  fullScreen?: boolean
  children?: ReactNode
}

const Sidebar: React.FC<SidebarProps> = ({
  children,
  classes = '',
  closeFunc,
  fullScreen = false,
  id,
  onClosedFunc,
  open = false,
  overlay = false,
  side = 'left',
  width,
}) => {
  const [isMounted, setIsMounted] = useState(false)

  const cx = {
    sidebar: true,
    overlay: overlay,
    open: open,
    'sidebar-full-screen': fullScreen,
    [classes as string]: !!classes,
    [('sidebar-' + side) as string]: true,
  }

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    if (isMounted && !open) {
      handleOnClosed()
    }
  }, [open])

  const sidebarClasses = classNames(cx)
  const style = width ? { width } : {}

  const handleOnClose = (closeFunc: () => void) => {
    closeFunc()
    if (onClosedFunc) handleOnClosed()
  }

  const handleOnClosed = () => {
    if (onClosedFunc) setTimeout(() => onClosedFunc(), fullScreen ? 0 : 300)
  }

  return (
    <aside
      {...(id && { id })}
      data-side={side}
      className={`${sidebarClasses}`}
      style={style}
    >
      {closeFunc && (
        <div className="sidebar--close-btn">
          <Button
            onClick={() => handleOnClose(closeFunc)}
            size="lg"
            style="link"
            icon={<CloseIcon />}
          />
        </div>
      )}
      {children}
    </aside>
  )
}

export default Sidebar
