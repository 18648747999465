import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const baseURL = Config.OAPI_URL_V2

export const OrgService = {
    getRedirectUrl: async(org_id: string): Promise<AxiosResponse> => {
        return axios({
            baseURL,
            url: `/orgs/${org_id}`
        })
    }
}