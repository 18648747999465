import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const oapiUrlV2 = Config.OAPI_URL_V2

type DeeplinkArgs = {
  deeplink: string
  deeplinkProtocolVersion?: string // Indicates what deeplinks the client supports (Defaults to 1.0 if not passed by client)
  state?: string
  signal: AbortSignal
}

export const DeeplinkService = {
  getDeeplinkActions: ({
    deeplink,
    deeplinkProtocolVersion = '1.0',
    state,
    signal,
  }: DeeplinkArgs): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL: oapiUrlV2,
      url: '/deeplinks',
      signal,
      data: {
        deeplink,
        deeplinkProtocolVersion,
        ...(state && { state }),
      },
    })
  },
}
