import React from 'react'
import '@web/js/components/ContactCard/ContactCard.scss'
import { DeeplinkInviter } from '@web/_types'
import { classNames, toCamelCase } from '@web/_utils'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import Avatar from '../Avatar'

interface InviterCardProps {
  inviter: DeeplinkInviter
}

const InviterCard: React.FC<InviterCardProps> = ({ inviter }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const contactCardClasses = classNames({
    'contact-card': true,
    [inviter.type.toLowerCase()]: true,
  })

  return (
    <div className={contactCardClasses} data-contact-id={inviter.id}>
      <div className="contact-card--avatar">
        <Avatar inviter={inviter} size="lg" />
      </div>
      <div className="contact-card--details">
        <div className="contact-card--details--header">
          <div className="contact-card--details--name">
            <span className="text-default-semibold">{inviter.displayName}</span>
          </div>
        </div>

        {!!inviter.title && inviter.type === 'HCP' && (
          <div className="contact-card--details--title text-small">
            {inviter.title}
          </div>
        )}

        {!!inviter.roleCategory && inviter.type === 'REP' && (
          <div className="contact-card--details--role text-small">
            {t(`roleCategory.${toCamelCase(inviter.roleCategory)}`)}
          </div>
        )}

        {!!inviter.groupName && (
          <div className="contact-card--details--group text-small">
            {inviter.groupName}
          </div>
        )}
      </div>
    </div>
  )
}

export default InviterCard
