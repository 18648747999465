import React, { useContext, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import './PostEngagementSignup.scss'
import isEmail from 'validator/lib/isEmail'
import { MeetingService } from '@web/_services/MeetingService'
import { useLocalStorage } from '@web/common/hooks'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { parseDossier, validateForm } from '@web/_utils'
import { ConfigType, Errors, Rules } from '@web/_types'
import { getQueryStringParams } from '@web/_utils'
import * as DOMPurify from 'dompurify'
import {
  ROUTES,
  I18N,
  LOCALSTORAGE,
  LOGIN_SOURCE,
  MODALS,
} from '@web/_constants'
import Button from '@web/js/components/Button'
import FormField from '@web/js/components/FormField'
import CameraIcon from '@web/common/img/CameraIcon.svg'
import ContactsIcon from '@web/common/img/ContactsIcon.svg'
import ReachOutIcon from '@web/common/img/ReachOutIcon.svg'
import { AppContext } from '@web/js/context/AppContext'
import { AuthContext } from '@web/js/context/AuthContext'
import { ToastContext } from '@web/js/context/ToastContext'
import _ from 'lodash'
import { ModalContext } from '@web/js/context/ModalContext'

const PostEngagementSignup: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { config, isSurnameOrdered } = useContext(AppContext)
  const { hcpVerification, sessionId } = useContext(AuthContext)
  const { addToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)

  const navigate = useNavigate()
  const { search, state } = useLocation()

  const [email, setEmail] = useState('')
  const [repName, setRepName] = useState(state?.repName || '')
  const [errors, setErrors] = useState<Errors>({})

  const queryArgs = getQueryStringParams(search)
  const meetingId = queryArgs.meetingId

  const isDBCSignup = !!useMatch(ROUTES.dbcSignup)
  const isPostMeeting = !!useMatch(ROUTES.postMeeting)
  const isPostPresentation = !!useMatch(ROUTES.postPresentation)
  const isPostScheduleMeetingRequest = !!useMatch(ROUTES.postMeetingRequest)

  const [firstName, setFirstName] = useLocalStorage<string | null>(
    LOCALSTORAGE.firstName,
    null
  )
  const [lastName, setLastName] = useLocalStorage<string | null>(
    LOCALSTORAGE.lastName,
    null
  )
  const [accountId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingAccountId,
    null
  )

  const [loginSource, setLoginSource] = useLocalStorage<string | null>(
    LOCALSTORAGE.loginSource,
    null
  )

  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isPostMeeting) getPostMeetingRep()
  }, [])

  useEffect(() => {
    isSurnameOrdered
      ? lastNameRef.current?.focus()
      : firstNameRef.current?.focus()
  }, [isSurnameOrdered])

  const getPostMeetingRep = async () => {
    if (!meetingId) return

    try {
      const repResponse = await MeetingService.getMeetingRepUnAuthenticated(
        meetingId,
        sessionId ?? ''
      )
      const dossier = parseDossier(repResponse.data)
      const repName = dossier.reps[dossier.repId as string].displayName
      setRepName(repName)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    const fields = {
      email,
      lastName: lastName?.trim() || '',
      firstName: firstName?.trim() || '',
    }

    const rules: Rules = {
      email: {
        required: true,
        valid: 'email',
      },
      lastName: {
        required: true,
      },
      firstName: {
        required: true,
      },
    }

    const errors = validateForm(fields, rules)
    setErrors(errors)
    if (!_.isEmpty(errors)) return

    if (isEmail(email)) {
      try {
        if (!isDBCSignup || !loginSource) {
          let source = null
          if (isPostMeeting) source = LOGIN_SOURCE.POST_ENGAGE_MEETING
          if (isPostPresentation) source = LOGIN_SOURCE.POST_REMOTE_CLM
          if (isPostScheduleMeetingRequest)
            source = LOGIN_SOURCE.POST_MEETING_REQUEST
          setLoginSource(source)
        }

        await hcpVerification(email)
        navigate(ROUTES.verify, {
          state: { shoudSkipSignup: true }, //tell verify route to make signup call and skip signup route
        })
      } catch (error) {
        const errorResponse = error.response

        switch (errorResponse.status) {
          case 400:
            setErrors({ email: t('errorInvalidEmail') })
            break
          case 403:
            showModal({
              name: MODALS.BLOCKED_DOMAIN_MODAL,
            })
            break
          case 429:
            addToast(t('errorTooManyRequests'))
            break
        }
      }
    }
  }

  const clearErrors = (errorKey: string) => {
    const updatedErrors = _.clone(errors)
    updatedErrors[errorKey] = ''
    setErrors(updatedErrors)
  }

  if (
    !(config as ConfigType).supportsSignup ||
    !(!!accountId || isPostPresentation || isDBCSignup)
  ) {
    navigate(ROUTES.join, { state: { redirected: true } })
    return null
  }

  return (
    <div id="post-engagement-signup-route">
      {!isDBCSignup && (
        <div id="top-statement">
          {repName && (
            <div id="add-to-contacts">
              {t('addToContacts', { name: repName })}
            </div>
          )}
          <div>
            {isPostScheduleMeetingRequest && (
              <div id="save-meetings">{t('saveMeetings')} -</div>
            )}
            <div>{t('completeRegistrationToday')}</div>
          </div>
        </div>
      )}

      <div id="post-engagement-signup-form">
        {!isDBCSignup && (
          <div id="value-pitch">
            <div className="pitch-row">
              <img src={CameraIcon} />
              <label>{t('joinMeetingFaster')}</label>
            </div>
            <div className="pitch-row">
              <img src={ContactsIcon} />
              <label>{t('savePharmaContacts')}</label>
            </div>
            <div className="pitch-row">
              <img src={ReachOutIcon} />
              <label>{t('reachOut')}</label>
            </div>
          </div>
        )}

        <form id="registration-form" onSubmit={handleSubmit}>
          <label id="sign-up">
            {isDBCSignup
              ? t('signUpToConnectWith', { name: repName })
              : t('signUpNow')}
          </label>

          <div id="ordered-inputs">
            <FormField
              label={t('firstName')}
              name="firstName"
              forRef={firstNameRef}
              error={errors.firstName}
              value={firstName ?? ''}
              onChange={(e) => {
                setFirstName(e.currentTarget.value)
                clearErrors('firstName')
              }}
              order={isSurnameOrdered ? 2 : 1}
            />

            <FormField
              label={t('lastName')}
              name="lastName"
              forRef={lastNameRef}
              error={errors.lastName}
              value={lastName ?? ''}
              onChange={(e) => {
                setLastName(e.currentTarget.value)
                clearErrors('lastName')
              }}
              order={isSurnameOrdered ? 1 : 2}
            />

            <FormField
              label={t('email')}
              name="email"
              error={errors.email}
              value={email ?? ''}
              onChange={(e) => {
                setEmail(e.currentTarget.value)
                clearErrors('email')
              }}
              order={3}
            />
          </div>

          <div
            className="signup-terms"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                t('agreeToTerms', {
                  termsUrl: (config as ConfigType).termsUrl,
                  privacyUrl: (config as ConfigType).privacyUrl,
                }),
                { ADD_ATTR: ['target'] }
              ),
            }}
          />
          <Button block={true} style="primary" size="xl" type="submit">
            {t('continue')}
          </Button>
        </form>

        <div id="sign-in-cta">
          <div>{t('alreadyHaveAccount')}</div>
          <a onClick={() => navigate(ROUTES.login)}>{t('signIn')}</a>
        </div>
      </div>
    </div>
  )
}

export default PostEngagementSignup
