import React, { useEffect, useRef, useState } from 'react'
import './BrandCard.scss'
import { Brand, BrandActionCategory } from '@web/_types'
import { useDispatch } from 'react-redux'
import { classNames, formatNonProprietaryName, isValidUrl } from '@web/_utils'
import { ROUTES, COLORS, LOGGING } from '@web/_constants'
import _ from 'lodash'
import { createLog } from '@web/js/redux/logger/loggerActions'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import BrandIcon from '@web/common/img/BrandIcon'
import Config from '@web/_config'
import DOMPurify from 'dompurify'
import { useNavigate } from 'react-router-dom'

interface BrandCardProps {
  brand: Brand
  connectionId?: string
  category: BrandActionCategory
  redirect?: () => void
}

const BrandCard: React.FC<BrandCardProps> = ({
  brand,
  connectionId,
  category,
  redirect,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const regStatementRef = useRef<HTMLDivElement>(null)
  const [isRepProfile] = useState(category === 'REP_PROFILE')
  const [isBrandProfile] = useState(category === 'BRAND_PROFILE')

  useEffect(() => {
    if (regStatementRef.current) {
      const regStatementLinks = regStatementRef.current.querySelectorAll('a')
      regStatementLinks.forEach((link) =>
        link.addEventListener('click', (e) => {
          e.stopPropagation()
          e.preventDefault()
          if (e.currentTarget) {
            let url: string =
              (e.currentTarget as HTMLAnchorElement).getAttribute('href') || ''

            if (!isValidUrl(url)) {
              url = 'https://' + url
            }
            window.open(url, '_blank')
          }
        })
      )
    }
  }, [])

  const goToBrandPage = () => {
    logProfileEvent(LOGGING.ACTION_TYPE.CLICK_BRAND_CARD, brand.id)

    if (brand.hasPage) {
      logBrandEvent()

      if (redirect) {
        redirect()
        return
      }

      const url = `${ROUTES.brand}/${brand.id}`
      if (category === 'SAMPLES_SELECTOR') {
        window.open(`${Config.BASE_URL}${url}`, '_blank')
      } else {
        navigate(url)
      }
    }
  }

  const logProfileEvent = (actionType: string, referenceId: string) => {
    if (!isRepProfile) return
    const eventMessage = { actionType, referenceId, profileId: connectionId }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  const logBrandEvent = (referenceId?: string) => {
    const eventMessage = {
      brandId: brand.id,
      actionCategory: LOGGING.ACTION_CATEGORY[category],
      actionType: referenceId
        ? LOGGING.ACTION_TYPE.REGULATORY_LINK
        : LOGGING.ACTION_TYPE.BRAND_PROFILE,
      referenceId: referenceId ? referenceId : connectionId,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.BRAND_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  const renderRegulatoryHTMLStatement = () => {
    const regulatoryText = (brand.regStatementHtml as string).replace(
      /(?:\\r\\n|\\r|\\n)/g,
      '<br />'
    )
    const __html = DOMPurify.sanitize(regulatoryText, {
      ALLOWED_TAGS: ['b', 'i', 'a', 'ol', 'ul', 'li', 'br', 'p'],
    })

    return (
      <div
        ref={regStatementRef}
        className="brand-card--reg-statement text-small"
        dangerouslySetInnerHTML={{
          __html,
        }}
      />
    )
  }

  const brandCardClasses = classNames({
    'brand-card': true,
    nameless: isBrandProfile,
  })

  const brandCardHeaderClasses = classNames({
    'brand-card--header': true,
    clickable: brand.hasPage && !isBrandProfile,
  })

  return (
    <div className={brandCardClasses} key={brand.id}>
      <div
        className={brandCardHeaderClasses}
        {...(!isBrandProfile && { onClick: goToBrandPage })}
      >
        {!isBrandProfile && (
          <div
            className="brand-card--icon"
            style={{
              background: brand.hasPage ? brand.primaryColor : COLORS.white,
            }}
          >
            <BrandIcon {...(!brand.hasPage && { color: '#8A8A8E' })} />
          </div>
        )}

        <div className="brand-card--info">
          {!isBrandProfile && (
            <>
              <div className="brand-card--name text-default-semibold">
                {brand.name}
              </div>

              {!!brand.nonProprietaryName && (
                <div className="brand-card--nonprop text-supporting">
                  {formatNonProprietaryName(brand.nonProprietaryName)}
                </div>
              )}
            </>
          )}

          {!!brand.regStatementHtml && renderRegulatoryHTMLStatement()}
        </div>
      </div>
      {!!brand.regLinks.length && (
        <div className="brand-card--reg-links">
          {_.map(brand.regLinks, (link, i) => {
            return (
              <a
                className="brand-card--reg-link"
                key={i}
                href={link.url}
                target="_blank"
                onClick={() => {
                  logProfileEvent(
                    LOGGING.ACTION_TYPE.CLICK_REGULATORY_LINK,
                    link.id
                  )
                  logBrandEvent(link.id)
                }}
              >
                <div className="brand-card--reg-link--icon">
                  <InfoOutlinedIcon style={{ color: COLORS.black }} />
                </div>
                <div className="brand-card--reg-link--name text-small">
                  {link.name}
                </div>
              </a>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default BrandCard
