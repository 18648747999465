/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { AuthService } from '@web/_services'
import { AXIOS, LOCALSTORAGE } from '@web/_constants'
import { generateExpiry } from '@web/_utils'
import Config from '@web/_config'
import i18n from '@web/common/js/i18n'
import Bowser from 'bowser'
import _ from 'lodash'

type Headers = {
  [key: string]: string | number | null
}

type FailedResponseQueueType = {
  resolve: (value: unknown) => void
  reject: (reason?: any) => void
}

const clearLocalStorage = () => {
  const storageItems = [
    LOCALSTORAGE.email,
    LOCALSTORAGE.refreshToken,
    LOCALSTORAGE.user,
    LOCALSTORAGE.clientSecret,
    LOCALSTORAGE.userRole,
    LOCALSTORAGE.accessToken,
  ]
  storageItems.forEach((ls) => localStorage.removeItem(ls))
}

const setRefreshTokenExpiry = (): void => {
  const refreshToken = JSON.parse(
    localStorage.getItem(LOCALSTORAGE.refreshToken) || 'null'
  )

  if (refreshToken) {
    const lsPublic = window.localStorage.getItem(LOCALSTORAGE.public)
      ? JSON.parse(window.localStorage.getItem(LOCALSTORAGE.public) as string)
      : 'false'
    const isPublic = lsPublic && String(lsPublic) === 'true'

    //Do not reset local storage if not public and timeout is already set
    if (!isPublic && refreshToken.expiry === Config.SESSION_TIMEOUT_MAX) return

    const token = refreshToken.token
    const expiry = generateExpiry(isPublic as boolean)

    window.localStorage.setItem(
      LOCALSTORAGE.refreshToken,
      JSON.stringify({ token, expiry })
    )
    window.dispatchEvent(new Event('local-storage'))
  }
}

const debounceSetRefreshTokenExpiry = _.debounce(
  () => setRefreshTokenExpiry(),
  500
)

const generateHeaders = (): Headers => {
  const accessToken = window.localStorage.getItem(
    LOCALSTORAGE.accessToken
  ) as string

  const screenSize = `${screen.width} x ${screen.height}`
  const {
    name: browser,
    version: browserVersion,
    osname: os,
    osversion: osVersion,
  } = Bowser

  const headers = {
    'X-MVDE-web-Version': Config.VERSION,
    'X-MyVeeva-Device-Model': `${browser} ${browserVersion}`,
    'X-MyVeeva-Screen-Size': screenSize,
    'X-MyVeeva-OS': `${os} ${osVersion}`,
    'X-MyVeeva-Timezone': `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    ...(!!accessToken && { 'X-MyVeeva-Auth': JSON.parse(accessToken) }),
    'X-MyVeeva-Device-Id': JSON.parse(
      window.localStorage.getItem(LOCALSTORAGE.loggingId) as string
    ),
    'X-MyVeeva-Country-Code': JSON.parse(
      window.localStorage.getItem(LOCALSTORAGE.countryCode) as string
    ),
    'X-MyVeeva-Message-Protocol-Version': '4.0',
  }

  return headers
}

//Request Interceptor
const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  if (config && config.url === Config.COUNTRY_URL) return config
  if (!config.headers) config.headers = new AxiosHeaders()
  config.headers['Accept-Language'] = i18n.language

  // Exclude MVDE headers for "external" api calls
  if (config.headers && config.headers['external']) {
    delete config.headers['external']
  } else {
    const MVDEheaders = generateHeaders()
    config.headers = { ...config.headers, ...MVDEheaders } as AxiosHeaders
  }
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

axios.interceptors.request.use(onRequest, onRequestError)

// Response Interceptor
const onResponse = (response: AxiosResponse): AxiosResponse => {
  debounceSetRefreshTokenExpiry()
  return response
}

let isRefreshingToken = false
let failedResponseQueue: FailedResponseQueueType[] = []

const processQueue = (error: AxiosError | null, token = null) => {
  failedResponseQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedResponseQueue = []
}

const onResponseError = async (
  error: AxiosError
): Promise<AxiosError | AxiosRequestConfig> => {
  const originalRequest = error.config

  if (error.message === AXIOS.canceled) return Promise.reject(AXIOS.canceled)

  const requestUrl = originalRequest ? originalRequest.url : ''
  const isUnauthorized = error?.response?.status === 401
  const staleVersion = error?.response?.status === 412

  if (staleVersion) location.reload()

  if (isUnauthorized && requestUrl === '/token') {
    clearLocalStorage()
    return Promise.reject(error)
  }

  if (isUnauthorized && originalRequest && !originalRequest.headers.retry) {
    if (isRefreshingToken) {
      return new Promise(function (resolve, reject) {
        failedResponseQueue.push({ resolve, reject })
      })
        .then((token) => {
          originalRequest.headers['X-MyVeeva-Auth'] = token
          return axios(originalRequest)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }

    originalRequest.headers.retry = true
    isRefreshingToken = true

    const refreshToken = JSON.parse(
      localStorage.getItem(LOCALSTORAGE.refreshToken) || 'null'
    )

    const clientSecret = JSON.parse(
      localStorage.getItem(LOCALSTORAGE.clientSecret) || 'null'
    )

    return new Promise(function (resolve, reject) {
      AuthService.refreshAccessToken(refreshToken.token, clientSecret)
        .then((res) => {
          const token = res.headers['x-myveeva-auth']
          originalRequest.headers['X-MyVeeva-Auth'] = token

          window.localStorage.setItem(
            LOCALSTORAGE.accessToken,
            JSON.stringify(token)
          )

          processQueue(null, token)
          resolve(axios(originalRequest))
          console.log('access token refreshed')
        })
        .catch((err) => {
          processQueue(err, null)
          reject(err)
          clearLocalStorage()
        })
        .finally(() => {
          isRefreshingToken = false
        })
    })
  }

  return Promise.reject(error)
}

axios.interceptors.response.use(onResponse, onResponseError)
