import React, { useContext } from 'react'
import { I18N, SUPPORT_LINK } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import './InviteBlockedDomainErrorModal.scss'
import DOMPurify from 'dompurify'

const InviteBlockedDomainErrorModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal closeable={false} size="fixed">
      <ModalBody id="blocked-domain-error-modal">
        <div id="blocked-domain-error-title">
          {t('errorSomethingWentWrongTitle')}
        </div>

        <div
          id="blocked-domain-error-body"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('errorNotAllowed', {
                support: SUPPORT_LINK,
              })
            ),
          }}
        />
      </ModalBody>

      <ModalFooter
        onAccept={hideModal}
        onAcceptText={t('close')}
        stacked={true}
      />
    </Modal>
  )
}

export default InviteBlockedDomainErrorModal
