import React from 'react'
import './MeetingCard.scss'
import { Meeting, NotificationRequest, RemoteCLMSession } from '@web/_types'
import { useTranslation } from 'react-i18next'
import {
  I18N,
  ROUTES,
  LOGGING,
  LOCALSTORAGE,
  NOTIFICATION,
} from '@web/_constants'
import Avatar from '@web/js/components/Avatar'
import Button from '@web/js/components/Button'
import CameraIconSolid from '@web/common/img/CameraIconSolid'
import CalendarPlusGray from '@web/common/img/CalendarPlusGray.svg'
import CalendarCheckGray from '@web/common/img/CalendarCheckGray.svg'
import PresentationIcon from '@web/common/img/PresentationIcon.svg'
import PresentationIconGray from '@web/common/img/PresentationIconGray.svg'
import { classNames, getNotificationStartTime } from '@web/_utils'
import MeetingsIcon from '@web/common/img/MeetingsIcon'
import { useLocalStorage } from '@web/common/hooks'
import dayjs from 'dayjs'
import { isMeeting, isRemoteCLMSession } from '@web/_guards'
import { useNavigate } from 'react-router-dom'

interface MeetingCardProps {
  meeting: Meeting | NotificationRequest | RemoteCLMSession
  onClick: () => void
  active: boolean
}

const MeetingCard: React.FC<MeetingCardProps> = ({
  meeting,
  onClick,
  active,
}) => {
  const { t, i18n } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const connection =
    isMeeting(meeting) || isRemoteCLMSession(meeting)
      ? meeting.connection
      : meeting.contact
  const id =
    isMeeting(meeting) || isRemoteCLMSession(meeting)
      ? meeting.id
      : meeting.notificationId

  const generateTitle = () => {
    if (isMeeting(meeting)) {
      return meeting.title ? meeting.title : connection.displayName
    } else if (isRemoteCLMSession(meeting)) {
      return connection.displayName
    } else {
      switch (meeting.type) {
        case NOTIFICATION.meetingAdvAccepted:
          return t('meetingNotification.meetingInviteAccepted')
        case NOTIFICATION.meetingAdvDeclined:
          return t('meetingNotification.meetingInviteDeclined')
        case NOTIFICATION.meetingAdvRequest:
          return t('meetingNotification.meetingInvitePending')
        case NOTIFICATION.crmMeetingRequest:
          return t('meetingNotification.meetingRequestSent')
        case NOTIFICATION.crmMeetingAccepted:
          return t('meetingNotification.meetingRequestAccepted')
      }
    }
  }

  const title = generateTitle()

  const meetingStart =
    isMeeting(meeting) || isRemoteCLMSession(meeting)
      ? meeting.start
      : getNotificationStartTime(meeting)
  const remoteCLMSessionWithinBounds =
    isRemoteCLMSession(meeting) &&
    dayjs().isBetween(meetingStart, meeting.expiration, null, '[]')

  const isToday = dayjs(meetingStart).isToday()
  const isTomorrow = dayjs(meetingStart).isTomorrow()
  const meetingDate = new Date(meetingStart)
  const meetingTime = dayjs(meetingStart).format('LT')

  const [, setJoinType] = useLocalStorage<string | null>(
    LOCALSTORAGE.joinType,
    null
  )

  const generateIcon = () => {
    if (isMeeting(meeting)) {
      return <CameraIconSolid />
    } else if (isRemoteCLMSession(meeting)) {
      return <img src={PresentationIconGray} />
    } else {
      switch (meeting.type) {
        case NOTIFICATION.meetingAdvAccepted:
        case NOTIFICATION.crmMeetingAccepted:
          return <img src={CalendarCheckGray} />
        case NOTIFICATION.meetingAdvRequest:
          return <img src={CalendarPlusGray} />
        case NOTIFICATION.crmMeetingRequest:
          return null
      }
    }
  }

  const cx = {
    'meeting-card': true,
    active: active,
    sent:
      !isMeeting(meeting) &&
      !isRemoteCLMSession(meeting) &&
      meeting.type === NOTIFICATION.crmMeetingRequest,
  }
  const meetingsCardClasses = classNames(cx)

  const joinMeeting = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setJoinType(LOGGING.JOIN_TYPE.UPCOMING_MEETINGS_TAB)

    if (isMeeting(meeting)) {
      const meetingUrl = `/web${ROUTES.meeting}?m=${
        (meeting as Meeting).attendeeId
      }&p=${(meeting as Meeting).password}`

      window.location.assign(meetingUrl)
    } else if (isRemoteCLMSession(meeting)) {
      navigate(`${ROUTES.presentation}?pin=${meeting.pin}`)
    }
  }

  return (
    <div className={meetingsCardClasses} key={id} onClick={onClick}>
      <Avatar user={connection} size="lg" />

      {!isToday && !isTomorrow && (
        <div className="meeting-card--details">
          <div className="meeting-card--details--header">{title}</div>

          {isRemoteCLMSession(meeting) && (
            <div className="meeting-card--details--title">
              {t('presentation')}
            </div>
          )}

          {!isRemoteCLMSession(meeting) &&
            ((isMeeting(meeting) && meeting.title) || !isMeeting(meeting)) && (
              <div className="meeting-card--details--name">
                {connection.displayName}
              </div>
            )}

          <div className="meeting-card--details--date">
            {meetingDate.toLocaleDateString(i18n.language, {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
            })}
            {' • '}
            {meetingTime}
          </div>
        </div>
      )}

      {(isToday || isTomorrow) && (
        <div className="meeting-card--details">
          <div className="meeting-card--details--header">{meetingTime}</div>

          {isMeeting(meeting) && meeting.title && (
            <div className="meeting-card--details--title">{meeting.title}</div>
          )}

          {!isMeeting(meeting) && (
            <div className="meeting-card--details--title">
              {isRemoteCLMSession(meeting) ? t('presentation') : title}
            </div>
          )}

          {connection.displayName && (
            <div className="meeting-card--details--name">
              {connection.displayName}
            </div>
          )}
        </div>
      )}

      <div className="meeting-card--icon">
        {(isMeeting(meeting) && dayjs(meetingStart).isToday()) ||
        remoteCLMSessionWithinBounds ? (
          <Button
            size="sm"
            iconFront={
              isMeeting(meeting) ? (
                <MeetingsIcon />
              ) : (
                <img src={PresentationIcon} />
              )
            }
            onClick={joinMeeting}
          >
            {t('join')}
          </Button>
        ) : (
          generateIcon()
        )}
      </div>
    </div>
  )
}

export default MeetingCard
