import { Connection } from '@web/_types'
import _ from 'lodash'

interface ContactMapField {
  name: string
  map: string
  to: string
}

interface ContactMap {
  arr: string
  map: string
  fields?: ContactMapField[]
}

export const parseContacts = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any },
  single = false
): Connection | Connection[] => {
  const clonedData = JSON.parse(JSON.stringify(data))
  const dossier = clonedData.dossier

  const contactMap: ContactMap[] = [
    {
      arr: single ? 'hcpId' : 'hcpIds',
      map: 'hcps',
      fields: [{ name: 'commonHcpIds', map: 'hcps', to: 'commonHcps' }],
    },
    {
      arr: single ? 'repId' : 'repIds',
      map: 'reps',
      fields: [
        { name: 'groupId', map: 'groups', to: 'group' },
        { name: 'brandIds', map: 'brands', to: 'brands' },
        { name: 'commonHcpIds', map: 'hcps', to: 'commonHcps' },
      ],
    },
  ]
  const connectionsData: Connection[] = []

  _.each(contactMap, (cm: ContactMap) => {
    const arr = cm.arr
    const map = cm.map
    const fields = cm.fields
    if (!clonedData[arr]) return
    clonedData[arr] = _.isArray(clonedData[arr])
      ? clonedData[arr]
      : [clonedData[arr]]

    _.each(clonedData[arr], (id) => {
      const connection = dossier[map][id]

      _.each(fields, (field) => {
        const ids = connection[field.name]
        if (!ids) return

        const value = _.isArray(ids)
          ? _.map(ids, (id) => dossier[field.map][id])
          : dossier[field.map][ids]

        connection[field.to] = value
      })

      connectionsData.push(connection)
    })
  })

  return single ? connectionsData[0] : connectionsData
}
