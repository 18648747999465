import React, { useContext, useState, useEffect, useRef } from 'react'
import './EditProfile.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { genericSelectStyles } from '@web/_config/select'
import { SelectOption } from '@web/_types'
import { ConfigService, UserService } from '@web/_services'
import { AXIOS, I18N, ROUTES } from '@web/_constants'
import { AuthContext } from '@web/js/context/AuthContext'
import { AppContext } from '@web/js/context/AppContext'
import { ToastContext } from '@web/js/context/ToastContext'
import FormField from '@web/js/components/FormField'
import Select, { createFilter } from 'react-select'
import Button from '@web/js/components/Button'
import FormError from '@web/js/components/FormError'
import { buildName } from '@web/_utils/buildName'
import ProfileImage from '@web/js/components/ProfileImage'
import { useResponsive } from '@farfetch/react-context-responsive'
import axios from 'axios'
import _ from 'lodash'

interface ICountryOption {
  value: string
  label: string
}
import { useRouteGuard } from '@web/common/hooks'

const EditProfileRoute: React.FC = () => {
  const source = axios.CancelToken.source()
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const { lessThan } = useResponsive()
  const { isSurnameOrdered } = useContext(AppContext)
  const { user, setAuthenticatedUser } = useContext(AuthContext)
  const { addToast } = useContext(ToastContext)

  const [lastName, setLastName] = useState(user?.lastName || '')
  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [displayNamePlaceholder, setDisplayNamePlaceholder] = useState(
    user?.displayName || ''
  )
  const [rawDisplayName, setRawDisplayName] = useState(
    user?.rawDisplayName || ''
  )
  const [degree, setDegree] = useState(user?.degree || '')
  const [title, setTitle] = useState(user?.title || '')
  const [countryCode, setCountryCode] = useState(user?.countryCode || '')
  const [email] = useState(user?.email)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [error, setError] = useState('')

  const optionsRef = useRef<ICountryOption[]>([])
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)

  useRouteGuard(isDirty)

  //store original values for clean comparison
  const formRef = useRef({
    lastName,
    firstName,
    rawDisplayName,
    degree,
    countryCode,
    email,
    title,
  })

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: false,
    matchFrom: 'any' as 'any' | 'start' | undefined,
    stringify: (option: any) => `${option.label} ${option.value}`,
    trim: true,
  }

  useEffect(() => {
    isSurnameOrdered
      ? lastNameRef.current?.focus()
      : firstNameRef.current?.focus()

    init()

    return () => {
      source.cancel(AXIOS.canceled)
    }
  }, [])

  useEffect(() => {
    const form = formRef.current

    if (lastName === form.lastName && firstName === form.firstName) {
      setDisplayNamePlaceholder(user?.displayName as string)
    } else {
      setDisplayNamePlaceholder(
        buildName({ firstName, lastName, isSurnameOrdered })
      )
    }
  }, [firstName, lastName])

  useEffect(() => {
    const form = formRef.current

    const clean =
      lastName === form.lastName &&
      firstName === form.firstName &&
      rawDisplayName === form.rawDisplayName &&
      countryCode === form.countryCode &&
      degree === form.degree &&
      title === form.title

    const validLocation = !!countryCode
    const rawDisplayNameRequired = !!user?.rawDisplayName

    setIsDirty(!clean)
    setDisabled(
      !lastName ||
        !firstName ||
        !validLocation ||
        (rawDisplayNameRequired && !rawDisplayName) ||
        clean
    )
  }, [lastName, firstName, degree, rawDisplayName, title, countryCode])

  const init = async () => {
    try {
      const countriesResponse = await ConfigService.getCountries(source.token)
      const countriesData = countriesResponse.data.countries
      const countriesMap = _.chain(countriesData)
        .keys()
        .map((key: string) => ({ value: key, label: countriesData[key] }))
        .value()
      const locale = countriesResponse.data.locale
      const collator = new Intl.Collator(locale)
      const countries = countriesMap.sort((a, b) => {
        return collator.compare(a.label, b.label)
      })
      optionsRef.current = countries
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectCountry = (country: SelectOption) => {
    setCountryCode(country.value)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    try {
      const updateUserResponse = await UserService.updateUser({
        firstName,
        lastName,
        countryCode,
        title,
        degree,
        ...(!!rawDisplayName && { displayName: rawDisplayName }),
      })
      setAuthenticatedUser(updateUserResponse.data)
      addToast(t('profileUpdatedSuccessfully'), 'success', true, 5000)
      setIsDirty(false)
      goToProfile()
    } catch (error) {
      console.log(error)
      setError(t('errorGeneric'))
    }
  }

  const goToProfile = () => {
    navigate(ROUTES.profile, { replace: true })
  }

  if (!user || isLoading) return null

  return (
    <div id="edit-profile-route">
      {lessThan.sm && (
        <div id="edit-profile--page-title" className="text-large-semibold">
          {t('editMyProfile')}
        </div>
      )}

      <div id="profile-image-column">
        <ProfileImage />
      </div>

      <div id="profile-form-column">
        {!lessThan.sm && (
          <div id="edit-profile--page-title" className="text-large-semibold">
            {t('editMyProfile')}
          </div>
        )}

        <form id="profile-form" noValidate onSubmit={handleSubmit}>
          <div id="public-profile">
            <div className="text-headline-border">{t('publicProfile')}</div>

            <div id="ordered-inputs">
              <FormField
                label={t('firstName')}
                forRef={firstNameRef}
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                order={isSurnameOrdered ? 2 : 1}
              />

              <FormField
                label={t('lastName')}
                forRef={lastNameRef}
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
                order={isSurnameOrdered ? 1 : 2}
              />
            </div>

            <FormField
              label={t('displayName')}
              name="rawDisplayName"
              value={rawDisplayName}
              onChange={(e) => setRawDisplayName(e.currentTarget.value)}
              {...(!user.rawDisplayName && {
                placeholder: displayNamePlaceholder,
              })}
            />

            <FormField
              label={t('jobTitle')}
              name="title"
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />

            <FormField
              label={t('degree')}
              name="degree"
              value={degree}
              onChange={(e) => setDegree(e.currentTarget.value)}
            />
          </div>

          <div id="private-profile">
            <div className="text-headline-border">{t('privateProfile')}</div>
            <p className="disclaimer">{t('privateProfileDisclaimer')}</p>

            <FormField
              label={t('email')}
              name="email"
              value={email}
              disabled={true}
            />

            <div className="form-control">
              <label className="label-default-semibold">{t('country')}</label>
              <Select
                className="react-select"
                options={optionsRef.current}
                value={optionsRef.current.find(
                  (country) => country.value === countryCode
                )}
                styles={genericSelectStyles}
                filterOption={createFilter(filterConfig)}
                onChange={handleSelectCountry}
                placeholder={t('searchCountries')}
              />
            </div>

            <FormError text={error} />

            <div id="edit-profile--actions">
              <Button
                outline={true}
                style="primary"
                size="xl"
                onClick={goToProfile}
              >
                {t('cancel')}
              </Button>
              <Button
                style="primary"
                size="xl"
                type="submit"
                disabled={disabled}
              >
                {t('saveChanges')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditProfileRoute
