import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from 'react'
import { io, Socket } from 'socket.io-client'
import {
  LOCALSTORAGE,
  SOCKET_EVENT,
  SOCKET_EVENT_DISCONNECT_REASON,
} from '@web/_constants'
import { AuthService } from '@web/_services'
import { useLocalStorage } from '@web/common/hooks'
import { Token } from '@web/_types'

type EventCallback = (event: Record<string, unknown>) => void

export type ISocketContext = {
  connected: boolean
  socket: Socket | null
}

export type ISocketProvider = {
  children?: ReactNode
}

export const SocketContext = createContext<ISocketContext>({
  connected: false,
  socket: null,
})

export const SocketProvider: React.FC<ISocketProvider> = ({ children }) => {
  const [connected, setConnected] = useState<boolean>(false)
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const [subscriptions, setSubscriptions] = useState<Array<EventCallback>>([])
  const [socket, setSocket] = useState<Socket | null>(null)

  const subscriptionsRef = useRef(subscriptions)
  const socketRef = useRef<Socket | null>(null)
  const socketTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [accessToken, setAccessToken] = useLocalStorage<Token | null>(
    LOCALSTORAGE.accessToken,
    null
  )

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) resetSocket()
    })

    return () => {
      if (socketTimerRef.current) clearTimeout(socketTimerRef.current)
      if (socketRef.current) socketRef.current.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!socket) connect()
  }, [accessToken])

  useEffect(() => {
    if (connected && !subscribed) console.log('socket connected')
    if (subscribed) console.log('user subscribed')
  }, [connected, subscribed])

  useEffect(() => {
    socketRef.current = socket
  }, [socket])

  useEffect(() => {
    subscriptionsRef.current = subscriptions
  }, [subscriptions])

  const connect = () => {
    if (socketRef.current) return
    const rawSocket = io({ transports: ['websocket'], reconnection: false })

    rawSocket.on(SOCKET_EVENT.connect, () => {
      setConnected(true)
      if (!subscribed) joinUser(rawSocket)
      if (socketTimerRef.current) clearTimeout(socketTimerRef.current)
    })

    rawSocket.on(SOCKET_EVENT.connectError, () => {
      console.log('connection failed')
      reconnect()
    })

    rawSocket.on(SOCKET_EVENT.disconnect, (reason) => disconnect(reason))

    rawSocket.on(SOCKET_EVENT.joinuser, ({ joined }) => {
      if (joined) {
        console.log('subscribing user')
        setSubscribed(true)
      } else {
        console.log('resetting socket')
        resetSocket()
      }
    })

    rawSocket.connect()
    setSocket(rawSocket)
  }

  const joinUser = (rawSocket: Socket) => {
    rawSocket.emit(SOCKET_EVENT.joinuser, { token: accessToken })
  }

  const resetSocket = async () => {
    if (socketRef.current) socketRef.current.disconnect()
    setConnected(false)
    setSubscribed(false)
    setSocket(null)

    const refreshToken = JSON.parse(
      localStorage.getItem(LOCALSTORAGE.refreshToken) || 'null'
    )

    const clientSecret = JSON.parse(
      localStorage.getItem(LOCALSTORAGE.clientSecret) || 'null'
    )

    const refreshAccessTokenResponse = await AuthService.refreshAccessToken(
      refreshToken.token,
      clientSecret
    )

    if (refreshAccessTokenResponse) {
      const accessToken = refreshAccessTokenResponse.headers['x-myveeva-auth']
      setAccessToken(accessToken)
      console.log('access token refreshed (socket)')
    }
  }

  const reconnect = () => {
    socketTimerRef.current = setTimeout(() => {
      console.log('attempting to reconnect')
      socketRef.current?.connect()
    }, 3000)
  }

  const disconnect = (reason?: string) => {
    setConnected(false)
    setSubscriptions([])

    if (reason === SOCKET_EVENT_DISCONNECT_REASON.ioClientDisconnect) {
      setSubscribed(false)
      setSocket(null)
    } else {
      console.log('socket disconnected', reason)
      reconnect()
    }
  }

  const contextValue = useMemo(
    () => ({
      connected,
      socket,
    }),
    [connected, subscribed, socket]
  )

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  )
}
