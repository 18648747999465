import React, { ReactNode } from 'react'
import './OfficeCard.scss'
import { Office } from '@web/_types'
import OfficeIcon from '@web/common/img/OfficeIcon.svg'
import { useTranslation } from 'react-i18next'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'
import { I18N, LOGGING, PREV_PATH, ROUTES } from '@web/_constants'
import _ from 'lodash'
import StarIcon from '@mui/icons-material/Star'
import { classNames } from '@web/_utils'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'

interface OfficeCardProps {
  office: Office
  hcpId?: string
  children?: ReactNode
  contactCount?: number //providing contactCount triggers suggested layout
}

const OfficeCard: React.FC<OfficeCardProps> = ({
  office,
  contactCount,
  hcpId,
  children,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const isHomeRoute = matchRoutes([{ path: ROUTES.home }], location)

  const cx = {
    'office-card': true,
    suggested: !_.isUndefined(contactCount),
  }

  const officeCardClasses = classNames(cx)

  const goToOffice = () => {
    if (hcpId) {
      const eventMessage = {
        profileId: hcpId,
        actionType: LOGGING.ACTION_TYPE.CLICK_OFFICE,
        referenceId: office.id,
      }

      dispatch(
        createLog(
          LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
          JSON.stringify(eventMessage)
        )
      )
    }

    navigate(`${ROUTES.office}/${office.id}`, {
      state: { prevPath: isHomeRoute ? PREV_PATH.home : PREV_PATH.connections },
    })
  }

  return (
    <div className={officeCardClasses} onClick={goToOffice}>
      <div className="office-card--icon">
        <img src={OfficeIcon} alt="" />
      </div>

      <div className="office-card--details">
        <div className="office-card--details--name">{office.name}</div>
        {!_.isUndefined(contactCount) && (
          <div className="office-card--details--connections">
            <div className="office-card--details--connections--status">
              <StarIcon />
            </div>

            <span>{t('connectionsCount', { count: contactCount })}</span>
          </div>
        )}

        {children}
      </div>
    </div>
  )
}

export default OfficeCard
