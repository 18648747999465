import { v4 as uuidv4 } from 'uuid'
import axios, { AxiosResponse } from 'axios'
import {
  EngageApiResponse,
  EngageAttendeeDetails,
  EngageMeetingDetails,
  EngageMeetingToken,
  EngageMessageToken,
  ExperienceRating,
  FeedbackInfo,
  MeetingActivitiesLog,
  MeetingEvent,
  MeetingInstanceLog,
} from '@web/_types/engageApi'
import { EngageHostService } from './EngageHostService'
import Config from '@web/_config'
import { ENGAGE_SERVER_URL_HEADER } from '@web/_constants'

type GetSurveyUrlBody = {
  accountId: string
  userId: string
  meetingId: string
  country: string | null
}

type Headers = {
  authorization?: string
  attendeeId?: string
  clientUuid?: string
  tenantId?: string
}

type WrapEngage = {
  href: string
  token?: string
  attendeeId?: string
}

const headers = ({
  authorization,
  attendeeId,
  clientUuid,
  tenantId,
}: Headers): Record<string, string> => {
  return {
    Accept: 'application/json',
    'X-Source-Tag': uuidv4(),
    external: 'true',
    ...(!!authorization && {
      Authorization: `Bearer ${authorization}`,
    }),
    ...(!!attendeeId && {
      'Attendee-Id': attendeeId,
    }),
    ...(!!tenantId && {
      'Tenant-Id': tenantId,
    }),
    ...(!!clientUuid && {
      'client-uuid': clientUuid,
    }),
  }
}

let engageServerUrl: string | undefined = undefined

export const EngageService = {
  getEngageServerUrl: (): string => {
    return engageServerUrl ?? EngageHostService.getEngageApiBase() ?? Config.ENGAGE_API_BASE
  },

  setEngageServerUrl: (serverUrl: string): void => {
    engageServerUrl = serverUrl
  },

  _wrapEngage: async <T>({
    href,
    token,
    attendeeId,
  }: WrapEngage): Promise<EngageApiResponse<T>> => {
    const ENGAGE_API_HOST = EngageHostService.getEngageApiBase()
    const resp = await axios.get(`${ENGAGE_API_HOST}${href}`, {
      headers: headers({
        ...(!!token && {
          authorization: token,
        }),
        ...(!!attendeeId && {
          attendeeId,
        }),
      }),
    })

    if (resp.headers && resp.headers[ENGAGE_SERVER_URL_HEADER]) {
      const engageServerUrl = resp.headers[ENGAGE_SERVER_URL_HEADER]
      if (engageServerUrl) EngageService.setEngageServerUrl(engageServerUrl)
    }
    return resp.data
  },

  getMeetingToken: async (meetingId: string): Promise<EngageMeetingToken> => {
    const resp = await EngageService._wrapEngage<EngageMeetingToken>({
      href: `/api/v3/engage/engage-meeting/ids/${meetingId}/tokens`,
      attendeeId: meetingId,
    })
    return resp.data
  },

  getAttendeeDetails: async (
    meetingId: string,
    pwd: string,
    token: string
  ): Promise<EngageAttendeeDetails> => {
    const resp = await EngageService._wrapEngage<EngageAttendeeDetails>({
      href: `/api/v1/engage/engage-meeting/attendee-details?attendeeId=${meetingId}&pwd=${pwd}`,
      attendeeId: meetingId,
      token,
    })
    return resp.data
  },

  getMeetingDetails: async (
    meetingId: string
  ): Promise<EngageMeetingDetails> => {
    const resp = await EngageService._wrapEngage<EngageMeetingDetails>({
      href: `/api/v1/ids/${meetingId}/details`,
      attendeeId: meetingId,
    })
    return resp.data
  },

  getMessageToken: async (meetingId: string): Promise<string> => {
    const resp = await EngageService._wrapEngage<EngageMessageToken>({
      href: '/api/v1/hcp-message/tokens',
      attendeeId: meetingId,
    })
    return resp.data.token
  },

  getIntegratedSurvey: async (
    tenantId: string,
    meetingType: string,
    data: GetSurveyUrlBody,
    token: string,
    signal: AbortSignal
  ): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      headers: headers({ authorization: token, tenantId }),
      signal,
      data,
      url: `${EngageHostService.getEngageApiBase()}/api/v1/engage/engage-meeting/orgs/${tenantId}/surveys?meetingType=${meetingType}`,
    })
  },

  getMeetingInfoWithAttendee: async (
    meetingId: string,
    pwd: string
  ): Promise<
    [EngageMeetingDetails, EngageMeetingToken, EngageAttendeeDetails]
  > => {
    const details = await EngageService.getMeetingDetails(meetingId)
    const token = await EngageService.getMeetingToken(meetingId)
    const attendee = await EngageService.getAttendeeDetails(
      meetingId,
      pwd,
      token.token
    )
    return [details, token, attendee]
  },

  getMeetingInfo: async (
    meetingId: string
  ): Promise<[EngageMeetingDetails, EngageMeetingToken]> => {
    const details = await EngageService.getMeetingDetails(meetingId)
    const token = await EngageService.getMeetingToken(meetingId)
    return [details, token]
  },

  logClientEvent: async (event: MeetingEvent, token: string): Promise<void> => {
    return axios.post(
      `${EngageHostService.getEngageApiBase()}/api/v1/engage/client-events`,
      event,
      {
        headers: headers({
          authorization: token,
          attendeeId: event.engage_meeting_id,
        }),
      }
    )
  },

  logMeetingActivity: async (
    logData: MeetingActivitiesLog,
    meetingUuid: string | null,
    clientUuid: string,
    tenantId: string,
    token: string
  ): Promise<void> => {
    return axios.post(
      `${EngageHostService.getEngageApiBase()}/api/v2/engage/engage-meeting/meetings/${meetingUuid}/meeting-activities`,
      logData,
      {
        headers: headers({ authorization: token, clientUuid, tenantId }),
      }
    )
  },

  logMeetingInstance: async (
    logData: MeetingInstanceLog,
    meetingUuid: string | null,
    clientUuid: string,
    tenantId: string,
    token: string
  ): Promise<void> => {
    return axios.post(
      `${EngageHostService.getEngageApiBase()}/api/v2/engage/engage-meeting/meetings/${meetingUuid}/instances`,
      logData,
      {
        headers: headers({ authorization: token, clientUuid, tenantId }),
      }
    )
  },

  getFeedback: async (
    meetingId: string,
    token: string,
    language: string
  ): Promise<FeedbackInfo> => {
    const resp = await EngageService._wrapEngage<FeedbackInfo>({
      href: `/api/v1/hcp/questionnaires?q=language:${language}`,
      token,
      attendeeId: meetingId,
    })
    return resp.data
  },

  submitFeedback: async (
    participantId: string | number,
    token: string,
    experienceRating: ExperienceRating,
    meetingId: string
  ): Promise<void> => {
    return axios.post(
      `${EngageHostService.getEngageApiBase()}/api/v1/hcp/attendees/${participantId}/answers`,
      experienceRating,
      {
        headers: headers({
          authorization: token,
          attendeeId: meetingId,
        }),
      }
    )
  },
}
