import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, matchRoutes } from 'react-router-dom'
import './Nav.scss'
import { useTranslation } from 'react-i18next'
import { useResponsive } from '@farfetch/react-context-responsive'
import { I18N, ROUTES, COLORS, PREV_PATH } from '@web/_constants'
import _ from 'lodash'
import { ChatContext } from '@web/js/context/ChatContext'
import CameraIconSolid from '@web/common/img/CameraIconSolid'
import ChatIconNav from '@web/common/img/ChatIconNav'
import ConnectionsIcon from '@web/common/img/ConnectionsIcon'
import HomeIcon from '@web/common/img/HomeIcon'
import InviteIcon from '@web/common/img/InviteIcon'
import MeetingsIcon from '@web/common/img/MeetingsIcon'

const Nav: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { unreadMessagesCount } = useContext(ChatContext)
  const [prevPath, setPrevPath] = useState<string | null>(null)
  const location = useLocation()
  const { lessThan } = useResponsive()
  const homeRoutes = [{ path: ROUTES.home }]
  const connectionRoutes = [
    { path: ROUTES.hcpId },
    { path: ROUTES.repId },
    { path: ROUTES.connections },
    { path: ROUTES.connectionsId },
    { path: ROUTES.brandId },
    { path: ROUTES.brandIdSlug },
  ]

  useEffect(() => {
    //Keep parent route highlighted for offices
    if (location.state && location.state.prevPath) {
      setPrevPath(location.state.prevPath)
    } else if (!_.isNull(prevPath)) {
      setPrevPath(null)
    }
  }, [location])

  const homeMatch = matchRoutes(homeRoutes, location)
  const connectionsMatch = matchRoutes(connectionRoutes, location)

  return (
    <nav id="main-menu">
      <NavLink
        to={ROUTES.home}
        className={() =>
          !!homeMatch || prevPath === PREV_PATH.home ? 'active' : ''
        }
      >
        <div>
          <HomeIcon />
        </div>
        <div>{t('home')}</div>
      </NavLink>
      <NavLink to={ROUTES.chat}>
        <div>
          {!!unreadMessagesCount && <div className="dot" />}
          <ChatIconNav />
        </div>
        <div>{t('chat')}</div>
      </NavLink>
      <NavLink to={ROUTES.meetings}>
        <div>
          <MeetingsIcon />
        </div>
        <div>{t('meetings')}</div>
      </NavLink>
      <NavLink
        className={() =>
          !!connectionsMatch || prevPath === PREV_PATH.connections
            ? 'active'
            : ''
        }
        to={ROUTES.connections}
      >
        <div>
          <ConnectionsIcon />
        </div>
        <div>{t('connections')}</div>
      </NavLink>
      {lessThan.md && (
        <>
          <NavLink to={ROUTES.invite}>
            <div className="invite-coworkers--icon">
              <InviteIcon color={COLORS.white} />
            </div>
            <div>{t('invite')}</div>
          </NavLink>
          <NavLink to={ROUTES.joinMeeting}>
            <div className="join-meeting-icon">
              <CameraIconSolid color={COLORS.white} />
            </div>
            <div>{t('joinMeeting')}</div>
          </NavLink>
        </>
      )}
    </nav>
  )
}

export default Nav
