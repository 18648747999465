import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ClientIdService } from '@web/_services/ClientIdService'
import { EngageService } from '@web/_services/EngageService'
import { EngageMeetingInfo } from '@web/_types/engageApi'

export type IEngageMeetingContext = {
  participantId: string
  password: string
  displayName: string
  clientId: string
  info: EngageMeetingInfo | null
  error: Error | null
  getToken: () => Promise<string>
}

export const EngageMeetingContext = createContext<IEngageMeetingContext>({
  participantId: '',
  password: '',
  displayName: '',
  clientId: '',
  info: null,
  error: null,
  getToken: () => Promise.reject(),
})

type EngageContextProps = {
  participantId: string
  password: string
  displayName: string
  children?: React.ReactNode
}

type CachedToken = {
  token: string
  issued: number
}

const TOKEN_TIMEOUT = 1000 * 60 * 8 // 8 minutes in ms

export const EngageMeetingProvider: React.FC<EngageContextProps> = ({
  participantId,
  password,
  displayName,
  children,
}) => {
  const clientId = ClientIdService.getClientId()

  const [info, setInfo] = useState<EngageMeetingInfo | null>(null)
  const [cachedToken, setCachedToken] = useState<CachedToken>({
    token: '',
    issued: 0,
  })
  const [loadingError, setLoadingError] = useState<Error | null>(null)

  const getMeetingInfo = useCallback(async (): Promise<EngageMeetingInfo> => {
    if (info) return info
    try {
      const now = Date.now()
      const [details, token, attendee] =
        await EngageService.getMeetingInfoWithAttendee(participantId, password)
      const newInfo = { token, details, attendee }
      setCachedToken({ token: token.token, issued: now })
      setInfo(newInfo)
      return newInfo
    } catch (e) {
      setLoadingError(e)
      throw e
    }
  }, [info])

  const getToken = useCallback(async (): Promise<string> => {
    const { token, issued } = cachedToken
    const now = Date.now()
    if (now - issued > TOKEN_TIMEOUT) {
      const { token: newToken } = await EngageService.getMeetingToken(
        participantId
      )
      setCachedToken({ token: newToken, issued: now })
      return newToken
    } else {
      return token
    }
  }, [cachedToken])

  const contextValue = useMemo<IEngageMeetingContext>(
    () => ({
      participantId,
      password,
      displayName,
      clientId,
      getToken,
      info,
      error: loadingError,
    }),
    [getToken, getMeetingInfo, info, loadingError]
  )

  useEffect(() => {
    getMeetingInfo().then()
  }, [])

  return (
    <EngageMeetingContext.Provider value={contextValue}>
      {children}
    </EngageMeetingContext.Provider>
  )
}
