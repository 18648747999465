import React, { ReactNode, useContext, MouseEventHandler } from 'react'
import './AuthenticationLayout.scss'
import { useResponsive, useIsMobile } from '@farfetch/react-context-responsive'
import { classNames } from '@web/_utils'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { I18N, SUPPORT_EMAIL } from '@web/_constants'
import LoadingIndicator from '@web/js/components/LoadingIndicator'
import { useNavigate } from 'react-router-dom'
import VeevaLogo_Greyscale from '@web/common/img/VeevaLogo_Greyscale.svg'
import EngageLogo from '@web/common/img/Engage_Logo.svg'
import LoginBackground from '@web/common/img/Login_Background.jpg'
import { AppContext } from '@web/js/context/AppContext'

export interface AuthenticationLayoutProps {
  logoClickRedirect?: string | null
  hideConnectionLabel?: boolean
  showLoadingState?: boolean
  children?: ReactNode
}

const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = ({
  logoClickRedirect = null,
  hideConnectionLabel = false,
  showLoadingState = false,
  children,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { mediaType, lessThan, greaterThan } = useResponsive()
  const { config, appLoading } = useContext(AppContext)
  const { isMobile } = useIsMobile()
  const navigate = useNavigate()

  const cx = {
    [mediaType as string]: mediaType !== '_initial',
    xs: lessThan.sm,
    mobile: isMobile,
  }

  const authenticationLayoutClasses = classNames(cx)

  const clickRedirect: MouseEventHandler = () => {
    logoClickRedirect ? navigate(logoClickRedirect) : null
  }

  return (
    <main
      id="auth-layout"
      className={authenticationLayoutClasses}
      style={{ backgroundImage: lessThan.md ? `url(${LoginBackground})` : '' }}
    >
      {greaterThan.sm && <div className="auth-layout--header" />}
      <div className="auth-layout--content">
        <div className="auth-layout--content--form">
          {lessThan.md && <div className="auth-layout--header" />}
          <div className="auth-layout--logo">
            <img
              onClick={clickRedirect}
              className={`logo ${logoClickRedirect ? 'clickable' : ''}`}
              src={EngageLogo}
            />
            {!appLoading && !hideConnectionLabel && (
              <div className="brand-tag">{t('yourConnection')}</div>
            )}
          </div>

          {!appLoading && !showLoadingState && children}

          {(appLoading || showLoadingState) && <LoadingIndicator />}

          {!appLoading && (
            <div className="auth-layout--footer">
              <img className="veeva_logo" src={VeevaLogo_Greyscale} />
              <div className="copyright">
                {t('copyright', { year: dayjs().get('year') })}
              </div>
              <div className="links">
                <a href={config?.privacyUrl} target="_blank">
                  {t('privacyPolicy')}
                </a>
                <a href={config?.termsUrl} target="_blank">
                  {t('termsOfUse')}
                </a>
                <a href={`mailto:${SUPPORT_EMAIL}`}>{t('help')}</a>
              </div>
            </div>
          )}
        </div>
        {greaterThan.sm && (
          <div
            className="auth-layout--background"
            style={{ backgroundImage: `url(${LoginBackground})` }}
          />
        )}
      </div>
    </main>
  )
}

export default AuthenticationLayout
