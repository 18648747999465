import React, { useEffect, useState, useRef, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './Brand.scss'
import {
  Brand,
  BrandResource,
  BrandResourceCategory,
  RoleCategory,
  Connection,
  BrandContacts,
} from '@web/_types'
import { BrandService } from '@web/_services'
import { useTranslation } from 'react-i18next'
import {
  classNames,
  formatNonProprietaryName,
  parseDossier,
  toCamelCase,
} from '@web/_utils'
import _ from 'lodash'
import { useResponsive } from '@farfetch/react-context-responsive'
import { hasProfile, isRep } from '@web/_guards'

//Constants
import {
  I18N,
  RESOURCE_CATEGORIES,
  ROLES,
  ROUTES,
  ROLE_CATEGORY_LIST,
  LOGGING,
  MODALS,
} from '@web/_constants'

//Icons
import BrandIcon from '@web/common/img/BrandIcon'
import Chevron from '@web/common/img/Chevron.svg'
import DocumentIcon from '@web/common/img/DocumentIcon'
import MedicalInfoIcon from '@web/common/img/MedicalInfoIcon'
import PatientHubIcon from '@web/common/img/PatientHubIcon'
import WebsiteIcon from '@web/common/img/WebsiteIcon'

//Components
import NotFound from '@web/js/routes/NotFound'
import Button from '@web/js/components/Button'
import Sidebar from '@web/js/components/Sidebar'
import ContactCard from '@web/js/components/ContactCard'
import BrandResourceCard from '@web/js/components/BrandResourceCard'
import BrandResourceDetails from '@web/js/components/BrandResourceDetails'
import DetailsHeader from '@web/js/components/DetailsHeader'
import Image from '@web/js/components/Image'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import BrandCard from '@web/js/components/BrandCard'
import { ModalContext } from '@web/js/context/ModalContext'

interface RouterParams {
  id: string
  slug: string
}

type BrandResources = {
  [category in BrandResourceCategory]: BrandResource[]
}

const contactUsLimit = 3

const BrandRoute: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lessThan } = useResponsive()
  const { id, slug } = useParams<keyof RouterParams>() as RouterParams

  const { showModal } = useContext(ModalContext)

  const brandRouteRef = useRef<HTMLDivElement>(null)
  const [brand, setBrand] = useState<Brand | undefined>(undefined)
  const [shareBrandResource, setShareBrandResource] =
    useState<BrandResource | null>(null)
  const [sidebarWidth, setSidebarWidth] = useState<number>(0)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [viewAll, setViewAll] = useState(false)
  const [contacts, setContacts] = useState<BrandContacts>({})
  const [activeResourceCategory, setActiveResourceCategory] =
    useState<BrandResourceCategory | null>(null)
  const [resources, setResources] = useState<BrandResources>(
    {} as BrandResources
  )
  const [contactUsResources, setContactUsResources] = useState<BrandResource[]>(
    []
  )

  useEffect(() => {
    init()
    window.addEventListener('resize', setUpLayout)
    return () => {
      window.removeEventListener('resize', setUpLayout)
    }
  }, [])

  useEffect(() => {
    if (activeResourceCategory && !open) setOpen(true)
  }, [activeResourceCategory])

  useEffect(() => {
    if (shareBrandResource) {
      showModal({
        name: MODALS.SHARE_BRAND_RESOURCE_MODAL,
        data: {
          brand,
          resource: shareBrandResource,
          clearBrandResource: () => setShareBrandResource(null),
        },
      })
    }
  }, [shareBrandResource])

  useEffect(() => {
    const resource = slug ? RESOURCE_CATEGORIES[slug] : null

    if (resource) {
      setActiveResourceCategory(resource as BrandResourceCategory)
      if (!open) setOpen(true)
    } else {
      if (open) setOpen(false)
    }
  }, [slug])

  const setUpLayout = () => {
    const contentBodyEl = document.getElementById('content-body')
    if (!brandRouteRef.current || !contentBodyEl) return

    const contentBodyWidth = contentBodyEl.offsetWidth
    const brandRouteWidth = brandRouteRef.current.clientWidth
    const scrollBarWidth = contentBodyWidth - brandRouteWidth
    const width = brandRouteWidth / 2 + scrollBarWidth

    if (sidebarWidth !== width) setSidebarWidth(width)
  }

  const init = async () => {
    try {
      const getBrandResponse = await BrandService.getBrand(id)
      const rawData = getBrandResponse.data
      const parsedDossier = parseDossier(getBrandResponse.data)
      const brand = parsedDossier.brands[rawData.brandId]
      setBrand(brand)

      const resources = parsedDossier.resources
      const contactUsResources = resources.CONTACT_US
      setResources(_.omit(resources, ['CONTACT_US']) as BrandResources)
      if (contactUsResources) setContactUsResources(contactUsResources)

      let contacts = parsedDossier.contacts
      if (contacts['KEY_ACCOUNT_MANAGER']) {
        contacts['SALES'] = [
          ...(contacts['SALES'] || []),
          ...contacts['KEY_ACCOUNT_MANAGER'],
        ]
        contacts = _.omit(contacts, 'KEY_ACCOUNT_MANAGER')
      }

      setContacts(contacts)
      setIsLoading(false)
      setUpLayout()
    } catch (error) {
      setIsLoading(false)
    }
  }

  const goToHCPWebsite = () => {
    if (brand?.website) {
      logBrandEvent(LOGGING.ACTION_TYPE.WEBSITE, id)
      window.open(brand.website)
    }
  }

  const handleResourceSelect = (resource: BrandResourceCategory) => {
    const resourceSlug = resourceToSlug(resource)

    if (slug === resourceSlug) {
      navigate(`${ROUTES.brand}/${id}`, { replace: true })
    } else {
      logBrandEvent(LOGGING.ACTION_TYPE[resource], id)
      navigate(`${ROUTES.brand}/${id}/${resourceSlug}`, { replace: true })
    }
  }

  const resourceToSlug = (resource: string): string => {
    return resource.replace('_', '').toLowerCase()
  }

  const handleSidebarClose = (): void => {
    navigate(`${ROUTES.brand}/${id}`, { replace: true })
  }

  const getCategoryIcon = (resource: string) => {
    let Icon
    switch (resource) {
      case 'PATIENT_HUB':
        Icon = PatientHubIcon
        break
      case 'MEDINFO':
        Icon = MedicalInfoIcon
        break
      default:
        Icon = DocumentIcon
        break
    }

    return <Icon color={brand?.primaryColor} />
  }

  const goToContact = (contact: Connection) => {
    const path = isRep(contact) ? ROLES.rep : ROLES.hcp

    logBrandEvent(
      LOGGING.ACTION_TYPE.REP_PROFILE,
      contact.id,
      LOGGING.ACTION_CATEGORY.CONTACTS
    )

    const eventMessage = {
      profileId: contact.id,
      actionType: LOGGING.ACTION_TYPE.VIEW_PROFILE,
      actionDetail: LOGGING.ACTION_DETAIL.BRAND_PROFILE_CONTACTS,
      referenceId: brand?.id,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )

    return navigate(`/${path.toLowerCase()}/${contact?.id}`)
  }

  const logBrandEvent = (
    actionType: string,
    referenceId: string,
    actionCategory: string = LOGGING.ACTION_CATEGORY.BRAND_PROFILE
  ) => {
    const eventMessage = {
      brandId: id,
      actionCategory,
      actionType,
      referenceId,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.BRAND_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  if (isLoading) return null
  if (!brand) return <NotFound />

  const cx = {
    collapsed: lessThan.md,
  }
  const brandRouteClasses = classNames(cx)

  return (
    <div id="brand-route" className={brandRouteClasses} ref={brandRouteRef}>
      <DetailsHeader
        id="brand-header"
        primaryColor={brand.primaryColor as string}
        secondaryColor={brand.secondaryColor as string}
      >
        <>
          <div className="brand-header--icon-wrapper">
            <div className="brand-header--icon">
              <BrandIcon color={brand.primaryColor} />
            </div>
          </div>

          <div className="brand-header--info">
            <div className="brand-header--info-main">
              <h1 className="brand-title">{brand.name}</h1>
              {brand.nonProprietaryName && (
                <h2 className="brand-nonprop">
                  {formatNonProprietaryName(brand.nonProprietaryName)}
                </h2>
              )}
              {brand.website && (
                <Button
                  size="sm"
                  outline={true}
                  iconFront={<WebsiteIcon />}
                  onClick={goToHCPWebsite}
                >
                  {t('visitHCPWebsite')}
                </Button>
              )}
            </div>

            {brand.brandCompanyLogo && (
              <div className="brand-logo">
                <Image src={brand.brandCompanyLogo} alt="Brand Logo" />
              </div>
            )}
          </div>
        </>
      </DetailsHeader>

      <div className="brand-body row">
        <div className="brand-body--info-col col-xs-8 col-md-4">
          {(!!brand.regStatementHtml || !!brand.regLinks.length) && (
            <div className="regulatory-info">
              <BrandCard brand={brand} category="BRAND_PROFILE" />
            </div>
          )}

          {!_.without(_.keys(resources), 'MEDINFO', 'PATIENT_HUB').length && (
            <div className="brand-body--empty">{t('noResourcesAvailable')}</div>
          )}

          {!!_.keys(resources).length && (
            <div className="brand-body--resources">
              {_.map(_.keys(resources), (resource, i) => {
                const showSubtitle = _.includes(
                  ['MEDINFO', 'PATIENT_HUB'],
                  resource
                )

                return (
                  <div
                    className={`brand-body--resource ${
                      resourceToSlug(resource) === slug ? 'active' : ''
                    }`}
                    key={i}
                    onClick={() =>
                      handleResourceSelect(resource as BrandResourceCategory)
                    }
                  >
                    <div className="brand-body--resource--icon">
                      {getCategoryIcon(resource)}
                    </div>
                    <div className="brand-body--resource--name">
                      <span className="text-default-medium">
                        {t(`resourceType.${toCamelCase(resource)}`)}
                      </span>
                      {showSubtitle && (
                        <span className="text-small">
                          {t(`resourceType.${toCamelCase(resource)}_subTitle`)}
                        </span>
                      )}
                    </div>
                    <img
                      src={Chevron}
                      className="brand-body--resource--caret"
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>

        <div className="brand-body--contact-col col-xs-8 col-md-4">
          {brand && !!contactUsResources.length && (
            <div className="container contact-us">
              <div className="text-headline">{t('contactUs')}</div>
              {_.map(
                contactUsResources.slice(
                  0,
                  viewAll ? contactUsResources.length : contactUsLimit
                ),
                (resource) => (
                  <BrandResourceCard
                    setShareBrandResource={setShareBrandResource}
                    brandId={brand.id}
                    actionCategory={LOGGING.ACTION_TYPE.CONTACT_US}
                    resource={resource}
                    key={resource.id}
                  />
                )
              )}

              {!viewAll && contactUsResources.length > contactUsLimit && (
                <div className="view-more">
                  <Button
                    size="sm"
                    outline={true}
                    onClick={() => setViewAll(true)}
                  >
                    {t('viewNMore', {
                      n: contactUsResources.length - contactUsLimit,
                    })}
                  </Button>
                </div>
              )}
            </div>
          )}

          <div className="container contacts">
            <div className="text-headline">{t('contacts')}</div>
            {_.isEmpty(contacts) ? (
              <div className="text-subhead">{t('noContactsAvailable')}</div>
            ) : (
              _.map(ROLE_CATEGORY_LIST, (cat) => {
                const sortedContacts = _.orderBy(
                  contacts[cat as RoleCategory],
                  ['isContact', 'lastName', 'firstName'],
                  ['desc', 'asc', 'asc']
                )

                if (!sortedContacts.length) return null

                return (
                  <div className="contact-group" key={cat}>
                    <div className="text-small-semibold role-category">
                      {t(`roleCategory.${toCamelCase(cat)}`)}
                    </div>
                    {_.map(sortedContacts, (contact) => {
                      const clickable = hasProfile(contact)
                      const contactCx = {
                        contact: true,
                        clickable,
                      }

                      const contactClasses = classNames(contactCx)

                      return (
                        <div
                          key={contact.id}
                          className={contactClasses}
                          {...(clickable && {
                            onClick: () => goToContact(contact),
                          })}
                        >
                          <ContactCard
                            contact={contact}
                            key={contact.id}
                            showTitle={true}
                            showGroup={false}
                            showContactStatus={true}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })
            )}
          </div>
        </div>
      </div>

      <Sidebar
        side="right"
        open={open}
        closeFunc={handleSidebarClose}
        width={sidebarWidth ? sidebarWidth + 'px' : 'calc(50% - 125px)'}
        fullScreen={lessThan.md}
      >
        {!!activeResourceCategory && !!brand && (
          <BrandResourceDetails
            setShareBrandResource={setShareBrandResource}
            resources={resources[activeResourceCategory]}
            activeResourceCategory={activeResourceCategory}
            brand={brand}
            icon={getCategoryIcon(activeResourceCategory)}
          />
        )}
      </Sidebar>
    </div>
  )
}

export default BrandRoute
