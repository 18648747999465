import { ROUTES, I18N } from '@web/_constants'
import React, { useContext, useEffect, useState } from 'react'
import './Onboarding.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '@web/common/hooks'
import { LOCALSTORAGE } from '@web/_constants'
import VideoIcon from '@web/common/img/VideoIcon.svg'
import PersonIcon from '@web/common/img/PersonIcon.svg'
import ChatBubbleIcon from '@web/common/img/ChatBubbleIcon.svg'
import Button from '@web/js/components/Button'
import LoadingIndicator from '@web/js/components/LoadingIndicator'
import { AppContext } from '@web/js/context/AppContext'
import { DeeplinkContext } from '@web/js/context/DeeplinkContext'
import { ConfigType } from '@web/_types'

const Onboarding: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)
  const { config } = useContext(AppContext)
  const { actionableDeeplink } = useContext(DeeplinkContext)
  const [isLoading, setIsLoading] = useState(true)

  const [, setMeetingID] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )
  const [, setMeetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    if (actionableDeeplink?.deeplink) {
      setMeetingID(null)
      setMeetingPwd(null)
      navigate(ROUTES.login, { replace: true })
    } else {
      setIsLoading(false)
    }
  }

  if (isLoading) return <LoadingIndicator />

  if (!(config as ConfigType)?.supportsSignup) {
    navigate(ROUTES.join, { state: { redirected: true } })
    return null
  }

  return (
    <div id="onboarding-route">
      <Button
        id="join-meeting-btn"
        block={true}
        size="xl"
        onClick={() => {
          navigate(ROUTES.join)
        }}
      >
        {t('joinMeeting')}
      </Button>
      <div className="pitch">
        <label id="sign-up-label">{t('signUpForAccount')}</label>
        <div className="pitch-row">
          <img src={VideoIcon} />
          <label className="pitch-label">{t('joinMeetingFaster')}</label>
        </div>
        <div className="pitch-row">
          <img src={PersonIcon} />
          <label className="pitch-label">{t('savePharmaContacts')}</label>
        </div>
        <div className="pitch-row">
          <img src={ChatBubbleIcon} />
          <label className="pitch-label">{t('reachOut')}</label>
        </div>
      </div>
      <Button
        id="sign-up-btn"
        block={true}
        outline={true}
        size="xl"
        onClick={() => {
          navigate(ROUTES.login)
        }}
      >
        {t('signUpSignInNoColon')}
      </Button>
    </div>
  )
}

export default Onboarding
