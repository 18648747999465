import React, { ReactNode, useState } from 'react'
import './Button.scss'
import { classNames } from '@web/_utils'
import CheckIcon from '@web/common/img/CheckIcon'

type ButtonType = 'button' | 'submit' | 'reset'
type ButtonSize = 'xl' | 'lg' | 'md' | 'sm' | null
type ButtonStyle =
  | 'primary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'
  | 'link'
  | 'select'
  | 'teal'

interface ButtonProps {
  block?: boolean
  children?: ReactNode
  classes?: string
  disabled?: boolean
  icon?: React.ReactNode
  iconFront?: React.ReactNode
  id?: string
  onClick?(event: React.MouseEvent<HTMLElement>): void
  outline?: boolean
  selected?: boolean
  showCheck?: boolean
  size?: ButtonSize
  style?: ButtonStyle
  stateOnHover?: {
    outline?: boolean
    children?: string
    iconFront?: React.ReactNode
    style?: ButtonStyle
  }
  type?: ButtonType
}

const Button: React.FC<ButtonProps> = ({
  block = false,
  children,
  classes = '',
  disabled = false,
  icon,
  iconFront,
  id,
  onClick,
  outline = false,
  selected = false,
  showCheck = false,
  size = 'lg',
  style = 'primary',
  stateOnHover,
  type = 'button',
}) => {
  const [isHovering, setIsHovering] = useState(false)

  const cx = {
    btn: true,
    'btn-outline': outline || (!!stateOnHover?.outline && isHovering),
    'btn-block': block,
    disabled: disabled,
    [('btn-' + size) as string]: !!size,
    [('btn-' +
      `${
        !!stateOnHover?.style && isHovering ? stateOnHover?.style : style
      }`) as string]: true,
    'btn-icon': !!icon && !children,
    'btn-front-icon-children':
      !!children && (!!iconFront || (!!stateOnHover?.iconFront && isHovering)),
    selected: selected,
    [classes as string]: !!classes,
  }

  const buttonClasses = classNames(cx)

  return (
    <button
      {...(id && { id })}
      onClick={onClick}
      type={type}
      className={buttonClasses}
      disabled={disabled}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {(iconFront || (!!stateOnHover?.iconFront && isHovering)) &&
        (!!stateOnHover?.iconFront && isHovering
          ? stateOnHover?.iconFront
          : iconFront)}

      {selected && showCheck && <CheckIcon />}

      {(children || (isHovering && !!stateOnHover?.children)) && (
        <span>
          {isHovering && !!stateOnHover?.children
            ? stateOnHover?.children
            : children}
        </span>
      )}
      {icon && icon}
    </button>
  )
}

export default Button
