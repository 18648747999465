import * as React from 'react'
import { IconProps } from '@web/_types'

const EditIcon: React.FC<IconProps> = ({
  height = '16px',
  width = '16px',
  color = '#ffffff',
  className = 'icon-edit',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6143 1.23775C13.9313 0.92075 14.4452 0.92075 14.7622 1.23775C15.0792 1.55475 15.0792 2.06871 14.7622 2.38571L7.39034 9.75759L6.24238 8.60963L13.6143 1.23775ZM5.66713 9.18487L6.81509 10.3328L6.79423 10.3537L6.00006 10.6398C5.60195 10.7832 5.2168 10.398 5.36021 9.9999L5.64627 9.20573L5.66713 9.18487ZM1.99896 3.99896C2.15837 3.83955 2.37457 3.75 2.6 3.75H7.4C7.81421 3.75 8.15 3.41421 8.15 3C8.15 2.58579 7.81421 2.25 7.4 2.25H2.6C1.97674 2.25 1.37901 2.49759 0.938299 2.9383C0.497588 3.37901 0.25 3.97674 0.25 4.6V13.4C0.25 14.0233 0.497589 14.621 0.938299 15.0617C1.37901 15.5024 1.97674 15.75 2.6 15.75H11.4C12.0233 15.75 12.621 15.5024 13.0617 15.0617C13.5024 14.621 13.75 14.0233 13.75 13.4V8.6C13.75 8.18579 13.4142 7.85 13 7.85C12.5858 7.85 12.25 8.18579 12.25 8.6V13.4C12.25 13.6254 12.1604 13.8416 12.001 14.001C11.8416 14.1604 11.6254 14.25 11.4 14.25H2.6C2.37457 14.25 2.15837 14.1604 1.99896 14.001C1.83955 13.8416 1.75 13.6254 1.75 13.4V4.6C1.75 4.37457 1.83955 4.15837 1.99896 3.99896Z"
      fill={color}
    />
  </svg>
)

export default EditIcon
