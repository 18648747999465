import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { I18N } from '@web/_constants'
import i18nWeb from '../../../i18n/web'
import Config from '@web/_config'
import bowser from 'bowser'

export function getLang(): string {
  // Get array of languages in priority order, starting with query param
  const qLang = new URLSearchParams(window.location.search).get('lang')
  const languages = qLang ? [qLang] : []
  if (!bowser.msie) {
    navigator.languages.forEach((lang) => {
      // For Prod clusters, if the language is the test locale, default to the country-code-less variant
      if (!Config.USE_TEST_LOCALE && lang === Config.TEST_LOCALE) {
        lang = lang.split('-')[0]
      }
      // Add language and also add country-code-less variant if present (eg. es-CO -> [es-CO, es])
      languages.push(lang)
      const match = /([a-zA-Z][a-zA-Z])-([a-zA-Z][a-zA-Z])/.exec(lang)
      if (match) {
        languages.push(match[1])
      }
    })
  } else {
    let lang = navigator.language
    if (!Config.USE_TEST_LOCALE && lang === Config.TEST_LOCALE) {
      lang = lang.split('-')[0]
    }
    // Add language and also add country-code-less variant if present (eg. es-CO -> [es-CO, es])
    languages.push(lang)
    const match = /([a-zA-Z][a-zA-Z])-([a-zA-Z][a-zA-Z])/.exec(lang)
    if (match) {
      languages.push(match[1])
    }
  }

  const found = languages.find((s) => I18N.supportedLanguages.includes(s))
  return found || I18N.defaultLanguage
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: i18nWeb,
    lng: getLang(),
    fallbackLng: I18N.defaultLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '{',
      suffix: '}',
    },
  })

export default i18n
