import React from 'react'
import './RepTrap.scss'
import RepWarning from '@web/common/img/RepWarning.svg'
import EngageConnectAppIcon from '@web/common/img/EngageConnectAppIcon.svg'
import AppStoreIcon from '@web/common/img/AppStoreIcon.svg'
import PlayStoreIcon from '@web/common/img/PlayStoreIcon.svg'
import { APP_LINKS, FAQ_URL } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import { I18N, ROUTES } from '@web/_constants'
import Button from '@web/js/components/Button'
import { useNavigate } from 'react-router-dom'

const RepTrap: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)

  const navigateToJoin = () => {
    navigate(ROUTES.join)
  }

  return (
    <div id="rep-trap">
      <img src={RepWarning} />

      <p className="rep-trap-headline text-headline">
        {t('registrationIsLimited')}
      </p>

      <div className="rep-trap-subhead text-default-medium">
        {t('tryingToJoinAsAttendee')}
      </div>

      <p className="text-default-medium">{t('signInNotRequired')}</p>

      <Button block={true} size="xl" id="joinButton" onClick={navigateToJoin}>
        {t('joinMeeting')}
      </Button>

      <div className="text-default" id="faq-block">
        {t('haveQuestions')}&nbsp;
        <a href={FAQ_URL}>{t('readOurFAQ')}</a>
      </div>

      <div className="divider" />

      <img id="ec-app-icon" src={EngageConnectAppIcon} />

      <div className="text-default-medium">{t('wantToConnect')}</div>

      <p className="text-default">{t('pharmaUsersShouldGetEC')}</p>

      <div id="app-store-links">
        <a href={APP_LINKS.engageConnectAppStore}>
          <img src={AppStoreIcon} />
        </a>
        <a href={APP_LINKS.engageConnectPlayStore}>
          <img src={PlayStoreIcon} />
        </a>
      </div>
    </div>
  )
}

export default RepTrap
