import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const oapiV2 = Config.OAPI_URL_V2

export const EmailSubscriptionService = {
  getUnsubscibeInfo: (
    token: string,
    signal: AbortSignal
  ): Promise<AxiosResponse> => {
    return axios({
      method: 'get',
      baseURL: oapiV2,
      url: `/unsubscribe?token=${token}`,
      signal,
    })
  },

  unsubscribe: (
    token: string,
    unreadMessageEmail: boolean,
    signal: AbortSignal
  ): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      url: `/unsubscribe`,
      baseURL: oapiV2,
      data: {
        token,
        unreadMessageEmail,
      },
      signal,
    })
  },
}
