import React, { useContext, useEffect } from 'react'
import './MeetingEndedModal.scss'
import { useSearchParams } from 'react-router-dom'
import CameraIconSolid from '@web/common/img/CameraIconSolid'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import Timer from '@web/js/components/Timer'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

const MeetingEndedModal: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  useEffect(() => {
    if (searchParams.size) setSearchParams({}, { replace: true })
  }, [])

  return (
    <Modal closeable={false} size="sm">
      <ModalBody id="meeting-ended-modal">
        <div id="meeting-icon">
          <CameraIconSolid />
        </div>
        <div className="text-headline">{t('meetingEnded')}</div>

        <p className="text-small">
          {t('dismissingIn')}
          <Timer seconds={5} onTimeOut={hideModal} />
          ...
        </p>
      </ModalBody>

      <ModalFooter onAccept={hideModal} cancellable={false} />
    </Modal>
  )
}

export default MeetingEndedModal
