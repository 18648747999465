import { useDetectOutsideClick } from '@web/common/hooks'
import React, { useRef } from 'react'
import MoreHorizontalIcon from '@web/common/img/MoreHorizontalIcon.svg'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import './MoreMenu.scss'
import { classNames } from '@web/_utils'

interface IMoreMenu {
  id?: string
  animated?: boolean
  children: React.ReactChild
}

const MoreMenu: React.FC<IMoreMenu> = ({ id, children, animated = false }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [moreMenuActive, setMoreMenuActive] = useDetectOutsideClick(
    dropdownRef,
    false
  )

  const moreMenuClasses = classNames({
    menu: true,
    animated,
    active: moreMenuActive,
    inactive: !moreMenuActive,
  })

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setMoreMenuActive(!moreMenuActive)
  }

  return (
    <div className="more-actions" {...(id && { id })} onClick={toggleMenu}>
      <button className="more-actions--trigger" title={t('more')}>
        <img src={MoreHorizontalIcon} alt={t('more')} />
      </button>

      <div ref={dropdownRef} className={moreMenuClasses}>
        {children}
      </div>
    </div>
  )
}

export default MoreMenu
