import React from 'react'
import { useTranslation } from 'react-i18next'
import { I18N, ROUTES } from '@web/_constants'
import './SendInvite.scss'
import Button from '@web/js/components/Button'
import InviteIcon from '@web/common/img/InviteIcon'
import { useNavigate } from 'react-router-dom'

const SendInvite: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()

  return (
    <div id="invite-coworkers" className="container">
      <div className="text-headline">{t('inviteCoworkers')}</div>
      <div className="invite-coworkers--icon">
        <InviteIcon />
      </div>
      <p className="invite-coworkers--description">
        {t('inviteCoworkersDescription')}
      </p>
      <Button
        block={true}
        style="primary"
        outline={true}
        size="xl"
        onClick={() => navigate(ROUTES.invite)}
      >
        {t('sendInvitation')}
      </Button>
    </div>
  )
}

export default SendInvite
