import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import './ShareContactModal.scss'
import { AXIOS, I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ChatContext } from '@web/js/context/ChatContext'
import { Connection } from '@web/_types'
import TextareaAutosize from 'react-autosize-textarea/lib'
import SearchIcon from '@web/common/img/SearchIcon.svg'
import ClearSearchIcon from '@web/common/img/ClearSearchIcon.svg'
import { isRep } from '@web/_guards'
import ContactCard from '@web/js/components/ContactCard'
import { classNames } from '@web/_utils'
import { ChatService } from '@web/_services'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

interface ShareContactModalProps {
  channelId: string
}

const ShareContactModal: React.FC<ShareContactModalProps> = ({ channelId }) => {
  const { t } = useTranslation(I18N.namespaces.web)

  const { queueMessage } = useContext(ChatContext)
  const { hideModal } = useContext(ModalContext)

  const [init, setInit] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [hasContacts, setHasContacts] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [shareableContacts, setShareableContacts] = useState<Connection[]>([])
  const [selectedContact, setSelectedContact] = useState<Connection | null>(
    null
  )

  const initRef = useRef(init)
  const searchInputRef = useRef<HTMLTextAreaElement>(null)
  const controller = new AbortController()

  useEffect(() => {
    if (selectedContact) setSelectedContact(null)
  }, [shareableContacts])

  useEffect(() => {
    initRef.current = init
    if (!init) searchInputRef.current?.focus()
  }, [init])

  useEffect(() => {
    loadSharableContacts(searchValue)

    return () => {
      controller.abort(AXIOS.canceled)
    }
  }, [])

  const handleOnAccept = () => {
    queueMessage({ channelId, contactId: (selectedContact as Connection).id })
    hideModal()
  }

  const loadSharableContacts = async (q: string) => {
    setIsLoading(true)

    try {
      const getShareableContactsResponse =
        await ChatService.getShareableContacts(
          channelId,
          q.trim(),
          controller.signal
        )

      const shareableContactsData: Connection[] = []
      if (getShareableContactsResponse.data?.results.length) {
        const clonedData = getShareableContactsResponse.data
        const dossier = clonedData.dossier

        //parse recipients from results
        _.each(clonedData.results, (result) => {
          const isHCP = result.type === 'HCP'
          const id = result.id
          const map = isHCP ? 'hcps' : 'reps'
          const fields = [
            { name: 'commonHcpIds', map: 'hcps', to: 'commonHcps' },
            ...(!isHCP
              ? [
                  { name: 'groupId', map: 'groups', to: 'group' },
                  { name: 'brandIds', map: 'brands', to: 'brands' },
                ]
              : []),
          ]
          const connection = dossier[map][id]
          _.each(fields, (field) => {
            const ids = connection[field.name]
            if (!ids) return
            const value = _.isArray(ids)
              ? _.map(ids, (id) => dossier[field.map][id])
              : dossier[field.map][ids]
            connection[field.to] = value
          })
          shareableContactsData.push(connection)
        })
      }

      setShareableContacts(shareableContactsData)

      if (initRef.current) {
        setHasContacts(!!shareableContactsData.length)
        setInit(false)
      }
    } catch (error) {
      console.log(error)
    }

    setIsLoading(false)
  }

  const debounceLoadSharableContacts = useCallback(
    _.debounce((q: string) => {
      loadSharableContacts(q)
    }, 300),
    []
  )

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value
    setSearchValue(value)
    debounceLoadSharableContacts(value)
  }

  const clearSearch = () => {
    searchInputRef.current?.focus()
    setSearchValue('')
    loadSharableContacts('')
  }

  return (
    <Modal
      title={`${t('shareAContact')}`}
      closeable={true}
      onClose={hideModal}
      size="lg"
    >
      <ModalBody id="share-contact-modal">
        <div id="shareable-contacts-search-header">
          <div id="shareable-contacts-search">
            <img id="search-icon" src={SearchIcon} />

            <TextareaAutosize
              id="shareable-contacts-search-input"
              rows={1}
              ref={searchInputRef}
              value={searchValue}
              placeholder={t('searchByName')}
              style={{ minHeight: '44px' }}
              onChange={handleOnChange}
              disabled={!hasContacts}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault()
              }}
            />

            {!!searchValue && (
              <img
                onClick={clearSearch}
                id="clear-search-icon"
                src={ClearSearchIcon}
              />
            )}
          </div>
        </div>

        <div id="shareable-contacts">
          {!isLoading &&
            !!shareableContacts.length &&
            _.map(shareableContacts, (shareableContact) => {
              const shareableContactCx = {
                ['shareable-contact']: true,
                active:
                  !!selectedContact &&
                  shareableContact.id === selectedContact.id,
              }

              const shareableContactClasses = classNames(shareableContactCx)

              return (
                <div
                  className={shareableContactClasses}
                  onClick={() => setSelectedContact(shareableContact)}
                  key={shareableContact.id}
                >
                  <ContactCard
                    contact={shareableContact}
                    showTitle={!isRep(shareableContact)}
                    showRepIcon={isRep(shareableContact)}
                  />
                </div>
              )
            })}

          {!isLoading && !shareableContacts.length && (
            <div id="no-results">
              {hasContacts ? t('noResultsFound') : t('noConnectionsToShare')}
            </div>
          )}
        </div>
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onAcceptText={t('send')}
        onAccept={handleOnAccept}
        onCancel={hideModal}
        disabled={!selectedContact}
      />
    </Modal>
  )
}

export default ShareContactModal
