import * as bg from './bg/web.json'
import * as cs from './cs/web.json'
import * as da from './da/web.json'
import * as de from './de/web.json'
import * as el from './el/web.json'
import * as en from './en/web.json'
import * as es from './es/web.json'
import * as esES from './es-ES/web.json'
import * as fi from './fi/web.json'
import * as fr from './fr/web.json'
import * as frCa from './fr-CA/web.json'
import * as hu from './hu/web.json'
import * as id from './id/web.json'
import * as it from './it/web.json'
import * as ja from './ja/web.json'
import * as ko from './ko/web.json'
import * as nl from './nl/web.json'
import * as pl from './pl/web.json'
import * as ptBR from './pt-BR/web.json'
import * as ro from './ro/web.json'
import * as ru from './ru/web.json'
import * as sk from './sk/web.json'
import * as sv from './sv/web.json'
import * as th from './th/web.json'
import * as tr from './tr/web.json'
import * as uk from './uk/web.json'
import * as vi from './vi/web.json'
import * as zhCN from './zh-CN/web.json'
import * as zhTW from './zh-TW/web.json'

const web = {
  bg,
  cs,
  da,
  de,
  el,
  en,
  es,
  'es-ES': esES,
  fi,
  fr,
  'fr-CA': frCa,
  hu,
  id,
  it,
  ja,
  ko,
  nl,
  pl,
  'pt-BR': ptBR,
  ro,
  ru,
  sk,
  sv,
  th,
  tr,
  uk,
  vi,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
}

export default web
