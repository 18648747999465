import * as React from 'react'
import { IconProps } from '@web/_types'

const LocationIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-location',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.5C15.3137 3.5 18 6.18629 18 9.5C18 11.5946 17.6341 12.8526 16.1523 15.5241C15.5171 16.6692 14.133 18.6612 12 21.5C9.86675 18.6608 8.48251 16.6685 7.84729 15.5233C6.36576 12.8522 6 11.5945 6 9.5C6 6.18629 8.68629 3.5 12 3.5ZM12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5Z"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LocationIcon
