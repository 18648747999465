import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

type TrackSessionArgs = {
  sessionId?: string
  pin: string
}

//prevent default axios interception with new instance
const uninterceptedAxiosInstance = axios.create()

export const RemoteCLMService = {
  createSession: async (
    url: string,
    meetingId: string
  ): Promise<AxiosResponse> => {
    return uninterceptedAxiosInstance({
      method: 'get',
      headers: {
        'Pin-Id': meetingId,
      },
      url: `${url}/api/v1/engage/engage-remote/session/attendees?pin=${meetingId}`,
    })
  },
  trackSession: async ({
    sessionId,
    pin,
  }: TrackSessionArgs): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL: sessionId ? Config.OAPI_URL_V2 : Config.API_URL_V2,
      url: '/clmSession',
      data: {
        ...(sessionId && { sessionId }),
        pin,
      },
    })
  },
}
