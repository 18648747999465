import {
  Brand,
  Dossier,
  Group,
  HCP,
  Rep,
  Meeting,
  Channel,
  Member,
  Message,
  NotificationRequest,
  RemoteCLMSession,
} from '@web/_types'
import {
  CACHE_EVENT,
  CHANNEL_TYPES,
  MESSAGE_TYPES,
  ROLES,
} from '@web/_constants'
import _ from 'lodash'

export const parseDossier = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any },
  userId = ''
): Dossier => {
  const dossierData = data.dossier

  const groups: Record<string, Group> = dossierData.groups || {}
  const brands: Record<string, Brand> = dossierData.brands || {}

  const rawReps = dossierData.reps || {}
  const reps: Record<string, Rep> = Object.keys(rawReps).reduce(
    (acc: Record<string, Rep>, repId: string) => {
      const rawRep = rawReps[repId]
      const rep = Object.assign(rawRep, {
        group: groups[rawRep.groupId],
        brands: rawRep.brandIds.map((brandId: string) => brands[brandId]),
      })
      return Object.assign(acc, { [repId]: rep })
    },
    {}
  )

  const rawHcps = dossierData.hcps || {}
  const hcps: Record<string, HCP> = Object.keys(rawHcps).reduce(
    (acc: Record<string, HCP>, hcpId: string) => {
      const hcp = rawHcps[hcpId]
      return Object.assign(acc, { [hcpId]: hcp })
    },
    {}
  )

  const rawChannels = dossierData.channels || {}
  const channelIds = Object.keys(rawChannels)
  const channels: Record<string, Channel> = channelIds.reduce(
    (acc: Record<string, Channel>, channelId: string) => {
      const rawChannel = rawChannels[channelId]
      const isPartial = rawChannel.type === CHANNEL_TYPES.partial
      const messageActionsEnabled = rawChannel.active && !isPartial

      const chatMember = !isPartial
        ? _.filter(
            rawChannel.members,
            (member: Member) => member.id !== userId
          )[0]
        : rawChannel.partialUser
        ? rawChannel.partialUser
        : null

      const connection = chatMember
        ? reps[chatMember.id] || hcps[chatMember.id]
        : null
      const channel = Object.assign(rawChannel, {
        connection,
        messageActionsEnabled,
      })
      return Object.assign(acc, { [channelId]: channel })
    },
    {}
  )

  const rawMeetings = dossierData.meetings || {}
  const meetingIds = Object.keys(rawMeetings)
  const meetings: Record<string, Meeting> = meetingIds.reduce(
    (acc: Record<string, Meeting>, meetingId: string) => {
      const rawMeeting = rawMeetings[meetingId]
      const meetingParticipant = _.filter(
        rawMeeting.participants,
        (member: Member) => member.id !== userId
      )[0]

      const connection = reps[meetingParticipant.id]
      const meeting = Object.assign(rawMeeting, { connection })
      return Object.assign(acc, { [meetingId]: meeting })
    },
    {}
  )

  const rawRemoteCLMSessions = dossierData.remoteCLMSessions || {}
  const remoteCLMSessionIds = Object.keys(rawRemoteCLMSessions)
  const remoteCLMSessions: Record<string, RemoteCLMSession> =
    remoteCLMSessionIds.reduce(
      (acc: Record<string, RemoteCLMSession>, sessionId: string) => {
        const rawRemoteCLMSession = rawRemoteCLMSessions[sessionId]
        const connection = reps[rawRemoteCLMSession.presenterId]
        const remoteCLMSession = Object.assign(rawRemoteCLMSession, {
          connection,
        })
        return Object.assign(acc, { [sessionId]: remoteCLMSession })
      },
      {}
    )

  const rawNotifications = dossierData.notifications || {}
  const notificationIds = Object.keys(rawNotifications)
  const notificationRequests: Record<string, NotificationRequest> =
    notificationIds.reduce(
      (acc: Record<string, NotificationRequest>, notificationId: string) => {
        const rawNotification = rawNotifications[notificationId]
        const isRep = !!rawNotification.repId
        const notificationUserId = isRep
          ? rawNotification.repId
          : rawNotification.hcpId
        const contact = isRep
          ? reps[notificationUserId]
          : hcps[notificationUserId]
        const notification = {
          contact,
          date: rawNotification.time,
          notificationId: rawNotification.id,
          new: rawNotification.new,
          type: rawNotification.type,
          ...(rawNotification.scheduleDetails && {
            scheduleDetails: rawNotification.scheduleDetails,
          }),
          ...(rawNotification.scheduleAdvDetails && {
            scheduleAdvDetails: rawNotification.scheduleAdvDetails,
          }),
          ...(rawNotification.scheduleCrmDetails && {
            scheduleCrmDetails: rawNotification.scheduleCrmDetails,
          }),
        }

        return Object.assign(acc, { [notificationId]: notification })
      },
      {}
    )

  const rawMessages = data.messages || []
  const messages = rawMessages.map((message: Message) => {
    message.status = CACHE_EVENT.delivered

    if (
      message.contactId &&
      message.metadata &&
      message.metadata.shareContact
    ) {
      switch (message.metadata.shareContact.type) {
        case MESSAGE_TYPES.shareRep:
          message.metadata.shareContact.contact = reps[message.contactId]
          break
        case MESSAGE_TYPES.shareHCP:
          message.metadata.shareContact.contact = hcps[message.contactId]
          break
      }
    }

    if (message.originalAuthor) {
      switch (message.originalAuthor.type) {
        case ROLES.rep:
          message.author = reps[message.originalAuthor.id]
          break
        case ROLES.hcp:
          message.author = hcps[message.originalAuthor.id]
          break
      }
    }

    switch (message.sender.type) {
      case ROLES.rep:
        message.user = reps[message.sender.id]
        break
      case ROLES.hcp:
        message.user = hcps[message.sender.id]
        break
    }
    return message
  })

  const rawResources = data.categories || []
  const resources = _.mapValues(rawResources, (categoryResourceIds) => {
    return _.map(
      categoryResourceIds,
      (categoryResourceId) => dossierData.resources[categoryResourceId]
    )
  })

  const rawContacts = data.contacts || []
  const contacts = _.mapValues(rawContacts, (contactIds) => {
    return _.map(contactIds, (contactId) => dossierData.reps[contactId])
  })

  const parsedDossier = Object.assign({}, data, {
    brands,
    channels,
    contacts,
    groups,
    hcps,
    meetings,
    messages,
    notificationRequests,
    remoteCLMSessions,
    reps,
    resources,
  }) as Dossier

  return parsedDossier
}
