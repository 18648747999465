import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const apiUrl = Config.API_URL_V2
const oapiUrl = Config.OAPI_URL_V2

export const MeetingService = {
  recordAuthenticatedMeeting: async (
    hcpAttendeeId: string,
    password: string,
    hcpCrmId: string
  ): Promise<void> => {
    await axios({
      baseURL: apiUrl,
      method: 'post',
      url: '/meetings',
      data: { hcpAttendeeId, password, hcpCrmId },
    })
  },

  recordUnAuthenticatedMeeting: async (
    hcpAttendeeId: string,
    password: string,
    sessionId: string
  ): Promise<void> => {
    await axios({
      baseURL: oapiUrl,
      method: 'post',
      url: '/meetings',
      data: { sessionId, hcpAttendeeId, password },
    })
  },

  getMeetingRepUnAuthenticated: (
    hcpAttendeeId: string,
    sessionId: string
  ): Promise<AxiosResponse> => {
    return axios({
      baseURL: oapiUrl,
      method: 'get',
      url: `/meetings/${hcpAttendeeId}?sessionId=${sessionId}`,
    })
  },

  getMeetings: (
    params: Record<string, string | number>
  ): Promise<AxiosResponse> => {
    return axios({
      baseURL: apiUrl,
      method: 'get',
      url: `/meetingRefs`,
      params,
    })
  },
}
