export const parseJwt = (token: string): { [key: string]: string | number } => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const jwtSecToExpire = (exp: number): number => {
  const now = new Date()
  const expireDate = new Date(exp * 1000)
  const differenceValue = (expireDate.getTime() - now.getTime()) / 1000
  return Math.round(differenceValue)
}
