import React, { useContext } from 'react'
import { I18N, SUPPORT_EMAIL } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import DOMPurify from 'dompurify'

const BlockedDomainModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal closeable={false}>
      <ModalBody>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('errorBlockedDomainTopPart', {
                supportEmail: SUPPORT_EMAIL,
              })
            ),
          }}
        />
        <p>{t('errorBlockedDomainBottomPart')}</p>
      </ModalBody>

      <ModalFooter onAccept={hideModal} onAcceptText={t('close')} />
    </Modal>
  )
}

export default BlockedDomainModal
