import React, { useEffect } from 'react'
import './Modal.scss'
import { useResponsive } from '@farfetch/react-context-responsive'
import { classNames } from '@web/_utils'
import i18n from '@web/common/js/i18n'
import { I18N } from '@web/_constants'
import { ModalBodyProps, ModalFooterProps, ModalProps } from '@web/_types'

//Icons
import CloseIcon from '@mui/icons-material/Close'

//Components
import Button from '../Button'

const Modal: React.FC<ModalProps> = ({
  children,
  closeable = true,
  blur,
  onClose,
  onLoad,
  onUnload,
  size = 'sm',
  title,
}) => {
  const { lessThan, mediaType } = useResponsive()

  useEffect(() => {
    const mainLayout = document.getElementById('main-layout')
    if (blur) mainLayout?.classList.add('blur')
    if (onLoad) onLoad()

    return () => {
      mainLayout?.classList.remove('blur')
      if (onUnload) onUnload()
    }
  }, [])

  const modalClasses = classNames({
    modal: true,
    [('modal-' + size) as string]: !!size,
    'modal-closeable': closeable,
    [mediaType as string]: mediaType !== '_initial',
    xs: lessThan.sm,
  })

  const modalHeaderClasses = classNames({
    'modal-header': true,
    center: !closeable,
  })

  const modalBgClasses = classNames({
    'modal-bg': true,
    blur,
  })

  return (
    <div className="modal-overlay" tabIndex={1}>
      <div className={modalClasses}>
        {(title || closeable) && (
          <div className={modalHeaderClasses}>
            {title && title}
            {closeable && (
              <div className="modal-close">
                <Button
                  id="modalClose"
                  onClick={onClose}
                  style="link"
                  size="lg"
                  icon={<CloseIcon />}
                />
              </div>
            )}
          </div>
        )}

        {children}
      </div>

      <div className={modalBgClasses} />
    </div>
  )
}

export const ModalBody: React.FC<ModalBodyProps> = ({
  id,
  align,
  bodyRef,
  center,
  classes,
  children,
  onScrollBody,
}) => {
  const modalBodyClasses = classNames({
    'modal-body': true,
    [align as string]: !!align,
    [classes as string]: !!classes,
    center: center,
  })

  return (
    <div
      ref={bodyRef}
      className={modalBodyClasses}
      {...(id && { id })}
      {...(onScrollBody && { onScroll: onScrollBody })}
    >
      {children}
    </div>
  )
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  acceptable = true,
  block = false,
  cancellable = false,
  dangerous = false,
  disabled = false,
  onAccept = () => null,
  onAcceptText = i18n.t('ok', { ns: I18N.namespaces.web }),
  onCancel = () => null,
  onCancelText = i18n.t('cancel', { ns: I18N.namespaces.web }),
  stacked = false,
}) => {
  const { is } = useResponsive()

  const handleOnAccept = () => {
    if (onAccept) onAccept()
  }

  const modalFooterCx = {
    'modal-footer': true,
    center: !cancellable,
    block,
    stacked: stacked || is._initial,
  }
  const modalFooterClasses = classNames(modalFooterCx)

  return (
    <div className={modalFooterClasses}>
      {cancellable && (
        <Button
          id="modalCancel"
          outline={true}
          block={block}
          style={stacked ? 'link' : 'primary'}
          size="lg"
          onClick={onCancel}
        >
          {onCancelText}
        </Button>
      )}

      {acceptable && (
        <Button
          id="modalAccept"
          onClick={handleOnAccept}
          block={block}
          style={dangerous ? 'danger' : 'primary'}
          size="lg"
          disabled={disabled}
        >
          {onAcceptText}
        </Button>
      )}
    </div>
  )
}

export default Modal
