import React, { useState, useEffect } from 'react'
import FormError from '../FormError'
import { classNames } from '@web/_utils'
import './FormField.scss'

interface FormFieldProps {
  disabled?: boolean
  error?: string
  hasError?: boolean
  forRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement> | null
  id?: string
  label?: string
  maxLength?: number
  name: string
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  order?: number
  placeholder?: string
  round?: boolean
  rows?: number
  spellCheck?: boolean
  textarea?: boolean
  type?: string
  value?: string
}

const FormField: React.FC<FormFieldProps> = ({
  disabled = false,
  error = '',
  hasError = false,
  forRef = null,
  id,
  label = '',
  maxLength,
  name = '',
  onChange,
  order,
  placeholder = '',
  round = false,
  rows = 4,
  spellCheck = true,
  textarea = false,
  type = 'text',
  value = '',
}) => {
  const [inputValue, setInputValue] = useState(value)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (value !== inputValue) setInputValue(value)
  }, [value])

  const cx = {
    'form-control': true,
    error: !!error,
    'has-error': !!error || !!hasError,
    round,
    focused: focused && textarea,
  }

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (type) {
      case 'number':
        e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '')
        break
      case 'tel':
        e.currentTarget.value = e.currentTarget.value.replace(
          /[^0-9() .\-+]+/g,
          ''
        )
        break
      default:
        break
    }

    setInputValue(e.currentTarget.value)
    if (onChange) onChange(e)
  }

  const formFieldClasses = classNames(cx)

  return (
    <div
      data-name={`form-control-${name}`}
      className={formFieldClasses}
      {...(id && { id })}
      {...(!!order && { style: { order } })}
    >
      {!!label && (
        <label className="label-default-semibold" htmlFor={name}>
          {label}
        </label>
      )}

      {!textarea && (
        <input
          disabled={disabled}
          ref={forRef as React.Ref<HTMLInputElement> | null}
          name={name}
          placeholder={placeholder}
          value={inputValue}
          type={type === 'number' ? 'text' : type} //number does not respect maxLength
          spellCheck={spellCheck}
          onChange={(e) => handleOnChange(e)}
          {...(type === 'number' && {
            pattern: '[0-9]*',
            inputMode: 'numeric',
          })}
          {...(maxLength && { maxLength })}
        />
      )}

      {textarea && (
        <div className="form-control--textarea">
          <textarea
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={disabled}
            rows={rows}
            ref={forRef as React.Ref<HTMLTextAreaElement> | null}
            name={name}
            placeholder={placeholder}
            value={inputValue}
            spellCheck={spellCheck}
            onChange={(e) => handleOnChange(e)}
            {...(maxLength && { maxLength })}
          />
        </div>
      )}
      {!!error && <FormError text={error} name={name} />}
    </div>
  )
}

export default FormField
