import React, { useContext, useState, useEffect, useRef } from 'react'
import './ProfileImage.scss'
import { useTranslation } from 'react-i18next'
import { useDetectOutsideClick } from '@web/common/hooks'
import { resizeImage } from '@web/_utils'
import Avatar from '@web/js/components/Avatar'
import { I18N, MODALS, ROUTES } from '@web/_constants'
import { AuthContext } from '@web/js/context/AuthContext'
import { ModalContext } from '@web/js/context/ModalContext'
import { useResponsive } from '@farfetch/react-context-responsive'
import { matchRoutes, useLocation } from 'react-router-dom'

const ProfileImage: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { lessThan } = useResponsive()
  const location = useLocation()
  const isProfile = !!matchRoutes([{ path: ROUTES.profile }], location)

  const { user } = useContext(AuthContext)
  const { showModal } = useContext(ModalContext)

  const [src, setSrc] = useState<string | null>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const [initDropdownActive, setInitDropdownActive] = useDetectOutsideClick(
    dropdownRef,
    false
  )

  useEffect(() => {
    if (src) {
      showModal({
        name: MODALS.EDIT_PHOTO_MODAL,
        data: {
          src,
          setSrc,
        },
      })
      setInitDropdownActive(false)
    }
  }, [src])

  if (!user) return null

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation()
    setInitDropdownActive(!initDropdownActive)
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      e.persist()
      const reader = new FileReader()
      const file = e.target.files[0]

      reader.addEventListener(
        'load',
        async () => {
          if (reader.result) {
            const readerResult = reader.result.toString()

            //reduce size if height/width over 1000px
            const imageData = await resizeImage(readerResult, file.type)
            setSrc(imageData)
            e.target.value = ''
          }
        },
        false
      )
      reader.readAsDataURL(file)
    }
  }

  return (
    <div id="profile-image">
      {((isProfile && !lessThan.sm) || !isProfile) && (
        <div className="profile-avatar--overlay">
          <div className="profile-avatar--edit" onClick={toggleDropdown}>
            <span>{user.photoUrl ? t('editPhoto') : t('addPhoto')}</span>
          </div>
        </div>
      )}
      <div
        id="profile-avatar--wrapper"
        {...(isProfile &&
          lessThan.sm && {
            onClick: toggleDropdown,
            className: 'clickable',
          })}
      >
        <Avatar
          user={user}
          size={isProfile && lessThan.sm ? 'xl' : 'xxl'}
          invert
        />
      </div>

      <div
        ref={dropdownRef}
        id="profile-image--dropdown"
        className={initDropdownActive ? 'active' : ''}
      >
        <ul>
          <li id="profile-image--uploader">
            <input
              type="file"
              id="upload"
              accept="image/*"
              onChange={onSelectFile}
              hidden
            />
            <label
              htmlFor="upload"
              onClick={() => setInitDropdownActive(false)}
            >
              {t('uploadNewPhoto')}
            </label>
          </li>
          {!!user.photoUrl && (
            <li
              onClick={() => {
                showModal({
                  name: MODALS.DELETE_PHOTO_MODAL,
                })
                setInitDropdownActive(false)
              }}
            >
              {t('removePhoto')}
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default ProfileImage
