import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

const InviteYourselfModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal closeable={false}>
      <ModalBody>
        <p className="text-default">{t('errorInvitedYourself')}</p>
      </ModalBody>
      <ModalFooter onAccept={hideModal} onAcceptText={t('ok')} />
    </Modal>
  )
}

export default InviteYourselfModal
