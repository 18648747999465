import React, { useContext, useEffect, useRef, useState } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import './NotesModal.scss'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { ConnectionContext } from '@web/js/context/ConnectionContext'
import { NotificationsContext } from '@web/js/context/NotificationsContext'
import { ToastContext } from '@web/js/context/ToastContext'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Connection, Rep } from '@web/_types'
import { ContactService } from '@web/_services'
import { parseContacts } from '@web/_utils'

interface NotesModalProps {
  rep: Rep
  isFullProfile: boolean
}

const NotesModal: React.FC<NotesModalProps> = ({ rep, isFullProfile }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const notesRef = useRef<HTMLTextAreaElement>(null)

  const { updateConnection, getConnections } = useContext(ConnectionContext)
  const { addToast } = useContext(ToastContext)
  const { hideModal } = useContext(ModalContext)
  const { getNotifications } = useContext(NotificationsContext)

  const [note, setNote] = useState(rep.notes || '')
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    setNote(rep.notes || '')
    setChanged(false)
  }, [rep])

  useEffect(() => {
    if (!changed && note !== rep.notes) {
      setChanged(true)
    } else if (changed && note === rep.notes) {
      setChanged(false)
    }
  }, [note])

  const handleAccept = async () => {
    try {
      const addRepNotesResponse = await ContactService.addRepNotes(rep.id, note)
      const updatedRep = parseContacts(addRepNotesResponse.data, true)
      updateConnection(updatedRep as Connection)
      if(isFullProfile) {
        getConnections()
      } else {
        getNotifications()
      }
      hideModal()
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value
    setNote(value)
  }

  const focusMoveCaret = (v = '') => {
    if (notesRef.current) {
      notesRef.current?.focus()
      const value = v ? v : notesRef.current.value
      notesRef.current.value = ''
      notesRef.current.value = value
    }
  }

  const handleOnCancel = () => {
    hideModal()
    setNote(rep.notes || '')
  }

  return (
    <Modal
      title={`${rep.displayName} ${t('notes')}`}
      onLoad={focusMoveCaret}
      onClose={handleOnCancel}
      size="lg"
    >
      <ModalBody id="notes-modal">
        <textarea
          key="notes-text"
          className="notes-text"
          ref={notesRef}
          value={note}
          placeholder={t('enterNotesHere')}
          onChange={handleOnChange}
        />
        <div className="notes-visible">
          <InfoOutlinedIcon />
          {t('notesVisible')}
        </div>
      </ModalBody>

      <ModalFooter
        onAcceptText={t('save')}
        onAccept={handleAccept}
        onCancel={handleOnCancel}
        disabled={!changed}
        cancellable={true}
      />
    </Modal>
  )
}

export default NotesModal
