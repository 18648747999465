import * as React from 'react'

const ResourceInfoIcon: React.FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-resource-brochure"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2258 5C19.7781 5 20.2258 5.44772 20.2258 6V8.41753H21.2746C21.8269 8.41753 22.2746 8.86525 22.2746 9.41753V10.9814C22.2746 11.4529 21.8924 11.8351 21.4209 11.8351V11.8351C20.9495 11.8351 20.5673 12.2173 20.5673 12.6887V32.3655C20.5673 32.9178 20.1195 33.3655 19.5673 33.3655H9.61587C9.06359 33.3655 8.61587 32.9178 8.61587 32.3655V12.518C8.61587 12.1408 8.31011 11.8351 7.93294 11.8351V11.8351C7.55576 11.8351 7.25 11.5293 7.25 11.1521V9.41753C7.25 8.86525 7.69772 8.41753 8.25 8.41753H9.29881V6C9.29881 5.44772 9.74652 5 10.2988 5H19.2258Z"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M21.5917 8.41754H7.93294C7.55576 8.41754 7.25 8.72356 7.25 9.10105V11.1516C7.25 11.5291 7.55576 11.8351 7.93294 11.8351H21.5917C21.9688 11.8351 22.2746 11.5291 22.2746 11.1516V9.10105C22.2746 8.72356 21.9688 8.41754 21.5917 8.41754Z"
      fill="white"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M19.5429 5H9.98176C9.60459 5 9.29883 5.30602 9.29883 5.68351V7.73403C9.29883 8.11152 9.60459 8.41753 9.98176 8.41753H19.5429C19.92 8.41753 20.2258 8.11152 20.2258 7.73403V5.68351C20.2258 5.30602 19.92 5 19.5429 5Z"
      fill="white"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M15.1036 16.6196H8.61572V27.8975H15.1036V16.6196Z"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M26.9594 18.108C26.9594 18.0051 27.0224 17.9127 27.1182 17.8752L33.8631 15.2329C34.0271 15.1687 34.2043 15.2896 34.2043 15.4657V33.358C34.2043 33.4609 34.1413 33.5533 34.0455 33.5908L27.3005 36.2331C27.1366 36.2973 26.9594 36.1764 26.9594 36.0003V18.108Z"
      fill="white"
    />
    <path
      d="M26.9594 18.108C26.9594 18.0051 27.0224 17.9127 27.1182 17.8752L33.8631 15.2329C34.0271 15.1687 34.2043 15.2896 34.2043 15.4657V33.358C34.2043 33.4609 34.1413 33.5533 34.0455 33.5908L27.3005 36.2331C27.1366 36.2973 26.9594 36.1764 26.9594 36.0003V18.108Z"
      fill="#007AFF"
      fillOpacity="0.1"
    />
    <path
      d="M26.9594 18.108C26.9594 18.0051 27.0224 17.9127 27.1182 17.8752L33.8631 15.2329C34.0271 15.1687 34.2043 15.2896 34.2043 15.4657V33.358C34.2043 33.4609 34.1413 33.5533 34.0455 33.5908L27.3005 36.2331C27.1366 36.2973 26.9594 36.1764 26.9594 36.0003V18.108Z"
      stroke="#3D9BD6"
      strokeWidth="1.5"
    />
    <path
      d="M29.4312 21.0069L31.4312 20.209"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M29.499 24.347L31.499 23.5491"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M29.4312 27.6869L31.4312 26.889"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M29.499 31.027L31.499 30.2291"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.5024 18.108C12.5024 18.0051 12.5655 17.9127 12.6613 17.8752L19.4062 15.2329C19.5702 15.1687 19.7474 15.2896 19.7474 15.4657V33.358C19.7474 33.4609 19.6843 33.5533 19.5886 33.5908L12.8436 36.2331C12.6797 36.2973 12.5024 36.1764 12.5024 36.0003V18.108Z"
      fill="white"
    />
    <path
      d="M12.5024 18.108C12.5024 18.0051 12.5655 17.9127 12.6613 17.8752L19.4062 15.2329C19.5702 15.1687 19.7474 15.2896 19.7474 15.4657V33.358C19.7474 33.4609 19.6843 33.5533 19.5886 33.5908L12.8436 36.2331C12.6797 36.2973 12.5024 36.1764 12.5024 36.0003V18.108Z"
      fill="#007AFF"
      fillOpacity="0.1"
    />
    <path
      d="M12.5024 18.108C12.5024 18.0051 12.5655 17.9127 12.6613 17.8752L19.4062 15.2329C19.5702 15.1687 19.7474 15.2896 19.7474 15.4657V33.358C19.7474 33.4609 19.6843 33.5533 19.5886 33.5908L12.8436 36.2331C12.6797 36.2973 12.5024 36.1764 12.5024 36.0003V18.108Z"
      stroke="#3D9BD6"
      strokeWidth="1.5"
    />
    <path
      d="M14.5525 20.7538C14.5525 20.5497 14.6765 20.3661 14.8659 20.2899L16.8659 19.4853C17.1944 19.3531 17.5525 19.5949 17.5525 19.9491V22.6766C17.5525 22.8809 17.4281 23.0648 17.2383 23.1407L15.2383 23.9414C14.9099 24.0729 14.5525 23.8311 14.5525 23.4773V20.7538Z"
      fill="#3D9BD6"
    />
    <path
      d="M15.0525 28.0069L17.0525 27.209"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.0525 31.347L17.0525 30.5491"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M26.9703 18.108C26.9703 18.0051 26.9073 17.9127 26.8115 17.8752L20.0666 15.2329C19.9026 15.1687 19.7254 15.2896 19.7254 15.4657V33.358C19.7254 33.4609 19.7884 33.5533 19.8842 33.5908L26.6291 36.2331C26.7931 36.2973 26.9703 36.1764 26.9703 36.0003V18.108Z"
      fill="white"
    />
    <path
      d="M26.9703 18.108C26.9703 18.0051 26.9073 17.9127 26.8115 17.8752L20.0666 15.2329C19.9026 15.1687 19.7254 15.2896 19.7254 15.4657V33.358C19.7254 33.4609 19.7884 33.5533 19.8842 33.5908L26.6291 36.2331C26.7931 36.2973 26.9703 36.1764 26.9703 36.0003V18.108Z"
      fill="#007AFF"
      fillOpacity="0.1"
    />
    <path
      d="M26.9703 18.108C26.9703 18.0051 26.9073 17.9127 26.8115 17.8752L20.0666 15.2329C19.9026 15.1687 19.7254 15.2896 19.7254 15.4657V33.358C19.7254 33.4609 19.7884 33.5533 19.8842 33.5908L26.6291 36.2331C26.7931 36.2973 26.9703 36.1764 26.9703 36.0003V18.108Z"
      stroke="#3D9BD6"
      strokeWidth="1.5"
    />
    <path
      d="M24.2771 21.0069L22.2771 20.209"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M24.2094 24.347L22.2094 23.5491"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M24.2771 27.6869L22.2771 26.889"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M24.2094 31.027L22.2094 30.2291"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default ResourceInfoIcon
