import React, { useContext, useState } from 'react'
import './Settings.scss'
import { useTranslation } from 'react-i18next'
import { I18N, MODALS } from '@web/_constants'
import { Link } from 'react-router-dom'
import { AuthContext } from '@web/js/context/AuthContext'
import Toggle from '@web/js/components/Toggle'
import Option from '@web/js/components/Option'
import { UserService } from '@web/_services'
import { ModalContext } from '@web/js/context/ModalContext'

type ChatNotificationSettings = number | null

const SettingsRoute: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { showModal } = useContext(ModalContext)
  const { user, setAuthenticatedUser } = useContext(AuthContext)

  const [isLoadingNotificationSettings, setIsLoadingNotificationSettings] =
    useState(false)

  const [chatNotificationSettings, setChatNotificationSettings] =
    useState<ChatNotificationSettings>(
      user?.unreadMessageNotificationMinutes as ChatNotificationSettings
    )

  const updateEmailNotificationSettings = async (
    unreadMessageNotificationMinutes: ChatNotificationSettings
  ) => {
    const previousSetting = chatNotificationSettings
    if (unreadMessageNotificationMinutes === previousSetting) return
    setChatNotificationSettings(unreadMessageNotificationMinutes)
    setIsLoadingNotificationSettings(true)

    try {
      const updateUserResponse = await UserService.updateUser({
        unreadMessageNotificationMinutes,
      })
      setAuthenticatedUser(updateUserResponse.data)
    } catch (error) {
      console.log(error)
      setChatNotificationSettings(previousSetting)
    } finally {
      setIsLoadingNotificationSettings(false)
    }
  }

  return (
    <div id="privacy-settings-route">
      <div className="row">
        <div className="col-xs-8 col-md-6 col-lg-5">
          <div className="text-headline">{t('settings')}</div>
          <div className="container">
            <div id="email-notifications-settings">
              <div id="email-chat-notifications">
                <div className="text-headline">
                  {t('emailChatNotifications')}
                </div>
                <Toggle
                  isLoading={isLoadingNotificationSettings}
                  checkedDefault={!!chatNotificationSettings}
                  checked={!!chatNotificationSettings}
                  onToggle={(enabled) =>
                    updateEmailNotificationSettings(enabled ? 720 : null)
                  }
                />
              </div>

              {!chatNotificationSettings && <p>{t('turnNotificationsOn')}</p>}

              {!!chatNotificationSettings && (
                <div id="email-chat-notifications-options">
                  <div
                    className="email-chat-notifications-option"
                    onClick={() => updateEmailNotificationSettings(720)}
                  >
                    <div className="email-chat-notifications-option-text">
                      <div className="text-subhead">{t('recommended')}</div>
                      <div>{t('notifiedRecommended')}</div>
                    </div>
                    <div className="email-chat-notifications-option-control">
                      <Option
                        checked={chatNotificationSettings === 720}
                        checkedDefault={chatNotificationSettings === 720}
                        isLoading={isLoadingNotificationSettings}
                      />
                    </div>
                  </div>
                  <div
                    className="email-chat-notifications-option"
                    onClick={() => updateEmailNotificationSettings(15)}
                  >
                    <div className="email-chat-notifications-option-text">
                      <div className="text-subhead">{t('immediate')}</div>
                      <div>{t('notifiedImmediate')}</div>
                    </div>
                    <div className="email-chat-notifications-option-control">
                      <Option
                        checked={chatNotificationSettings === 15}
                        checkedDefault={chatNotificationSettings === 15}
                        isLoading={isLoadingNotificationSettings}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="container" id="delete-account">
            <Link
              className="danger"
              to="#"
              onClick={() =>
                showModal({
                  name: MODALS.ACCOUNT_DELETE_MODAL,
                })
              }
            >
              {t('deleteAccount.title')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsRoute
