import * as React from 'react'
import { IconProps } from '@web/_types'

const PingIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-ping',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.53612 9.55161C3.08466 8.98535 3.17261 8.15638 3.7322 7.70008L10.3665 2.29235C10.6076 2.09613 10.8968 2.00066 11.1835 2.00066V3.31753H11.1828L4.55042 8.72461L11.4805 17.4107L18.1631 12.0635L11.1848 3.31687L11.1841 2C11.5646 2 11.9417 2.1679 12.1991 2.49054L21.7408 14.4491C22.1923 15.0153 22.1043 15.8443 21.5447 16.3006L14.9104 21.7083C14.6694 21.9045 14.3801 22 14.0935 22C13.713 22 13.3358 21.8321 13.0785 21.5095L3.53612 9.55161ZM15.6322 16.7944C15.6322 17.3323 16.0634 17.7682 16.5957 17.7682C17.1279 17.7682 17.5592 17.3323 17.5592 16.7944C17.5592 16.2565 17.1279 15.8206 16.5957 15.8206C16.0634 15.8199 15.6322 16.2565 15.6322 16.7944ZM17.4569 2.79802C17.352 2.79012 17.2491 2.82634 17.1716 2.89811C17.0947 2.97054 17.0504 3.07193 17.0504 3.17794V3.73498C17.0504 3.93251 17.1996 4.09712 17.395 4.11424C19.2764 4.28082 20.7793 5.80049 20.9441 7.7014C20.961 7.89827 21.1239 8.04971 21.3193 8.04971H21.8705C21.9753 8.04971 22.0757 8.0056 22.1473 7.92724C22.219 7.84889 22.2548 7.74486 22.2463 7.63885C22.0489 5.05712 20.0106 2.99753 17.4569 2.79802ZM5.33932 16.2986C5.32238 16.1017 5.15952 15.9509 4.96409 15.9509H4.41296C4.30808 15.9509 4.20776 15.9951 4.1361 16.0734C4.06444 16.1518 4.02861 16.2558 4.03708 16.3618C4.23447 18.9435 6.2722 21.0031 8.82652 21.2026C8.9314 21.2105 9.03433 21.1743 9.11185 21.1026C9.18873 21.0301 9.23302 20.9287 9.23302 20.8221V20.265C9.23302 20.0675 9.08384 19.9029 8.88841 19.8858C7.00768 19.7192 5.50414 18.2002 5.33932 16.2986Z"
      fill={color}
    />
  </svg>
)

export default PingIcon
