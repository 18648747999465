import * as React from 'react'
import { IconProps } from '@web/_types'

const ContactIcon: React.FC<IconProps> = ({
  height = '18px',
  width = '18px',
  color = '#3D9BD6"',
  className = 'icon-contact',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.38462 5.86047C6.38462 4.38664 7.55556 3.19186 9 3.19186C10.4444 3.19186 11.6154 4.38664 11.6154 5.86047C11.6154 7.33429 10.4444 8.52907 9 8.52907C7.55556 8.52907 6.38462 7.33429 6.38462 5.86047ZM9 2.25C7.04576 2.25 5.46154 3.86646 5.46154 5.86047C5.46154 7.85447 7.04576 9.47093 9 9.47093C10.9542 9.47093 12.5385 7.85447 12.5385 5.86047C12.5385 3.86646 10.9542 2.25 9 2.25ZM6.23077 10.4128C5.36839 10.4128 4.54488 10.7754 3.94039 11.4151C3.33646 12.0541 3 12.9173 3 13.814V15.2791C3 15.5392 3.20664 15.75 3.46154 15.75C3.71644 15.75 3.92308 15.5392 3.92308 15.2791V13.814C3.92308 13.1563 4.17013 12.5289 4.60486 12.0689C5.03904 11.6094 5.62426 11.3547 6.23077 11.3547H11.7692C12.3757 11.3547 12.961 11.6094 13.3951 12.0689C13.8299 12.5289 14.0769 13.1563 14.0769 13.814V15.2791C14.0769 15.5392 14.2836 15.75 14.5385 15.75C14.7934 15.75 15 15.5392 15 15.2791V13.814C15 12.9173 14.6635 12.0541 14.0596 11.4151C13.4551 10.7754 12.6316 10.4128 11.7692 10.4128H6.23077Z"
      fill={color}
    />
  </svg>
)

export default ContactIcon
