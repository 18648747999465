import Config from '@web/_config'

let engageApiBase: string | undefined = undefined
let engageSampleBase: string | undefined = undefined
let engageWindowsDownload: string | undefined = undefined

export const EngageHostService = {
  getEngageApiBase: (): string => {
    return engageApiBase ?? Config.ENGAGE_API_BASE
  },
  getEngageSampleBase: (): string => {
    return engageSampleBase ?? Config.ENGAGE_SAMPLE_BASE
  },
  getEngageWindowsDownload: (): string => {
    return engageWindowsDownload ?? Config.ENGAGE_WINDOWS_DOWNLOAD_URL
  },
  resetHosts: (): void => {
    engageApiBase = undefined
    engageSampleBase = undefined
    engageWindowsDownload = undefined
  },
  setHosts: (apiHost: string, baseHost: string): void => {
    engageApiBase = apiHost
    if (baseHost) {
      engageSampleBase = `${baseHost}/samples`
      engageWindowsDownload = `${baseHost}/windows/Setup.exe`
    }
  },
}
