import { I18N, ROUTES } from '@web/_constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import MessagedYouIcon from '@web/common/img/MessagedYouIcon.svg'
import './HCPMessagedYouModal.scss'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { Connection, HCP } from '@web/_types'
import Avatar from '@web/js/components/Avatar'
import { useNavigate } from 'react-router-dom'

interface HCPMessagedYouModalProps {
  hcp: HCP
  approveConnectionRequest: (
    connection: Connection,
    returnConnection?: boolean
  ) => Connection
}

const HCPMessagedYouModal: React.FC<HCPMessagedYouModalProps> = ({
  hcp,
  approveConnectionRequest,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)
  const navigate = useNavigate()

  const handleOnAccept = async () => {
    const newConnection = await approveConnectionRequest(hcp, true)
    if (newConnection.channelId) navigate(`${ROUTES.chat}/${hcp.channelId}`)
    hideModal()
  }

  return (
    <Modal closeable={false} size="fixed" blur={true}>
      <ModalBody id="messaged-you-modal">
        <div id="messaged-you-header">
          <img src={MessagedYouIcon} />
          <Avatar user={hcp} size="xl" />
        </div>

        <p className="text-large-semibold">
          {t('hcpMessagedYou', { name: hcp.displayName })}
        </p>
        <p className="text-default">{t('connectToViewMessage')}</p>
      </ModalBody>

      <ModalFooter
        stacked
        cancellable={true}
        onAccept={handleOnAccept}
        onAcceptText={t('acceptConnection')}
        onCancel={hideModal}
        onCancelText={t('dismiss')}
      />
    </Modal>
  )
}

export default HCPMessagedYouModal
