import React, { useEffect } from 'react'
import './SignUpBanner.scss'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'

interface SignUpBannerProps {
  goToDBCSignup: () => void
}

const SignUpBanner: React.FC<SignUpBannerProps> = ({ goToDBCSignup }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const padding = 50

  const scaleTextToBtnWidth = () => {
    const btn = document.getElementById('signUpToConnect')
    const btnWidth = btn?.offsetWidth || 200 //en default
    const span = btn?.querySelectorAll('span')[0]
    const spanWidth = span?.offsetWidth || 150 //en default

    let ratio = 1
    if (btnWidth < spanWidth + padding) {
      ratio = btnWidth / (spanWidth + padding)
    }

    if (span) span.style.transform = `scale(${ratio})`
  }

  const myObserver = new ResizeObserver((entries) => {
    entries.forEach(scaleTextToBtnWidth)
  })

  useEffect(() => {
    const btn = document.getElementById('signUpToConnect')
    if (btn) myObserver.observe(btn)

    return () => {
      myObserver.disconnect()
    }
  }, [])

  return (
    <div id="sign-up-banner">
      <div className="text-headline">{t('signUpToAccessFullProfile')}</div>
      <Button id="signUpToConnect" onClick={goToDBCSignup}>
        {t('signUpToConnect')}
      </Button>
    </div>
  )
}

export default SignUpBanner
