import React, { useContext, useEffect } from 'react'
import './InvertedInviteModal.scss'
import { I18N, LOCALSTORAGE } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import InviteIcon from '@web/common/img/InviteIcon'
import DOMPurify from 'dompurify'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { useLocalStorage } from '@web/common/hooks'

const InvertedInviteModal: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  const [invertedInviteDisplayName, setInvertedInviteDisplayName] =
    useLocalStorage<string | null>(LOCALSTORAGE.invertedInviteDisplayName, null)

  useEffect(() => {
    if (searchParams.size) setSearchParams({}, { replace: true })
  }, [])

  const handleClose = () => {
    setInvertedInviteDisplayName(null)
    hideModal()
  }

  return (
    <Modal closeable={true} size="fixed" blur={true} onClose={handleClose}>
      <ModalBody id="inverted-invite-modal">
        <div id="inverted-invite-icon">
          <InviteIcon />
        </div>

        <div id="inverted-invite-title">{t('connectionInviteSentExclaim')}</div>

        <div
          id="inverted-invite-body"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('signedInDifferentEmail', {
                name: invertedInviteDisplayName,
              })
            ),
          }}
        />
      </ModalBody>

      <ModalFooter
        onAccept={handleClose}
        onAcceptText={t('dismiss')}
        stacked={true}
      />
    </Modal>
  )
}

export default InvertedInviteModal
