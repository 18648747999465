import { useLocalStorage } from '@web/common/hooks'
import { I18N, LOCALSTORAGE } from '@web/_constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import FunFettiWelcome from '@web/common/img/FunFettiWelcome.svg'
import './WelcomeModal.scss'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

const WelcomeModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  const [, setShowWelcome] = useLocalStorage<boolean | null>(
    LOCALSTORAGE.welcome,
    null
  )

  const dismissModal = () => {
    setShowWelcome(null)
    hideModal()
  }

  return (
    <Modal closeable={false} size="fixed" blur={true}>
      <ModalBody id="welcome-modal">
        <div id="presentation-icon">
          <img src={FunFettiWelcome} />
        </div>
        <div className="text-headline">{t('welcomeToEngage')}</div>
        <p className="text-small">{t('welcomeBody')}</p>
      </ModalBody>

      <ModalFooter
        acceptable={false}
        stacked={true}
        cancellable={true}
        onCancel={dismissModal}
        onCancelText={t('dismiss')}
      />
    </Modal>
  )
}

export default WelcomeModal
