type buildNameArgs = {
  firstName?: string
  lastName?: string
  isSurnameOrdered: boolean
}

export const buildName = ({
  firstName = '',
  lastName = '',
  isSurnameOrdered = false,
}: buildNameArgs): string => {
  let name = ''

  if (isSurnameOrdered) {
    name = `${lastName}${firstName}`
  } else if (firstName && lastName) {
    name = `${firstName} ${lastName}`
  } else if (firstName) {
    name = firstName
  } else if (lastName) {
    name = lastName
  }

  return name
}
