import React, { useState, useEffect } from 'react'
import './JoinMeeting.scss'
import {
  matchRoutes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { AuthContext } from '@web/js/context/AuthContext'
import { useContext } from 'react'
import { LOCALSTORAGE, ROUTES } from '@web/_constants'
import JoinMeetingForm from '@web/js/components/JoinMeetingForm'
import { useResponsive } from '@farfetch/react-context-responsive'
import { getQueryStringParams } from '@web/_utils'
import { useLocalStorage } from '@web/common/hooks'

const JoinMeetingRoute: React.FC = () => {
  const { authenticated } = useContext(AuthContext)
  const location = useLocation()
  // This route can be ROUTES.join || ROUTES.joinMeeting
  // ROUTES.joinMeeting is the authenticated mobile route
  // used to access presentations
  const joinMeetingMatch = !!matchRoutes(
    [{ path: ROUTES.joinMeeting }],
    location
  )
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { search } = useLocation()
  const { greaterThan } = useResponsive()
  const queryArgs = getQueryStringParams(window.location.search)

  const [, setMeetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )
  const [, setMeetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    if (queryArgs.m) {
      setMeetingId(queryArgs.m)
      setMeetingPwd(queryArgs.p ?? null)
    } else if (queryArgs.pin) {
      setMeetingId(queryArgs.pin)
      setMeetingPwd(null)
    }

    const isPresentation =
      !!queryArgs.pin && queryArgs.pin.length === 6 && !queryArgs.invalid

    // if authed, redirect to correct locations
    if (authenticated) {
      // redirect to CLM presentation when coming from
      // veev.io or /meet?pin=XXXXXX
      if (isPresentation && !joinMeetingMatch) {
        if (searchParams.size) setSearchParams({}, { replace: true })
        let url = ROUTES.presentation
        if (search) url += search
        navigate(url, { state: { redirected: true } })
        return
      } else if (
        // go to meetings route if not mobile joinMeeting route
        !joinMeetingMatch ||
        (joinMeetingMatch && greaterThan.sm)
      ) {
        let url = ROUTES.meetings
        if (search) url += search
        navigate(url, { state: { redirected: true } })
      } else if (joinMeetingMatch) {
        // if joinMeeting route, proceed to manual workflow
        setIsLoading(false)
      }
    } else {
      // if unauthed, proceed to manual workflow which will attempt
      // to autoload in JoinMeetingForm if params are present
      setIsLoading(false)
    }
  }

  if (isLoading) return null

  //manual workflow
  return (
    <div
      id="joinmeeting-route"
      className={joinMeetingMatch ? 'authed' : 'unauthed'}
    >
      <div className="meeting-form-wrapper container">
        <JoinMeetingForm />
      </div>
    </div>
  )
}

export default JoinMeetingRoute
