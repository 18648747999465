import React, { useRef, useState, useEffect } from 'react'
import './BrandResourceDetails.scss'
import { Brand, BrandResource, BrandResourceCategory } from '@web/_types'
import { I18N, LOGGING } from '@web/_constants'
import { toCamelCase } from '@web/_utils'
import { useTranslation } from 'react-i18next'
import BrandResourceCard from '@web/js/components/BrandResourceCard'
import Button from '@web/js/components/Button'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import _ from 'lodash'
import * as DOMPurify from 'dompurify'

interface BrandResourceDetailsProps {
  brand: Brand
  activeResourceCategory: BrandResourceCategory
  resources: BrandResource[]
  icon: JSX.Element
  setShareBrandResource: (brandResource: BrandResource) => void
}

const BrandResourceDetails: React.FC<BrandResourceDetailsProps> = ({
  activeResourceCategory,
  resources,
  brand,
  icon,
  setShareBrandResource,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const [dockable, setDockable] = useState(false)
  const [docked, setDocked] = useState(false)
  const [top, setTop] = useState(false)
  const [regulatoryWidth, setRegulatoryWidth] = useState('100%')

  //Refs
  const resourceBodyRef = useRef<HTMLDivElement>(null)
  const resourceItemsRef = useRef<HTMLDivElement>(null)
  const regulatoryInfoRef = useRef<HTMLDivElement>(null)
  const _preventEvent = useRef<boolean>(false)

  useEffect(() => {
    window.addEventListener('resize', setUpLayout)

    return () => {
      window.removeEventListener('resize', setUpLayout)
    }
  }, [])

  useEffect(() => {
    setUpLayout()
  }, [activeResourceCategory])

  const setUpLayout = () => {
    if (!resourceBodyRef.current || !resourceItemsRef.current) return
    const resourceBodyEl = resourceBodyRef.current
    const resourceItemsEl = resourceItemsRef.current
    const dockable =
      resourceBodyEl.clientHeight * 0.67 < resourceItemsEl.clientHeight

    setDockable(dockable)
    resourceBodyEl.scrollTop = 0

    if (dockable) {
      setDocked(true)
      updateRegulatoryWidth()
    }
  }

  const updateRegulatoryWidth = () => {
    if (resourceItemsRef.current) {
      const width = resourceItemsRef.current.clientWidth - 1 + 'px'
      setRegulatoryWidth(width)
    }
  }

  const handleScroll = (): void => {
    if (_preventEvent.current) {
      _preventEvent.current = false
      return
    }

    if (!resourceBodyRef.current || !regulatoryInfoRef.current) return
    const resourceBodyEl = resourceBodyRef.current
    const regulatoryInfoEl = regulatoryInfoRef.current

    const isTop = resourceBodyEl.scrollTop + 1 >= regulatoryInfoEl.offsetTop
    if ((!top && isTop) || (top && !isTop)) {
      setTop(isTop)
    }

    const shouldDock =
      resourceBodyEl.scrollTop + resourceBodyEl.clientHeight * 0.67 <=
      regulatoryInfoEl.offsetTop + 16

    if ((!docked && shouldDock) || (docked && !shouldDock))
      setDocked(shouldDock)
  }

  const setScrollTop = (pos: number) => {
    if (!resourceBodyRef.current) return
    _preventEvent.current = true
    const resourceBodyEl = resourceBodyRef.current
    resourceBodyEl.scrollTop = pos
  }

  const handleRegulatoryPosition = (toTop: boolean): void => {
    if (!regulatoryInfoRef.current) return
    const regulatoryInfoEl = regulatoryInfoRef.current
    setScrollTop(toTop ? regulatoryInfoEl?.offsetTop : 0)
    setTop(toTop)
    if (dockable) setDocked(!toTop)
  }

  const renderRegulatoryText = () => {
    const regulatoryText = brand.regText || ''
    const __html = DOMPurify.sanitize(
      regulatoryText.replace(/(?:\\r\\n|\\r|\\n)/g, '<br />')
    )

    return (
      <div
        id="brand-details--regulatory-info--text"
        className="text-default-light"
        dangerouslySetInnerHTML={{
          __html,
        }}
      />
    )
  }

  return (
    <div id="brand-details">
      <div id="brand-details--header" className="text-default-medium">
        {icon}
        {t(`resourceType.${toCamelCase(activeResourceCategory)}`)}
      </div>
      <div
        id="brand-details--resource-body"
        ref={resourceBodyRef}
        onScroll={handleScroll}
      >
        <div id="brand-details--resource-items" ref={resourceItemsRef}>
          {_.map(resources, (resource) => {
            return (
              <BrandResourceCard
                setShareBrandResource={setShareBrandResource}
                brandId={brand.id}
                resource={resource}
                key={resource.id}
                actionCategory={LOGGING.ACTION_TYPE[activeResourceCategory]}
              />
            )
          })}
        </div>

        {/* clone */}
        {brand.regText && brand.regTitle && (
          <div id="brand-details--regulatory-info" ref={regulatoryInfoRef}>
            <div id="brand-details--header" className="text-headline sticky">
              <span>{brand.regTitle}</span>
              <Button
                onClick={() => handleRegulatoryPosition(!top)}
                size="xl"
                style="link"
                icon={
                  top ? (
                    <ArrowDownwardOutlinedIcon />
                  ) : (
                    <ArrowUpwardOutlinedIcon />
                  )
                }
              />
            </div>
            {renderRegulatoryText()}
          </div>
        )}

        {dockable && docked && brand.regText && brand.regTitle && (
          <div
            id="brand-details--regulatory-info-docked"
            style={{ width: regulatoryWidth }}
          >
            <div className="brand-details--header">
              <span>{brand.regTitle}</span>
              <Button
                onClick={() => handleRegulatoryPosition(true)}
                size="xl"
                style="link"
                icon={<ArrowUpwardOutlinedIcon />}
              />
            </div>
            <div id="brand-details--regulatory-info--body">
              {renderRegulatoryText()}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BrandResourceDetails
