export const isMobile = checkMobileDevice()
function checkMobileDevice(): boolean {
  let hasTouchScreen = false
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0
  }
  const UA = navigator.userAgent
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|IEMobile|Opera Mini/i.test(
      UA
    )
  return hasTouchScreen && isMobileDevice
}

export const isIPad = checkIPad()
function checkIPad(): boolean {
  let isMacintosh = false
  if (/Macintosh/i.test(navigator.userAgent)) {
    isMacintosh = true
  }
  let isTouchScreen = false
  if (navigator.maxTouchPoints && navigator.maxTouchPoints > 0) {
    isTouchScreen = true
  }
  return isMacintosh && isTouchScreen
}
