import React, { useEffect, useState } from 'react'
import './Toggle.scss'
import { classNames } from '@web/_utils'
import _ from 'lodash'

interface IToggleProps {
  checkedDefault: boolean
  checked?: boolean //controlled component
  isLoading?: boolean
  onToggle?: (checked: boolean) => void
}

const Toggle: React.FC<IToggleProps> = ({
  checkedDefault,
  checked,
  isLoading,
  onToggle,
}) => {
  const [toggleChecked, setToggleChecked] = useState(checkedDefault)

  useEffect(() => {
    if (!_.isUndefined(checked)) setToggleChecked(checked)
  }, [checked])

  const cx = {
    toggle: true,
    checked: toggleChecked,
    loading: !!isLoading,
  }

  const toggleClasses = classNames(cx)

  const handleToggle = () => {
    if (isLoading) return
    if (_.isUndefined(checked)) setToggleChecked(!toggleChecked)
    if (onToggle) onToggle(!toggleChecked)
  }

  return (
    <div className={toggleClasses} onClick={handleToggle}>
      <div className="switch" />
    </div>
  )
}

export default Toggle
