import React, { createContext, useMemo, useState } from 'react'
import {
  NotificationRequest,
} from '@web/_types'
import { NotificationsService } from '@web/_services'
import { 
  SCHEDULING_SCH_ADV_NOTIFICATIONS,
  SCHEDULING_SCH_CRM_NOTIFICATIONS,
  SCHEDULING_SCH_NOTIFICATIONS,
} from '@web/_constants'
import _ from 'lodash'

export type INotificationsContext = {
  notifications: NotificationRequest[] | [],
  setNotifications: (data: NotificationRequest[]) => void,
  getNotifications: () => void,
}

export const NotificationsContext = createContext<INotificationsContext>({
  notifications: [],
  setNotifications: () => null,
  getNotifications: () => null,
})

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationRequest[]>([])

  const getNotifications = async () => {
    const getNotificationsResponse =
      await NotificationsService.getNotifications()

    if (getNotificationsResponse) {
      const data = getNotificationsResponse.data
      const dossier = data.dossier
      const notificationIds = data.notificationIds

      if (notificationIds.length) {
        const notifications = _.map(notificationIds, (id: string) => {
          const notification = dossier.notifications[id]
          const isRepNotification = !!notification.repId
          const userId = isRepNotification
            ? notification.repId
            : notification.hcpId
          const contact = isRepNotification
            ? dossier.reps[userId]
            : dossier.hcps[userId]

          const fields = [
            { name: 'commonHcpIds', map: 'hcps', to: 'commonHcps' },
            ...(isRepNotification
              ? [{ name: 'groupId', map: 'groups', to: 'group' }]
              : []),
            ...(isRepNotification
              ? [{ name: 'brandIds', map: 'brands', to: 'brands' }]
              : []),
          ]

          _.each(fields, (field) => {
            const ids = contact[field.name]
            const value = _.isArray(ids)
              ? _.map(ids, (id) => dossier[field.map][id])
              : dossier[field.map][ids]

            contact[field.to] = value
          })

          return {
            contact,
            date: notification.time,
            notificationId: notification.id,
            new: notification.new,
            type: notification.type,
            ...(notification.inviteMessage && {
              inviteMessage: notification.inviteMessage,
            }),
            unreadMessages: !!notification.unreadMessages,
            ...(_.includes(SCHEDULING_SCH_NOTIFICATIONS, notification.type) && {
              scheduleDetails: notification.scheduleDetails,
            }),
            ...(_.includes(
              SCHEDULING_SCH_ADV_NOTIFICATIONS,
              notification.type
            ) && { scheduleAdvDetails: notification.scheduleAdvDetails }),
            ...(_.includes(
              SCHEDULING_SCH_CRM_NOTIFICATIONS,
              notification.type
            ) && { scheduleCrmDetails: notification.scheduleCrmDetails }),
          }
        })

        const sortedNotifications = _.orderBy(notifications, ['date'], ['desc'])
        setNotifications(sortedNotifications)
      }
    }
  }

  const contextValue = useMemo(
    () => ({
      notifications,
      setNotifications,
      getNotifications,
    }),
    [
      notifications,
      setNotifications,
      getNotifications,
    ]
  )

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  )
}
