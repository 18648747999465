import * as React from 'react'
import { IconProps } from '@web/_types'

const StarIcon: React.FC<IconProps> = ({
    height = '35px',
    width = '35px',
    color = '#bdbdbd',
    className = 'icon-star',
}: React.SVGProps<SVGSVGElement>) => (

    <svg className={className} width={width} height={height} viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M17 0.186523L20.929 12.2787L33.6435 12.2787L23.3572 19.7521L27.2862 31.8443L17 24.3709L6.71376 31.8443L10.6428 19.7521L0.35651 12.2787L13.071 12.2787L17 0.186523Z" 
            fill={color} 
        />
    </svg>
)

export default StarIcon
