import React, { useContext } from 'react'
import './Notes.scss'
import { useTranslation } from 'react-i18next'
import { I18N, LOGGING, MODALS } from '@web/_constants'
import { Rep } from '@web/_types'
import { formatText } from '@web/_utils'
import EditIcon from '@web/common/img/EditIcon'
import Button from '@web/js/components/Button'
import Linkify from 'linkify-react'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import { ModalContext } from '@web/js/context/ModalContext'

interface NotesProps {
  rep: Rep
  isFullProfile: boolean
}

const Notes: React.FC<NotesProps> = ({ rep, isFullProfile }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18N.namespaces.web)
  const { showModal } = useContext(ModalContext)

  const addEditNote = (actionType: string) => {
    showModal({
      name: MODALS.NOTES_MODAL,
      data: {
        rep,
        isFullProfile,
      },
    })

    const eventMessage = {
      profileId: rep.id,
      actionType,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  return (
    <div id="notes">
      <div className="text-headline">
        {t('notes')}

        {!!rep.notes && (
          <a
            className="edit"
            onClick={() => addEditNote(LOGGING.ACTION_TYPE.EDIT_REP_NOTE)}
          >
            <EditIcon />
          </a>
        )}
      </div>

      {!rep.notes && (
        <Button
          size="sm"
          outline={true}
          onClick={() => addEditNote(LOGGING.ACTION_TYPE.ADD_REP_NOTE)}
        >
          {t('addNote')}
        </Button>
      )}

      {!!rep.notes && (
        <div className="note">
          <Linkify
            options={{
              target: { url: '_blank' },
            }}
          >
            {formatText(rep.notes)}
          </Linkify>
        </div>
      )}
    </div>
  )
}

export default Notes
