/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import './RecipientSelector.scss'
import { AXIOS, EMAIL_STATUS, I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-autosize-textarea'
import { Connection, RecipientEmail } from '@web/_types'
import SendViaEmail from '@web/common/img/SendViaEmail.svg'
import _ from 'lodash'
import { isRep } from '@web/_guards'
import ContactCard from '../ContactCard'
import Toast from '../Toasts/Toast'
import { AxiosResponse } from 'axios'

interface IRecipientSelectorProps {
  loadData: (
    q: string,
    signal: AbortSignal
  ) => Promise<AxiosResponse<any> | undefined>
  setRecipient: (recipient: Connection | null) => void
  setEmail: ({ email, emailStatus }: RecipientEmail) => void
}

const RecipientSelector: React.FC<IRecipientSelectorProps> = ({
  loadData,
  setRecipient,
  setEmail,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const [searchValue, setSearchValue] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const toInputRef = useRef<HTMLTextAreaElement>(null)
  const [emailStatus, setEmailStatus] = useState<string | null>(null)
  const [recipients, setRecipients] = useState<Connection[]>([])
  const controller = new AbortController()

  useEffect(() => {
    toInputRef.current?.focus()
    loadRecipients(searchValue)

    return () => {
      controller.abort(AXIOS.canceled)
    }
  }, [])

  const loadRecipients = async (q: string) => {
    try {
      const getRecipientsResponse = await loadData(q.trim(), controller.signal)

      const connectionsData: Connection[] = []
      if (getRecipientsResponse?.data?.results.length) {
        const clonedData = getRecipientsResponse.data
        const dossier = clonedData.dossier

        //parse recipients from results
        _.each(clonedData.results, (result) => {
          const isHCP = result.type === 'HCP'
          const id = result.id
          const map = isHCP ? 'hcps' : 'reps'
          const fields = [
            { name: 'commonHcpIds', map: 'hcps', to: 'commonHcps' },
            ...(!isHCP
              ? [
                  { name: 'groupId', map: 'groups', to: 'group' },
                  { name: 'brandIds', map: 'brands', to: 'brands' },
                ]
              : []),
          ]
          const connection = dossier[map][id]
          _.each(fields, (field) => {
            const ids = connection[field.name]
            if (!ids) return
            const value = _.isArray(ids)
              ? _.map(ids, (id) => dossier[field.map][id])
              : dossier[field.map][ids]
            connection[field.to] = value
          })
          connectionsData.push(connection)
        })
      }

      setEmailStatus(getRecipientsResponse?.data.emailStatus)
      setRecipients(connectionsData)
    } catch (error) {
      console.log(error)
    }
  }

  const debounceLoadUsers = useCallback(
    _.debounce((q: string) => loadRecipients(q), 300),
    []
  )

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value
    setSearchValue(value)
    debounceLoadUsers(value)
  }

  const selectEmail = () => {
    switch (emailStatus) {
      case EMAIL_STATUS.invalid:
        setError(t('errorPleaseEnterValidEmail'))
        break
      case EMAIL_STATUS.self:
        setError(t('errorCannotMessageYourself'))
        break
      default:
        setEmail({ email: searchValue, emailStatus })
        break
    }
  }

  return (
    <div id="recipient-selector">
      {error && (
        <Toast
          toast={{
            id: 'recipient-selector-error',
            message: error,
            type: 'danger',
            dismissable: true,
            time: 0,
            title: '',
          }}
          dismiss={() => setError(null)}
        />
      )}

      <div className="recipient-selector-to-input">
        <div id="to">{t('to')}</div>

        <TextareaAutosize
          id="to-input"
          rows={1}
          ref={toInputRef}
          placeholder={t('recipientNameOrEmail')}
          style={{ minHeight: '22px' }}
          onChange={handleOnChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        />
      </div>

      <div id="recipients-list">
        {_.map(recipients, (recipient) => {
          return (
            <div
              className="recipient"
              onClick={() => setRecipient(recipient)}
              key={recipient.id}
            >
              <ContactCard
                contact={recipient}
                showTitle={!isRep(recipient)}
                showRepIcon={isRep(recipient)}
              />
            </div>
          )
        })}

        {searchValue && emailStatus !== EMAIL_STATUS.hide && (
          <div id="recipient-selector-email">
            <div className="text-headline">{t('sendEmailTo')}</div>

            <div id="to-email" className="recipient" onClick={selectEmail}>
              <img src={SendViaEmail} />
              <div>{t('sendTo', { to: searchValue })}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default RecipientSelector
