import * as React from 'react'
import { IconProps } from '@web/_types'

const MeetingsIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '26px',
  color = '#ffffff',
  className = 'icon-meeetings',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2015 6.94236C16.5557 6.31734 15.6596 5.92859 14.6732 5.92859H4.3223C3.68226 5.92859 3.09985 6.18144 2.68108 6.58674C2.26226 6.99208 2 7.55689 2 8.17861V15.4644C2 16.4214 2.40267 17.2898 3.04851 17.9148C3.69431 18.5398 4.59035 18.9286 5.57681 18.9286H15.9277C16.5677 18.9286 17.1502 18.6757 17.5689 18.2704C17.9877 17.8651 18.25 17.3003 18.25 16.6786V9.39276C18.25 8.43576 17.8473 7.56742 17.2015 6.94236ZM14.6732 7.41977C15.2361 7.41977 15.7458 7.6406 16.1147 7.99764C16.4836 8.35468 16.7118 8.84793 16.7118 9.39276V16.6786C16.7118 16.8881 16.624 17.0778 16.4821 17.2152C16.3402 17.3525 16.1442 17.4374 15.9277 17.4374H5.57681C5.01387 17.4374 4.50423 17.2166 4.13531 16.8595C3.7664 16.5025 3.53823 16.0092 3.53823 15.4644V8.17861C3.53823 7.96906 3.62599 7.77935 3.76788 7.64203C3.90977 7.5047 4.10578 7.41977 4.3223 7.41977H14.6732ZM24.0731 7.30241C24.0731 7.30241 23.9376 7.18534 23.7725 7.10917C23.6075 7.03299 23.3478 7.01839 23.3478 7.01839C22.5834 7.01183 22.422 7.15919 22.1126 7.47866L19.7829 9.88417C19.4682 10.2091 19.3334 10.625 19.3334 11.0044V13.8524C19.3334 14.2318 19.4682 14.6477 19.7829 14.9726L22.1126 17.3781C22.3768 17.651 22.7613 17.845 23.2066 17.845C23.7223 17.845 24.1321 17.5889 24.3819 17.2874C24.629 16.989 24.7501 16.6204 24.7501 16.2579V8.59886C24.7501 8.35156 24.6916 8.03311 24.481 7.72329C24.3331 7.50571 24.1571 7.36636 24.0731 7.30241ZM20.7608 11.0044C20.7608 10.9634 20.7756 10.9245 20.8014 10.8978L23.1311 8.49231C23.1555 8.46715 23.186 8.4563 23.2156 8.45909C23.3128 8.53313 23.3227 8.56545 23.3227 8.59886V16.2579C23.3227 16.2966 23.3097 16.3317 23.2887 16.3571C23.2677 16.3824 23.2386 16.3981 23.2066 16.3981C23.1789 16.3981 23.1521 16.3862 23.1311 16.3645L20.8014 13.959C20.7756 13.9323 20.7608 13.8934 20.7608 13.8524V11.0044Z"
      fill={color}
    />
  </svg>
)

export default MeetingsIcon
