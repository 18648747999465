import * as React from 'react'

const ResourceInfoIcon: React.FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-resource-info"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9758 5C20.5281 5 20.9758 5.44772 20.9758 6V8.41753H22.0246C22.5769 8.41753 23.0246 8.86525 23.0246 9.41753V10.9814C23.0246 11.4529 22.6424 11.8351 22.1709 11.8351V11.8351C21.6995 11.8351 21.3173 12.2173 21.3173 12.6887V32.3655C21.3173 32.9178 20.8695 33.3655 20.3173 33.3655H10.3659C9.81359 33.3655 9.36587 32.9178 9.36587 32.3655V12.518C9.36587 12.1408 9.06011 11.8351 8.68294 11.8351V11.8351C8.30576 11.8351 8 11.5293 8 11.1521V9.41753C8 8.86525 8.44772 8.41753 9 8.41753H10.0488V6C10.0488 5.44772 10.4965 5 11.0488 5H19.9758Z"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M22.3417 8.41754H8.68294C8.30576 8.41754 8 8.72356 8 9.10105V11.1516C8 11.5291 8.30576 11.8351 8.68294 11.8351H22.3417C22.7188 11.8351 23.0246 11.5291 23.0246 11.1516V9.10105C23.0246 8.72356 22.7188 8.41754 22.3417 8.41754Z"
      fill="white"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M20.2929 5H10.7318C10.3546 5 10.0488 5.30602 10.0488 5.68351V7.73403C10.0488 8.11152 10.3546 8.41753 10.7318 8.41753H20.2929C20.67 8.41753 20.9758 8.11152 20.9758 7.73403V5.68351C20.9758 5.30602 20.67 5 20.2929 5Z"
      fill="white"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M15.8536 16.6196H9.36572V27.8975H15.8536V16.6196Z"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <circle cx="26" cy="27" r="8" fill="white" />
    <circle cx="26" cy="27" r="8" fill="#007AFF" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 19.9285C22.0946 19.9285 18.9286 23.0945 18.9286 27C18.9286 30.9054 22.0946 34.0714 26 34.0714C29.9055 34.0714 33.0715 30.9054 33.0715 27C33.0715 23.0945 29.9055 19.9285 26 19.9285ZM17.3572 27C17.3572 22.2267 21.2267 18.3571 26 18.3571C30.7734 18.3571 34.6429 22.2267 34.6429 27C34.6429 31.7733 30.7734 35.6428 26 35.6428C21.2267 35.6428 17.3572 31.7733 17.3572 27Z"
      fill="#3D9BD6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9999 26.2143C26.4339 26.2143 26.7857 26.5661 26.7857 27V30.1429C26.7857 30.5768 26.4339 30.9286 25.9999 30.9286C25.566 30.9286 25.2142 30.5768 25.2142 30.1429V27C25.2142 26.5661 25.566 26.2143 25.9999 26.2143Z"
      fill="#3D9BD6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9999 24.6428C26.4339 24.6428 26.7857 24.2911 26.7857 23.8571C26.7857 23.4232 26.4339 23.0714 25.9999 23.0714C25.566 23.0714 25.2142 23.4232 25.2142 23.8571C25.2142 24.2911 25.566 24.6428 25.9999 24.6428Z"
      fill="#3D9BD6"
    />
  </svg>
)

export default ResourceInfoIcon
