import React, { useRef, useContext } from 'react'
import './UserMenu.scss'
import { NavLink, Link } from 'react-router-dom'
import { useDetectOutsideClick } from '@web/common/hooks'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useTranslation } from 'react-i18next'
import { I18N, SUPPORT_EMAIL, ROUTES } from '@web/_constants'
import { classNames } from '@web/_utils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Avatar from '@web/js/components/Avatar'
import { AuthContext } from '@web/js/context/AuthContext'

const UserMenu: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { lessThan } = useResponsive()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
  const { user } = useContext(AuthContext)

  const userMenuTriggerClasses = classNames({
    'menu-trigger': true,
    open: isActive,
  })

  const userMenuClasses = classNames({
    menu: true,
    animated: true,
    active: isActive,
    inactive: !isActive,
  })

  if (!user) return null

  return (
    <div id="user-menu">
      <button
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          setIsActive(!isActive)
        }}
        className={userMenuTriggerClasses}
      >
        {lessThan.sm && <ExpandMoreIcon />}
        <Avatar user={user} />
        {!lessThan.sm && (
          <>
            <span>{user.displayName}</span>
            <ExpandMoreIcon />
          </>
        )}
      </button>

      <div ref={dropdownRef} className={userMenuClasses}>
        <ul>
          <li>
            <NavLink
              to={ROUTES.profile}
              onClick={() => setIsActive(false)}
              className="my-profile"
            >
              {t('myProfile')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={ROUTES.invite}
              onClick={() => setIsActive(false)}
              className="invite-coworkers"
            >
              {t('inviteCoworkers')}
            </NavLink>
          </li>
          <li className="separator" />
          <li>
            <NavLink
              to={ROUTES.settings}
              onClick={() => setIsActive(false)}
              className="settings"
            >
              {t('settings')}
            </NavLink>
          </li>
          <li className="separator" />
          <li>
            <Link
              to="#"
              className="contact-support"
              onClick={(e) => {
                setIsActive(false)
                window.open(`mailto:${SUPPORT_EMAIL}`)
                e.preventDefault()
              }}
            >
              {t('contactSupport')}
            </Link>
          </li>
          <li className="separator" />
          <li>
            <NavLink to={ROUTES.logout} className="sign-out">
              {t('signOut')}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default UserMenu
