import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'
import { CHAT } from '@web/_constants'
import _ from 'lodash'

const apiUrl = Config.API_URL
const apiUrlV2 = Config.API_URL_V2

interface ISendMessage {
  channelId: string
  body?: string
  nonce?: string
  sampleIds?: string[]
  contactId?: string
}

interface IForwardMessage {
  messageId: string
  forwardedComment: string | null
  userId: string | null
  email: string | null
  comment: boolean | null
}

interface IBrandResource {
  optionalMessage?: string | null
  userId?: string | null
  email?: string | null
  brandResourceId: string
}

export const ChatService = {
  getChannels: (): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, url: '/user/channels' })
  },

  getChannel: (channelId: string): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, url: `/channel/${channelId}` })
  },

  getUnreadCount: (): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrlV2, url: '/user/channels/unreadCount' })
  },

  sendMessageViaEmail: (
    email: string,
    body: string
  ): Promise<AxiosResponse> => {
    return axios({
      baseURL: apiUrlV2,
      url: '/messages',
      method: 'post',
      data: {
        email,
        body,
      },
    })
  },

  getRecipients: (
    q: string | null,
    signal: AbortSignal
  ): Promise<AxiosResponse> => {
    const url = `/messages/contacts?&q=${q ? encodeURIComponent(q) : ''}`
    return axios({
      baseURL: apiUrlV2,
      url,
      signal,
    })
  },

  forwardMessage: ({
    messageId,
    forwardedComment,
    userId,
    email,
    comment,
  }: IForwardMessage): Promise<AxiosResponse> => {
    const data = {
      messageId,
      ...(forwardedComment && { forwardedComment }),
      ...(userId && { userId }),
      ...(email && { email }),
      ...(_.isBoolean(comment) && { comment }),
    }

    return axios({
      baseURL: apiUrlV2,
      url: '/messages/forward',
      method: 'post',
      data,
    })
  },

  getShareableContacts: (
    channelId: string,
    q: string | null,
    signal: AbortSignal
  ): Promise<AxiosResponse> => {
    const url = `/contacts/shareable-contacts?channelId=${encodeURIComponent(
      channelId
    )}&q=${q ? encodeURIComponent(q) : ''}`

    return axios({
      baseURL: apiUrlV2,
      url,
      signal,
    })
  },

  getForwardMessageUsers: (
    messageId: string,
    q: string | null,
    comment: number,
    signal: AbortSignal
  ): Promise<AxiosResponse> => {
    const url = `/messages/forward/contacts?messageId=${encodeURIComponent(
      messageId
    )}&comment=${comment}&q=${q ? encodeURIComponent(q) : ''}`

    return axios({
      baseURL: apiUrlV2,
      url,
      signal,
    })
  },

  getSamples: (repId: string, signal: AbortSignal): Promise<AxiosResponse> => {
    return axios({
      baseURL: apiUrl,
      url: `/reps/${repId}/content`,
      signal,
    })
  },

  getMessages: (
    channelId: string,
    index: number | undefined,
    count = CHAT.count,
    direction = CHAT.desc
  ): Promise<AxiosResponse> => {
    const params = {
      ...(index && { index }),
      direction,
      count,
    }

    return axios({
      baseURL: apiUrl,
      url: `/channels/${channelId}/messages`,
      params,
    })
  },

  sendMessage: ({
    channelId,
    body,
    nonce,
    sampleIds,
    contactId,
  }: ISendMessage): Promise<AxiosResponse> => {
    const data = {
      ...(body && { body }),
      ...(nonce && { nonce }),
      ...(sampleIds && { sampleIds }),
      ...(contactId && { contactId }),
    }

    return axios({
      baseURL: apiUrlV2,
      method: 'post',
      url: `/channels/${channelId}/messages`,
      data,
    })
  },

  sendBrandResource: ({
    optionalMessage,
    userId,
    email,
    brandResourceId,
  }: IBrandResource): Promise<AxiosResponse> => {
    const data = {
      brandResourceId,
      ...(email && { email }),
      ...(userId && { userId }),
      ...(optionalMessage && { optionalMessage }),
    }

    return axios({
      baseURL: apiUrlV2,
      method: 'post',
      url: `/messages/brands`,
      data,
    })
  },

  isTyping: (channelId: string, typing = false): Promise<AxiosResponse> => {
    const data = { typing }

    return axios({
      baseURL: apiUrl,
      method: 'patch',
      url: `/user/channels/${channelId}`,
      data,
    })
  },
}
