import React, { useEffect, useState, useContext } from 'react'
import './Office.scss'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { I18N, LOGGING, MODALS, ROUTES } from '@web/_constants'
import { OfficesService } from '@web/_services'
import { Connection, HCP, Office } from '@web/_types'
import { formatPhoneNumber, formatText } from '@web/_utils'
import _ from 'lodash'
import Linkify from 'linkify-react'
import JoinIcon from '@web/common/img/JoinIcon'
import LeaveIcon from '@web/common/img/LeaveIcon'
import EditIcon from '@web/common/img/EditIcon'
import CheckThinIcon from '@web/common/img/CheckThinIcon.svg'
import OfficeIcon from '@web/common/img/OfficeIcon.svg'
import Button from '@web/js/components/Button'
import LocationIcon from '@web/common/img/LocationIcon'
import PhoneIcon from '@web/common/img/PhoneIcon'
import DetailsBody from '@web/js/components/DetailsBody'
import DetailsHeader from '@web/js/components/DetailsHeader'
import DetailsWrapper from '@web/js/components/DetailsWrapper'
import ContactCard from '@web/js/components/ContactCard'
import CardList from '@web/js/components/CardList'
import NotFound from '../NotFound'
import { AuthContext } from '@web/js/context/AuthContext'
import { ToastContext } from '@web/js/context/ToastContext'
import OfficeHours from '@web/js/components/OfficeHours'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import { hasProfile } from '@web/_guards'
import MoreMenu from '@web/js/components/MoreMenu'
import { ModalContext } from '@web/js/context/ModalContext'

interface RouterParams {
  id: string
}

const Office: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams<keyof RouterParams>() as RouterParams
  const { t } = useTranslation(I18N.namespaces.web)

  const { addToast } = useContext(ToastContext)
  const { user } = useContext(AuthContext)
  const { showModal } = useContext(ModalContext)

  const [office, setOffice] = useState<Office | null>(null)
  const [hcps, setHcps] = useState<Connection[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isOfficeMember, setIsOfficeMember] = useState(false)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      const getOfficeResponse = await OfficesService.getOffice(id)
      parseOffice(getOfficeResponse)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      addToast(t('errorGeneric'))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseOffice = (response: { data: any }) => {
    const data = response.data
    const officeId = data.officeId
    const office = data.dossier.offices[officeId]
    setOffice(office)

    const hcps = _.map(data.memberIds, (memberId) => {
      const hcp = data.dossier.hcps[memberId]
      return hcp
    })
    setHcps(hcps)

    const isOfficeMember = _.chain(hcps)
      .map('id')
      .includes(user?.id as string)
      .value()
    setIsOfficeMember(isOfficeMember)
  }

  const joinOffice = async () => {
    try {
      const joinOfficeResponse = await OfficesService.joinOffice(id)
      parseOffice(joinOfficeResponse)
    } catch (error) {
      console.log(error)
      addToast(t('errorGeneric'))
    }
  }

  const goToProfile = (hcp: HCP) => {
    const eventMessage = {
      profileId: hcp.id,
      actionType: LOGGING.ACTION_TYPE.VIEW_PROFILE,
      actionDetail: LOGGING.ACTION_DETAIL.OFFICE_DETAIL,
      referenceId: (office as Office).id,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )

    navigate(`/hcp/${hcp.id}`)
  }

  if (isLoading) return null
  if (!isLoading && !office) return <NotFound />

  return (
    <DetailsWrapper id="office-route">
      <DetailsHeader
        id="office-header"
        classes={isOfficeMember ? 'with-actions' : ''}
      >
        <>
          <div id="office-icon">
            <img src={OfficeIcon} alt="Office Icon" />
          </div>

          {isOfficeMember && (
            <MoreMenu id="office-more-actions">
              <ul>
                <li>
                  <button
                    onClick={() => {
                      showModal({
                        name: MODALS.CONFIRM_LEAVE_OFFICE_MODAL,
                        data: {
                          parseOffice,
                          office,
                        },
                      })
                    }}
                  >
                    {t('offices.leave')}
                  </button>
                </li>
              </ul>
            </MoreMenu>
          )}

          <div id="office-details">
            <div id="office-name" className="text-extra-large-semibold">
              {office?.name}
            </div>

            {(!!office?.postalCode || !!office?.phones.length) && (
              <div id="office-location-phone">
                {!!office?.postalCode && (
                  <div id="office-location">
                    <LocationIcon />
                    <span>
                      {t('offices.postalCode', { code: office?.postalCode })}
                    </span>
                  </div>
                )}

                {!!office?.phones.length && (
                  <div id="office-phone">
                    <PhoneIcon />
                    <span>
                      {office?.phones[0].label}:{' '}
                      <a
                        href="#"
                        onClick={() =>
                          window.open(`tel:${office?.phones[0].number}`)
                        }
                      >
                        {formatPhoneNumber(office?.phones[0].number as string)}
                      </a>
                      {office?.phones[0].extension && (
                        <>
                          &nbsp;
                          {t('ext')} {office?.phones[0].extension}
                        </>
                      )}
                    </span>
                  </div>
                )}
              </div>
            )}

            {(isOfficeMember || user?.countryCode === office?.countryCode) && (
              <div id="office-actions">
                {isOfficeMember && (
                  <div>
                    <Button
                      size="sm"
                      outline={true}
                      iconFront={<EditIcon />}
                      onClick={() =>
                        navigate(`${ROUTES.office}/${office?.id}/edit`)
                      }
                    >
                      {t('offices.edit')}
                    </Button>
                  </div>
                )}

                <div>
                  {isOfficeMember && (
                    <Button
                      size="sm"
                      style="warning"
                      stateOnHover={{
                        outline: true,
                        children: t('offices.leave') as string,
                        iconFront: <LeaveIcon />,
                      }}
                      onClick={() => {
                        showModal({
                          name: MODALS.CONFIRM_LEAVE_OFFICE_MODAL,
                          data: {
                            parseOffice,
                            office,
                          },
                        })
                      }}
                      iconFront={<img src={CheckThinIcon} />}
                    >
                      {t('offices.joined')}
                    </Button>
                  )}

                  {!isOfficeMember &&
                    user?.countryCode === office?.countryCode && (
                      <Button
                        size="sm"
                        style="warning"
                        outline={true}
                        onClick={joinOffice}
                        iconFront={<JoinIcon />}
                      >
                        {t('offices.join')}
                      </Button>
                    )}
                </div>
              </div>
            )}
          </div>
        </>
      </DetailsHeader>

      <DetailsBody id="office-body">
        <div id="office-info">
          <div className="text-headline">{t('offices.info')}</div>
          {office?.description ? (
            <Linkify
              options={{
                target: { url: '_blank' },
              }}
            >
              <div id="office-info-description">
                {formatText(office.description)}
              </div>
            </Linkify>
          ) : (
            <div id="no-office-info">{t('offices.noInfo')}</div>
          )}
        </div>

        {((office && !!office.hours.length) || (office && isOfficeMember)) && (
          <OfficeHours
            office={office}
            onSave={init}
            isOfficeMember={isOfficeMember}
          />
        )}

        <div id="office-people">
          <div className="text-headline">
            {t('offices.peopleCount', { count: hcps.length })}
          </div>

          <CardList>
            {_.map(hcps, (hcp) => {
              const clickable = hasProfile(hcp) && hcp.id !== (user as HCP).id

              return (
                <ContactCard
                  showTitle={true}
                  contact={hcp}
                  key={hcp.id}
                  showMyself={true}
                  showContactStatus={true}
                  {...(clickable && {
                    onClick: () => goToProfile(hcp as HCP),
                  })}
                />
              )
            })}
          </CardList>
        </div>
      </DetailsBody>
    </DetailsWrapper>
  )
}

export default Office
