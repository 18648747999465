import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
  ReactNode,
  useRef,
} from 'react'
import { ConfigService } from '@web/_services'
import { useLocalStorage } from '@web/common/hooks'
import {
  SUPPORTED_BROWSERS,
  LOCALSTORAGE,
  APP_LINKS,
  I18N,
  LOGGING,
  AXIOS,
  ROUTES,
  SURNAME_ORDER_COUNTRIES,
} from '@web/_constants'
import AuthenticationLayout from '@web/js/layouts/AuthenticationLayout'
import { useResponsive } from '@farfetch/react-context-responsive'
import { AuthContext } from '@web/js/context/AuthContext'
import { isMobile, isIPad } from '@admin/common/js/IsMobile'
import Bowser from 'bowser'
import UnsupportedBrowser from '../components/UnsupportedBrowser'
import { getQueryStringParams } from '@web/_utils'
import MeetingUnsupportedBrowser from '../components/MeetingUnsupportedBrowser'
import { getLang } from '@web/common/js/i18n'
import { EngageHostService } from '@web/_services/EngageHostService'
import { matchRoutes, useLocation } from 'react-router-dom'
import { ConfigType } from '@web/_types'
import Config from '@web/_config'
import _ from 'lodash'

//DayJS
import dayjs from 'dayjs'
I18N.supportedLanguages.map((lang) => {
  try {
    return require(`dayjs/locale/${lang.toLowerCase()}.js`)
  } catch (error) {
    return
  }
})

import isToday from 'dayjs/plugin/isToday'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isoWeek from 'dayjs/plugin/isoWeek'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
import { useDispatch } from 'react-redux'
import { setFlush } from '../redux/logger/loggerSlice'
import { createLog, initLogger } from '../redux/logger/loggerActions'

dayjs.extend(isToday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(LocalizedFormat)
dayjs.extend(isTomorrow)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(minMax)

export type IAppContext = {
  appLoading: boolean
  config: ConfigType | null
  countryCode: string | null
  downloadAppUrl: string | null
  isLocale24h: boolean
  isSurnameOrdered: boolean
}

export const AppContext = createContext<IAppContext>({
  appLoading: true,
  config: null,
  countryCode: null,
  downloadAppUrl: null,
  isLocale24h: true,
  isSurnameOrdered: false,
})

interface IAppProvider {
  children?: ReactNode
}

export const AppProvider: React.FC<IAppProvider> = ({ children }) => {
  const controller = new AbortController()
  const dispatch = useDispatch()
  const location = useLocation()
  const hideAuthenticationLayoutLoader = matchRoutes(
    [
      { path: ROUTES.dbc },
      { path: ROUTES.repId },
      { path: ROUTES.linkMobileWelcome },
      { path: ROUTES.linkMobileLink },
      { path: ROUTES.unsubscribe },
      { path: ROUTES.meeting },
    ],
    location
  )

  const [appLoading, setAppLoading] = useState<boolean>(true)
  const [isLocale24h, setIsLocale24h] = useState<boolean>(true)
  const [config, setConfig] = useState<ConfigType | null>(null)
  const { authenticated, user } = useContext(AuthContext)
  const [isSupportedBrowser, setIsSupportedBrowser] = useState<boolean>(true)
  const [meetingId, setMeetingId] = useState<string | undefined>()
  const [meetingPwd, setMeetingPwd] = useState<string | undefined>()
  const [downloadAppUrl, setDownloadAppUrl] = useState<string | null>(null)
  const [isSurnameOrdered, setIsSurnameOrdered] = useState(false)
  const { orientation } = useResponsive()

  const [countryCode, setCountryCode] = useLocalStorage<string | null>(
    LOCALSTORAGE.countryCode,
    null
  )
  const [locale, setLocale] = useLocalStorage<string | null>(
    LOCALSTORAGE.locale,
    null
  )

  const locationRef = useRef({})

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    document.body.classList.remove('mobile')
    document.body.classList.remove('landscape')
    document.body.classList.remove('portrait')

    if (isMobile) {
      document.body.classList.add('mobile')
      if (orientation) document.body.classList.add(orientation as string)
    }
  }, [isMobile, orientation])

  useEffect(() => {
    const keylessLocation = _.omit(location, 'key')
    if (!_.isEqual(locationRef.current, keylessLocation)) {
      const wasRedirected = location.state && !!location.state.redirected
      logNavigation(wasRedirected)
      locationRef.current = keylessLocation
    }
  }, [location])

  useEffect(() => {
    if (countryCode) {
      setIsSurnameOrdered(_.includes(SURNAME_ORDER_COUNTRIES, countryCode))
    }
  }, [countryCode])

  useEffect(() => {
    if (!appLoading) fetchConfig()
  }, [user?.countryCode])

  useEffect(() => {
    if (config) {
      dispatch(
        setFlush({
          count: config.androidLogFlushCount,
          delay: config.androidLogFlushDelay,
        })
      )
      EngageHostService.setHosts(config.engageApiHost, config.engageBaseHost)
    }
  }, [config])

  useEffect(() => {
    if (locale) dayjs.locale(locale.toLowerCase())
  }, [locale])

  const init = async () => {
    if (location.pathname === ROUTES.redirect) {
      setAppLoading(false)
      return
    }

    dispatch(initLogger())

    //supported browsers
    const browser = Bowser.detect(window.navigator.userAgent)

    if (!SUPPORTED_BROWSERS.has(browser.name)) {
      setIsSupportedBrowser(false)
      const queryArgs = getQueryStringParams(window.location.search)
      setMeetingId(queryArgs.m ?? null)
      setMeetingPwd(queryArgs.p ?? null)
    }

    const bowserLocale = getLang()
    setLocale(bowserLocale)
    setIsLocale24h(
      !new Intl.DateTimeFormat(getLang(), { hour: 'numeric' })
        .format(0)
        .match(/\s/)
    )

    await fetchConfig()
    setAppLoading(false)
  }

  const logNavigation = (wasRedirected: boolean) => {
    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.NAV,
        wasRedirected
          ? LOGGING.EVENT_MESSAGES.REDIRECTED_TO
          : LOGGING.EVENT_MESSAGES.NAVIGATED_TO,
        location.pathname
      )
    )
  }

  const fetchConfig = async () => {
    let countryCode: string | null = null

    try {
      //set offline engage meeting config
      const timer = setTimeout(() => {
        controller.abort(AXIOS.canceled)
        setCountryCode(countryCode)
        setConfig(Config.DEFAULT_CONFIG)
        return
      }, 2000)

      if (user) {
        countryCode = user.countryCode
      } else {
        const getCountryResponse = await ConfigService.getCountry(
          controller.signal
        )
        countryCode = getCountryResponse.data.countryCode
      }

      const getConfigResponse = await ConfigService.getConfig(
        countryCode as string,
        controller.signal
      )

      clearTimeout(timer)
      setCountryCode(countryCode)
      setConfig(getConfigResponse.data)
    } catch (error) {
      console.log(error)
    }

    if (isMobile || isIPad) {
      const isAndroid = Bowser.android
      const isChina = countryCode === 'CN'
      const downloadUrl = isAndroid
        ? isChina
          ? APP_LINKS.androidCN
          : APP_LINKS.engagePlayStore
        : APP_LINKS.engageAppStore
      setDownloadAppUrl(downloadUrl)
    }
  }

  const contextValue = useMemo(
    () => ({
      appLoading,
      config,
      countryCode,
      downloadAppUrl,
      isLocale24h,
      isSurnameOrdered,
    }),
    [appLoading, isSupportedBrowser, isSurnameOrdered, config, countryCode]
  )

  if (!isSupportedBrowser) {
    const isMeetingLink = !!meetingId && !!meetingPwd
    return (
      <AppContext.Provider value={contextValue}>
        <AuthenticationLayout>
          {isMeetingLink ? (
            <MeetingUnsupportedBrowser
              meetingId={meetingId ?? ''}
              meetingPwd={meetingPwd ?? ''}
            />
          ) : (
            <UnsupportedBrowser />
          )}
        </AuthenticationLayout>
      </AppContext.Provider>
    )
  }

  //keeping behavior of showing nothing while config loads for authed users (for now)
  if (appLoading)
    return authenticated || hideAuthenticationLayoutLoader ? null : (
      <AuthenticationLayout />
    )

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}
