import React, { useContext, useState } from 'react'
import {
  I18N,
  NOTIFICATION,
  SCHEDULING_ACCEPTED_NOTIFICATIONS,
  SCHEDULING_SCH_CRM_NOTIFICATIONS,
  SCHEDULING_DECLINED_NOTIFICATIONS,
  SCHEDULING_INVITE_NOTIFICATIONS,
  MODALS,
} from '@web/_constants'
import { useTranslation } from 'react-i18next'
import MeetingRequestIcon from '@web/common/img/MeetingRequestIcon.svg'
import MeetingRequestAccepted from '@web/common/img/MeetingRequestAccepted.svg'
import MeetingRequestDeclined from '@web/common/img/MeetingRequestDeclined.svg'
import ThumbsUp from '@web/common/img/ThumbsUp.svg'
import ThumbsDown from '@web/common/img/ThumbsDown.svg'
import Button from '@web/js/components/Button'
import './MeetingNotification.scss'
import FriendlyDate from '../FriendlyDate'
import { NotificationRequest } from '@web/_types'
import { getLang } from '@web/common/js/i18n'
import { MeetingRequestService } from '@web/_services/MeetingRequestService'
import 'dayjs'
import { classNames, formatPhoneNumber } from '@web/_utils'
import { hasProfile } from '@web/_guards'
import _ from 'lodash'
import { ModalContext } from '@web/js/context/ModalContext'

interface MeetingNotificationProps {
  active: boolean
  notification: NotificationRequest
  openContactDetails: (connectionRequest: NotificationRequest) => void
}

const MeetingNotification: React.FC<MeetingNotificationProps> = ({
  active,
  notification,
  openContactDetails,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { showModal } = useContext(ModalContext)
  const {
    contact,
    date,
    scheduleDetails,
    scheduleAdvDetails,
    type,
    notificationId,
    scheduleCrmDetails,
  } = notification

  const [isSimpleSchedule] = useState<boolean>(!!scheduleDetails?.timeslot)
  const [isInvite, setIsInvite] = useState<boolean>(
    _.includes(SCHEDULING_INVITE_NOTIFICATIONS, type)
  )
  const [isRequest] = useState<boolean>(type === NOTIFICATION.crmMeetingRequest)
  const [isAccepted] = useState<boolean>(
    _.includes(SCHEDULING_ACCEPTED_NOTIFICATIONS, type)
  )
  const [isDeclined] = useState<boolean>(
    _.includes(SCHEDULING_DECLINED_NOTIFICATIONS, type)
  )
  const [isCrmNotification] = useState<boolean>(
    _.includes(SCHEDULING_SCH_CRM_NOTIFICATIONS, type)
  )

  const [requestImage, setRequestImage] = useState(
    isRequest || isInvite
      ? MeetingRequestIcon
      : isAccepted
      ? MeetingRequestAccepted
      : MeetingRequestDeclined
  )

  let initialTitleText = ''
  if (isInvite) {
    initialTitleText = t('meetingNotification.newInvite')
  } else if (isRequest) {
    initialTitleText = t('meetingNotification.sentMeetingRequest')
  } else if (isAccepted) {
    initialTitleText = isCrmNotification
      ? t('meetingNotification.acceptedRequest')
      : t('meetingNotification.acceptedInvite')
  } else if (isDeclined) {
    initialTitleText = isCrmNotification
      ? t('meetingNotification.declinedRequest')
      : t('meetingNotification.declinedInvite')
  }

  const [titleText, setTitleText] = useState<string>(initialTitleText)
  const [accepted, setAccepted] = useState<boolean>(false)
  const [declined, setDeclined] = useState<boolean>(false)

  const cx = {
    'meeting-notification': true,
    clickable: hasProfile(contact),
    active,
  }
  const notificationClasses = classNames(cx)

  //have to get UTC values for date, month, and year to prevent time zone issues
  const advDetails = scheduleAdvDetails || scheduleCrmDetails
  const scheduledDateObject =
    (scheduleDetails && new Date(scheduleDetails.date)) ||
    (advDetails && new Date(advDetails.dateTime))
  if (!scheduledDateObject) {
    return null
  }
  const parsedDate = {
    month: scheduledDateObject.getUTCMonth(),
    numericDate: scheduledDateObject.getUTCDate(),
    year: scheduledDateObject.getUTCFullYear(),
    hours: scheduledDateObject.getUTCHours(),
    minutes: scheduledDateObject.getUTCMinutes(),
  }

  const { month, numericDate, year, hours, minutes } = parsedDate
  const scheduledDate = isSimpleSchedule
    ? new Date(year, month, numericDate, hours, minutes)
    : new Date(Date.UTC(year, month, numericDate, hours, minutes))

  const isThisYear = scheduledDate.getFullYear() === new Date().getFullYear()

  let dateString = isThisYear
    ? scheduledDate.toLocaleDateString(getLang(), {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
      })
    : scheduledDate.toLocaleDateString(getLang(), {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })

  if (advDetails) {
    const timeString = scheduledDate.toLocaleTimeString(getLang(), {
      hour: 'numeric',
      minute: '2-digit',
    })
    dateString = `${dateString} • ${timeString}`
  }

  const acceptRequest = async () => {
    try {
      await MeetingRequestService.respondToMeetingRequest(notificationId, true)
      setTitleText(t('meetingNotification.acceptedInvite'))
      setIsInvite(false)
      setRequestImage(MeetingRequestAccepted)
      setAccepted(true)
    } catch (error) {
      handleError(error)
    }
  }

  const declineRequest = async () => {
    try {
      await MeetingRequestService.respondToMeetingRequest(notificationId, false)
      setTitleText(t('meetingNotification.declinedInvite'))
      setIsInvite(false)
      setRequestImage(MeetingRequestDeclined)
      setDeclined(true)
    } catch (error) {
      handleError(error)
    }
  }

  const handleError = (error: unknown) => {
    console.log(error)
    showModal({
      name: MODALS.GENERIC_ERROR_MODAL,
      data: {
        error: { body: t('errorGeneric') },
      },
    })
  }

  let meetingType: string | null = null
  if (advDetails?.meetingType == 'IN_PERSON') {
    meetingType = t('schedulingView.meetingType.inPerson')
    if (advDetails?.officeName) {
      meetingType = `${meetingType} • ${advDetails.officeName}`
    }
  } else if (advDetails?.meetingType == 'VIRTUAL') {
    meetingType = t('schedulingView.meetingType.virtual')
  } else if (advDetails?.meetingType == 'PHONE') {
    meetingType = t('schedulingView.meetingType.phone')
    if (advDetails?.phoneNumber) {
      meetingType += ' • ' + formatPhoneNumber(advDetails.phoneNumber)
      if (advDetails?.phoneExtension) {
        meetingType += `, ${t('ext')} ${advDetails.phoneExtension}`
      }
    }
  }

  return (
    <div
      className={notificationClasses}
      onClick={(e) => {
        e.stopPropagation()
        openContactDetails(notification)
      }}
    >
      <div className="meeting-notification--title">
        <span className="text-small">{titleText}</span>
        <FriendlyDate date={date} classes="text-small" />
      </div>

      <div className="request-body">
        <img src={requestImage} />

        <div className="request-info">
          <div className="requestor-name text-default-semibold">
            {contact.displayName}
          </div>

          <div className="text-small-semibold">{dateString}</div>

          {scheduleDetails?.timeslot && (
            <div>
              {t(
                `schedulingView.times.${scheduleDetails.timeslot.toLowerCase()}`
              )}
            </div>
          )}

          {meetingType && (
            <div className="request-meeting-type">{meetingType}</div>
          )}

          {accepted && (
            <div className="request-response text-bold accepted">
              {t('meetingNotification.inviteAccepted')}
            </div>
          )}

          {declined && (
            <div className="request-response text-bold declined">
              {t('meetingNotification.inviteDeclined')}
            </div>
          )}

          {isInvite && (
            <div className="response-buttons">
              <Button
                size="sm"
                style="success"
                iconFront={<img src={ThumbsUp} />}
                onClick={(e) => {
                  e.stopPropagation()
                  acceptRequest()
                }}
              >
                {t('meetingNotification.accept')}
              </Button>
              <Button
                size="sm"
                style="danger"
                iconFront={<img src={ThumbsDown} />}
                onClick={(e) => {
                  e.stopPropagation()
                  declineRequest()
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MeetingNotification
