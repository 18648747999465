import * as React from 'react'

const ResourceDocumentIcon: React.FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-resource-document"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2258 5C19.7781 5 20.2258 5.44772 20.2258 6V8.41753H21.2746C21.8269 8.41753 22.2746 8.86525 22.2746 9.41753V10.9814C22.2746 11.4529 21.8924 11.8351 21.4209 11.8351V11.8351C20.9495 11.8351 20.5673 12.2173 20.5673 12.6887V32.3655C20.5673 32.9178 20.1195 33.3655 19.5673 33.3655H9.61587C9.06359 33.3655 8.61587 32.9178 8.61587 32.3655V12.518C8.61587 12.1408 8.31011 11.8351 7.93294 11.8351V11.8351C7.55576 11.8351 7.25 11.5293 7.25 11.1521V9.41753C7.25 8.86525 7.69772 8.41753 8.25 8.41753H9.29881V6C9.29881 5.44772 9.74652 5 10.2988 5H19.2258Z"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M21.5917 8.41754H7.93294C7.55576 8.41754 7.25 8.72356 7.25 9.10105V11.1516C7.25 11.5291 7.55576 11.8351 7.93294 11.8351H21.5917C21.9688 11.8351 22.2746 11.5291 22.2746 11.1516V9.10105C22.2746 8.72356 21.9688 8.41754 21.5917 8.41754Z"
      fill="white"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M19.5429 5H9.98176C9.60459 5 9.29883 5.30602 9.29883 5.68351V7.73403C9.29883 8.11152 9.60459 8.41753 9.98176 8.41753H19.5429C19.92 8.41753 20.2258 8.11152 20.2258 7.73403V5.68351C20.2258 5.30602 19.92 5 19.5429 5Z"
      fill="white"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <path
      d="M15.1036 16.6196H8.61572V27.8975H15.1036V16.6196Z"
      stroke="black"
      strokeOpacity="0.9"
      strokeWidth="1.5"
    />
    <mask id="path-5-inside-1" fill="white">
      <rect x="14" y="15" width="16" height="20" rx="1" />
    </mask>
    <rect x="14" y="15" width="16" height="20" rx="1" fill="white" />
    <rect
      x="14"
      y="15"
      width="16"
      height="20"
      rx="1"
      fill="#007AFF"
      fillOpacity="0.1"
    />
    <rect
      x="14"
      y="15"
      width="16"
      height="20"
      rx="1"
      stroke="#3D9BD6"
      strokeWidth="3"
      mask="url(#path-5-inside-1)"
    />
    <line
      x1="18.75"
      y1="19.85"
      x2="25.25"
      y2="19.85"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="18.75"
      y1="23.25"
      x2="25.25"
      y2="23.25"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="18.75"
      y1="26.45"
      x2="25.25"
      y2="26.45"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="18.75"
      y1="29.75"
      x2="25.25"
      y2="29.75"
      stroke="#3D9BD6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default ResourceDocumentIcon
