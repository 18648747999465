import React, { createContext, useState, useContext, ReactNode } from 'react'
import { Connection } from '@web/_types'
import { UserService, ContactService } from '@web/_services'
import { isRep } from '@web/_guards'
import _ from 'lodash'
import { parseContacts } from '@web/_utils'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import { ToastContext } from './ToastContext'

interface ConnectionContextData {
  connections: Connection[]
  findConnection: (id: string) => Connection | null
  updateConnection: (connection: Connection) => void
  getConnections: () => void
  setConnections: (connections: Connection[]) => void
  removeConnection: (connection: Connection, _callback?: () => void) => void
  connect: (
    connection: Connection,
    contactSourceType: string,
    _callback?: () => void
  ) => void
}

const ConnectionContextDefaultValue: ConnectionContextData = {
  connections: [],
  findConnection: () => null,
  updateConnection: () => null,
  getConnections: () => null,
  setConnections: () => null,
  removeConnection: () => null,
  connect: () => null,
}
interface IConnectionProvider {
  children?: ReactNode
}

const useConnectionContextValue = (): ConnectionContextData => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { addToast } = useContext(ToastContext)
  const [disconnectedHcps, setDisconnectedHcps] = useState<Connection[]>([])
  const [connections, setConnections] = useState<Connection[]>([])

  const removeConnection = async (
    connection: Connection,
    _callback?: () => void
  ) => {
    if (!connection) return
    const contactId = connection.id

    try {
      if (isRep(connection)) {
        await ContactService.deleteRepConnection(contactId)
      } else {
        const cx = { ...connection, isContact: false }
        await ContactService.deleteHcpConnection(contactId)
        setDisconnectedHcps([...disconnectedHcps, cx])
      }

      addToast(
        t('noLongerConnectedTo', { name: connection.displayName }),
        'success',
        true,
        4000
      )
      getConnections()
      if (_callback) _callback()
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const connect = async (
    connection: Connection,
    contactSourceType: string,
    _callback?: () => void
  ) => {
    if (!connection) return
    const contactId = connection.id

    try {
      await ContactService.connectRep(contactId, contactSourceType)
      addToast(
        t('connectionEstablished', { connection: connection.displayName }),
        'success',
        true,
        4000
      )
      getConnections()

      if (_callback) _callback()
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const findConnection = (id: string) => {
    if (!connections.length) return null
    const match = [...connections, ...disconnectedHcps].find(
      (connection) => connection.id === id
    )
    return match || null
  }

  const updateConnection = (connection: Connection) => {
    if (!connections.length) {
      setConnections([connection])
    } else {
      const updatedConnections = _.map(connections, (cx: Connection) => {
        if (connection.id === cx.id) cx = connection
        return cx
      })

      setConnections(updatedConnections)
    }
  }

  const getConnections = async () => {
    const getConnectionsResponse = await UserService.getConnections()

    if (getConnectionsResponse) {
      const connectionsData = parseContacts(getConnectionsResponse.data)

      setConnections(
        _.orderBy(
          connectionsData,
          [
            (cx: Connection) => cx.lastName.toLowerCase(),
            (cx: Connection) => cx.firstName.toLowerCase(),
          ],
          ['asc', 'asc']
        )
      )
    }
  }

  return {
    connections,
    findConnection,
    getConnections,
    setConnections,
    removeConnection,
    updateConnection,
    connect,
  }
}

export const ConnectionContext = createContext<ConnectionContextData>(
  ConnectionContextDefaultValue
)

export const ConnectionProvider: React.FC<IConnectionProvider> = ({
  children,
}) => {
  return (
    <ConnectionContext.Provider value={useConnectionContextValue()}>
      {children}
    </ConnectionContext.Provider>
  )
}
