import { ROUTES } from '@web/_constants'

interface IGetZoomRedirectRoute {
  showPostMeetingSignup: boolean
  showExperienceRating: boolean
  participantId: string
}

export const getZoomRedirectRoute = ({
  showPostMeetingSignup,
  showExperienceRating,
  participantId,
}: IGetZoomRedirectRoute): string => {
  let redirectRoute = showPostMeetingSignup ? ROUTES.postMeeting : ROUTES.join

  const params = new URLSearchParams({
    ...(showPostMeetingSignup && { meetingId: participantId }),
    ...(showExperienceRating && { experienceId: participantId }),
    ...(!showExperienceRating && { meetingEnded: 'true' }),
  })

  redirectRoute += `?${params.toString()}`

  return redirectRoute
}
