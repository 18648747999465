/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import './ZoomMeeting.scss'
import { ZoomService } from '@web/_services/ZoomService'
import { MeetingService } from '@web/_services/MeetingService'
import {
  ENGAGE,
  LOCALSTORAGE,
  ROUTES,
  ENGAGE_LANDING_URL,
  LOGGING,
  AXIOS,
} from '@web/_constants'
import { EngageMeetingContext } from '@web/js/context/EngageMeetingContext'
import SignatureRequest from '@web/js/components/SignatureRequest'
import { EngageLoggingContext } from '@web/js/context/EngageLoggingContext'
import { AuthContext } from '@web/js/context/AuthContext'
import {
  EngageMeetingInfo,
  EngageMeetingToken,
  MeetingActivity,
  SurveyData,
} from '@web/_types/engageApi'
import { AppContext } from '@web/js/context/AppContext'
import { useLocalStorage } from '@web/common/hooks'
import AuthenticationLayout from '@web/js/layouts/AuthenticationLayout'
import ZoomQRCode from '../ZoomQRCode'
import { cutStringLength } from '@web/_utils/cutStringLength'
import { getZoomRedirectRoute } from '@web/_utils'
import Constants from '@engage/js/Constants'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import { EngageService } from '@web/_services'
import _ from 'lodash'
import LoadingIndicator from '../LoadingIndicator'

interface ICurrentUser {
  userId: number
  userName: string
  muted: boolean
  audio: string
  isHost: boolean
  isCoHost: string
  isGuest: boolean
  isHold: boolean
  persistentID: string
  participantUUID: string
  customerKey: string
  isOriginHost: boolean
}

type Step =
  | 'LAUNCH'
  | 'WAIT'
  | 'UNRESPONSIVE'
  | 'FAIL'
  | 'LAUNCHED'
  | 'DISCONNECTED'

type ZoomMeetingProps = { displayName: string }
const ZoomMeetingInner: React.FC<ZoomMeetingProps> = ({ displayName }) => {
  const controller = new AbortController()

  const { participantId, password, clientId, info } =
    useContext(EngageMeetingContext)
  const {
    logClientEvent,
    createActivity,
    logMeetingInstance,
    logMeetingActivity,
  } = useContext(EngageLoggingContext)
  const { authenticated, sessionId } = useContext(AuthContext)
  const dispatch = useDispatch()
  const { config } = useContext(AppContext)
  const [step, setStep] = useState<Step>('LAUNCH')
  const stepRef = useRef(step)
  const [zoomUserId, setZoomUserId] = useState<string>('')

  const [, setExperience] = useLocalStorage<string | null>(
    LOCALSTORAGE.experience,
    null
  )

  const [, setSurveyData] = useLocalStorage<SurveyData | null>(
    LOCALSTORAGE.surveyData,
    null
  )

  const [accountId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingAccountId,
    null
  )
  const [, setMeetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )
  const [, setMeetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )
  const [joinType, setJoinType] = useLocalStorage<string | null>(
    LOCALSTORAGE.joinType,
    null
  )
  const [firstName] = useLocalStorage<string | null>(
    LOCALSTORAGE.firstName,
    null
  )
  const [lastName] = useLocalStorage<string | null>(LOCALSTORAGE.lastName, null)
  const [countryCode] = useLocalStorage<string | null>(
    LOCALSTORAGE.countryCode,
    null
  )

  let waitingTimeout: ReturnType<typeof setTimeout> | null = null
  let qrCodeTimeout: ReturnType<typeof setTimeout> | null = null

  useEffect(() => {
    if (participantId) startMeeting()
  }, [])

  useEffect(() => {
    stepRef.current = step
    const reactRoot = document.getElementById('reactRoot')

    if (step === 'LAUNCHED' || step === 'DISCONNECTED' || step === 'WAIT') {
      reactRoot?.classList.remove('elevate')
    } else {
      reactRoot?.classList.add('elevate')
    }

    return () => {
      reactRoot?.classList.add('elevate')
    }
  }, [step])

  //HACK: override Zoom waiting room Exit btn to go to join instead of leaveUrl
  useEffect(() => {
    let el: HTMLElement
    if (step === 'WAIT') {
      waitForElement('.waiting-room-container .leave-btn').then(
        (element: HTMLElement) => {
          el = element
          if (el) el.addEventListener('click', goToJoin)
        }
      )
    }

    return () => {
      if (el) el.removeEventListener('click', goToJoin)
    }
  }, [step])

  const goToJoin = (event: any) => {
    event.stopPropagation()
    window.location.assign(`/web${ROUTES.join}`)
  }

  const waitForElement = (selector: string) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector))
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          observer.disconnect()
          resolve(document.querySelector(selector))
        }
      })

      // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      })
    })
  }

  const logJoinError = async (e: unknown): Promise<void> => {
    const tE = e as {
      errorCode: string | number | null
      errorMessage: string | null
    }
    await logClientEvent(
      ENGAGE.LOGGING.EVENT_TYPES.ERROR_JOIN,
      tE.errorCode,
      tE.errorMessage
    )
  }

  const clearTimeouts = () => {
    clearQRCodeTimeout()
    clearWaitingTimeout()
  }

  const clearQRCodeTimeout = () => {
    if (qrCodeTimeout) {
      console.log('clear QR timeout')
      clearTimeout(qrCodeTimeout)
      qrCodeTimeout = null
    }
  }

  const clearWaitingTimeout = () => {
    if (waitingTimeout) {
      console.log('clearing waiting for host timeout')
      clearTimeout(waitingTimeout)
      waitingTimeout = null
    }
  }

  const getIntegratedSurvey = useCallback(async () => {
    let surveyData: SurveyData | null = null
    setSurveyData(surveyData)

    const timer = setTimeout(() => {
      controller.abort(AXIOS.canceled)
      return
    }, 3000)

    try {
      const { attendee, token, details } = info as EngageMeetingInfo
      const body = {
        accountId: attendee.accountId,
        userId: (attendee?.hostSalesforceId || attendee?.hostId) as string,
        meetingId: token.meetingId as string,
        country: countryCode,
      }

      const getSurveyDataResponse = await EngageService.getIntegratedSurvey(
        (attendee?.orgId || attendee?.tenantId) as string,
        details.meetingType,
        body,
        token.token, //meeting token
        controller.signal //cancel axios
      )

      surveyData = getSurveyDataResponse.data.data
    } catch (error) {
      console.log(error)
    }

    clearTimeout(timer)
    setSurveyData(surveyData)
  }, [info])

  const doJoin = useCallback(
    async (
      screenShareEnabled: boolean,
      tokenDetails: EngageMeetingToken,
      activities: Array<MeetingActivity>,
      tries = 0
    ): Promise<void> => {
      if (!info) return

      const tenantId = (info.attendee.orgId || info.attendee.tenantId) as string

      const showPostMeetingSignup =
        !authenticated && config?.supportsSignup && accountId
      const showExperienceRating =
        info?.token.clientAppParameters.isExperienceRating

      if (showExperienceRating) await getIntegratedSurvey()

      const redirectRoute = getZoomRedirectRoute({
        showPostMeetingSignup: !!showPostMeetingSignup,
        showExperienceRating: !!showExperienceRating,
        participantId,
      })

      let lastActivity: MeetingActivity | null = null

      try {
        logClientEvent(
          '',
          null,
          ENGAGE.LOGGING.EVENT_MESSAGES.INVOKE_ZOOM_SDK_JOIN
        )

        const zoomMtg = await ZoomService.init(
          `/web${redirectRoute}`,
          screenShareEnabled
        )

        zoomMtg.inMeetingServiceListener(
          'onMeetingStatus',
          (data: { meetingStatus: number }) => {
            // {meetingStatus: 1 (connecting), 2 (connected), 3 (disconnected), 4 (reconnecting)}
            if (data.meetingStatus === 1 && stepRef.current !== 'WAIT') {
              setStep('WAIT')
              clearQRCodeTimeout()
              waitingTimeout = setTimeout(() => {
                logClientEvent(
                  ENGAGE.LOGGING.EVENT_TYPES.ERROR_JOIN,
                  null,
                  ENGAGE.LOGGING.EVENT_MESSAGES.WAITING_ROOM_RETRY_TIMEOUT
                )
                window.location.assign(`/web${ROUTES.join}`)
              }, ENGAGE.WAITING_ROOM_RETRY_TIMEOUT)
            } else if (
              data.meetingStatus === 2 &&
              stepRef.current !== 'LAUNCHED'
            ) {
              setStep('LAUNCHED')
            } else if (
              data.meetingStatus === 3 &&
              stepRef.current !== 'DISCONNECTED'
            ) {
              setStep('DISCONNECTED')
            }
          }
        )

        await new Promise<void>((resolve, reject) => {
          zoomMtg.join({
            meetingNumber: Number(tokenDetails.zoomMeetingId),
            passWord: password || '',
            userName: cutStringLength(displayName, 100),
            userEmail: tokenDetails.participantEmail || '',
            signature: tokenDetails.signature,
            sdkKey: tokenDetails.sdkKey,
            customerKey: tokenDetails.participantId?.toString() || '',
            success: () => {
              console.log('join success')
              clearTimeouts()
              resolve()
            },
            error: (error: unknown) => {
              console.log('join error', error)
              reject(error)
            },
          })
        })

        logClientEvent(ENGAGE.LOGGING.EVENT_TYPES.SUCCESS_JOIN, null, null)
        const zoomUserId = await ZoomService.getUserId()

        if (info.token.meetingId) {
          lastActivity = createActivity(
            ENGAGE.LOGGING.EVENT_TYPES.PARTICIPANT_JOIN_SUCCESS
          )
          activities.push(lastActivity)
          const zoomInstanceId = await ZoomService.getInstanceId()
          logMeetingInstance(
            activities.map((a) => a),
            zoomInstanceId,
            zoomUserId,
            tenantId
          )
          activities.length = 0
        }

        setZoomUserId(zoomUserId)

        // Record Meeting
        if (authenticated) {
          MeetingService.recordAuthenticatedMeeting(
            participantId,
            password,
            info.attendee.accountId
          )
        } else {
          if (sessionId) {
            MeetingService.recordUnAuthenticatedMeeting(
              participantId,
              password,
              sessionId
            )
          } else {
            console.error('Unauthenticated sessionId not defined')
          }
        }

        setStep('LAUNCHED')

        const engageEventMessageOptions = {
          engageMeetingId: info.token.meetingId,
          participantId,
          joinedFirstName: countryCode === 'CN' ? displayName : firstName,
          joinedLastName: countryCode === 'CN' ? null : lastName,
          joinType: joinType || LOGGING.JOIN_TYPE.OTHER,
        }

        dispatch(
          createLog(
            LOGGING.EVENT_TYPES.ENGAGE_MEETING_JOIN,
            JSON.stringify(engageEventMessageOptions)
          )
        )

        let currentUser: ICurrentUser | null = null

        zoomMtg.getCurrentUser({
          success: (data: any) => {
            currentUser = data.result.currentUser
            console.log('current user', currentUser)
          },
        })

        zoomMtg.inMeetingServiceListener('onUserLeave', (data: any) => {
          if (currentUser && _.isEmpty(data)) {
            console.log('current user leaving', currentUser)

            const activity = createActivity(
              ENGAGE.LOGGING.EVENT_TYPES.PARTICIPANT_LEAVE
            )

            activities.push(activity)
            logMeetingActivity(activities, tenantId)
            activities.length = 0
          }
        })

        setExperience(participantId)
      } catch (e) {
        console.log(e)
        clearTimeouts()

        if (info.token.meetingId) {
          // Ported logic to prevent repeatedly logging failure activity on retries
          const activity = createActivity(
            ENGAGE.LOGGING.EVENT_TYPES.PARTICIPANT_JOIN_FAILURE,
            `${e.errorCode} - ${e.errorMessage}`
          )
          if (
            !lastActivity ||
            (lastActivity.activityType !== activity.activityType &&
              lastActivity.message !== activity.message)
          ) {
            lastActivity = activity
            activities.push(lastActivity)
            logMeetingActivity(activities, tenantId)
            activities.length = 0
          }
        }

        if (e.errorCode == 3004) {
          // Wrong password
          logJoinError(e)
          window.location.assign(`/web${ROUTES.join}`)
        } else if (e.errorCode == 1) {
          // Zoom Fail
          setStep('LAUNCH')
          logJoinError(e)
          if (ENGAGE.JOIN_FAIL_RETRY_INTERVAL[tries]) {
            setTimeout(
              () =>
                doJoin(screenShareEnabled, tokenDetails, activities, tries + 1),
              ENGAGE.JOIN_FAIL_RETRY_INTERVAL[tries]
            )
          } else {
            setStep('FAIL')
          }
        } else {
          // Generic Catchall Error
          logJoinError(e)
          setStep('FAIL')
        }
      }
    },
    [info, logClientEvent]
  )

  const startMeeting = useCallback(async (): Promise<void> => {
    if (!info) return
    const activities: Array<MeetingActivity> = []
    const { token: tokenDetails, details: meetingDetails } = info
    const screenShareEnabled =
      tokenDetails.clientAppParameters.eventAllowAttendeeShare ||
      meetingDetails.meetingType === 'MEETING' ||
      meetingDetails.meetingRole === Constants.SpeakerRole

    qrCodeTimeout = setTimeout(() => {
      setStep('UNRESPONSIVE')
      logClientEvent('', null, ENGAGE.LOGGING.EVENT_MESSAGES.SHOW_QR)
      return
    }, ENGAGE.QR_CODE_TIMEOUT)

    logClientEvent('', null, ENGAGE.LOGGING.EVENT_MESSAGES.CLICK_JOIN)
    activities.push(
      createActivity(ENGAGE.LOGGING.EVENT_TYPES.PARTICIPANT_JOIN_TRY)
    )
    setMeetingId(null)
    setMeetingPwd(null)
    setJoinType(null)
    await doJoin(screenShareEnabled, tokenDetails, activities)
  }, [info, logClientEvent, doJoin])

  if (!participantId) {
    window.location.assign('/web')
    return <div />
  }

  // Specifically do not use our modal component since it doesn't play nice with the bootstrap styling
  if (step === 'LAUNCHED' && zoomUserId) {
    return (
      <SignatureRequest
        zoomUserId={zoomUserId}
        meetingId={participantId}
        clientId={clientId}
      />
    )
  }

  if (_.includes(['LAUNCH', 'WAIT'], step)) {
    return null
  }

  return (
    <div className="zoom-meeting">
      <AuthenticationLayout hideConnectionLabel={true}>
        <ZoomQRCode
          qrCodeUrl={`${window.location.hostname}${ENGAGE_LANDING_URL}?j=${participantId}&pwd=${password}`}
        />
      </AuthenticationLayout>
    </div>
  )
}

const ZoomMeeting: React.FC<ZoomMeetingProps> = (props) => {
  const { info, error } = useContext(EngageMeetingContext)
  const [lsMeetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )

  const [lsMeetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )

  if (error) {
    return (
      <AuthenticationLayout hideConnectionLabel={true}>
        <ZoomQRCode
          qrCodeUrl={`${window.location.hostname}${ENGAGE_LANDING_URL}?j=${lsMeetingId}&pwd=${lsMeetingPwd}`}
        />
      </AuthenticationLayout>
    )
  }

  if (!info) return <LoadingIndicator engage={true} fullPage={true} />
  return <ZoomMeetingInner {...props} />
}

export default ZoomMeeting
