import React, { useContext } from 'react'
import Button from '../Button'
import './DownloadAppBanner.scss'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import EngageAppIcon from '@web/common/img/EngageAppIcon.svg'
import { AppContext } from '@web/js/context/AppContext'

const DownloadAppBanner: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { downloadAppUrl } = useContext(AppContext)

  return (
    <div id="download-app-banner">
      <div id="download-app-banner--app-icon">
        <img src={EngageAppIcon} alt="Engage App Logo" />
      </div>
      <div id="download-app-banner--text">
        <div>Veeva Engage</div>
        <div>{t('openInTheEngageApp')}</div>
      </div>
      {downloadAppUrl && (
        <div id="download-app-banner--btn">
          <Button size="sm" onClick={() => window.open(downloadAppUrl)}>
            {t('install')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default DownloadAppBanner
