import React, { useEffect, useState, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useResponsive } from '@farfetch/react-context-responsive'
import _ from 'lodash'
import {
  I18N,
  LOCALSTORAGE,
  LOGGING,
  MODALS,
  NOTIFICATION,
  ROLES,
  ROUTES,
  SCHEDULING_NOTIFICATIONS,
} from '@web/_constants'
import './Home.scss'
import EmptyHome from '@web/common/img/EmptyHome.svg'
import InviteIcon from '@web/common/img/InviteIcon_sm.svg'
import OfficeIcon from '@web/common/img/OfficeIcon_sm.svg'
import CloseIcon from '@mui/icons-material/Close'
import { ToastContext } from '@web/js/context/ToastContext'
import { ConnectionContext } from '@web/js/context/ConnectionContext'
import { AuthContext } from '@web/js/context/AuthContext'
import { ChatContext } from '@web/js/context/ChatContext'
import { Connection, NotificationRequest } from '@web/_types'
import {
  NotificationsService,
  OfficesService,
  ContactService,
  UserService,
} from '@web/_services'
import SendInvite from '@web/js/components/SendInvite'
import ConnectionNotification from '@web/js/components/ConnectionNotification'
import MeetingNotification from '@web/js/components/MeetingNotification'
import Button from '@web/js/components/Button'
import Sidebar from '@web/js/components/Sidebar'
import ConnectionDetails from '@web/js/components/ConnectionDetails'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import { isHCP, isRep } from '@web/_guards'
import { useLocalStorage } from '@web/common/hooks'
import { ModalContext } from '@web/js/context/ModalContext'
import { NotificationsContext } from '@web/js/context/NotificationsContext'
import { useNavigate, useSearchParams } from 'react-router-dom'

const HomeRoute: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { lessThan } = useResponsive()
  const { t } = useTranslation(I18N.namespaces.web)
  const [searchParams, setSearchParams] = useSearchParams()

  const { addToast } = useContext(ToastContext)
  const { getChannels } = useContext(ChatContext)
  const { getConnections } = useContext(ConnectionContext)
  const { user, setAuthenticatedUser } = useContext(AuthContext)
  const { showModal } = useContext(ModalContext)

  const [approved, setApproved] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { notifications, setNotifications, getNotifications } = useContext(NotificationsContext)
  const [hasOffices, setHasOffices] = useState(false)
  const [openProfile, setOpenProfile] = useState(false)
  const [activeNotification, setActiveNotification] =
    useState<NotificationRequest | null>(null)

  const initialSession = useRef<boolean>(false)

  const [showWelcome, setShowWelcome] = useLocalStorage<boolean | null>(
    LOCALSTORAGE.welcome,
    null
  )

  const [isInitialSessionManual, setIsInitialSessionManual] = useLocalStorage<
    boolean | null
  >(LOCALSTORAGE.isInitialSessionManual, null)

  useEffect(() => {
    init()
    if (searchParams.size) setSearchParams({}, { replace: true })
  }, [])

  useEffect(() => {
    if (!isLoading) handleModals()
  }, [isLoading])

  useEffect(() => {
    if (!isLoading) {
      getConnections()
      getChannels()
    }
  }, [notifications])

  useEffect(() => {
    if (activeNotification) {
      const match = _.find(
        notifications,
        (n) => n.notificationId === activeNotification.notificationId
      )

      if (match) {
        setActiveNotification(match)
      } else {
        setOpenProfile(false)
      }
    }
  }, [notifications])

  const init = async () => {
    initialSession.current = !!isInitialSessionManual
    if (isInitialSessionManual) setIsInitialSessionManual(null)

    const getUserOfficesResponse = await OfficesService.getUserOffices()
    const getUserOfficesResponseData = getUserOfficesResponse.data
    const officeIds = getUserOfficesResponseData.officeIds
    setHasOffices(!!officeIds.length)
    await getNotifications()
    setIsLoading(false)
  }

  const handleModals = () => {
    if (!initialSession.current && !showWelcome) return

    const hcpNotification = _.find(notifications, (n) => {
      return (
        isHCP(n.contact) &&
        n.type === NOTIFICATION.connectionRequest &&
        (n.unreadMessages || !!n.inviteMessage)
      )
    })

    const hasHCPNotifications = !!hcpNotification
    if (initialSession.current && hasHCPNotifications) {
      setShowWelcome(null)
      showModal({
        name: MODALS.MESSAGED_YOU_MODAL,
        data: {
          hcp: hcpNotification.contact,
          approveConnectionRequest,
        },
      })
    } else if (showWelcome) {
      showModal({
        name: MODALS.WELCOME_MODAL,
      })
    }
  }

  const goTo = (path: string) => {
    navigate(path)
  }

  const approveConnectionRequest = async (
    connection: Connection,
    returnConnection = false
  ) => {
    const invitedNotificationId = connection.invitedNotificationId as string

    try {
      const newConnection = await ContactService.connectFromNotification(
        invitedNotificationId
      )
      setApproved([...approved, newConnection.id])
      await getNotifications()
      if (returnConnection) return newConnection
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const declineConnectionRequest = async (
    connectionRequest: NotificationRequest
  ) => {
    const { contact } = connectionRequest
    const notificationId = connectionRequest.notificationId

    try {
      await NotificationsService.delineConnectionRequest(notificationId)

      const updatedNotificationContacts = _.map(notifications, (n) => {
        if (n.contact.id === contact.id) n.contact.invitedNotificationId = null
        return n
      })

      setNotifications(updatedNotificationContacts)
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const openContactDetails = (notification: NotificationRequest) => {
    if (notification.notificationId === activeNotification?.notificationId) {
      setOpenProfile(false)
      return
    }

    const eventMessage = {
      profileId: notification.contact.id,
      actionType: LOGGING.ACTION_TYPE.VIEW_PROFILE,
      actionDetail: LOGGING.ACTION_DETAIL.NOTIFICATION_CARD,
      referenceId: notification.notificationId,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )

    if (lessThan.md) {
      const path =
        notification.contact && isRep(notification.contact)
          ? ROLES.rep
          : ROLES.hcp
      navigate(`${path.toLowerCase()}/${notification.contact.id}`)
    } else {
      setActiveNotification(notification)
      if (!openProfile) setOpenProfile(true)
    }
  }

  const profileActionCallback = async () => {
    await init()
    setApproved([])
  }

  const dismissOnboardingGetStarted = async () => {
    const updateUserResponse = await UserService.updateUser({
      onboardingGetStartedDismissed: true,
    })
    setAuthenticatedUser(updateUserResponse.data)
  }

  if (isLoading) return null

  return (
    <div id="home-route">
      <div className="row gutter-md">
        <div className="col-md-5 col-xs-8">
          {(!notifications.length || !user?.onboardingGetStartedDismissed) && (
            <div className="container onboarding-card">
              {!!notifications.length &&
                !user?.onboardingGetStartedDismissed && (
                  <div className="onboarding-card--close">
                    <Button
                      id="dismiss-onboarding-get-started"
                      onClick={dismissOnboardingGetStarted}
                      style="link"
                      size="lg"
                      icon={<CloseIcon />}
                    />
                  </div>
                )}

              <img src={EmptyHome} />
              <div className="text-headline center">
                {!notifications.length
                  ? t('noActivity')
                  : t('readyToGetStarted')}
              </div>
              {!notifications.length && (
                <div className="text-subhead center">
                  {t('findActivityNotifications')}
                </div>
              )}

              {!hasOffices && (
                <Button
                  id="create-office"
                  iconFront={<img src={OfficeIcon} />}
                  size="sm"
                  style="teal"
                  onClick={() => goTo(ROUTES.profile)}
                >
                  {t('createOffice')}
                </Button>
              )}

              <Button
                id="send-invitation"
                iconFront={<img src={InviteIcon} />}
                size="sm"
                style="warning"
                onClick={() => goTo(ROUTES.invite)}
              >
                {t('sendInvitation')}
              </Button>
            </div>
          )}

          {!!notifications.length && (
            <div className="container feed">
              <div className="text-headline">{t('feed')}</div>
              <div id="notifications-list">
                {notifications.map((notification) => {
                  const id = notification.notificationId
                  const active = id === activeNotification?.notificationId
                  const isScheduling = _.includes(
                    SCHEDULING_NOTIFICATIONS,
                    notification.type
                  )

                  if (isScheduling) {
                    return (
                      <MeetingNotification
                        key={id}
                        active={active}
                        notification={notification}
                        openContactDetails={openContactDetails}
                      />
                    )
                  }

                  const approvedNotification =
                    notification.type === NOTIFICATION.newConnection &&
                    _.includes(approved, notification.contact.id)

                  return (
                    <ConnectionNotification
                      key={id}
                      active={active}
                      approved={approvedNotification}
                      approveConnectionRequest={approveConnectionRequest}
                      declineConnectionRequest={declineConnectionRequest}
                      notification={notification}
                      openContactDetails={openContactDetails}
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>

        {!lessThan.md && (
          <div className="col-md-3">
            <SendInvite />
          </div>
        )}
      </div>

      <Sidebar
        side="right"
        open={openProfile}
        closeFunc={() => setOpenProfile(false)}
        onClosedFunc={() => setActiveNotification(null)}
        width="calc(50% - 125px)"
        fullScreen={lessThan.md}
      >
        {activeNotification && (
          <ConnectionDetails
            connection={activeNotification?.contact}
            {...(activeNotification &&
              !!activeNotification.contact.invitedNotificationId && {
                approveConnectionRequest: approveConnectionRequest,
              })}
            removeConnectionCallback={profileActionCallback}
            createConnectionCallback={profileActionCallback}
          />
        )}
      </Sidebar>
    </div>
  )
}

export default HomeRoute
