import React, { useRef, useState, useContext } from 'react'
import { ERROR, I18N, MODALS, ROUTES, SUPPORT_LINK } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import {
  Brand,
  BrandResource,
  Connection,
  HCP,
  Message,
  RecipientEmail,
} from '@web/_types'
import { BrandService, ChatService } from '@web/_services'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '@web/js/context/AuthContext'
import { ChatContext } from '@web/js/context/ChatContext'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import RecipientSelector from '@web/js/components/RecipientSelector'
import OptionalMessagePreview from '@web/js/components/OptionalMessagePreview'

interface ShareBrandResourceModalProps {
  brand: Brand
  resource: BrandResource
  clearBrandResource: () => void
}

const ShareBrandResourceModal: React.FC<ShareBrandResourceModalProps> = ({
  brand,
  resource,
  clearBrandResource,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)

  const { user } = useContext(AuthContext)
  const { chatChannels, getChannels } = useContext(ChatContext)
  const { hideModal, showModal } = useContext(ModalContext)

  const isSharingResourceRef = useRef(false)
  const optionalMessageRef = useRef<HTMLTextAreaElement>(null)

  const [recipient, setRecipient] = useState<Connection | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [emailStatus, setEmailStatus] = useState<string | null>(null)
  const [message] = useState<Message>({
    metadata: {
      brandResource: {
        brand: {
          id: brand.id,
          name: brand.name,
          nonProprietaryName: brand.nonProprietaryName,
          regStatementHtml: brand.regStatementHtml,
        },
        resource,
        footers: brand.regLinks,
      },
    },
    status: 'DELIVERED',
    type: 'BRAND_RESOURCE',
    user: user as HCP,
  } as Message)

  const handleAccept = async () => {
    if (!resource || isSharingResourceRef.current) return

    //using ref because setting state is too slow
    isSharingResourceRef.current = true

    const optionalMessage = optionalMessageRef.current?.value
      ? optionalMessageRef.current?.value
      : null

    try {
      const sendBrandResourceResponse = await ChatService.sendBrandResource({
        brandResourceId: resource.id,
        optionalMessage,
        ...(recipient && { userId: recipient.id }),
        ...(email && { email }),
      })

      const channelId = sendBrandResourceResponse.data.channelId

      if (channelId) {
        const match = _.find(chatChannels, (c) => c.id === channelId)
        if (!match || !match.active) await getChannels()
        const route = `${ROUTES.chat}/${channelId}`
        navigate(route)
        hideModal()
      } else {
        isSharingResourceRef.current = false
        handleClose()
      }
    } catch (error) {
      let errorResponse = null
      let name = email

      if (error.response.data.code) errorResponse = error.response.data.code
      if (error.response.data.autoConnectedName)
        name = error.response.data.autoConnectedName

      //if error code exists without name/email present, trigger default error
      if (
        (!!errorResponse && !name) ||
        (errorResponse === ERROR.crossCompany &&
          !error.response.data.autoConnectedName)
      ) {
        errorResponse = null
      }

      handleClose()

      let messageError
      switch (errorResponse) {
        case ERROR.chatNotEnabled:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorCannotReceiveChats', {
              name,
            }),
          }
          break
        case ERROR.crossCompany:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorPharmaContactsDiffCompany', {
              name,
            }),
          }
          break
        case ERROR.disconnected:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorMustBeConnected', {
              name,
            }),
          }
          break
        default:
          messageError = {
            title: t('errorSomethingWentWrongTitle'),
            body: t('errorNotAllowed', {
              support: SUPPORT_LINK,
            }),
          }
          break
      }

      if (messageError) {
        handleClose()
        showModal({
          name: MODALS.GENERIC_ERROR_MODAL,
          data: {
            error: messageError,
          },
        })
      }

      isSharingResourceRef.current = false
    }
  }
  const handleClose = () => {
    hideModal()
    clearBrandResource()
  }

  const setRecipientEmail = ({ email, emailStatus }: RecipientEmail) => {
    setEmail(email)
    setEmailStatus(emailStatus)
  }

  const clearRecipientEmail = () => {
    setRecipient(null)
    setEmail(null)
    setEmailStatus(null)
  }

  const loadUsers = (q: string, signal: AbortSignal) =>
    BrandService.getBrandShareableContacts(
      brand.id,
      resource.id,
      q.trim(),
      signal
    )

  return (
    <Modal size="lg" title={t('sendResource')} onClose={handleClose}>
      <ModalBody>
        {!!resource && !email && !recipient && (
          <RecipientSelector
            loadData={loadUsers}
            setRecipient={setRecipient}
            setEmail={setRecipientEmail}
          />
        )}

        {!!resource && !!message && (!!email || !!recipient) && (
          <OptionalMessagePreview
            ref={optionalMessageRef}
            message={message}
            recipient={recipient}
            email={email}
            emailStatus={emailStatus}
            comment={false}
            clearRecipientData={clearRecipientEmail}
          />
        )}
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onCancel={handleClose}
        onCancelText={t('cancel')}
        onAcceptText={t('send')}
        onAccept={handleAccept}
        disabled={!email && !recipient}
      >
        <>
          {!!resource && !email && !recipient && (
            <RecipientSelector
              loadData={loadUsers}
              setRecipient={setRecipient}
              setEmail={setRecipientEmail}
            />
          )}

          {!!resource && !!message && (!!email || !!recipient) && (
            <OptionalMessagePreview
              ref={optionalMessageRef}
              message={message}
              recipient={recipient}
              email={email}
              emailStatus={emailStatus}
              comment={false}
              clearRecipientData={clearRecipientEmail}
            />
          )}
        </>
      </ModalFooter>
    </Modal>
  )
}
export default ShareBrandResourceModal
