import React, { ReactNode } from 'react'
import './CardList.scss'
import { classNames } from '@web/_utils'

interface CardListProps {
  id?: string
  classes?: string
  children?: ReactNode
}

const CardList: React.FC<CardListProps> = ({ classes, id, children }) => {
  const cx = {
    'card-list': true,
    [classes as string]: !!classes,
  }

  const cardListClasses = classNames(cx)

  return (
    <div {...(id && { id })} className={cardListClasses}>
      {children}
    </div>
  )
}

export default CardList
