import React, { useContext } from 'react'
import './OfficeHours.scss'
import { Office, PartitionedHoursGrouped } from '@web/_types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import EditIcon from '@web/common/img/EditIcon'
import { WEEK, I18N, MODALS } from '@web/_constants'
import { generateOfficeHours } from '@web/_utils'
import { ModalContext } from '@web/js/context/ModalContext'

interface IOfficeHoursProps {
  office: Office
  isOfficeMember: boolean
  onSave?: () => void
}

const OfficeHours: React.FC<IOfficeHoursProps> = ({
  office,
  onSave,
  isOfficeMember,
}) => {
  const { t, i18n } = useTranslation(I18N.namespaces.web)
  const { showModal } = useContext(ModalContext)

  const renderOfficeHoursTable = () => {
    return (
      <table id="office-rep-hours--table">
        {_.map(
          generateOfficeHours(office.hours) as PartitionedHoursGrouped,
          (partition) => {
            return (
              <tbody key={`tbody_${partition[0].day}`}>
                {_.map(partition, (officeHours, i) => {
                  const startDay =
                    i === 0 ? t(`days.${WEEK[officeHours.day]}`) : ''

                  const endTime = new Date(
                    officeHours.endTime
                  ).toLocaleTimeString(i18n.language, {
                    hour: 'numeric',
                    minute: '2-digit',
                    timeZone: 'UTC',
                  })

                  return (
                    <tr
                      key={`${startDay}_${officeHours.startTime}`}
                      className="office-rep-hours--table-row"
                    >
                      <td className="office-rep-hours--table-col--day">
                        {startDay}
                      </td>
                      <td className="office-rep-hours--table-col--start">
                        {new Date(officeHours.startTime).toLocaleTimeString(
                          i18n.language,
                          {
                            hour: 'numeric',
                            minute: '2-digit',
                            timeZone: 'UTC',
                          }
                        )}{' '}
                        -
                      </td>
                      <td className="office-rep-hours--table-col--end">
                        {endTime === '0:00' ? '24:00' : endTime}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            )
          }
        )}
      </table>
    )
  }

  return (
    <div id="office-rep-hours">
      <div className="text-headline">
        {t('offices.repHours')}

        {isOfficeMember && (
          <a
            id="office-rep-hours--edit"
            onClick={() =>
              showModal({
                name: MODALS.OFFICE_HOURS_EDITOR_MODAL,
                data: {
                  office,
                  ...(onSave && { onSave }),
                },
              })
            }
          >
            <EditIcon />
          </a>
        )}
      </div>

      {!office.hours.length ? (
        <div id="no-office-rep-hours">{t('offices.noRepHoursSet')}</div>
      ) : (
        renderOfficeHoursTable()
      )}
    </div>
  )
}

export default OfficeHours
