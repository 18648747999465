import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'
import { Connection } from '@web/_types'
import { parseContacts } from '@web/_utils'
import _ from 'lodash'

const apiUrl = Config.API_URL
const oapiUrlV2 = Config.OAPI_URL_V2

interface IUpdateUser {
  firstName?: string
  lastName?: string
  displayName?: string
  degree?: string
  title?: string
  countryCode?: string
  onboardingGetStartedDismissed?: boolean
  unreadMessageNotificationMinutes?: number | null
}

export const UserService = {
  getConnections: (): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, url: '/user/contacts' })
  },

  getCurrentUser: (): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, url: '/user' })
  },

  getPublicDBC: (inviteUrl: string): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL: oapiUrlV2,
      url: '/reps/digital-business-card',
      data: { inviteUrl },
    })
  },

  getUserByType: async (id: string, type: string): Promise<Connection> => {
    const getUserResponse = await axios({
      baseURL: apiUrl,
      url: `${type}/${id}`,
    })
    const getUserContactsResponse = await UserService.getUserContactsByType(
      id,
      type
    )
    const commonHcpIds = getUserContactsResponse.data.hcpIds || []
    getUserResponse.data.dossier[type][id].commonHcpIds = commonHcpIds

    const mergedData = _.merge(
      getUserResponse.data,
      getUserContactsResponse.data
    )

    const cx = parseContacts(mergedData, true)
    return cx as Connection
  },

  getUserContactsByType: (id: string, type: string): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, url: `${type}/${id}/contacts` })
  },

  setPhoto: (blob: Blob): Promise<AxiosResponse> => {
    return axios({
      baseURL: apiUrl,
      data: blob,
      url: '/user/photo',
      method: 'post',
      headers: { 'content-type': 'image/jpeg' },
    })
  },

  removePhoto: (): Promise<AxiosResponse> => {
    return axios({
      baseURL: apiUrl,
      url: '/user/photo',
      method: 'delete',
    })
  },

  updateUser: (data: IUpdateUser): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, method: 'patch', url: '/user', data })
  },
}
