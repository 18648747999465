import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import './FriendlyDate.scss'

//DayJs
import dayjs from 'dayjs'

interface FriendlyDateProps {
  date: string
  showTime?: boolean
  classes?: string
}

const FriendlyDate: React.FC<FriendlyDateProps> = ({
  date = new Date().toString(),
  showTime = true,
  classes = '',
}) => {
  const { t, i18n } = useTranslation(I18N.namespaces.web)
  const [friendlyDate, setFriendlyDate] = useState('')

  useEffect(() => {
    const localDate = dayjs(date).format()
    const yearAgo = dayjs().subtract(1, 'year').format()
    const weekAgo = dayjs().subtract(6, 'day').startOf('day').format()
    const isPastWeek = dayjs(localDate).isSameOrAfter(weekAgo)
    const isBeforeUTCYearAgo = dayjs(localDate).isSameOrBefore(yearAgo)
    const isToday = dayjs(date).isToday()

    const d = new Date(date)

    if (isToday) {
      if (showTime) {
        const time = dayjs(date).format('LT')
        setFriendlyDate(time)
        return
      }

      setFriendlyDate(t('days.today'))
      return
    }

    let dateOptions: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    }
    if (isPastWeek) dateOptions = { weekday: 'long' }
    if (isBeforeUTCYearAgo) dateOptions = { month: 'short', year: 'numeric' }
    setFriendlyDate(d.toLocaleDateString(i18n.language, dateOptions))
  }, [date])

  return <span className={`friendly-date ${classes}`}>{friendlyDate}</span>
}

export default FriendlyDate
