import React, { useContext } from 'react'
import './Profile.scss'
import { useTranslation } from 'react-i18next'
import { I18N, ROUTES } from '@web/_constants'
import { AuthContext } from '@web/js/context/AuthContext'
import Offices from '@web/js/components/Offices'
import DetailsHeader from '@web/js/components/DetailsHeader'
import Button from '@web/js/components/Button'
import EditIcon from '@web/common/img/EditIcon'
import DetailsWrapper from '@web/js/components/DetailsWrapper'
import DetailsBody from '@web/js/components/DetailsBody'
import ProfileImage from '@web/js/components/ProfileImage'
import { useNavigate } from 'react-router-dom'

const ProfileRoute: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  if (!user) return null

  return (
    <DetailsWrapper id="profile-route">
      <DetailsHeader id="profile-header">
        <>
          <ProfileImage />

          <div>
            <div id="profile-user" className="text-extra-large-semibold">
              <div id="profile-user--name">{user.displayName}</div>
              {user.title && <div id="profile-user--email">{user.email}</div>}
            </div>

            <Button
              size="sm"
              outline={true}
              iconFront={<EditIcon />}
              onClick={() => navigate(ROUTES.editProfile)}
            >
              {t('editMyProfile')}
            </Button>
          </div>
        </>
      </DetailsHeader>
      <DetailsBody>
        <Offices />
      </DetailsBody>
    </DetailsWrapper>
  )
}

export default ProfileRoute
