import * as React from 'react'
import { IconProps } from '@web/_types'

const HomeIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-home',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5212 11.9239V18.7302C19.5212 19.8247 18.6789 20.7134 17.6396 20.7134H15.5146V15.5334C15.5146 14.1618 14.4557 13.0467 13.1544 13.0467H10.7055C9.40412 13.0467 8.34529 14.1618 8.34529 15.5334V20.7134H6.35052C5.31204 20.7134 4.46969 19.8247 4.46969 18.7302V11.9239V9.66975L11.6536 3.50848C11.7627 3.46388 11.8791 3.44072 11.9955 3.44072C12.1118 3.44072 12.2282 3.46388 12.3373 3.50848L19.5212 9.66975V11.9239ZM9.56608 20.7134V15.5334C9.56608 14.8703 10.0764 14.3334 10.7055 14.3334H13.1544C13.7835 14.3334 14.2938 14.8703 14.2938 15.5334V20.7134H9.56608ZM12.9582 2.35823C12.3926 1.88303 11.6007 1.88046 11.0327 2.35137L1.23297 10.4752C0.968469 10.6939 0.922079 11.0988 1.13043 11.3784C1.33877 11.6572 1.7221 11.7052 1.98823 11.4865L3.2489 10.4409V11.9239V18.7302C3.2489 20.5332 4.6406 22 6.35052 22H8.34529H9.56608H14.2938H15.5146H17.6396C19.3503 22 20.742 20.5332 20.742 18.7302V11.9239V10.5378L21.8692 11.4839C21.9815 11.5783 22.1158 11.6237 22.2493 11.6237C22.4283 11.6237 22.6065 11.5405 22.727 11.381C22.937 11.1031 22.8938 10.6982 22.6293 10.4769L12.9582 2.35823Z"
      fill={color}
    />
  </svg>
)

export default HomeIcon
