import React, { ReactNode } from 'react'
import './DetailsBody.scss'
import { classNames } from '@web/_utils'

interface DetailsBodyProps {
  id?: string
  classes?: string
  children?: ReactNode
}

const DetailsBody: React.FC<DetailsBodyProps> = ({ classes, id, children }) => {
  const cx = {
    'details-body': true,
    [classes as string]: !!classes,
  }

  const detailsBodyClasses = classNames(cx)

  return (
    <div {...(id && { id })} className={detailsBodyClasses}>
      {children}
    </div>
  )
}

export default DetailsBody
