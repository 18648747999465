import * as React from 'react'
import { IconProps } from '@web/_types'

const ChatIconNav: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-chat',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1653 16.5586L19.4992 15.9582C20.1574 14.7749 20.5288 13.4296 20.5288 12C20.5288 7.34926 16.5487 3.47727 11.5096 3.47727C6.47054 3.47727 2.49045 7.34927 2.49045 12C2.49045 16.6507 6.47054 20.5227 11.5096 20.5227C13.293 20.5227 14.9484 20.035 16.3417 19.1977L16.8873 18.8698L20.4221 19.8167L19.1653 16.5586ZM22.86 22L17.1144 20.4609C15.4922 21.4358 13.5701 22 11.5096 22C5.70531 22 1 17.5228 1 12C1 6.47713 5.70531 2 11.5096 2C17.3139 2 22.0192 6.47713 22.0192 12C22.0192 13.6874 21.5799 15.2772 20.8045 16.6714L22.86 22Z"
      fill={color}
    />
  </svg>
)

export default ChatIconNav
