import React from 'react'
import './Header.scss'
import { I18N, ROUTES } from '@web/_constants'
import { useResponsive } from '@farfetch/react-context-responsive'
import UserMenu from '@web/js/components/UserMenu'
import Logo from '@web/common/img/Engage_Logo.svg'
import MenuIcon from '@mui/icons-material/Menu'
import MeetingsIcon from '@web/common/img/MeetingsIcon'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface HeaderProps {
  toggleMenu: () => void
}

const Header: React.FC<HeaderProps> = ({ toggleMenu }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { lessThan, greaterThan } = useResponsive()
  const navigate = useNavigate()

  return (
    <header>
      <div className="row row-space-between row-align-center">
        <div className="header-nav col-xs-2">
          {lessThan.md && (
            <div className="header-nav--toggle" onClick={toggleMenu}>
              <MenuIcon />
            </div>
          )}
          <img
            src={Logo}
            className="header--logo"
            onClick={() => navigate(ROUTES.home)}
          />
        </div>
        <div id="header-actions" className="col-auto">
          {greaterThan.sm && (
            <Button
              iconFront={<MeetingsIcon />}
              size="md"
              classes="join-meeting-btn"
              onClick={() => navigate(ROUTES.meetings)}
            >
              {t('joinMeeting')}
            </Button>
          )}
          <UserMenu />
        </div>
      </div>
    </header>
  )
}

export default Header
