import React, { ReactNode } from 'react'
import './DetailsWrapper.scss'
import { classNames } from '@web/_utils'

interface DetailsWrapperProps {
  id?: string
  classes?: string
  children?: ReactNode
}

const DetailsWrapper: React.FC<DetailsWrapperProps> = ({
  classes,
  id,
  children,
}) => {
  const cx = {
    'details-wrapper': true,
    [classes as string]: !!classes,
  }

  const detailsWrapperClasses = classNames(cx)

  return (
    <div {...(id && { id })} className={detailsWrapperClasses}>
      {children}
    </div>
  )
}

export default DetailsWrapper
