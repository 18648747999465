/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext, useEffect } from 'react'
import './Unsubscribe.scss'
import { I18N, SUPPORT_EMAIL, NAV_LOCATIONS, VEEVA_URL } from '@web/_constants'
import { classNames, getQueryStringParams } from '@web/_utils'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { AppContext } from '@web/js/context/AppContext'
import EngageLogo from '@web/common/img/Engage_Logo.svg'
import Button from '@web/js/components/Button'
import Envelope from '@web/common/img/Envelope.png'
import { AuthContext, AuthProvider } from '@web/js/context/AuthContext'
import { useResponsive } from '@farfetch/react-context-responsive'
import { Link } from 'react-router-dom'
import CheckIcon from '@web/common/img/CheckIcon'
import OopsIcon from '@web/common/img/OopsIcon.svg'
import ChevronLeftIcon from '@web/common/img/ChevronLeftIcon.svg'
import VeevaLogo_Greyscale from '@web/common/img/VeevaLogo_Greyscale.svg'
import { EmailSubscriptionService, UserService } from '@web/_services'
import Config from '@web/_config'
import DOMPurify from 'dompurify'

interface IUnsubscibeInfo {
  email: string
  unreadMessageEmail: boolean
}

const UnsubscribeRoute: React.FC = () => {
  const controller = new AbortController()
  const { t } = useTranslation(I18N.namespaces.web)
  const { lessThan } = useResponsive()

  const { config } = useContext(AppContext)
  const { authenticated, setAuthenticatedUser } = useContext(AuthContext)

  const [error, setError] = useState(false)
  const [token, setToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [unsubscribed, setUnsubscribed] = useState(false)
  const [unsubscibeInfo, setUnsubscibeInfo] = useState<IUnsubscibeInfo | null>(
    null
  )

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const queryArgs = getQueryStringParams(window.location.search)
    if (!queryArgs.token) goToApp()
    const token = queryArgs.token

    try {
      const unsubscibeInfoResponse =
        await EmailSubscriptionService.getUnsubscibeInfo(
          token,
          controller.signal
        )
      setToken(token)
      setUnsubscibeInfo(unsubscibeInfoResponse.data)
      setUnsubscribed(!unsubscibeInfoResponse.data.unreadMessageEmail)
    } catch (error) {
      console.log(error)
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const unsubscribe = async () => {
    try {
      await EmailSubscriptionService.unsubscribe(
        token as string,
        (unsubscibeInfo as IUnsubscibeInfo).unreadMessageEmail,
        controller.signal
      )
      setUnsubscribed(true)

      if (authenticated) {
        const getCurrentUserResponse = await UserService.getCurrentUser()
        setAuthenticatedUser(getCurrentUserResponse.data)
      }
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }

  const goToApp = () => {
    window.open(
      `${location.origin}${Config.INVITE_DEEP_LINK_BASE}?nav=${NAV_LOCATIONS.LOGIN}`,
      '_blank'
    )
  }

  const goToVeeva = () => {
    window.open(VEEVA_URL, '_blank')
  }

  const goToSettings = () => {
    const location = window.location
    location.replace(
      `${location.origin}${Config.INVITE_DEEP_LINK_BASE}?nav=${NAV_LOCATIONS.NOTIFICATION_SETTINGS}`
    )
  }

  const cx = {
    error: !!error,
    condensed: lessThan.md,
    xs: lessThan.sm,
  }

  const unsubscribeRouteClasses = classNames(cx)

  if (isLoading) return null

  return (
    <AuthProvider>
      <div id="unsubscribe-route" className={unsubscribeRouteClasses}>
        <div id="unsubscribe-route--header">
          <div id="logo-wrapper">
            <img onClick={goToApp} id="engage-logo" src={EngageLogo} />
          </div>
          <div id="unsubscribe-route--header--actions">
            {!lessThan.md && (
              <Button size="md" id="back-to-engage" onClick={goToApp}>
                {t('backToEngage')}
              </Button>
            )}
          </div>
        </div>

        <div id="unsubscribe-route--body">
          {!unsubscribed && !error && (
            <div id="unsubscribe-route--body-content">
              <img className="envelope" src={Envelope} />
              <div className="text-headline">
                {t('pleaseConfirmUnsubscribe')}
              </div>
              <div
                className="text-default"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    t('emailStrong', { email: unsubscibeInfo?.email })
                  ),
                }}
              />
              <div className="text-default" id="stop-receiving-notifications">
                {t('stopReceivingNotifications')}
              </div>
              <Button size="lg" onClick={unsubscribe}>
                {t('unsubscribe')}
              </Button>
              <Link onClick={goToApp} to="#" id="back-to-engage">
                <img src={ChevronLeftIcon} />
                {t('backToEngage')}
              </Link>
            </div>
          )}

          {unsubscribed && !error && (
            <div id="unsubscribe-route--body-content">
              <div id="success-icon">
                <CheckIcon />
              </div>

              <div className="text-headline">{t('unsubscribed')}</div>
              <div className="text-default ">
                {t('noLongerReceiveChatNotifications')}
              </div>

              <hr />

              <div id="change-your-mind">
                {t('changeYourMind')}{' '}
                <a onClick={goToSettings}>{t('manageNotificationSettings')}</a>
              </div>
            </div>
          )}

          {error && (
            <div id="unsubscribe-route--body-content">
              <img src={OopsIcon} />
              <div className="text-headline">{t('oopsLink')}</div>
              <div className="text-default ">
                {t('pleaseTryNewLinkOrSignIn')}
              </div>
              <Button size="lg" onClick={goToApp}>
                {t('signIn')}
              </Button>
            </div>
          )}
        </div>

        <div id="unsubscribe-route--footer">
          {lessThan.md && (
            <div id="veeva-logo" onClick={goToVeeva}>
              <img src={VeevaLogo_Greyscale} />
            </div>
          )}
          <div id="copyright">
            {t('copyright', { year: dayjs().get('year') })}
          </div>
          <div id="links">
            <a href={config?.privacyUrl} target="_blank">
              {t('privacyPolicy')}
            </a>
            <a href={config?.termsUrl} target="_blank">
              {t('termsOfUse')}
            </a>
            <a href={`mailto:${SUPPORT_EMAIL}`}>{t('help')}</a>
          </div>
        </div>
      </div>
    </AuthProvider>
  )
}

export default UnsubscribeRoute
