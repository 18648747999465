import React, { useEffect, useContext, useRef, useState } from 'react'
import {
  ROUTES,
  NEXT,
  I18N,
  ERROR,
  LOCALSTORAGE,
  MODALS,
} from '@web/_constants'
import { classNames, getQueryStringParams } from '@web/_utils'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '@web/common/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import './Verify.scss'
import { AuthContext } from '@web/js/context/AuthContext'
import { ToastContext } from '@web/js/context/ToastContext'
import Button from '@web/js/components/Button'
import FormError from '@web/js/components/FormError'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import Timer from '@web/js/components/Timer'
import Checkbox from '@web/js/components/Checkbox'
import { ModalContext } from '@web/js/context/ModalContext'
import { DeeplinkContext } from '@web/js/context/DeeplinkContext'

const Verify: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const codeLength = 6
  const navigate = useNavigate()
  const { state } = useLocation()
  const queryArgs = getQueryStringParams(window.location.search)

  const { addToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)
  const { actionableDeeplink } = useContext(DeeplinkContext)
  const { login, email, hcpVerification, clientSecret, logout } =
    useContext(AuthContext)

  const [code, setCode] = useState<string>('')
  const [timer, setTimer] = useState(true)
  const [error, setError] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(true)
  const [isPublic, setIsPublic] = useState<boolean>(false)

  const [meetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )

  const [meetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )

  const verificationRef = useRef<HTMLInputElement>(null)
  const checkboxRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    verificationRef.current?.focus()
  }, [])

  useEffect(() => {
    setDisabled(code.length < 1)
  }, [code])

  const handleOnChange = () => {
    const value = (verificationRef.current as HTMLInputElement).value
    validateInput(value)
  }

  const validateInput = (value: string) => {
    const cleanCode = value.replace(/\D/g, '')
    setCode(cleanCode)
    setError('')
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const { clipboardData } = event
    const pastedCode = clipboardData.getData('text')
    validateInput(pastedCode)
  }

  const handleResend = () => {
    setTimer(true)
    showModal({
      name: MODALS.CODE_RESENT_MODAL,
    })
    if (email) hcpVerification(email)
  }

  const handleIsPublic = () => {
    if (checkboxRef.current) setIsPublic(checkboxRef.current.checked)
  }

  const routeToInitPage = async () => {
    if (queryArgs.showScheduling) {
      window.location.assign(`${ROUTES.scheduling}${window.location.search}`)
    } else if (!!meetingId && !!meetingPwd) {
      navigate(ROUTES.meetings)
    } else if (!actionableDeeplink) {
      navigate(ROUTES.home)
    }
  }

  const routeToSignUpFlow = () => {
    let skipSignup = false

    if (state) {
      const { shoudSkipSignup } = state
      skipSignup = shoudSkipSignup
    }

    if (skipSignup) {
      navigate(ROUTES.consent, { replace: true })
    } else {
      navigate(ROUTES.signup, { replace: true })
    }
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    try {
      const loginResponse = await login(code, isPublic)

      switch (loginResponse) {
        case NEXT.success:
          routeToInitPage()
          break
        case NEXT.signup:
          routeToSignUpFlow()
          break
      }
    } catch (error) {
      const errorResponse = error.response

      switch (errorResponse.status) {
        case 401:
          setError(t('errorInvalidEmail'))
          break
        case 403:
          if (error.response.data.code === ERROR.verificationInvalidated) {
            addToast(t('errorTooManyRequests'))
            logout()
            break
          }
          setError(t('errorInvalidCode'))
          break
      }
    }
  }

  const cx = {
    error: !!error,
  }

  const codeVerificationInputClasses = classNames(cx)

  if (!email && !clientSecret) {
    navigate(ROUTES.login, { state: { redirected: true } })
    return null
  }

  return (
    <div id="verify-route">
      <form className="verify-form" onSubmit={handleSubmit} noValidate>
        <div className="text-headline">{t('verifyEmail')}</div>

        <div className="verification-form--sent-to">
          <div className="verification-form--sent-to--icon">
            <div className="verification-form--sent-to--notification">1</div>
            <MailOutlineIcon />
          </div>
          <div className="overflow-hidden">
            <div>{t('verificationEmailSentTo')}</div>
            <div className="verification-form--sent-to--email">{email}</div>
          </div>
        </div>

        <label className="text-default-medium">
          {t('enterVerificationCode')}
        </label>

        <div className="verification-form--code">
          <input
            className={codeVerificationInputClasses}
            type="text"
            maxLength={codeLength}
            ref={verificationRef}
            value={code}
            onPaste={handlePaste}
            onChange={handleOnChange}
            placeholder="000000"
          />
        </div>

        <FormError text={error} align="center" />

        <Button
          block={true}
          style="primary"
          type="submit"
          size="xl"
          disabled={disabled}
        >
          {t('verify')}
        </Button>

        <div className="verification-form--public">
          <Checkbox
            forRef={checkboxRef}
            value="public"
            defaultChecked={false}
            onChange={handleIsPublic}
          >
            {t('publicSharedComputer')}
          </Checkbox>
        </div>
      </form>

      <div className="resend-code center">
        {t('haventReceivedEmail')}
        {timer ? (
          <React.Fragment>
            <br />
            <strong>
              {t('resendCodeTimer1')}
              <Timer seconds={60} onTimeOut={() => setTimer(false)} />
              {t('resendCodeTimer2')}
            </strong>
          </React.Fragment>
        ) : (
          <React.Fragment>
            &nbsp;<a onClick={handleResend}>{t('resendCode')}</a>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default Verify
