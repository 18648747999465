import React from 'react'
import './BrandResourceCard.scss'
import { BrandResource } from '@web/_types'
import { COLORS, I18N, LOGGING } from '@web/_constants'
import { classNames, formatPhoneNumber } from '@web/_utils'
import _ from 'lodash'
import { createLog } from '@web/js/redux/logger/loggerActions'

//Icons
import EmailResourceIcon from '@web/common/img/EmailResourceIcon'
import ErrorIcon from '@web/common/img/ErrorIcon'
import InfoIcon from '@web/common/img/InfoIcon'
import PhoneIcon from '@web/common/img/PhoneIcon'
import ResourceBrochureIcon from '@web/common/img/ResourceBrochureIcon'
import ResourceDocumentIcon from '@web/common/img/ResourceDocumentIcon'
import ResourceFormIcon from '@web/common/img/ResourceFormIcon'
import ResourceInfoIcon from '@web/common/img/ResourceInfoIcon'
import WebsiteIcon from '@web/common/img/WebsiteIcon'
import SendIcon from '@web/common/img/SendIcon.svg'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

interface BrandResourceCardProps {
  brandId: string
  actionCategory: string
  resource: BrandResource
  onClick?: () => void
  setShareBrandResource?: (brandResource: BrandResource) => void
}

const BrandResourceCard: React.FC<BrandResourceCardProps> = ({
  brandId,
  resource,
  onClick,
  actionCategory,
  setShareBrandResource,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18N.namespaces.web)

  const goToResource = (resource: BrandResource) => {
    if (onClick) onClick()

    let link: string
    switch (resource.type) {
      case 'PHONE':
        link = `tel:${resource.value}`
        break
      case 'EMAIL':
        link = `mailto:${resource.value}`
        break
      default:
        link = resource.value
        break
    }

    const eventMessage = {
      brandId,
      actionCategory,
      actionType: resource.type,
      referenceId: resource.id,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.BRAND_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )

    window.open(link)
  }

  const renderIcon = (resource: BrandResource) => {
    let Icon
    switch (resource.icon) {
      case 'PHONE':
        Icon = <PhoneIcon color={COLORS.midnight} />
        break
      case 'EMAIL':
        Icon = <EmailResourceIcon color={COLORS.midnight} />
        break
      case 'WEB':
        Icon = <WebsiteIcon color={COLORS.midnight} />
        break
      case 'INFORMATION':
        Icon = <InfoIcon color={COLORS.black} />
        break
      case 'WARNING':
        Icon = <ErrorIcon color={COLORS.black} />
        break
      case 'ADVERSE_EVENT':
        Icon = <ErrorIcon color={COLORS.red} />
        break
      case 'DOCUMENT':
        Icon = <ResourceDocumentIcon />
        break
      case 'FORM':
        Icon = <ResourceFormIcon />
        break
      case 'PRODUCT_INFORMATION':
        Icon = <ResourceInfoIcon />
        break
      case 'BROCHURE':
        Icon = <ResourceBrochureIcon />
        break
    }

    return Icon
  }

  const brandResourceCardIconClasses = classNames({
    'brand-resource-card--icon': true,
    blue: _.includes(['PHONE', 'EMAIL', 'WEB'], resource.icon),
    warning: resource.icon === 'ADVERSE_EVENT',
  })

  return (
    <div
      className="brand-resource-card"
      key={resource.id}
      onClick={() => goToResource(resource)}
    >
      <div className={brandResourceCardIconClasses}>{renderIcon(resource)}</div>
      <div className="brand-resource-card--info">
        <div className="brand-resource-card--info-title text-default-medium">
          {resource.title}
        </div>
        {resource.subheader && (
          <div className="brand-resource-card--info-subtitle text-small">
            {resource.subheader}
          </div>
        )}
        {resource.value && resource.type !== 'LINK' && (
          <div className="brand-resource-card--info-value text-small">
            {resource.type === 'PHONE'
              ? formatPhoneNumber(resource.value)
              : resource.value}
          </div>
        )}

        {setShareBrandResource && (
          <div className="brand-resource-card--info--action">
            <Button
              size="sm"
              outline={true}
              iconFront={<img src={SendIcon} />}
              onClick={(e) => {
                e.stopPropagation()
                setShareBrandResource(resource)
              }}
            >
              {t('send')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default BrandResourceCard
