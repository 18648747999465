import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const baseURL = Config.OAPI_URL
const oapiUrlV2 = Config.OAPI_URL_V2

export const AuthService = {
  login: (
    email: string | null,
    code: string | null,
    source: string | null,
    clientSecret: string | null,
    sessionId: string | null
  ): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL: oapiUrlV2,
      url: '/login_hcp',
      data: {
        email,
        code,
        source,
        clientSecret,
        sessionId,
      },
    })
  },

  logout: (): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL,
      url: '/logout',
    })
  },

  deleteAccount: (): Promise<AxiosResponse> => {
    return axios({
      method: 'delete',
      baseURL: Config.API_URL,
      url: '/user',
    })
  },

  dataConsent: (): Promise<AxiosResponse> => {
    return axios({
      baseURL: oapiUrlV2,
      url: '/dataConsent',
    })
  },

  signup: (
    firstName: string,
    lastName: string,
    source: string | null,
    countryCode: string,
    clientSecret: string | null,
    sessionId: string,
    dataConsent: boolean
  ): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL: oapiUrlV2,
      url: '/hcps',
      data: {
        firstName,
        lastName,
        source,
        countryCode,
        clientSecret,
        isPrescriber: true,
        sessionId,
        dataConsent,
      },
    })
  },

  hcpVerification: (email: string): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL: oapiUrlV2,
      url: '/hcpVerification',
      data: { email },
    })
  },

  refreshAccessToken: (
    refreshToken: string,
    clientSecret: string
  ): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL,
      url: '/token',
      data: { refreshToken, clientSecret },
    })
  },
}
