import * as React from 'react'
import { IconProps } from '@web/_types'

const ScheduleMeetingIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-schedule-meeting',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6323 0.473633C15.0465 0.473633 15.3823 0.809419 15.3823 1.22363V2.82504H17.1382C18.5573 2.82504 19.892 3.84607 19.892 5.31762V10.7397C19.0105 10.3393 18.0313 10.1163 17 10.1163C16.3504 10.1163 15.7215 10.2047 15.1247 10.3703H2.46095H1.85791V17.5157C1.85791 17.9689 2.31746 18.5082 3.11169 18.5082H10.1384C10.244 19.0318 10.4081 19.5341 10.6234 20.0082H3.11169C1.69261 20.0082 0.35791 18.9872 0.35791 17.5157V5.31762C0.35791 3.84607 1.69261 2.82504 3.11169 2.82504H4.86575V1.2248C4.86575 0.81059 5.20153 0.474803 5.61575 0.474803C6.02996 0.474803 6.36575 0.81059 6.36575 1.2248V2.82504H13.8823V1.22363C13.8823 0.809419 14.2181 0.473633 14.6323 0.473633ZM4.86575 4.32504V5.1456C4.86575 5.55981 5.20153 5.8956 5.61575 5.8956C6.02996 5.8956 6.36575 5.55981 6.36575 5.1456V4.32504H13.8823V5.14443C13.8823 5.55864 14.2181 5.89443 14.6323 5.89443C15.0465 5.89443 15.3823 5.55864 15.3823 5.14443V4.32504H17.1382C17.9324 4.32504 18.392 4.86437 18.392 5.31762V8.8703H17.7899H2.46095H1.85791V5.31762C1.85791 4.86437 2.31746 4.32504 3.11169 4.32504H4.86575Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 22.6162C20.0376 22.6162 22.5 20.1538 22.5 17.1162C22.5 14.0786 20.0376 11.6162 17 11.6162C13.9624 11.6162 11.5 14.0786 11.5 17.1162C11.5 20.1538 13.9624 22.6162 17 22.6162ZM17 24.1162C20.866 24.1162 24 20.9822 24 17.1162C24 13.2502 20.866 10.1162 17 10.1162C13.134 10.1162 10 13.2502 10 17.1162C10 20.9822 13.134 24.1162 17 24.1162Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13.3662C17.4142 13.3662 17.75 13.702 17.75 14.1162L17.75 20.1162C17.75 20.5304 17.4142 20.8662 17 20.8662C16.5858 20.8662 16.25 20.5304 16.25 20.1162L16.25 14.1162C16.25 13.702 16.5858 13.3662 17 13.3662Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.25 17.1162C13.25 16.702 13.5858 16.3662 14 16.3662H20C20.4142 16.3662 20.75 16.702 20.75 17.1162C20.75 17.5304 20.4142 17.8662 20 17.8662H14C13.5858 17.8662 13.25 17.5304 13.25 17.1162Z"
      fill={color}
    />
  </svg>
)

export default ScheduleMeetingIcon
