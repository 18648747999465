import React, { useContext, useEffect } from 'react'
import { AuthContext } from '@web/js/context/AuthContext'

const LogoutRoute: React.FC = () => {
  const { logout } = useContext(AuthContext)

  useEffect(() => {
    logout()
  }, [])

  return null
}

export default LogoutRoute
