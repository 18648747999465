import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AuthContext } from './context/AuthContext'
import { AppProvider } from './context/AppContext'
import CookieConsent from './components/CookieConsent'
import Toasts from './components/Toasts'
import { ROUTES } from '@web/_constants'

//Routes
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import BrandRoute from './routes/Brand'
import ChatRoute from './routes/Chat'
import ConnectionProfile from './routes/ConnectionProfile'
import ConnectionsRoute from './routes/Connections'
import ContentRoute from './routes/Content'
import DataConsentRoute from './routes/DataConsent'
import EditProfileRoute from './routes/EditProfile'
import EnterName from './routes/EnterName'
import HomeRoute from './routes/Home'
import Invite from './routes/Invite'
import JoinMeetingRoute from './routes/JoinMeeting'
import LinkRoute from './routes/Link'
import Login from './routes/Login'
import Logout from './routes/Logout'
import MeetingsRoute from './routes/Meetings'
import NotFound from './routes/NotFound'
import OfficeForm from './routes/OfficeForm'
import OfficeRoute from './routes/Office'
import OnboardingRoute from './routes/Onboarding'
import PostEngagementSignup from './routes/PostEngagementSignup'
import PresentationRoute from './routes/Presentation'
import SettingsRoute from './routes/Settings'
import ProfileRoute from './routes/Profile'
import RepTrap from './routes/RepTrap'
import SignUp from './routes/SignUp'
import UnauthenticatedRoute from './routes/UnauthenticatedRoute'
import Verify from './routes/Verify'
import Version from './routes/Version'
import ZoomMeetingRoute from './routes/Meeting'
import Redirect from './routes/Redirect'
import UnsubscribeRoute from './routes/Unsubscribe'
import MinimalRoute from './routes/MinimalRoute'

const App: React.FC = () => {
  const { authenticated } = useContext(AuthContext)

  return (
    <AppProvider>
      <CookieConsent />

      <Routes>
        <Route path={ROUTES.redirect} element={<Redirect />} />
        <Route path={ROUTES.link} element={<LinkRoute />} />
        <Route path={ROUTES.linkMobileLink} element={<LinkRoute />} />
        <Route path={ROUTES.linkMobileWelcome} element={<LinkRoute />} />
        <Route path={ROUTES.content} element={<ContentRoute />} />
        <Route path={ROUTES.unsubscribe} element={<UnsubscribeRoute />} />

        <Route element={<MinimalRoute />}>
          <Route path={ROUTES.presentation} element={<PresentationRoute />} />
        </Route>

        {authenticated && (
          <Route element={<AuthenticatedRoute />}>
            <Route path={ROUTES.home} element={<HomeRoute />} />
            <Route path={ROUTES.dbc} element={<ConnectionProfile />} />
            <Route path={ROUTES.repId} element={<ConnectionProfile />} />
            <Route path={ROUTES.hcpId} element={<ConnectionProfile />} />
          </Route>
        )}

        {!authenticated && (
          <Route element={<UnauthenticatedRoute />}>
            <Route path={ROUTES.home} element={<OnboardingRoute />} />
          </Route>
        )}

        {!authenticated && (
          <>
            <Route path={ROUTES.repId} element={<ConnectionProfile />} />
            <Route path={ROUTES.dbc} element={<ConnectionProfile />} />
          </>
        )}

        <Route
          element={<UnauthenticatedRoute logoClickRedirect={ROUTES.join} />}
        >
          <Route path={ROUTES.name} element={<EnterName />} />
        </Route>

        <Route element={<UnauthenticatedRoute logoClickRedirect={ROUTES.home} />}>
          <Route path={ROUTES.login} element={<Login />} />
          <Route path={ROUTES.loginRep} element={<RepTrap />} />
        </Route>

        <Route element={<UnauthenticatedRoute autoRedirect={false} logoClickRedirect={ROUTES.home}  />}>
          <Route path={ROUTES.join} element={<JoinMeetingRoute />} />
        </Route>

        <Route
          element={
            <UnauthenticatedRoute
              autoRedirect={false}
              hideConnectionLabel={true}
            />
          }
        >
          <Route path={ROUTES.verify} element={<Verify />} />
          <Route path={ROUTES.consent} element={<DataConsentRoute />} />
        </Route>

        <Route element={<UnauthenticatedRoute hideConnectionLabel={true} />}>
          <Route path={ROUTES.signup} element={<SignUp />} />
          <Route path={ROUTES.postMeeting} element={<PostEngagementSignup />} />
          <Route
            path={ROUTES.postMeetingRequest}
            element={<PostEngagementSignup />}
          />
          <Route
            path={ROUTES.postPresentation}
            element={<PostEngagementSignup />}
          />
          <Route path={ROUTES.dbcSignup} element={<PostEngagementSignup />} />
        </Route>

        <Route path={ROUTES.meeting} element={<ZoomMeetingRoute />} />

        <Route element={<AuthenticatedRoute />}>
          <Route path={ROUTES.chat} element={<ChatRoute />} />
          <Route path={ROUTES.chatChannel} element={<ChatRoute />} />
          <Route path={ROUTES.joinMeeting} element={<JoinMeetingRoute />} />
          <Route path={ROUTES.invite} element={<Invite />} />
          <Route path={ROUTES.meetings} element={<MeetingsRoute />} />
          <Route path={ROUTES.meetingsId} element={<MeetingsRoute />} />
          <Route path={ROUTES.connections} element={<ConnectionsRoute />} />
          <Route path={ROUTES.connectionsId} element={<ConnectionsRoute />} />
          <Route path={ROUTES.brandId} element={<BrandRoute />} />
          <Route path={ROUTES.brandIdSlug} element={<BrandRoute />} />
          <Route path={ROUTES.settings} element={<SettingsRoute />} />
          <Route path={ROUTES.profile} element={<ProfileRoute />} />
          <Route path={ROUTES.editProfile} element={<EditProfileRoute />} />
          <Route path={ROUTES.officeCreate} element={<OfficeForm />} />
          <Route path={ROUTES.officeEdit} element={<OfficeForm />} />
          <Route path={ROUTES.officeId} element={<OfficeRoute />} />
          <Route path={ROUTES.logout} element={<Logout />} />
        </Route>

        <Route path={ROUTES.version} element={<Version />} />
        <Route element={<NotFound />} />
      </Routes>
      <Toasts />
    </AppProvider>
  )
}

export default App
