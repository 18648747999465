import { Connection } from '@web/_types'
import { isHCP } from './hcp'
import { isRep } from './rep'

export const hasProfile = (connection: Connection): boolean => {
  return (
    !!connection &&
    ((isRep(connection) && !connection.inactive) ||
      (isHCP(connection) && !connection.deleted))
  )
}
