import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  ReactNode,
} from 'react'
import { HCP, Role, Dossier, Token } from '@web/_types'
import { useLocalStorage } from '@web/common/hooks'
import { AuthService, UserService } from '@web/_services'
import { generateExpiry, generateUuid } from '@web/_utils'
import { LOCALSTORAGE, LOGGING, NEXT, ROLES } from '@web/_constants'
import Config from '@web/_config'
import { useDispatch } from 'react-redux'
import { createLog } from '../redux/logger/loggerActions'

interface AuthenticatedUserData {
  dossier: Dossier
  refreshToken: string
  hcpId: string
  repId: string
}

export type IAuthContext = {
  authenticated: boolean
  clearSession: () => void
  clientSecret: string | null
  deleteAccount: () => void
  email: string | null
  getExpirationTime: () => number
  hasExpired: boolean
  hcpVerification: (email: string) => void
  isExpiring: boolean
  login: (code: string, isPublic: boolean) => Promise<string>
  logout: () => void
  refreshSession: () => void
  sessionId: string | null
  setAuthenticatedUser: (data: AuthenticatedUserData) => void
  setHasExpired: (hasExpired: boolean) => void
  setShowAccountDeleted: (deleted: boolean) => void
  showAccountDeleted: boolean
  signup: (firstName: string, lastName: string, dataConsent: boolean) => void
  user: HCP | null
  userRole: Role | null
}

const noop = () => null

export const AuthContext = createContext<IAuthContext>({
  authenticated: false,
  clearSession: noop,
  clientSecret: null,
  deleteAccount: noop,
  email: null,
  getExpirationTime: () => 0,
  hasExpired: false,
  hcpVerification: noop,
  isExpiring: false,
  login: () => Promise.resolve(''),
  logout: noop,
  refreshSession: noop,
  sessionId: null,
  setAuthenticatedUser: noop,
  setHasExpired: noop,
  setShowAccountDeleted: noop,
  showAccountDeleted: false,
  signup: noop,
  user: null,
  userRole: null,
})

interface IAuthProvider {
  children?: ReactNode
}

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const publicRef = useRef<boolean | null>(null)
  const [userLoaded, setUserLoaded] = useState(false)

  const dispatch = useDispatch()
  const [showAccountDeleted, setShowAccountDeleted] = useState<boolean>(false)

  const [loggingId, setLoggingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.loggingId,
    null
  )

  const [, setConsent] = useLocalStorage<boolean | null>(
    LOCALSTORAGE.cookieConsent,
    null
  )

  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    LOCALSTORAGE.sessionId,
    null
  )

  const [isPublic, setIsPublic] = useLocalStorage<boolean | null>(
    LOCALSTORAGE.public,
    null
  )

  const [user, setUser] = useLocalStorage<HCP | null>(LOCALSTORAGE.user, null)

  const [clientSecret, setClientSecret] = useLocalStorage<string | null>(
    LOCALSTORAGE.clientSecret,
    null
  )

  //HCP or Rep
  const [userRole, setUserRole] = useLocalStorage<Role | null>(
    LOCALSTORAGE.userRole,
    null
  )

  const [email, setEmail] = useLocalStorage<string | null>(
    LOCALSTORAGE.email,
    null
  )

  const [refreshToken, setRefreshToken] = useLocalStorage<Token | null>(
    LOCALSTORAGE.refreshToken,
    null
  )

  const [, setAccessToken] = useLocalStorage<Token | null>(
    LOCALSTORAGE.accessToken,
    null
  )

  const [, setFirstName] = useLocalStorage<Token | null>(
    LOCALSTORAGE.firstName,
    null
  )

  const [, setLastName] = useLocalStorage<Token | null>(
    LOCALSTORAGE.lastName,
    null
  )

  const [loginSource, setLoginSource] = useLocalStorage<string | null>(
    LOCALSTORAGE.loginSource,
    null
  )

  const checkExpiration = () => {
    if (!refreshToken) return { expiring: false, expired: true }
    const now = new Date()
    const expiry = refreshToken.expiry
    const ttl = expiry - now.getTime()
    const alertTime = expiry - Config.SESSION_TIMEOUT_ALERT
    const expiring = now.getTime() < expiry && now.getTime() >= alertTime
    const expired = now.getTime() > expiry
    return { expiring, expired, ttl }
  }

  const checkAuthenticationStatus = (): boolean => {
    const { expired } = checkExpiration()
    return !!refreshToken && !!user && !!clientSecret && !expired
  }

  const [authenticated, setAuthenticated] = useState<boolean>(
    checkAuthenticationStatus()
  )

  //refreshToken expiration state
  const [hasExpired, setHasExpired] = useState<boolean>(false)
  const [isExpiring, setIsExpiring] = useState<boolean>(false)
  const [hasExpirationTimer, setHasExpirationTimer] = useState<boolean>(false)
  const [expirationTime, setExpirationTime] = useState<number>(0)

  //INIT
  useEffect(() => {
    init()
  }, [])

  //Expiration Timer
  useEffect(() => {
    if (hasExpirationTimer && isPublic) {
      timerRef.current = setTimeout(() => {
        const { expiring, expired, ttl } = checkExpiration()

        //launch expiring modal
        if (!isExpiring && expiring) {
          setIsExpiring(true)
        }

        //launch expired modal, clearSession
        if (expired) {
          expire()
        } else {
          //else retrigger timeout
          if (ttl) setExpirationTime(ttl)
        }
      }, 1000)
    }
  }, [hasExpirationTimer, expirationTime])

  useEffect(() => {
    publicRef.current = isPublic
  }, [isPublic])

  //clear session if authenticated and refreshToken is missing
  useEffect(() => {
    if (authenticated && !refreshToken) clearSession()
  }, [refreshToken])

  useEffect(() => {
    if (userLoaded && !user) clearSession()
  }, [user])

  useEffect(() => {
    if (!authenticated && !sessionId) setSessionId(generateUuid())
  }, [authenticated])

  const expire = () => {
    setHasExpired(true)
    clearSession()
  }

  const init = async () => {
    if (!loggingId) setLoggingId(generateUuid())

    if (authenticated) {
      try {
        const getCurrentUserResponse = await UserService.getCurrentUser()
        setAuthenticatedUser(getCurrentUserResponse.data)
      } catch (error) {
        console.log(error)
        if (user) setUserLoaded(true)
      }
    }

    if (!isPublic) return

    const { expired } = checkExpiration()
    if (!expired) {
      //delay timer trigger for any init api calls
      setTimeout(() => setHasExpirationTimer(true), 1000)
    } else {
      if (user) setHasExpired(true)
    }
  }

  const clearSession = () => {
    //destroy localstorage
    setAuthenticated(false)
    setUser(null)
    setUserRole(null)
    setEmail(null)
    setRefreshToken(null)
    setAccessToken(null)
    setClientSecret(null)
    setIsPublic(null)

    //reset expiration
    setIsExpiring(false)
    setHasExpirationTimer(false)
    setExpirationTime(0)

    setUserLoaded(false)

    //stop clock immediately
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  const deleteAccount = async () => {
    await AuthService.deleteAccount()
    await logout()
    setShowAccountDeleted(true)
    setConsent(null)
  }

  const logout = async () => {
    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.AUTH,
        LOGGING.EVENT_MESSAGES.LOGOUT,
        user?.id
      )
    )
    try {
      await AuthService.logout()
      clearSession()
    } catch (error) {
      console.log(error)
      clearSession()
    }
  }

  const authenticate = (data: AuthenticatedUserData) => {
    const isPublic = !!publicRef.current

    //set expiration on refreshToken
    const token = data.refreshToken
    const expiry = generateExpiry(isPublic)
    setRefreshToken({ token, expiry })

    setSessionId(null)
    setAuthenticatedUser(data)
    setAuthenticated(true)
    setHasExpirationTimer(isPublic)
  }

  const setAuthenticatedUser = (data: AuthenticatedUserData) => {
    const id = data['hcpId']
    const authenticatedUser = data.dossier.hcps[id]
    setUser(authenticatedUser)
    setUserLoaded(!!authenticatedUser)
  }

  const refreshSession = () => {
    if (refreshToken) {
      //stop clock immediately
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      const now = new Date()
      const token = refreshToken.token
      const expiry = generateExpiry(!!isPublic)
      const ttl = expiry - now.getTime()

      setRefreshToken({ token, expiry })
      setIsExpiring(false)
      setExpirationTime(ttl)
    }
  }

  const login = async (code: string, isPublic: boolean) => {
    setIsPublic(isPublic)
    publicRef.current = isPublic
    const loginResponse = await AuthService.login(
      email,
      code,
      loginSource || null,
      clientSecret,
      sessionId
    )

    const accessToken = loginResponse.headers['x-myveeva-auth']
    setAccessToken(accessToken)
    const data = loginResponse.data
    const next = data.next

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.AUTH,
        LOGGING.EVENT_MESSAGES.LOGIN,
        data['hcpId']
      )
    )

    if (next === NEXT.success) {
      authenticate(data)
      setLoginSource(null)
    } else {
      if (data.firstName) setFirstName(data.firstName)
      if (data.lastName) setLastName(data.lastName)
    }

    return next
  }

  const hcpVerification = (email: string) => {
    return AuthService.hcpVerification(email).then(() => {
      setEmail(email)
      setClientSecret(generateUuid())
    })
  }

  const signup = async (
    firstName: string,
    lastName: string,
    dataConsent: boolean
  ) => {
    const countryCode = JSON.parse(
      localStorage.getItem(LOCALSTORAGE.countryCode) as string
    )

    try {
      const signupResponse = await AuthService.signup(
        firstName,
        lastName,
        loginSource || null,
        countryCode,
        clientSecret,
        sessionId as string,
        dataConsent
      )

      const accessToken = signupResponse.headers['x-myveeva-auth']
      setAccessToken(accessToken)
      authenticate(signupResponse.data)
      setUserRole(ROLES.hcp as Role)
      setLoginSource(null)
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const getExpirationTime = (): number => {
    const { ttl } = checkExpiration()
    return ttl ? ttl : 0
  }

  const contextValue = useMemo(
    () => ({
      authenticated,
      clearSession,
      clientSecret,
      deleteAccount,
      email,
      getExpirationTime,
      hasExpired,
      hcpVerification,
      isExpiring,
      login,
      logout,
      refreshSession,
      sessionId,
      setAuthenticatedUser,
      setHasExpired,
      setShowAccountDeleted,
      showAccountDeleted,
      signup,
      user,
      userRole,
    }),
    [
      authenticated,
      clientSecret,
      email,
      hasExpired,
      isExpiring,
      sessionId,
      showAccountDeleted,
      user,
      userRole,
    ]
  )

  if (authenticated && !userLoaded) return null

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
