import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import './ExpiredLinkModal.scss'
import { DeeplinkContext } from '@web/js/context/DeeplinkContext'

const ExpiredLinkModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { clearDeeplinkState } = useContext(DeeplinkContext)
  const { hideModal } = useContext(ModalContext)

  const handleCloseModal = () => {
    hideModal()
    clearDeeplinkState()
  }

  return (
    <Modal
      closeable={true}
      onClose={handleCloseModal}
      size="md"
      title={t('oopsExpired')}
    >
      <ModalBody id="expired-link-modal">{t('pleaseTryNewLink')}</ModalBody>
      <ModalFooter
        onAccept={handleCloseModal}
        onAcceptText={t('ok')}
        stacked={true}
      />
    </Modal>
  )
}

export default ExpiredLinkModal
