import { createImage } from '.'

export const resizeImage = async (
  imageSrc: string,
  fileType: string
): Promise<string> => {
  const MAX = 1000
  const image = (await createImage(imageSrc)) as HTMLImageElement
  if (image.width <= MAX && image.height <= 1000) return imageSrc

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

  let width = image.width
  let height = image.height

  if (width > height) {
    if (width > MAX) {
      height *= MAX / width
      width = MAX
    }
  } else {
    if (height > MAX) {
      width *= MAX / height
      height = MAX
    }
  }

  canvas.width = width
  canvas.height = height
  ctx.drawImage(image, 0, 0, width, height)

  // As Base64 string
  const canvasDataUrl = canvas.toDataURL(fileType)
  return canvasDataUrl
}
