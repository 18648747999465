import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
  ReactNode,
} from 'react'
import {
  Channel,
  SocketEventData,
  CacheEvent,
  CachedMessage,
  ChatMessages,
  HCP,
  Message,
  ForwardMessage,
} from '@web/_types'
import { generateUuid } from '@web/_utils'
import {
  CHAT,
  SOCKET_EVENT_TYPE,
  CACHE_EVENT,
  I18N,
  SOCKET_EVENT,
  MODALS,
} from '@web/_constants'
import { parseDossier } from '@web/_utils'
import _ from 'lodash'
import dayjs from 'dayjs'
import { ChatService } from '@web/_services'
import { AuthContext } from './AuthContext'
import { SocketContext } from './SocketContext'
import { isHCP } from '@web/_guards'
import { useTranslation } from 'react-i18next'
import { ModalContext } from './ModalContext'

interface IChatProvider {
  children?: ReactNode
}

interface ChannelMap {
  id: string
  created: string
}

interface QueueMessageArgs {
  channelId: string
  body?: string
  sampleIds?: string[]
  contactId?: string
}

export type IChatContext = {
  chatChannels: Channel[]
  activeChannel: Channel | null
  isLoadingChat: boolean
  forwardMessage: ForwardMessage | null
  openMiniChatTo: string | null
  unreadChannelsCount: number
  unreadMessagesCount: number
  chatMessages: Record<string, ChatMessages>
  queueMessage: (args: QueueMessageArgs) => void
  getChannels: () => Promise<void>
  startForwardMessage: (message: Message, isOptionalMessage: boolean) => void
  setForwardMessage: (forwardMessage: ForwardMessage | null) => void
  setOpenMiniChatTo: (openMiniChatTo: string | null) => void
  activateChannel: (channel: Channel | null) => void
  getMessages: (
    channelId: string,
    index?: number,
    count?: number,
    direction?: string
  ) => Promise<void>
}

const noop = () => null
const asyncNoop = async () => {}

export const ChatContext = createContext<IChatContext>({
  activateChannel: noop,
  activeChannel: null,
  chatChannels: [],
  chatMessages: {},
  forwardMessage: null,
  getChannels: asyncNoop,
  getMessages: asyncNoop,
  isLoadingChat: true,
  openMiniChatTo: null,
  queueMessage: noop,
  setForwardMessage: noop,
  setOpenMiniChatTo: noop,
  startForwardMessage: noop,
  unreadChannelsCount: 0,
  unreadMessagesCount: 0,
})

export const ChatProvider: React.FC<IChatProvider> = ({ children }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { user } = useContext(AuthContext)
  const { socket } = useContext(SocketContext)
  const { showModal } = useContext(ModalContext)

  const [activeChannel, setActiveChannel] = useState<Channel | null>(null)
  const [unreadChannelsCount, setUnreadChannelsCount] = useState<number>(0)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0)
  const [chatChannels, setChatChannels] = useState<Channel[]>([])
  const [isLoadingChat, setIsLoadingChat] = useState(true)
  const [openMiniChatTo, setOpenMiniChatTo] = useState<string | null>(null)

  const newMessageTimer = useRef<ReturnType<typeof setInterval> | null>(null)

  const [isDocumentHidden, setIsDocumentHidden] = useState(document.hidden)
  const isDocumentHiddenRef = useRef(document.hidden)
  const wasDocumentHiddenRef = useRef(isDocumentHiddenRef.current)

  const [forwardMessage, setForwardMessage] = useState<ForwardMessage | null>(
    null
  )

  const [updateChannelConnection, setUpdateChannelConnection] = useState<
    string | null
  >(null)
  const [chatMessages, setChatMessages] = useState<
    Record<string, ChatMessages>
  >({})

  const unreadMessagesRef = useRef(unreadMessagesCount)
  const activeChannelRef = useRef(activeChannel)
  const chatMessagesRef = useRef(chatMessages)

  useEffect(() => {
    init()

    document.addEventListener('visibilitychange', () => {
      setIsDocumentHidden(document.hidden)
    })

    return () => {
      resetDocumentTitle()
    }
  }, [])

  const socketEventListener = useCallback(
    (event: SocketEventData) => {
      if (event.type === SOCKET_EVENT_TYPE.msg) {
        const totalUnreadCount = event.data.totalUnreadCount
        debounceSetUnreadMessagesCount(totalUnreadCount)

        //fetch channels state for active tabs only
        if (
          !isDocumentHiddenRef.current &&
          activeChannelRef.current?.id !== event.data.channelId
        ) {
          getChannels()
        }
      } else if (event.type === SOCKET_EVENT_TYPE.unreadChanged) {
        const totalUnreadCount = event.data.totalUnreadCount
        debounceSetUnreadMessagesCount(totalUnreadCount)
      } else if (event.type === SOCKET_EVENT_TYPE.channel) {
        const channelId = event.data.channelId
        setUpdateChannelConnection(channelId)
        getChannels()
      }
    },
    [socket]
  )

  useEffect(() => {
    if (socket) socket.on(SOCKET_EVENT.message, socketEventListener)

    return () => {
      if (socket) socket.off(SOCKET_EVENT.message, socketEventListener)
    }
  }, [socketEventListener])

  const debounceSetUnreadMessagesCount = _.debounce(setUnreadMessagesCount, 500)

  useEffect(() => {
    chatMessagesRef.current = chatMessages
  }, [chatMessages])

  useEffect(() => {
    if (forwardMessage) showModal({ name: MODALS.FORWARD_MESSAGE_MODAL })
  }, [forwardMessage])

  useEffect(() => {
    activeChannelRef.current = activeChannel
  }, [activeChannel])

  useEffect(() => {
    isDocumentHiddenRef.current = isDocumentHidden
    if (newMessageTimer.current) clearInterval(newMessageTimer.current)
    setPageTitle()

    if (!isDocumentHiddenRef.current && !!wasDocumentHiddenRef.current) {
      //when inactive tab becomes active, fetch channels state
      getChannels()
    } else if (isDocumentHiddenRef.current) {
      rotatePageTitle()
    }

    wasDocumentHiddenRef.current = isDocumentHiddenRef.current
  }, [isDocumentHidden])

  useEffect(() => {
    unreadMessagesRef.current = unreadMessagesCount
    if (newMessageTimer.current) clearInterval(newMessageTimer.current)
    setPageTitle()
    rotatePageTitle()
  }, [unreadMessagesCount])

  useEffect(() => {
    const unreadChannels = chatChannels.filter(
      (channel) => !!channel.unreadCount || _.isNull(channel.unreadCount)
    )
    setUnreadChannelsCount(unreadChannels.length)

    if (activeChannelRef.current) refreshActiveChannel()

    //update user in messages if HCP deleted
    if (updateChannelConnection) {
      const channel = _.find(
        chatChannels,
        (channel) => channel.id === updateChannelConnection
      )
      if (channel) {
        const connection = channel.connection
        const chatchannelMessages = chatMessages[channel.id]
        if (
          connection &&
          isHCP(connection) &&
          connection.deleted &&
          chatchannelMessages &&
          !!chatchannelMessages.messages.length
        ) {
          const updatedChatMessages = _.map(
            chatchannelMessages.messages,
            (message) => {
              const me = !!message.user && message.user.id === (user as HCP).id
              return me ? message : { ...message, user: connection }
            }
          )

          setChatMessages({
            ...chatMessagesRef.current,
            [updateChannelConnection]: {
              ...chatMessagesRef.current[updateChannelConnection],
              messages: updatedChatMessages,
            },
          })
        }
      }
    }
  }, [chatChannels])

  const init = async () => {
    if (user) {
      await loadUnreadMessagesCount()
      await getChannels()
    }
    setIsLoadingChat(false)
  }

  const loadUnreadMessagesCount = async () => {
    const getUnreadMessagesCountResponse = await ChatService.getUnreadCount()
    const unreadMessages = getUnreadMessagesCountResponse.data.unreadCount
    setUnreadMessagesCount(unreadMessages)
  }

  const rotatePageTitle = () => {
    if (document.hidden && unreadMessagesRef.current) {
      let displayCountTitle = false

      newMessageTimer.current = setInterval(() => {
        if (displayCountTitle) {
          setPageTitle()
        } else {
          document.title = t('newMessage')
        }
        displayCountTitle = !displayCountTitle
      }, 2000)
    } else {
      setPageTitle()
    }
  }

  const getFavicon = (): [HTMLLinkElement, string] => {
    const favicon = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement

    const faviconBaseUrl = favicon.href.replace(
      /favicon.ico$|favicon_unread.ico$/g,
      ''
    )

    return [favicon, faviconBaseUrl]
  }

  const setPageTitle = () => {
    const [favicon, faviconBaseUrl] = getFavicon()
    if (unreadMessagesRef.current) {
      favicon.href = `${faviconBaseUrl}favicon_unread.ico`
      const count =
        unreadMessagesRef.current > 99 ? '99+' : unreadMessagesRef.current
      document.title = `(${count}) Veeva Engage`
    } else {
      resetDocumentTitle()
    }
  }

  const resetDocumentTitle = () => {
    const [favicon, faviconBaseUrl] = getFavicon()
    favicon.href = `${faviconBaseUrl}favicon.ico`
    document.title = 'Veeva Engage'
  }

  const startForwardMessage = (
    message: Message,
    isOptionalMessage: boolean
  ) => {
    const forwardMessage = {
      message,
      recipient: null,
      userId: null,
      email: null,
      emailStatus: null,
      comment: isOptionalMessage,
    }
    setForwardMessage(forwardMessage)
  }

  const queueMessage = async ({
    channelId,
    body,
    sampleIds,
    contactId,
  }: QueueMessageArgs) => {
    if (!user) return
    const id = generateUuid()
    const lastMessage = chatMessagesRef.current[channelId]
      ? _.last(chatMessagesRef.current[channelId].messages)
      : null
    const created = dayjs().utc().format()
    const index = lastMessage ? lastMessage.index : 0

    const queuedMessage = {
      id,
      index,
      channelId,
      user: user,
      created,
      ...(body && { body }),
      ...(contactId && { contactId }),
      ...(sampleIds && { sampleIds }),
      status: CACHE_EVENT.sending as CacheEvent,
    }
    const messages = chatMessagesRef.current[channelId]
      ? chatMessagesRef.current[channelId].messages
      : []

    const channelChatMessages = [...messages, queuedMessage]

    setChatMessages({
      ...chatMessagesRef.current,
      [channelId]: {
        ...chatMessagesRef.current[channelId],
        messages: channelChatMessages,
      },
    })

    sendMessage(queuedMessage)
  }

  const sendMessage = async (cachedMessage: CachedMessage) => {
    const { channelId, id, body, sampleIds, contactId } = cachedMessage

    try {
      await ChatService.sendMessage({
        channelId,
        body,
        nonce: id,
        ...(body && { body }),
        ...(contactId && { contactId }),
        ...(sampleIds && { sampleIds }),
      })
    } catch (error) {
      updateCachedStatus({
        ...cachedMessage,
        status: CACHE_EVENT.failed as CacheEvent,
      })
    }
  }

  const updateCachedStatus = (cachedMessage: CachedMessage) => {
    const channelId = cachedMessage.channelId
    const messages = chatMessagesRef.current[channelId].messages

    const updatedMessages = _.map(messages, (msg) => {
      return msg.id === cachedMessage.id ? cachedMessage : msg
    })

    setChatMessages({
      ...chatMessagesRef.current,
      [channelId]: {
        ...chatMessagesRef.current[channelId],
        messages: updatedMessages,
      },
    })
  }

  const refreshActiveChannel = () => {
    const match = _.find(
      chatChannels,
      (cc) => activeChannelRef.current?.id === cc.id
    )
    setActiveChannel(match || null)
  }

  const activateChannel = (channel: Channel | null) => {
    if (!channel) {
      setActiveChannel(null)
      return
    }

    if (activeChannelRef.current?.id === channel.id) return
    setActiveChannel(channel)
  }

  const getChannels = async () => {
    const getChannelsResponse = await ChatService.getChannels()
    const parsedDossier = parseDossier(
      getChannelsResponse.data,
      (user as HCP).id
    )
    const channelIds = parsedDossier.channelIds || []
    const channels = channelIds?.map((id: string) => parsedDossier.channels[id])
    const sortedChannels = _.chain(channels)
      .map((channel: Channel) => ({
        id: channel.id,
        created: channel.latestMessage
          ? channel.latestMessage.created
          : channel.created,
      }))
      .orderBy(['created'], ['desc'])
      .map((channelMap: ChannelMap) => parsedDossier.channels[channelMap.id])
      .value()

    setChatChannels(sortedChannels)
  }

  const getMessages = async (
    channelId: string,
    index: number | undefined = undefined,
    count = CHAT.count,
    direction = CHAT.desc
  ) => {
    if (
      chatMessagesRef.current[channelId] &&
      !chatMessagesRef.current[channelId].next &&
      direction === CHAT.desc
    ) {
      return
    }

    const getMessagesResponse = await ChatService.getMessages(
      channelId,
      index,
      count,
      direction
    )
    const parsedDossier = parseDossier(getMessagesResponse.data)
    const messages = chatMessagesRef.current[channelId]
      ? chatMessagesRef.current[channelId].messages
      : []

    const nonceArr = _.map(parsedDossier.messages, 'nonce')
    const cleanSendingMessages = _.reject(
      messages,
      (msg) =>
        msg.status === CACHE_EVENT.sending && _.includes(nonceArr, msg.id)
    )

    const mergedMessages = [...cleanSendingMessages, ...parsedDossier.messages]
    const sortedMessages = _.chain(mergedMessages)
      .orderBy(['index', 'created'], ['asc', 'asc'])
      .uniqBy('id')
      .value()

    const channelChatMessages = {
      ...chatMessagesRef.current[channelId],
      messages: sortedMessages,
      ...(direction === CHAT.desc && { next: parsedDossier.next }),
      ...(direction === CHAT.asc && { prev: parsedDossier.next }),
    }

    setChatMessages({
      ...chatMessagesRef.current,
      [channelId]: channelChatMessages,
    })

    //if retrieving 'new' messages, keep loading messages until null next
    if (direction === CHAT.asc && !!channelChatMessages.prev) {
      const lastMessage = _.last(sortedMessages)
      const idx = lastMessage?.index
      getMessages(channelId, idx, count, direction)
    }
  }

  const contextValue = useMemo(
    () => ({
      activateChannel,
      activeChannel,
      chatChannels,
      chatMessages,
      setOpenMiniChatTo,
      openMiniChatTo,
      forwardMessage,
      getChannels,
      getMessages,
      isLoadingChat,
      queueMessage,
      setForwardMessage,
      startForwardMessage,
      unreadChannelsCount,
      unreadMessagesCount,
    }),
    [
      activeChannel,
      chatChannels,
      chatMessages,
      openMiniChatTo,
      forwardMessage,
      isLoadingChat,
      unreadChannelsCount,
      unreadMessagesCount,
    ]
  )

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  )
}
