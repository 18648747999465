import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import Timer from '@web/js/components/Timer'
import { AuthContext } from '@web/js/context/AuthContext'

const SessionExpiringModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)
  const { refreshSession, getExpirationTime } = useContext(AuthContext)

  const refreshSessionAndClose = () => {
    refreshSession()
    hideModal()
  }

  return (
    <Modal title={t('sessionExpiringTitle')} closeable={false}>
      <ModalBody>
        <p>
          {t('sessionExpiringDescription1')}
          <Timer
            format
            seconds={parseInt((getExpirationTime() / 1000).toFixed(0))}
          />
          {t('sessionExpiringDescription2')}
        </p>
      </ModalBody>

      <ModalFooter
        onAcceptText={t('continue')}
        onAccept={refreshSessionAndClose}
      />
    </Modal>
  )
}

export default SessionExpiringModal
