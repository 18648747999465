import React from 'react'
import { Outlet } from 'react-router-dom'
import ModalRoot from '@web/js/modals/ModalRoot'
import { ModalProvider } from '@web/js/context/ModalContext'

const MinimalRoute: React.FC = (): JSX.Element => {
  return (
    <ModalProvider>
      <ModalRoot />
      <Outlet />
    </ModalProvider>
  )
}

export default MinimalRoute
