import * as React from 'react'
import { IconProps } from '@web/_types'

const ChatIcon: React.FC<IconProps> = ({
  height = '16px',
  width = '17px',
  color = '#ffffff',
  className = 'icon-chat',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.443 11.1565L12.7726 10.552C13.1884 9.78929 13.4231 8.92249 13.4231 8C13.4231 5.0042 10.9084 2.5 7.71154 2.5C4.51473 2.5 2 5.00421 2 8C2 10.9958 4.51473 13.5 7.71154 13.5C8.84307 13.5 9.89142 13.1846 10.7735 12.6438L11.3268 12.3046L13.0558 12.7771L12.443 11.1565ZM15.5 15L11.5575 13.9226C10.4443 14.6051 9.1254 15 7.71154 15C3.72872 15 0.5 11.866 0.5 8C0.5 4.13399 3.72872 1 7.71154 1C11.6944 1 14.9231 4.13399 14.9231 8C14.9231 9.18119 14.6216 10.294 14.0895 11.27L15.5 15Z"
      fill={color}
    />
  </svg>
)

export default ChatIcon
