import React from 'react'
import { useLocalStorage } from '@web/common/hooks'
import './CookieConsent.scss'
import { LOCALSTORAGE } from '@web/_constants'
import Button from '@web/js/components/Button'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import { getQueryStringParams } from '@web/_utils'

const CookieConsent: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const queryArgs = getQueryStringParams(window.location.search)
  const isGlassMode = !!queryArgs.vo && queryArgs.vo === '1'

  const [consent, setConsent] = useLocalStorage<boolean | null>(
    LOCALSTORAGE.cookieConsent,
    null
  )

  if (consent || isGlassMode) return null

  return (
    <div id="cookie-consent">
      <div>{t('engageUsesCookies')}</div>
      <Button size="lg" onClick={() => setConsent(true)}>
        {t('iAgree')}
      </Button>
    </div>
  )
}

export default CookieConsent
