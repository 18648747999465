import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  ActionableDeeplink,
  Connection,
  DeeplinkDetails,
  DeeplinkInviter,
  DossierType,
} from '@web/_types'
import { DeeplinkService } from '@web/_services'
import _ from 'lodash'
import {
  AXIOS,
  DEEPLINK_ACTIONS,
  DEEPLINK_ERRORS,
  I18N,
  LOCALSTORAGE,
  MODALS,
  NAVIGATE_TO,
  NAV_LOCATIONS,
  ROLES_TO_DOSSIER,
  ROUTES,
  SUPPORT_LINK,
} from '@web/_constants'
import { useLocalStorage } from '@web/common/hooks'
import { ModalContext } from './ModalContext'
import { useTranslation } from 'react-i18next'
import { AuthContext } from './AuthContext'
import { parseDossier } from '@web/_utils'
import { isHCP, isRep } from '@web/_guards'
import { useNavigate } from 'react-router-dom'

interface IDeeplinkProvider {
  children?: ReactNode
}

export type IDeeplinkContext = {
  loginEmail: string | null
  connectionCard: DeeplinkInviter | null
  actionableDeeplink: ActionableDeeplink | null
  getDeeplinkActions: (
    url: string,
    useTimer?: boolean
  ) => Promise<ActionableDeeplink | null | void>
  clearDeeplinkState: () => void
}

interface IParseConnectionCard {
  actionableDeeplink: ActionableDeeplink
  type: 'REP' | 'HCP'
  id: string
  details?: DeeplinkDetails | null
}

export const DeeplinkContext = createContext<IDeeplinkContext>(
  {} as IDeeplinkContext
)

export const DeeplinkProvider: React.FC<IDeeplinkProvider> = ({ children }) => {
  const controller = new AbortController()
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const { showModal } = useContext(ModalContext)
  const { authenticated } = useContext(AuthContext)
  const [loginEmail, setLoginEmail] = useState<string | null>(null)
  const [connectionCard, setConnectionCard] = useState<DeeplinkInviter | null>(
    null
  )
  const [actionableDeeplink, setActionableDeeplink] =
    useState<ActionableDeeplink | null>(null)

  useEffect(() => {
    return () => {
      controller.abort(AXIOS.canceled)
    }
  }, [])

  useEffect(() => {
    //clear state after login and load any navigation actions
    if (authenticated && actionableDeeplink) {
      const deeplink = _.clone(actionableDeeplink.deeplink)
      clearDeeplinkState()
      getDeeplinkActions(deeplink)
    }
  }, [authenticated])

  const [showWelcome, setShowWelcome] = useLocalStorage<boolean | null>(
    LOCALSTORAGE.welcome,
    null
  )

  const [, setInvertedInviteDisplayName] = useLocalStorage<string | null>(
    LOCALSTORAGE.invertedInviteDisplayName,
    null
  )

  const getDeeplinkActions = async (
    url: string,
    useTimer = true
  ): Promise<ActionableDeeplink | null | void> => {
    // If engage meeting link, skip remote call and go to /engage
    const uri = new URL(url)
    const params = uri.searchParams
    const j = params.get('j')
    const pwd = params.get('pwd')
    if (j && pwd) {
      uri.pathname = ROUTES.engage
      window.location.replace(uri)
      return
    } else {
      const startTime = performance.now()

      const getDeeplinkActionsResponse =
        await DeeplinkService.getDeeplinkActions({
          deeplink: url,
          signal: controller.signal,
        })
      const actionableDeeplink = getDeeplinkActionsResponse.data
      const duration = performance.now() - startTime

      let timeout = 0
      if (useTimer && duration > 250 && duration < 1250) {
        timeout = 1250 - duration
      }

      //keep this for sanity checking
      console.log({ duration, timeout })

      setTimeout(() => {
        processDeeplinkActions(actionableDeeplink)
      }, timeout)

      return actionableDeeplink
    }
  }

  const processDeeplinkActions = (actionableDeeplink: ActionableDeeplink) => {
    const deeplinkUrl = actionableDeeplink.deeplink
    const deeplinkUrlUri = new URL(deeplinkUrl)
    const deeplinkUrlParams = deeplinkUrlUri.searchParams

    _.each(actionableDeeplink.actions, (actionObj) => {
      const action = actionObj.action
      const details = actionObj.details

      switch (action) {
        case DEEPLINK_ACTIONS.LOGIN: {
          if (details.email) setLoginEmail(details.email)
          setActionableDeeplink(actionableDeeplink)

          if (details.inviterRef || actionableDeeplink.dossier) {
            let connectionCard = null

            if (details.inviterRef) {
              connectionCard = parseConnectionCard({
                actionableDeeplink,
                type: details.inviterRef.type,
                id: details.inviterRef.id,
                details,
              })
            } else if (actionableDeeplink.dossier) {
              const id = _.keys(actionableDeeplink.dossier.reps)[0]
              connectionCard = parseConnectionCard({
                actionableDeeplink,
                type: 'REP',
                id,
                details,
              })
            }

            if (connectionCard) setConnectionCard(connectionCard)
          }

          navigate(ROUTES.login, { replace: true })
          break
        }
        case DEEPLINK_ACTIONS.NAVIGATE: {
          let location: string

          if (
            !authenticated &&
            details.location === NAV_LOCATIONS.REP_PROFILE
          ) {
            location = ROUTES.dbc
            if (deeplinkUrlUri.search) location += deeplinkUrlUri.search
            setActionableDeeplink(actionableDeeplink)

            if (actionableDeeplink.dossier) {
              const connectionCard = parseConnectionCard({
                actionableDeeplink,
                type: 'REP',
                id: details.referenceId as string,
              })

              setConnectionCard(connectionCard)
            }
          } else {
            location = NAVIGATE_TO[details.location as keyof typeof NAVIGATE_TO]
            if (details.referenceId) location += `/${details.referenceId}`
          }

          if (details.location !== NAV_LOCATIONS.HOME) {
            if (showWelcome) setShowWelcome(null)
          }

          navigate(location, { replace: true })
          break
        }
        case DEEPLINK_ACTIONS.REVERSE_INVITE:
          if (showWelcome) setShowWelcome(null)
          setInvertedInviteDisplayName(details.displayName as string)
          navigate(ROUTES.home)
          break
        case DEEPLINK_ACTIONS.JOIN_MEETING: {
          const pin = deeplinkUrlParams.get('pin')
          const isPresentation = !!pin && pin.length === 6

          if (isPresentation) {
            let location = ROUTES.presentation
            if (deeplinkUrlUri.search) location += deeplinkUrlUri.search
            navigate(location, { replace: true, state: { redirected: true } })
          } else {
            const params = new URLSearchParams()
            details.meetingId && params.append('m', details.meetingId)
            details.password && params.append('p', details.password)
            let location =
              details.meetingId && details.password
                ? ROUTES.meeting
                : ROUTES.join
            if (params) location += params.toString()
            navigate(location, { replace: true, state: { redirected: true } })
          }
          break
        }
        case DEEPLINK_ACTIONS.ERROR:
          handleDeeplinkError(details)
          break
        default:
          break
      }
    })
  }

  const parseConnectionCard = ({
    actionableDeeplink,
    type,
    id,
    details = null,
  }: IParseConnectionCard): DeeplinkInviter => {
    let dossierConnection: Connection | null = null
    if (actionableDeeplink?.dossier) {
      const dossierType = ROLES_TO_DOSSIER[type] as DossierType
      const parsedDossier = parseDossier(actionableDeeplink)
      if (id) dossierConnection = parsedDossier[dossierType][id]
    }

    let connectionCard: DeeplinkInviter | null = {
      id,
      type,
      displayName: (details?.inviterDisplayName ||
        dossierConnection?.displayName) as string,
      ...((!!details?.inviterTitle || !!dossierConnection?.title) && {
        title: (details?.inviterTitle || dossierConnection?.title) as string,
      }),
      firstName: (details?.inviterFirstName ||
        dossierConnection?.firstName) as string,
      lastName: (details?.inviterLastName ||
        dossierConnection?.lastName) as string,
      photoUrl: details?.inviterPhotoUrl || dossierConnection?.photoUrl,
    }

    connectionCard = {
      ...connectionCard,
      ...(details?.email && { email: details.email }),
      ...(dossierConnection &&
        isRep(dossierConnection) && {
          groupName: dossierConnection.group?.name,
          primaryColor: dossierConnection.group?.primaryColor,
          roleCategory: dossierConnection.roleCategory,
        }),
      ...(dossierConnection &&
        isHCP(dossierConnection) && {
          deleted: dossierConnection.deleted,
        }),
    }

    return connectionCard
  }

  const clearDeeplinkState = () => {
    setLoginEmail(null)
    setConnectionCard(null)
    setActionableDeeplink(null)
  }

  const handleDeeplinkError = (details: DeeplinkDetails) => {
    let name = MODALS.GENERIC_ERROR_MODAL
    let data = null

    switch (details.code) {
      case DEEPLINK_ERRORS.LINK_EXPIRED:
        name = MODALS.EXPIRED_LINK_MODAL
        break
      case DEEPLINK_ERRORS.SIGN_IN_CHAT:
      case DEEPLINK_ERRORS.SIGN_IN_NOTIFICATIONS:
        name = MODALS.SIGN_IN_REQUIRED_MODAL
        data = { type: details.code }
        break
      case DEEPLINK_ERRORS.CANNOT_VIEW_MESSAGE:
        name = MODALS.CHAT_ACCESS_REQUIRED_MODAL
        break
      case DEEPLINK_ERRORS.CUSTOM:
        data = {
          error: {
            ...(details.data?.title && { title: details.data.title }),
            ...(details.data?.message && { body: details.data.message }),
          },
        }
        break
      default:
        data = {
          error: {
            title: t('errorSomethingWentWrongTitle'),
            body: t('errorNotAllowed', {
              support: SUPPORT_LINK,
            }),
          },
        }
        break
    }

    showModal({ name, ...(data && { data }) })
  }

  const contextValue = useMemo(
    () => ({
      loginEmail,
      connectionCard,
      actionableDeeplink,
      getDeeplinkActions,
      clearDeeplinkState,
    }),
    [actionableDeeplink, connectionCard, loginEmail]
  )

  return (
    <DeeplinkContext.Provider value={contextValue}>
      {children}
    </DeeplinkContext.Provider>
  )
}
