import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import DOMPurify from 'dompurify'

const CodeResentModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal title={t('codeResentTitle')} closeable={false}>
      <ModalBody>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t('codeResentDescription')),
          }}
        />
      </ModalBody>

      <ModalFooter onAccept={hideModal} onAcceptText={t('ok')} />
    </Modal>
  )
}

export default CodeResentModal
