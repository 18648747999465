import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const baseURL = Config.OAPI_URL_V2

export const TimezoneService = {
  getTimezones: (): Promise<AxiosResponse> => {
    return axios({
      method: 'get',
      baseURL,
      url: '/timezones',
    })
  },
}
