import React, { useRef, useEffect, useState, useContext } from 'react'
import './JoinMeetingForm.scss'
import { useTranslation } from 'react-i18next'
import { I18N, ROUTES, LOCALSTORAGE, LOGGING } from '@web/_constants'
import CameraIconSolid from '@web/common/img/CameraIconSolid'
import { AuthContext } from '@web/js/context/AuthContext'
import { useLocalStorage } from '@web/common/hooks'
import { getQueryStringParams } from '@web/_utils'
import { EngageService, OrgService, RemoteCLMService } from '@web/_services'
import Button from '@web/js/components/Button'
import FormField from '@web/js/components/FormField'
import FormError from '../FormError'
import Config from '@web/_config'
import { AppContext } from '@web/js/context/AppContext'
import _ from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'

const JoinMeetingForm: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { config } = useContext(AppContext)
  const { authenticated } = useContext(AuthContext)
  const [formError, setFormError] = useState<string>('')
  const [isLoading, setIsLoading] = useState(true)
  const [meetingIdError, setMeetingIdError] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')

  const meetingIdRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const isAutoLoading = useRef(false)

  const [meetingId, setMeetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )
  const [meetingPwd, setMeetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )
  const [, setFirstName] = useLocalStorage<string | null>(
    LOCALSTORAGE.firstName,
    null
  )
  const [, setLastName] = useLocalStorage<string | null>(
    LOCALSTORAGE.lastName,
    null
  )
  const [, setAccountId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingAccountId,
    null
  )
  const [, setJoinType] = useLocalStorage<string | null>(
    LOCALSTORAGE.joinType,
    null
  )

  useEffect(() => {
    meetingIdRef.current?.focus()
    const queryArgs = getQueryStringParams(window.location.search)
    if (searchParams.size) setSearchParams({}, { replace: true })

    if (queryArgs.m) {
      setMeetingId(queryArgs.m)
      setMeetingPwd(queryArgs.p ?? null)
    } else if (queryArgs.pin) {
      setMeetingId(queryArgs.pin)
      setMeetingPwd(null)
    }

    //invalid presentation id
    if (queryArgs.invalid) {
      setMeetingIdError(t('errorMeetingIdNotFound'))
      setIsLoading(false)
      return
    }

    if (authenticated) {
      setIsLoading(false)
      return
    }

    // autoload meeting/presentation when unauthed
    if ((queryArgs.m && queryArgs.p) || queryArgs.pin) {
      isAutoLoading.current = true
      setTimeout(() => {
        handleAccept()
      }, 0)
    } else {
      setIsLoading(false)
    }
  }, [])

  const validateForm = () => {
    setMeetingIdError('')
    setPasswordError('')

    if (meetingId && meetingId.length === 7) {
      setMeetingIdError(t('errorInvalidMeetingId'))
      return false
    } else if (meetingId && meetingId.length > 7 && !meetingPwd) {
      setPasswordError(t('pleaseEnterPwd'))
      return false
    } else {
      return true
    }
  }

  const handleAccept = async () => {
    const isValid = validateForm()
    if (!isValid) return

    const isPresentation = meetingId && meetingId.length === 6
    if (isPresentation) {
      await joinPresentation()
    } else {
      await joinMeeting()
    }

    if (isLoading) setIsLoading(false)
  }

  const joinPresentation = async () => {
    if (isAutoLoading.current) {
      setJoinType(LOGGING.JOIN_TYPE.LINK)
    } else {
      setJoinType(LOGGING.JOIN_TYPE.MANUAL)
    }

    try {
      const createSessionResponse = await RemoteCLMService.createSession(
        config?.engageApiHost || Config.ENGAGE_API_BASE,
        meetingId as string
      )

      const presentee = _.find(
        createSessionResponse.data.data,
        (member) => member.role === 'account'
      )

      if (presentee) {
        setFirstName(presentee.firstName)
        setLastName(presentee.lastName)
      }

      if (authenticated) {
        navigate(`${ROUTES.presentation}?pin=${meetingId}`, {
          state: { verified: true, response: createSessionResponse.data },
        })
      } else {
        navigate(ROUTES.name, {
          ...(isAutoLoading.current && { state: { redirected: true } }),
        })
      }
    } catch (error) {
      console.log(error)
      setMeetingIdError(t('errorMeetingIdNotFound'))
    }
  }

  const joinMeeting = async () => {
    let attendee
    try {
      const [, , engageAttendee] =
        await EngageService.getMeetingInfoWithAttendee(
          meetingId as string,
          meetingPwd as string
        )

      setJoinType(LOGGING.JOIN_TYPE.MANUAL)
      attendee = engageAttendee
    } catch (error) {
      console.log(error)

      const errorResponse = error.response
      if (errorResponse) {
        if (errorResponse.status === 404) {
          setFormError(t('meetingNotFound'))
          return
        }
      }
      setFormError(t('somethingWentWrong'))
      return
    }

    if (attendee?.orgId) {
      try {
        const redirect = await OrgService.getRedirectUrl(attendee.orgId)
        let redirectUrl = redirect.data.redirectTo
        if (redirectUrl) {
          const url = new URL(redirectUrl)
          if (url.protocol !== 'https:') {
            redirectUrl = `https://${redirectUrl}`
          }

          window.location.assign(
            `${redirectUrl}/web/meeting?m=${meetingId}&p=${meetingPwd}`
          )
          return
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (authenticated) {
      const meetingUrl = `/web${ROUTES.meeting}?m=${meetingId}&p=${meetingPwd}`
      window.location.assign(meetingUrl)
    } else {
      if (attendee) {
        //checking firstName and lastName before setting in order to keep stored values if server does not return values
        if (attendee.firstName) setFirstName(attendee.firstName)
        if (attendee.lastName) setLastName(attendee.lastName)
        setAccountId(attendee.accountId)
      }

      navigate(ROUTES.name, {
        ...(isAutoLoading.current && { state: { redirected: true } }),
      })
    }
  }

  if (isLoading) return null

  return (
    <div id="join-meeting">
      {authenticated && (
        <>
          <div className="text-headline">{t('joinMeeting')}</div>
          <div className="join-meeting-icon-wrapper">
            <div className="join-meeting-icon">
              <CameraIconSolid />
            </div>
          </div>
        </>
      )}

      {!authenticated && (
        <div className="text-headline center">{t('joinMeeting')}</div>
      )}

      <FormField
        forRef={meetingIdRef}
        label={authenticated ? t('meetingId') : ''}
        placeholder={!authenticated ? t('meetingId') : ''}
        round={!authenticated}
        name="meetingId"
        error={meetingIdError}
        value={meetingId || ''}
        onChange={(e) => {
          setMeetingId(e.target.value)
          if (meetingIdError) setMeetingIdError('')
          if (formError) setFormError('')
        }}
      />

      {!!meetingId && meetingId.length > 7 && (
        <FormField
          forRef={passwordRef}
          label={authenticated ? t('password') : ''}
          placeholder={!authenticated ? t('password') : ''}
          round={!authenticated}
          name="password"
          error={passwordError}
          value={meetingPwd || ''}
          onChange={(e) => {
            setMeetingPwd(e.target.value)
            if (passwordError) setPasswordError('')
            if (formError) setFormError('')
          }}
        />
      )}

      <FormError text={formError} align="center" />

      <Button
        onClick={handleAccept}
        style="primary"
        size={authenticated ? 'lg' : 'xl'}
        block={true}
        disabled={!meetingId || meetingId.length < 6}
      >
        {authenticated ? t('joinNow') : t('continue')}
      </Button>
    </div>
  )
}

export default JoinMeetingForm
