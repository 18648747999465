import React, { useState, useEffect, useRef, useContext } from 'react'
import { useWindowSize } from '@web/common/hooks'
import './ChatDock.scss'
import { classNames } from '@web/_utils'
import { useNavigate } from 'react-router-dom'
import ChatList from '../ChatList'
import ChatMessenger from '../ChatMessenger'
import { useTranslation } from 'react-i18next'
import { I18N, LOGGING, MODALS, ROLES, ROUTES } from '@web/_constants'
import { ChatContext } from '@web/js/context/ChatContext'
import { Channel, Connection } from '@web/_types'
import CaretIcon from '@web/common/img/CaretIcon'
import ProfileIcon from '@web/common/img/ProfileIcon'
import CloseIcon from '@web/common/img/CloseIcon.svg'
import { hasProfile, isRep } from '@web/_guards'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import ChatIcon from '@web/common/img/ChatIcon'
import Button from '../Button'
import { ModalContext } from '@web/js/context/ModalContext'

interface ActiveChannelState {
  id: string
  minimized: boolean
}

const ChatDock: React.FC = () => {
  const dispatch = useDispatch()
  const { showModal } = useContext(ModalContext)
  const {
    unreadMessagesCount,
    chatChannels,
    isLoadingChat,
    openMiniChatTo,
    setOpenMiniChatTo,
  } = useContext(ChatContext)

  const { width } = useWindowSize()
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const dockContainerWidth = 344
  const dockContainerPadding = 20
  const dockPadding = 16

  const chatDockRef = useRef<HTMLDivElement>(null)

  const [numChatDockMessengers, setNumChatDockMessengers] = useState<number>(2)
  const [minimizedList, setMinimizedList] = useState(true)
  const [activeChannelStates, setActiveChannelStates] = useState<
    ActiveChannelState[]
  >([])

  useEffect(() => {
    if (openMiniChatTo) {
      const toChannel = _.find(chatChannels, (ch) => ch.id === openMiniChatTo)
      if (toChannel) loadChannel(toChannel)
      setOpenMiniChatTo(null)
    }
  }, [openMiniChatTo])

  useEffect(() => {
    setTimeout(() => {
      setNumChatDockMessengers(
        Math.floor(width / (dockContainerWidth + dockContainerPadding)) - 1
      )
    }, 0)
  }, [width])

  useEffect(() => {
    if (activeChannelStates.length > numChatDockMessengers) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [removedChannel, ...newActiveChannelStates] = activeChannelStates
      setActiveChannelStates(newActiveChannelStates)
    }
  }, [numChatDockMessengers])

  const toggleChat = (
    channel: Channel,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation()
    const index = activeChannelStates.findIndex(
      (state: ActiveChannelState) => state.id === channel.id
    )
    const newActiveStates = [...activeChannelStates]
    newActiveStates[index].minimized = !activeChannelStates[index].minimized
    setActiveChannelStates(newActiveStates)
  }

  const goToProfile = (
    connection: Connection,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation()

    const eventMessage = {
      profileId: connection.id,
      actionType: LOGGING.ACTION_TYPE.VIEW_PROFILE,
      actionDetail: LOGGING.ACTION_DETAIL.CHAT_THREAD_HEADER,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )

    const path = connection && isRep(connection) ? ROLES.rep : ROLES.hcp
    navigate(`/${path.toLowerCase()}/${connection.id}`, {
      state: { connection },
    })
  }

  const goToChat = (
    channelId: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation()
    navigate(`${ROUTES.chat}/${channelId}`)
  }

  const loadChannel = (channel: Channel): void => {
    //if already in array, return
    if (
      activeChannelStates.findIndex(
        (activeChannel: ActiveChannelState) => activeChannel.id === channel.id
      ) > -1
    )
      return

    const newState = { id: channel.id, minimized: false }
    if (activeChannelStates.length === numChatDockMessengers) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [removedChannel, ...newActiveChannelStates] = activeChannelStates
      setActiveChannelStates([...newActiveChannelStates, newState])
    } else {
      setActiveChannelStates([...activeChannelStates, newState])
    }
  }

  const deactivateChannels = (
    channel: Channel,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation()
    const channels = activeChannelStates.filter(
      (activeChannelState: ActiveChannelState) =>
        activeChannelState.id !== channel.id
    )
    setActiveChannelStates([...channels])
  }

  const sendMessage = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (minimizedList) setMinimizedList(false)
    showModal({
      name: MODALS.SEND_MESSAGE_MODAL,
      data: {
        shouldRoute: false,
      },
    })
  }

  const renderList = () => {
    const clcx = {
      'chat-dock--list': true,
      minimized: minimizedList,
      hide: !numChatDockMessengers,
    }

    const chatListClasses = classNames(clcx)

    return (
      <div className={chatListClasses}>
        <div
          className="chat-dock--header"
          onClick={() => setMinimizedList(!minimizedList)}
        >
          <div className="chat-dock--header--title">
            {t('chat')}{' '}
            {!!unreadMessagesCount && (
              <span>
                ({unreadMessagesCount > 99 ? '99+' : unreadMessagesCount})
              </span>
            )}
          </div>
          <div className="chat-dock--header--actions">
            <div className="chat-dock--header--send-message">
              <Button size="sm" onClick={sendMessage}>
                <ChatIcon />
              </Button>
            </div>
            <div className="chat-dock--header--toggle">
              <CaretIcon />
            </div>
            <div
              className="chat-dock--header--go"
              onClick={() => navigate(ROUTES.chat)}
            >
              <ProfileIcon />
            </div>
          </div>
        </div>

        <ChatList loadChannel={loadChannel} showActive={false} inDock={true} />
      </div>
    )
  }

  if (isLoadingChat) return null

  return (
    <div id="chat-dock" ref={chatDockRef}>
      {renderList()}

      {activeChannelStates.map(
        (activeChannelState: ActiveChannelState, i: number) => {
          const channel = chatChannels.find(
            (channel: Channel) => channel.id === activeChannelState.id
          )

          if (!channel) return null

          const cmcx = {
            'chat-dock--messenger': true,
            minimized: activeChannelState?.minimized,
          }
          const chatMessengerClasses = classNames(cmcx)

          const noProfile =
            !!channel.connection && !hasProfile(channel.connection)
          const chcx = {
            'chat-dock--header': true,
            'no-profile': noProfile,
          }
          const chatHeaderClasses = classNames(chcx)

          return (
            <div
              key={channel.id}
              className={chatMessengerClasses}
              style={{
                right:
                  (dockContainerWidth + dockContainerPadding) * (i + 1) +
                  dockPadding,
              }}
            >
              <div
                className={chatHeaderClasses}
                onClick={(event) => {
                  if (!noProfile)
                    goToProfile(channel.connection as Connection, event)
                }}
              >
                <div className="chat-dock--header--title">
                  {channel.connection
                    ? channel.connection.displayName
                    : channel.partialEmail}
                </div>

                <div className="chat-dock--header--actions">
                  <div
                    className="chat-dock--header--toggle"
                    onClick={(event) => toggleChat(channel, event)}
                  >
                    <CaretIcon />
                  </div>

                  <div
                    className="chat-dock--header--go"
                    onClick={(event) => goToChat(channel.id, event)}
                  >
                    <ProfileIcon />
                  </div>

                  <div
                    className="chat-dock--header--close"
                    onClick={(event) => deactivateChannels(channel, event)}
                  >
                    <img src={CloseIcon} />
                  </div>
                </div>
              </div>

              <ChatMessenger
                inDock={true}
                key={channel.id}
                activeChannel={channel}
                showHeader={false}
              />
            </div>
          )
        }
      )}
    </div>
  )
}

export default ChatDock
