import React, { useContext, useRef } from 'react'
import { ERROR, I18N, LOGGING, MODALS, ROLES } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import './InviteToConnectModal.scss'
import TextareaAutosize from 'react-autosize-textarea/lib'
import Avatar from '@web/js/components/Avatar'
import { Connection } from '@web/_types'
import { InvitationsService } from '@web/_services'
import { ToastContext } from '@web/js/context/ToastContext'

interface InviteToConnectModalProps {
  connection: Connection
  setSentInvite: (sent: boolean) => void
  createConnectionCallback?: () => void
}

const InviteToConnectModal: React.FC<InviteToConnectModalProps> = ({
  connection,
  setSentInvite,
  createConnectionCallback,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal, showModal } = useContext(ModalContext)
  const optionalMessageRef = useRef<HTMLTextAreaElement>(null)
  const { addToast } = useContext(ToastContext)

  const sendInvite = async () => {
    const inviteMessage = optionalMessageRef.current?.value

    try {
      const inviteResponse = await InvitationsService.invite({
        hcpId: connection.id,
        ...(!!inviteMessage && {
          inviteMessage: inviteMessage.trim().replace(/^\n|\n$/g, ''),
        }),
        source: LOGGING.ACTION_TYPE.HCP_PROFILE_CONNECT,
      })

      hideModal()
      const autoConnected = inviteResponse.data.result === ROLES.hcp
      if (autoConnected) {
        if (createConnectionCallback) createConnectionCallback()
      } else {
        connection.invitePending = true
        setSentInvite(true)
        showModal({
          name: MODALS.INVITE_SENT_MODAL,
          data: {
            displayName: connection.displayName,
          },
        })
      }
    } catch (error) {
      const errorResponse = error.response
      const errorData = errorResponse.data

      if (errorData.detail.length) {
        switch (errorData.detail[0].reason) {
          case ERROR.duplicate:
            addToast(t('errorDuplicateInvitation'))
            break
          default:
            addToast(t('errorGeneric'))
        }
      }
    }
  }

  return (
    <Modal size="lg" title={t('inviteToConnect')} onClose={hideModal}>
      <ModalBody id="invite-to-connect-modal">
        <div id="invite-message-to">
          <div id="to">{t('to')}</div>

          <div id="to-recipient">
            <Avatar user={connection} size="lg" />
            <span>{connection.displayName}</span>
          </div>
        </div>

        <div id="invite-message-body">
          <TextareaAutosize
            id="optional-message"
            rows={1}
            ref={optionalMessageRef}
            maxLength={300}
            placeholder={t('addPersonalizedMessage')}
            style={{ minHeight: '32px' }}
          />
        </div>
      </ModalBody>

      <ModalFooter
        onAcceptText={t('send')}
        cancellable={true}
        onCancel={hideModal}
        onAccept={sendInvite}
      />
    </Modal>
  )
}

export default InviteToConnectModal
