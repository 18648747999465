import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'
import { OfficeCRUD } from '@web/_types'

const baseURL = Config.API_URL_V2

export const OfficesService = {
  getUserOffices: async (): Promise<AxiosResponse> => {
    return axios({
      baseURL,
      url: `/user/offices`,
    })
  },

  createOffice: async (office: OfficeCRUD): Promise<AxiosResponse> => {
    return axios({
      baseURL,
      method: 'post',
      url: `/offices`,
      data: { ...office },
    })
  },

  updateOffice: async (
    officeId: string,
    office: OfficeCRUD
  ): Promise<AxiosResponse> => {
    return axios({
      baseURL,
      method: 'patch',
      url: `/offices/${officeId}`,
      data: { ...office },
    })
  },

  getOffice: async (officeId: string): Promise<AxiosResponse> => {
    return axios({
      baseURL,
      url: `/offices/${officeId}`,
    })
  },

  getOfficesByHcpId: async (hcpId: string): Promise<AxiosResponse> => {
    return axios({
      baseURL,
      url: `/hcps/${hcpId}/offices`,
    })
  },

  joinOffice: async (officeId: string): Promise<AxiosResponse> => {
    return axios({
      baseURL,
      method: 'post',
      url: `/offices/${officeId}/join`,
    })
  },

  leaveOffice: async (officeId: string): Promise<AxiosResponse> => {
    return axios({
      baseURL,
      method: 'post',
      url: `/offices/${officeId}/leave`,
    })
  },
}
