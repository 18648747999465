import * as React from 'react'
import { IconProps } from '@web/_types'

const ArrowForward: React.FC<IconProps> = ({
  height = '18px',
  width = '18px',
  color = '#ffffff',
  className = 'icon-arrow-forward',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70825 0.792893C9.31772 0.402369 8.68456 0.402369 8.29403 0.792893C7.90351 1.18342 7.90351 1.81658 8.29403 2.20711L14.0881 8.00114H1.5C0.947715 8.00114 0.5 8.44886 0.5 9.00114C0.5 9.55343 0.947715 10.0011 1.5 10.0011H14.0883L8.29403 15.7954C7.90351 16.1859 7.90351 16.8191 8.29403 17.2096C8.68456 17.6001 9.31772 17.6001 9.70825 17.2096L17.2072 9.71061C17.219 9.69891 17.2305 9.68692 17.2417 9.67466C17.3045 9.60578 17.356 9.53024 17.3961 9.45045C17.4642 9.31536 17.5025 9.16273 17.5025 9.00114C17.5025 8.71863 17.3854 8.46348 17.197 8.28163L9.70825 0.792893Z"
      fill={color}
    />
  </svg>
)

export default ArrowForward
