import React, { useState, useContext, useEffect } from 'react'
import './Connections.scss'
import { I18N, ROUTES, LOGGING } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import { Connection } from '@web/_types'
import { useResponsive } from '@farfetch/react-context-responsive'
import { classNames } from '@web/_utils'
import { useNavigate, useParams } from 'react-router-dom'
import { isHCP, isRep } from '@web/_guards'
import EmptyConnections from '@web/common/img/EmptyConnections.svg'
import SendInvite from '@web/js/components/SendInvite'
import ContactCard from '@web/js/components/ContactCard'
import Sidebar from '@web/js/components/Sidebar'
import ConnectionDetails from '@web/js/components/ConnectionDetails'
import Button from '@web/js/components/Button'
import { ConnectionContext } from '@web/js/context/ConnectionContext'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'

interface RouterParams {
  id: string
}

const ConnectionsRoute: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(I18N.namespaces.web)
  const { id } = useParams<keyof RouterParams>() as RouterParams
  const [activeConnection, setActiveConnection] = useState<Connection | null>(
    null
  )

  const { connections, getConnections, findConnection } =
    useContext(ConnectionContext)
  const [open, setOpen] = useState(!!activeConnection)
  const [isLoading, setIsLoading] = useState(true)
  const { lessThan, mediaType } = useResponsive()

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (connections.length) handleOnChangeId(id)

    if (activeConnection) {
      const match = findConnection(activeConnection.id)
      if (match) {
        setActiveConnection(match)
        if (!open) setOpen(true)
      }
    }
  }, [connections])

  useEffect(() => {
    if (!isLoading) setOpen(false)
  }, [mediaType])

  useEffect(() => {
    if (!isLoading) handleOnChangeId(id)
  }, [id])

  useEffect(() => {
    if (!isLoading) {
      if (activeConnection) {
        navigate(`${ROUTES.connections}/${activeConnection.id}`, {
          replace: true,
        })
      } else {
        navigate(ROUTES.connections, { replace: true })
      }
    }
  }, [activeConnection])

  const init = async () => {
    await getConnections()
    setIsLoading(false)
  }

  const handleOnChangeId = (id?: string) => {
    if (id) {
      const match = findConnection(id)
      if (match) {
        setActiveConnection(match)
        if (!open) setOpen(true)
      }
    } else {
      if (open) setOpen(false)
    }
  }

  const toggleDetails = (connection: Connection) => {
    const isSameConnection =
      !!activeConnection && connection.id === activeConnection.id
    const path = connection && isRep(connection) ? ROUTES.rep : ROUTES.hcp

    const eventMessage = {
      profileId: connection.id,
      actionType: LOGGING.ACTION_TYPE.VIEW_PROFILE,
      actionDetail: LOGGING.ACTION_DETAIL.CONNECTIONS,
    }

    const logSharedContactEvent = () =>
      dispatch(
        createLog(
          LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
          JSON.stringify(eventMessage)
        )
      )

    if (lessThan.md) {
      logSharedContactEvent()
      return navigate(`${path}/${connection.id}`)
    }

    if (open && isSameConnection) {
      setOpen(false)
    } else {
      setActiveConnection(connection)
      setOpen(true)
      logSharedContactEvent()
    }
  }

  if (isLoading) return null

  return (
    <div id="connections-route">
      <div className="row">
        {!connections.length && (
          <div className="connections-empty col-sm-8 col-md-5">
            <div className="connections-empty--icon">
              <img src={EmptyConnections} />
            </div>
            <div className="text-headline center">
              {t('emptyConnectionsTitle')}
            </div>
            <div className="text-subhead center">
              {t('emptyConnectionsDescription')}
            </div>
            <div className="connections-empty--action">
              <Button size="sm" onClick={() => navigate(ROUTES.invite)}>
                {t('sendInvitation')}
              </Button>
            </div>
          </div>
        )}

        {!!connections.length && (
          <div className="connections-list col-sm-8 col-md-5">
            {connections.map((connection) => {
              const cx = {
                'connections-list--item': true,
                active: connection.id === activeConnection?.id,
              }
              const chatListItemClasses = classNames(cx)

              return (
                <div
                  key={connection.id}
                  className={chatListItemClasses}
                  onClick={() => toggleDetails(connection)}
                >
                  <ContactCard
                    contact={connection}
                    showTitle={isHCP(connection)}
                    showRepIcon={true}
                  />
                </div>
              )
            })}
          </div>
        )}
        {!lessThan.md && !!connections.length && (
          <div className="connections-list--invite col-md-3">
            <SendInvite />
          </div>
        )}
      </div>

      <Sidebar
        side="right"
        open={open}
        closeFunc={() => setOpen(false)}
        onClosedFunc={() => setActiveConnection(null)}
        width="calc(50% - 125px)"
      >
        <ConnectionDetails
          connection={activeConnection}
          removeConnectionCallback={() => setActiveConnection(null)}
        />
      </Sidebar>
    </div>
  )
}

export default ConnectionsRoute
