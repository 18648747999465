import axios, {AxiosResponse, CancelToken} from 'axios'
import Config from '@web/_config'

const oapiUrl = Config.OAPI_URL
const oapiUrlV2 = Config.OAPI_URL_V2

export const ConfigService = {
  getConfig: (
    countryCode: string,
    signal: AbortSignal
  ): Promise<AxiosResponse> => {
    return axios({
      method: 'get',
      baseURL: oapiUrl,
      url: `/config?countryCode=${countryCode}`,
      signal,
    })
  },

  getCountry: (signal: AbortSignal): Promise<AxiosResponse> => {
    return axios({
      method: 'get',
      url: Config.COUNTRY_URL,
      signal,
    })
  },

  getCountries: (cancelToken: CancelToken): Promise<AxiosResponse> => {
    return axios({
      method: 'get',
      baseURL: oapiUrlV2,
      url: '/countries',
      cancelToken,
    })
  },
}
