import * as React from 'react'
import { IconProps } from '@web/_types'

const ErrorIcon: React.FC<IconProps> = ({
  height = '33px',
  width = '32px',
  color = '#ffffff',
  className = 'icon-error',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9995 4.94806C9.79075 4.94806 4.75757 9.98124 4.75757 16.19C4.75757 22.3987 9.79075 27.4319 15.9995 27.4319C22.2082 27.4319 27.2414 22.3987 27.2414 16.19C27.2414 9.98124 22.2082 4.94806 15.9995 4.94806ZM2.66663 16.19C2.66663 8.82644 8.63595 2.85712 15.9995 2.85712C23.363 2.85712 29.3323 8.82644 29.3323 16.19C29.3323 23.5535 23.363 29.5228 15.9995 29.5228C8.63595 29.5228 2.66663 23.5535 2.66663 16.19ZM15.9994 10.2295C16.5768 10.2295 17.0449 10.6976 17.0449 11.275V16.1899C17.0449 16.7673 16.5768 17.2354 15.9994 17.2354C15.422 17.2354 14.954 16.7673 14.954 16.1899V11.275C14.954 10.6976 15.422 10.2295 15.9994 10.2295ZM15.9992 22.3329C16.6775 22.3329 17.2274 21.783 17.2274 21.1047C17.2274 20.4264 16.6775 19.8765 15.9992 19.8765C15.3209 19.8765 14.771 20.4264 14.771 21.1047C14.771 21.783 15.3209 22.3329 15.9992 22.3329Z"
      fill={color}
    />
  </svg>
)

export default ErrorIcon
