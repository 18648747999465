export const cutStringLength = (text: string, len: number): string => {
  let encodedText = new TextEncoder().encode(text)
  let charLength = encodedText.length
  while (charLength > len) {
    text = text.slice(0, -1)
    encodedText = new TextEncoder().encode(text)
    charLength = encodedText.length
  }
  return text
}
