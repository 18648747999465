export const formatNonProprietaryName = (
  str: string,
  parentheses = false
): string => {
  let nonProprietaryName = str

  if (
    str.length >= 2 &&
    str.charAt(0) == '"' &&
    str.charAt(str.length - 1) == '"'
  ) {
    nonProprietaryName = str.substring(1, str.length - 1)
  } else if (parentheses) {
    nonProprietaryName = `(${nonProprietaryName})`
  }

  return nonProprietaryName
}
