import React, { useState, useEffect, useRef, useContext } from 'react'
import './Presentation.scss'
import { AuthContext } from '@web/js/context/AuthContext'
import {
  CLM,
  I18N,
  LOCALSTORAGE,
  LOGGING,
  MODALS,
  ROUTES,
} from '@web/_constants'
import { isIPad } from '@admin/common/js/IsMobile'
import { RemoteCLMService } from '@web/_services'
import { useDispatch } from 'react-redux'
import { useFullScreen, useLocalStorage } from '@web/common/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsMobile } from '@farfetch/react-context-responsive'
import { useTranslation } from 'react-i18next'
import AuthenticationLayout from '@web/js/layouts/AuthenticationLayout'
import Button from '@web/js/components/Button'
import Config from '@web/_config'
import FullScreenClose from '@web/common/img/FullScreenClose.svg'
import FullScreenOpen from '@web/common/img/FullScreenOpen.svg'
import Logo from '@web/common/img/Engage_Logo.svg'
import { createLog } from '@web/js/redux/logger/loggerActions'
import { RemoteClmSessionMember } from '@web/_types'
import _ from 'lodash'
import { AppContext } from '@web/js/context/AppContext'
import { getQueryStringParams } from '@web/_utils'
import { ModalContext } from '@web/js/context/ModalContext'
import { buildName } from '@web/_utils/buildName'

const PresentationRoute: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { search, state } = useLocation()
  const { t } = useTranslation(I18N.namespaces.web)

  const { config, isSurnameOrdered } = useContext(AppContext)
  const { authenticated, user } = useContext(AuthContext)
  const { showModal, hideModal } = useContext(ModalContext)

  const { isMobile } = useIsMobile()

  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState<string>(t('presentation'))
  const [src, setSrc] = useState('')
  const [showInstabilityBanner, setShowInstabilityBanner] = useState(false)

  const clmBodyRef = useRef<HTMLDivElement>(null)
  const clmPresentationRef = useRef<HTMLIFrameElement>(null)

  const joined = useRef(false)
  const ended = useRef(false)
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [firstName, setFirstName] = useLocalStorage<string | null>(
    LOCALSTORAGE.firstName,
    null
  )
  const [lastName, setLastName] = useLocalStorage<string | null>(
    LOCALSTORAGE.lastName,
    null
  )
  const [fullName] = useLocalStorage<string | null>(LOCALSTORAGE.fullName, null)
  const [meetingId, setMeetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )
  const [joinType, setJoinType] = useLocalStorage<string | null>(
    LOCALSTORAGE.joinType,
    null
  )
  const [sessionId] = useLocalStorage<string | null>(
    LOCALSTORAGE.sessionId,
    null
  )
  const [locale] = useLocalStorage<string | null>(LOCALSTORAGE.locale, null)
  const presentationIdRef = useRef<string | null>(meetingId)
  const presenterNameRef = useRef<string | null>(null)

  const { toggleFullScreen, fullScreenEnabled, isFullScreen } =
    useFullScreen(clmBodyRef)

  useEffect(() => {
    if (!joinType) {
      setJoinType(
        state?.redirected ? LOGGING.JOIN_TYPE.LINK : LOGGING.JOIN_TYPE.OTHER
      )
    }

    init()
  }, [])

  useEffect(() => {
    if (!isLoading && clmPresentationRef.current) {
      clmPresentationRef.current.addEventListener('load', onPresentationLoaded)
    }

    return () => {
      if (!isLoading) {
        unloadSocketEventListeners()
        clmPresentationRef.current?.removeEventListener(
          'load',
          onPresentationLoaded
        )
      }
    }
  }, [isLoading])

  const init = async () => {
    const queryArgs = getQueryStringParams(search)

    // handle copy/paste direct presentation url with no pin,
    // pre-existing/missing meetingId in localstorage
    if (!queryArgs.pin) {
      setMeetingId(null)
      onInvalidPin()
      return
    } else if (queryArgs.pin !== meetingId || !meetingId) {
      setMeetingId(queryArgs.pin)
      presentationIdRef.current = queryArgs.pin
    } else {
      presentationIdRef.current = meetingId
    }

    try {
      let clmData

      if (state && state.verified && state.response) {
        clmData = state.response
      } else {
        const { data } = await RemoteCLMService.createSession(
          config?.engageApiHost || Config.ENGAGE_API_BASE,
          presentationIdRef.current
        )

        clmData = data
      }

      // if unauthed and accessing presentation route directly
      const shouldVerifyName =
        !authenticated && (!state || (!!state && !state.verified))

      if (shouldVerifyName) {
        const presentee = _.find(
          clmData.data,
          (member) => member.role === 'account'
        )

        if (presentee) {
          setFirstName(presentee.firstName)
          setLastName(presentee.lastName)
        }

        navigate(ROUTES.name)
        return
      }

      const presenter = _.find(
        clmData.data,
        (member) => member.role === 'presenter'
      )

      if (presenter) {
        const { firstName: presenterFirstName, lastName: presenterLastName } =
          presenter as RemoteClmSessionMember

        if (presenterFirstName || presenterLastName) {
          let title = `${t('presentation')}`

          if (presenterFirstName || presenterLastName) {
            const presenterName = buildName({
              ...(presenterFirstName && { firstName: presenterFirstName }),
              ...(presenterLastName && { lastName: presenterLastName }),
              isSurnameOrdered,
            })

            title += ` - ${presenterName}`
            presenterNameRef.current = presenterName
          }

          setTitle(title)
        }
      }

      let src = `${config?.engageRemoteCLMHost || Config.CLM_PLAYER_BASE}?pin=${
        presentationIdRef.current
      }&locale=${locale}`

      if (user) {
        src += `&firstName=${encodeURIComponent(
          user.firstName
        )}&lastName=${encodeURIComponent(user.lastName)}`
      } else if (fullName) {
        src += `&firstName=${encodeURIComponent(fullName as string)}`
      } else {
        src += `&firstName=${encodeURIComponent(
          firstName as string
        )}&lastName=${encodeURIComponent(lastName as string)}`
      }

      setMeetingId(null)
      setSrc(src)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      onInvalidPin()
    }
  }

  const onInvalidPin = () => {
    setJoinType(null)

    let pathname = ''
    if (authenticated) {
      pathname = isMobile || isIPad ? ROUTES.joinMeeting : ROUTES.meetings
    } else {
      pathname = ROUTES.join
    }

    if (search) pathname += search + '&invalid=true'
    navigate(pathname, {
      state: { redirected: true },
    })
  }

  const logPresentation = () => {
    let joinedFirstName
    let joinedLastName

    if (user) {
      joinedFirstName = user.firstName
      joinedLastName = user.lastName
    } else if (fullName) {
      joinedFirstName = fullName
      joinedLastName = null
    } else {
      joinedFirstName = firstName
      joinedLastName = lastName
    }

    const remoteCrmEventMessageOptions = {
      meetingPin: presentationIdRef.current,
      joinedFirstName,
      joinedLastName,
      joinType,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.REMOTE_CLM_JOIN,
        JSON.stringify(remoteCrmEventMessageOptions)
      )
    )

    setJoinType(null)
  }

  const trackCLMSession = () => {
    RemoteCLMService.trackSession({
      pin: presentationIdRef.current as string,
      ...(sessionId && { sessionId }),
    })
  }

  const onPresentationLoaded = () => {
    if (clmPresentationRef.current) {
      console.log('subscribing to presentation events')
      window.addEventListener('message', presentationListener, false)
    }
  }

  const presentationListener = (event: MessageEvent) => {
    const clmPresentationIFrame = (
      clmPresentationRef.current as HTMLIFrameElement
    )?.contentWindow

    if (event.source !== clmPresentationIFrame) return
    console.log('CLM event:', event.data.type, event)

    switch (event.data.type) {
      case CLM.EVENTS.INVALID_PIN:
        onInvalidPin()
        break
      case CLM.EVENTS.MEETING_ENDED:
        if (joined.current) {
          ended.current = true
          leavePresentation()
        }
        break
      case CLM.EVENTS.JOIN_SUCCESS:
        if (!joined.current) {
          joined.current = true
          logPresentation()
          trackCLMSession()
        } else {
          clearPresentationDisconnectedState()
        }
        break
      case CLM.EVENTS.DISCONNECTED:
        if (joined.current && !ended.current) presentationDisconnectedState()
        break
      case CLM.EVENTS.RECONNECTED:
        hideModal()
        break
    }
  }

  const presentationDisconnectedState = () => {
    setShowInstabilityBanner(true)

    timerRef.current = setTimeout(() => {
      setShowInstabilityBanner(false)
      showModal({ name: MODALS.NO_CONNECTION_MODAL })
    }, 10000)
  }

  const clearPresentationDisconnectedState = () => {
    if (timerRef.current) clearTimeout(timerRef.current)
    setShowInstabilityBanner(false)
    hideModal()
  }

  const unloadSocketEventListeners = () => {
    console.log('unsubscribing from presentation events')
    window.removeEventListener('message', presentationListener)
  }

  const leavePresentation = () => {
    let pathname = ''
    if (authenticated) {
      pathname = isMobile || isIPad ? ROUTES.joinMeeting : ROUTES.meetings
    } else {
      pathname = ROUTES.postPresentation
    }

    navigate(pathname, {
      ...(!authenticated && { state: { repName: presenterNameRef.current } }),
    })

    showModal({
      name: MODALS.PRESENTATION_ENDED_MODAL,
    })
  }

  if (isLoading) {
    return <AuthenticationLayout showLoadingState={true} />
  }

  return (
    <div id="clm-route" className={isMobile ? 'mobile' : ''}>
      <div id="clm-header">
        <div id="clm-header--logo">
          <img src={Logo} />
        </div>

        {!isMobile && (
          <div id="clm-header--title" className="text-default-semibold">
            {title}
          </div>
        )}
        <div id="clm-header--leave">
          <Button
            size={isMobile ? 'sm' : 'lg'}
            style="danger"
            onClick={() => {
              showModal({
                name: MODALS.LEAVE_PRESENTATION_MODAL,
                data: {
                  leavePresentation,
                },
              })
            }}
          >
            {t('leave')}
          </Button>
        </div>
      </div>
      {isMobile && (
        <div id="clm-mobile-title" className="text-default-semibold">
          {title}
        </div>
      )}
      <div id="clm-body" ref={clmBodyRef}>
        {showInstabilityBanner && (
          <div id="connection-instability-banner">
            {t('connectionUnstable')}
            <br />
            {t('tryingToReconnect')}
          </div>
        )}

        {fullScreenEnabled && (
          <div id="fullscreen-btn" onClick={toggleFullScreen}>
            <img src={isFullScreen ? FullScreenClose : FullScreenOpen} />
          </div>
        )}

        <iframe
          id="clm-presentation-frame"
          ref={clmPresentationRef}
          src={src}
          scrolling="no"
          height="100%"
          width="100%"
          allowFullScreen={true}
        />
      </div>
    </div>
  )
}

export default PresentationRoute
