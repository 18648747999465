import * as React from 'react'
import { IconProps } from '@web/_types'

const EmailIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-email',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30753 6.56414H18.4357C18.5877 6.56414 18.7399 6.66365 18.8084 6.81908C18.7686 6.83619 18.7301 6.85779 18.6934 6.88398L11.8716 11.7567L5.04978 6.88398C5.01313 6.85781 4.97458 6.83622 4.93483 6.81912C5.00333 6.66366 5.15555 6.56414 5.30753 6.56414ZM4.89727 8.28755V17C4.89727 17.255 5.10262 17.4359 5.30753 17.4359H18.4357C18.6406 17.4359 18.846 17.255 18.846 17V8.28747L12.2293 13.0137C12.0153 13.1665 11.7279 13.1665 11.5139 13.0137L4.89727 8.28755ZM5.30753 5.33337H18.4357C19.3383 5.33337 20.0768 6.08337 20.0768 7.00004V17C20.0768 17.9167 19.3383 18.6667 18.4357 18.6667H5.30753C4.40497 18.6667 3.6665 17.9167 3.6665 17V7.00004C3.6665 6.08337 4.40497 5.33337 5.30753 5.33337Z"
      fill={color}
    />
  </svg>
)

export default EmailIcon
