import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import '@web/css/web.scss'
import * as React from 'react'
import '@web/common/js/i18n'
import { BrowserRouter as Router } from 'react-router-dom'
import App from '@web/js/App'
import { ResponsiveProvider } from '@farfetch/react-context-responsive'
import Config from '@web/_config'
import { BREAKPOINTS, BREAKPOINTS_MAX } from '@web/_constants'
import { Provider } from 'react-redux'
import store from './redux/store'
import { AuthProvider } from './context/AuthContext'
import { ToastProvider } from './context/ToastContext'
import { DeeplinkProvider } from '@web/js/context/DeeplinkContext'
import { ModalProvider } from '@web/js/context/ModalContext'
import { createRoot } from 'react-dom/client'

const container = document.getElementById(Config.ROOT_ELEMENT)
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <ResponsiveProvider
      breakpoints={BREAKPOINTS}
      breakpointsMax={BREAKPOINTS_MAX}
      mobileBreakpoint="sm"
    >
      <Router basename={Config.BASE_URL}>
        <ToastProvider>
          <AuthProvider>
            <ModalProvider>
              <DeeplinkProvider>
                <App />
              </DeeplinkProvider>
            </ModalProvider>
          </AuthProvider>
        </ToastProvider>
      </Router>
    </ResponsiveProvider>
  </Provider>
)
