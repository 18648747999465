import React from "react"
import "./UnsupportedBrowser.scss"
import { useTranslation } from "react-i18next"
import { I18N } from "@web/_constants"
import ChromeLogo from "@web/common/img/ChromeLogo.png"
import FirefoxLogo from "@web/common/img/FirefoxLogo.png"
import SafariLogo from "@web/common/img/SafariLogo.png"
import EdgeLogo from "@web/common/img/EdgeLogo.png"

const UnsupportedBrowser: React.FC = () => {
    const { t } = useTranslation(I18N.namespaces.web)

    return (
        <div id="unsupported-browser-display">
            <div id="top-message">
                {t('unsupportedBrowser')}
            </div>
            <div id="use-message">
                {t('useBrowsers')}
            </div>
            <div id="supported-browser-list">
                <div className="supported-browser">
                    <img src={ChromeLogo} />
                    <label>
                        Chrome
                    </label>
                </div>
                <div className="supported-browser">
                    <img src={FirefoxLogo} />
                    <label>
                        Firefox
                    </label>
                </div>
                <div className="supported-browser">
                    <img src={SafariLogo} />
                    <label>
                        Safari
                    </label>
                </div>
                <div className="supported-browser">
                    <img src={EdgeLogo} />
                    <label>
                        Edge
                    </label>
                </div>
            </div>
        </div>
    )
}

export default UnsupportedBrowser