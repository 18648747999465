import React from 'react'
import './NotFound.scss'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'

const NotFound: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)

  return (
    <div id="not-found">
      <h5>{t('pageNotFound')}</h5>
    </div>
  )
}

export default NotFound
