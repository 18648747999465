import React, { useEffect, useState } from 'react'
import './MeetingDetails.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  I18N,
  ROUTES,
  CHANNEL_TYPE,
  LOGO_EXT,
  LOCALSTORAGE,
  LOGGING,
  NOTIFICATION,
  SCHEDULING_SCH_CRM_NOTIFICATIONS,
} from '@web/_constants'
import { Meeting, NotificationRequest, RemoteCLMSession } from '@web/_types'
import {
  canCommunicate,
  hasProfile,
  isHCP,
  isMeeting,
  isRemoteCLMSession,
  isRep,
} from '@web/_guards'
import { useLocalStorage } from '@web/common/hooks'
import CameraIconSolid from '@web/common/img/CameraIconSolid'
import CalendarPlus from '@web/common/img/CalendarPlus.svg'
import CalendarCheck from '@web/common/img/CalendarCheck.svg'
import CalendarDecline from '@web/common/img/CalendarDecline.svg'
import PresentationIcon from '@web/common/img/PresentationIcon.svg'
import ThumbsUp from '@web/common/img/ThumbsUp.svg'
import ThumbsDown from '@web/common/img/ThumbsDown.svg'
import MeetingVirtual from '@web/common/img/MeetingVirtual.svg'
import MeetingInPerson from '@web/common/img/MeetingInPerson.svg'
import MeetingPhone from '@web/common/img/MeetingPhone.svg'
import { formatPhoneNumber, getNotificationStartTime } from '@web/_utils'
import Button from '@web/js/components/Button'
import ContactCard from '../ContactCard'
import dayjs from 'dayjs'
import { classNames } from '@web/_utils'
import OfficeIcon from '@web/common/img/OfficeIcon.svg'
import ChatIcon from '@web/common/img/ChatIcon'
import PingIcon from '@web/common/img/PingIcon'
import _ from 'lodash'

interface MeetingDetailsProps {
  meeting: Meeting | NotificationRequest | RemoteCLMSession
  openProfile: () => void
  acceptRequest: (meeting: NotificationRequest) => void
  declineRequest: (meeting: NotificationRequest) => void
}

const MeetingDetails: React.FC<MeetingDetailsProps> = ({
  meeting,
  openProfile,
  acceptRequest,
  declineRequest,
}) => {
  const { t, i18n } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()

  const [accepted, setAccepted] = useState(false)
  const [declined, setDeclined] = useState(false)
  const [meetingId, setMeetingId] = useState('')

  const [meetingDay, setMeetingDay] = useState<string | null>(null)
  const [meetingDate, setMeetingDate] = useState('')
  const [isToday, setIsToday] = useState(false)

  const [logo, setLogo] = useState('')

  const connection =
    isMeeting(meeting) || isRemoteCLMSession(meeting)
      ? meeting.connection
      : meeting.contact

  const meetingStart =
    isMeeting(meeting) || isRemoteCLMSession(meeting)
      ? meeting.start
      : getNotificationStartTime(meeting)

  const remoteCLMSessionWithinBounds =
    isRemoteCLMSession(meeting) &&
    dayjs().isBetween(meetingStart, meeting.expiration, null, '[]')

  const [, setJoinType] = useLocalStorage<string | null>(
    LOCALSTORAGE.joinType,
    null
  )

  useEffect(() => {
    if (accepted) setAccepted(false)
    if (declined) setDeclined(false)
  }, [meetingId])

  useEffect(() => {
    const id =
      isMeeting(meeting) || isRemoteCLMSession(meeting)
        ? meeting.id
        : meeting.notificationId
    if (id !== meetingId) setMeetingId(id)

    //Logo
    let groupLogo = ''
    if (connection && isRep(connection) && !!connection.group.photoUrl) {
      groupLogo = connection.group.photoUrl + LOGO_EXT
    }
    setLogo(groupLogo)

    const today = dayjs().startOf('day').format()
    const isToday = dayjs(meetingStart).isToday()
    const isTomorrow = dayjs(meetingStart).isTomorrow()
    if (isToday) setMeetingDay(t('days.today'))
    if (isTomorrow) setMeetingDay(t('days.tomorrow'))
    if (!isToday && !isTomorrow) setMeetingDay(null)
    setIsToday(isToday)

    const isPrevious = dayjs(meetingStart).isBefore(dayjs(today))
    const sameYear = dayjs().isSame(meetingStart, 'year')

    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    }
    if (isPrevious || !sameYear) dateOptions.year = 'numeric'
    const meetingDate = new Date(meetingStart).toLocaleDateString(
      i18n.language,
      dateOptions
    )
    setMeetingDate(meetingDate)
  }, [meeting])

  const joinMeeting = () => {
    const today = dayjs().startOf('day').format()
    const joinType = dayjs(meetingStart).isBefore(dayjs(today))
      ? LOGGING.JOIN_TYPE.PREVIOUS_MEETINGS_DETAILS
      : LOGGING.JOIN_TYPE.UPCOMING_MEETINGS_DETAILS
    setJoinType(joinType)

    if (isMeeting(meeting)) {
      const meetingUrl = `/web${ROUTES.meeting}?m=${
        (meeting as Meeting).attendeeId
      }&p=${(meeting as Meeting).password}`

      window.location.assign(meetingUrl)
    } else if (isRemoteCLMSession(meeting)) {
      navigate(`${ROUTES.presentation}?pin=${meeting.pin}`)
    }
  }

  const goToChat = () => {
    navigate(`${ROUTES.chat}/${connection.channelId}`)
  }

  const generateIcon = () => {
    if (isMeeting(meeting)) {
      return <CameraIconSolid />
    } else if (isRemoteCLMSession(meeting)) {
      return <img src={PresentationIcon} />
    } else {
      switch (meeting.type) {
        case NOTIFICATION.crmMeetingDeclined:
        case NOTIFICATION.meetingAdvDeclined:
          return <img src={CalendarDecline} />
        case NOTIFICATION.crmMeetingAccepted:
        case NOTIFICATION.meetingAdvAccepted:
          return <img src={CalendarCheck} />
        case NOTIFICATION.crmMeetingRequest:
        case NOTIFICATION.meetingAdvRequest:
          return <img src={CalendarPlus} />
      }
    }
  }

  const generateMeetingType = () => {
    if (isMeeting(meeting) || isRemoteCLMSession(meeting)) return null

    const meetingDetailsObj = _.includes(
      SCHEDULING_SCH_CRM_NOTIFICATIONS,
      meeting.type
    )
      ? 'scheduleCrmDetails'
      : 'scheduleAdvDetails'
    const meetingDetails = meeting[meetingDetailsObj]
    const meetingType = meetingDetails?.meetingType

    let directive = ''
    let icon
    let phone
    let office
    switch (meetingType) {
      case 'IN_PERSON':
        icon = MeetingInPerson
        directive = t('meetingStatus.selectedMeetingLocation')

        if (meetingDetails?.officeId && meetingDetails?.officeName) {
          directive = t('meetingStatus.selectedMeetingLocation')
          office = (
            <div
              className="container office"
              key={meetingDetails.officeId}
              onClick={() =>
                navigate(`${ROUTES.office}/${meetingDetails.officeId}`)
              }
            >
              <div className="office-icon">
                <img src={OfficeIcon} alt="" />
              </div>
              <div className="office-name">{meetingDetails.officeName}</div>
            </div>
          )
        } else {
          directive = t('meetingStatus.pleaseConfirmLocation')
        }

        break
      case 'PHONE':
        icon = MeetingPhone
        if (meetingDetails?.phoneNumber) {
          phone = formatPhoneNumber(meetingDetails.phoneNumber) as string
          if (meetingDetails.phoneExtension)
            phone += ` ${t('ext')} ${meetingDetails.phoneExtension}`
          directive = t('meetingStatus.hostWillCall', { phone })
        } else {
          directive = t('meetingStatus.pleaseConfirmPhone')
        }

        break
      case 'VIRTUAL':
        icon = MeetingVirtual
        directive = t('meetingStatus.willReceiveMeetingLink')
        break
    }

    return (
      <>
        <div className="meeting-details--body--meeting-type">
          <img src={icon} />
          {directive}
        </div>
        {office && office}
      </>
    )
  }

  const headerStyle = isRep(connection)
    ? {
        background: `linear-gradient(90deg, ${connection.group.secondaryColor} 0%, ${connection.group.primaryColor} 100%)`,
      }
    : {}

  const iconCx = {
    'meeting-details--header-icon': true,
    clickable: (isToday && isMeeting(meeting)) || remoteCLMSessionWithinBounds,
    blue: isMeeting(meeting) || remoteCLMSessionWithinBounds,
    gray:
      isRemoteCLMSession(meeting) &&
      !dayjs().isBetween(meetingStart, meeting.expiration, null, '[]'),
  }

  const meetingDetailsIconClasses = classNames(iconCx)

  const connectionCx = {
    'meeting-details--body--connection': true,
    container: true,
    clickable: hasProfile(connection),
  }

  const meetingConnectionClasses = classNames(connectionCx)

  return (
    <div id="meeting-details">
      <div className="meeting-details--header" style={headerStyle}>
        {!!logo && (
          <div className="meeting-details--header-logo">
            <img src={logo} alt="logo" />
          </div>
        )}

        <div
          className={meetingDetailsIconClasses}
          {...(((isToday && isMeeting(meeting)) ||
            remoteCLMSessionWithinBounds) && { onClick: joinMeeting })}
        >
          {generateIcon()}
        </div>
      </div>

      <div className="meeting-details--body">
        {meetingDay && (
          <div className="meeting-details--body--day">{meetingDay}</div>
        )}

        <div className="meeting-details--body--date">{meetingDate}</div>

        <div className="meeting-details--body--time">
          {dayjs(meetingStart).format('LT')}
        </div>

        {isMeeting(meeting) && meeting.title && (
          <div className="meeting-details--body--title">{meeting.title}</div>
        )}

        {isRemoteCLMSession(meeting) && (
          <div className="meeting-details--body--title">
            {t('presentation')}
          </div>
        )}

        {accepted &&
          !isMeeting(meeting) &&
          !isRemoteCLMSession(meeting) &&
          meeting.type !== NOTIFICATION.meetingAdvRequest && (
            <div className="meeting-details--body--response-accepted">
              {t('meetingNotification.acceptedInviteUppercase')}
            </div>
          )}

        {declined &&
          !isMeeting(meeting) &&
          !isRemoteCLMSession(meeting) &&
          meeting.type !== NOTIFICATION.meetingAdvRequest && (
            <div className="meeting-details--body--response-declined">
              {t('meetingNotification.declinedInviteUppercase')}
            </div>
          )}

        {!isMeeting(meeting) &&
          !isRemoteCLMSession(meeting) &&
          meeting.type === NOTIFICATION.meetingAdvRequest && (
            <div className="meeting-details--body--response-buttons">
              <Button
                size="sm"
                style="success"
                iconFront={<img src={ThumbsUp} />}
                onClick={(e) => {
                  e.stopPropagation()
                  setAccepted(true)
                  acceptRequest(meeting)
                }}
              >
                {t('meetingNotification.accept')}
              </Button>
              <Button
                size="sm"
                style="danger"
                iconFront={<img src={ThumbsDown} />}
                onClick={(e) => {
                  e.stopPropagation()
                  setDeclined(true)
                  declineRequest(meeting)
                }}
              ></Button>
            </div>
          )}

        {generateMeetingType()}

        {connection && (
          <div
            className={meetingConnectionClasses}
            onClick={() => {
              if (hasProfile(connection)) openProfile()
            }}
          >
            <ContactCard contact={connection} showContactStatus={true} />
          </div>
        )}

        <div className="meeting-details--body--actions">
          {(isMeeting(meeting) || remoteCLMSessionWithinBounds) && (
            <Button
              size="lg"
              block={true}
              onClick={joinMeeting}
              id="join-meeting"
            >
              {t('joinMeeting')}
            </Button>
          )}

          {canCommunicate(connection) && (
            <Button
              size="lg"
              block={true}
              outline={true}
              onClick={goToChat}
              iconFront={
                isHCP(connection) ? (
                  <ChatIcon />
                ) : connection.channelType === CHANNEL_TYPE.chat ? (
                  <ChatIcon />
                ) : (
                  <PingIcon />
                )
              }
              id="init-com"
            >
              {isHCP(connection)
                ? t('chatNow')
                : connection.channelType === CHANNEL_TYPE.ping
                  ? t('ping')
                  : t('chatNow')}
            </Button>
          )}
        </div>

        {(isMeeting(meeting) || remoteCLMSessionWithinBounds) && (
          <div className="meeting-details--body--credentials">
            <div>
              {t('meetingId')}:{' '}
              {isMeeting(meeting)
                ? meeting.attendeeId
                : (meeting as RemoteCLMSession).pin}
            </div>

            {isMeeting(meeting) && (
              <div>
                {t('password')}: {meeting.password}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default MeetingDetails
