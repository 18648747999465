import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { ENGAGE } from '@web/_constants'
import { IEngageLoggingContext } from '@web/js/context/EngageLoggingContext'
import { EngageService } from './EngageService'

let stompClient: Client | null = null
let _loggingContext: IEngageLoggingContext | null = null
const appType = 'WEB_CLIENT'

export const StompService = {
  init: (
    participantId: string,
    zoomId: string,
    appVersion: string,
    supportedSignature: string,
    messageToken: string,
    loggingContext: IEngageLoggingContext
  ): void => {
    _loggingContext = loggingContext
    const remoteSampleAttendeeId = `${participantId}_${zoomId}`
    stompClient = new Client({
      connectHeaders: {
        'Access-Control-Allow-Origin': '*',
        'Veeva-App-Type': appType,
        'Veeva-Attendee-Id': remoteSampleAttendeeId,
        'Veeva-App-Version': appVersion,
        'Veeva-Supported-Remote-Signatures': supportedSignature,
        Authorization: `Bearer ${messageToken}`,
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      onWebSocketClose: (event) => {
        console.log('ws close', event)
        if (event.wasClean) {
          const logMessage =
            ENGAGE.LOGGING.EVENT_MESSAGES.SOCKET_DISCONNECT_SUCCESS.replace(
              '{0}',
              remoteSampleAttendeeId
            )
          loggingContext
            .logClientEvent(
              ENGAGE.LOGGING.EVENT_TYPES.SOCKET_DISCONNECT,
              null,
              logMessage
            )
            .then()
        } else {
          const logMessage =
            ENGAGE.LOGGING.EVENT_MESSAGES.SOCKET_DISCONNECT_ERROR.replace(
              '{0}',
              remoteSampleAttendeeId
            )
              .replace('{1}', event.code)
              .replace('{2}', event.reason)
          loggingContext
            .logClientEvent(
              ENGAGE.LOGGING.EVENT_TYPES.SOCKET_DISCONNECT,
              null,
              logMessage
            )
            .then()
        }
      },
      onWebSocketError: console.error,
      onDisconnect: () => {
        loggingContext
          .logClientEvent(
            ENGAGE.LOGGING.EVENT_TYPES.SOCKET_DISCONNECT,
            null,
            null
          )
          .then()
      },
      onStompError: () => {
        loggingContext
          .logClientEvent(
            ENGAGE.LOGGING.EVENT_TYPES.ERROR_WEB_SOCKET,
            null,
            null
          )
          .then()
      },
      webSocketFactory: () =>
        new SockJS(
          `${EngageService.getEngageServerUrl()}/api/v1/hcp-message/ws-connect/`
        ),
    })
  },
  activate: (
    messageHandler: (data: Record<string, never>) => void,
    messageToken: string
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (stompClient) {
        stompClient.onConnect = () => {
          console.log('stomp connected')
          _loggingContext
            ?.logClientEvent(
              ENGAGE.LOGGING.EVENT_TYPES.SOCKET_CONNECT,
              null,
              null
            )
            .then()
          StompService._subscribe(messageHandler, messageToken)
          resolve()
        }
        stompClient.activate()
      } else {
        reject()
      }
    })
  },
  deactivate: (): void => {
    stompClient?.deactivate()
  },
  _subscribe: (
    messageHandler: (data: Record<string, never>) => void,
    messageToken: string
  ): void => {
    if (stompClient) {
      _loggingContext?.logClientEvent(
        ENGAGE.LOGGING.EVENT_TYPES.SOCKET_SUBSCRIBE,
        null,
        null
      )
      stompClient.subscribe(
        '/user/ws-topic/signatures',
        (message) => {
          if (message.body) {
            messageHandler(JSON.parse(message.body))
          } else {
            messageHandler({})
          }
          message.nack()
        },
        {
          Authorization: `Bearer ${messageToken}`,
        }
      )
    }
  },
}
