import { MODALS } from '@web/_constants'
import { ModalContext } from '@web/js/context/ModalContext'
import { useCallback, useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

const useConfirmExit = (
  confirmExit: (confirmNavigation: () => void) => void,
  when = false
) => {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) return
    const push = navigator.push
    navigator.push = (...args: Parameters<typeof push>) => {
      const confirm = () => push(...args)
      confirmExit(confirm)
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, confirmExit, when])
}

export const useRouteGuard = (when = true) => {
  const { showModal } = useContext(ModalContext)

  const confirmExit = useCallback((confirmNavigation: () => void) => {
    const onConfirm = () => confirmNavigation()

    showModal({
      name: MODALS.CONFIRM_ROUTE_CHANGE,
      data: {
        setConfirmedNavigation: onConfirm,
      },
    })
  }, [])

  useConfirmExit(confirmExit, when)
}
