import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import './ChatAccessRequiredModal.scss'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

const ChatAccessRequiredModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal
      title={t('unableToViewMessage')}
      closeable={true}
      onClose={hideModal}
    >
      <ModalBody id="chat-access-required-modal">
        {t('permissionToAccessMessages')}
      </ModalBody>

      <ModalFooter onAcceptText={t('close')} onAccept={hideModal} />
    </Modal>
  )
}

export default ChatAccessRequiredModal
