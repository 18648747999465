import React, { ReactNode } from 'react'
import './DetailsHeader.scss'
import { classNames } from '@web/_utils'

interface DetailsHeaderProps {
  secondaryColor?: string
  primaryColor?: string
  id?: string
  classes?: string
  children?: ReactNode
}

const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  primaryColor,
  secondaryColor,
  classes,
  id,
  children,
}) => {
  const headerStyle =
    !!primaryColor && !!secondaryColor
      ? {
          background: `linear-gradient(90deg, ${secondaryColor} 0%, ${primaryColor} 100%)`,
        }
      : {}

  const cx = {
    'details-header': true,
    [classes as string]: !!classes,
  }

  const detailsHeaderClasses = classNames(cx)

  return (
    <div
      {...(id && { id })}
      className={detailsHeaderClasses}
      style={headerStyle}
    >
      {children}
    </div>
  )
}

export default DetailsHeader
