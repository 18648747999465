import React, { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ROUTES } from '@web/_constants'
import MainLayout from '@web/js/layouts/MainLayout'
import { AuthContext } from '@web/js/context/AuthContext'
import { SocketProvider } from '@web/js/context/SocketContext'
import { ConnectionProvider } from '@web/js/context/ConnectionContext'
import { ChatProvider } from '@web/js/context/ChatContext'
import { MeetingsProvider } from '@web/js/context/MeetingsContext'
import ModalRoot from '@web/js/modals/ModalRoot'
import {NotificationsProvider} from '@web/js/context/NotificationsContext'

const AuthenticatedRoute: React.FC = () => {
  const { authenticated } = useContext(AuthContext)
  const navigate = useNavigate()

  if (!authenticated) {
    navigate(ROUTES.home, { state: { redirected: true } })
    return null
  }

  return (
    <ConnectionProvider>
      <MeetingsProvider>
        <SocketProvider>
          <ChatProvider>
            <NotificationsProvider>
              <ModalRoot />
              <MainLayout>
                    <Outlet />
                  </MainLayout>
            </NotificationsProvider>
          </ChatProvider>
        </SocketProvider>
      </MeetingsProvider>
    </ConnectionProvider>
  )
}

export default AuthenticatedRoute
