import { getQueryStringParams } from '@web/_utils'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Redirect: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    const params = getQueryStringParams(location.search)
    if (params.ref) window.location.assign(params.ref)
  }, [])

  return null
}

export default Redirect
