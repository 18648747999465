import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

interface ReinviteHCPModalProps {
  displayName: string
}

const ReinviteHCPModal: React.FC<ReinviteHCPModalProps> = ({ displayName }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal closeable={true} title={t('invitationSent')} onClose={hideModal}>
      <ModalBody>
        <p className="text-default">
          {t('invitationToConnect', {
            name: displayName,
          })}
        </p>
      </ModalBody>

      <ModalFooter onAcceptText={t('close')} onAccept={hideModal} />
    </Modal>
  )
}

export default ReinviteHCPModal
