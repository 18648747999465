import { DEEPLINK_ERRORS, I18N, NAV_LOCATIONS } from '@web/_constants'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import './SignInRequiredModal.scss'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import _ from 'lodash'

interface SignInRequiredModalProps {
  type: string
}

const SignInRequiredModal: React.FC<SignInRequiredModalProps> = ({ type }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal title={t('signInRequired')} closeable={true} onClose={hideModal}>
      <ModalBody id="sign-in-required-modal">
        {_.includes(
          [
            NAV_LOCATIONS.NOTIFICATION_SETTINGS,
            DEEPLINK_ERRORS.SIGN_IN_NOTIFICATIONS,
          ],
          type
        )
          ? t('signInManageNotifications')
          : t('signInViewMessage')}
      </ModalBody>

      <ModalFooter onAcceptText={t('close')} onAccept={hideModal} />
    </Modal>
  )
}

export default SignInRequiredModal
