import React, { useContext } from 'react'
import './ContactCard.scss'
import { ContactCardProps } from '@web/_types'
import { isRep, isHCP, hasCommonHCPs } from '@web/_guards'
import { classNames, toCamelCase } from '@web/_utils'
import { useTranslation } from 'react-i18next'
import CaretIcon from '@web/common/img/CaretIcon'
import { COLORS, I18N } from '@web/_constants'
import Avatar from '../Avatar'
import StarIcon from '@mui/icons-material/Star'
import FriendlyDate from '../FriendlyDate'
import { AuthContext } from '@web/js/context/AuthContext'

const ContactCard: React.FC<ContactCardProps> = ({
  children,
  contact,
  email,
  date,
  onClick,
  showArrow = false,
  showContactStatus = false,
  showGroup = true,
  showTitle = false,
  showRepIcon = false,
  showMyself = false,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { user } = useContext(AuthContext)
  const isMyself = showMyself && !!contact && user?.id === contact.id

  const cx = {
    'contact-card': true,
    clickable: !!onClick,
    padded: !!onClick,
    hcp: !!contact && isHCP(contact),
    rep: !!contact && isRep(contact),
    email: !!email,
  }

  const contactCardClasses = classNames(cx)

  return (
    <div
      className={contactCardClasses}
      data-contact-id={contact ? contact.id : email}
      {...(!!onClick && { onClick })}
    >
      <div className="contact-card--avatar">
        <Avatar
          {...(!!contact && { user: contact })}
          {...(!!email && { email })}
          size="lg"
          showRepIcon={showRepIcon}
        />
      </div>

      {/* Is myself or deleted HCP */}
      {!!contact && isHCP(contact) && (!!contact.deleted || isMyself) ? (
        <div className="contact-card--details">
          <div className="contact-card--details--header">
            <div className="contact-card--details--name">
              {!!contact.deleted && (
                <span className="text-default-semibold">
                  {t('deletedUser')}
                </span>
              )}

              {isMyself && (
                <span className="text-default-semibold">{t('myself')}</span>
              )}
            </div>

            {date && (
              <div className="contact-card--details--date">
                <FriendlyDate date={date} classes="text-extra-small" />
              </div>
            )}
          </div>

          {!isMyself &&
            !!React.Children.toArray(children).filter(Boolean).length && (
              //workaround because children is technically an array of false when there are no children
              <div className="contact-card--details--children">{children}</div>
            )}
        </div>
      ) : (
        <div className="contact-card--details">
          <div className="contact-card--details--header">
            <div className="contact-card--details--name">
              <span className="text-default-semibold">
                {contact ? contact.displayName : email}
              </span>

              {showContactStatus && !!contact && contact.isContact && (
                <div className="contact-card--details--status">
                  <StarIcon />
                </div>
              )}
            </div>

            {date && (
              <div className="contact-card--details--date">
                <FriendlyDate date={date} classes="text-extra-small" />
              </div>
            )}
          </div>

          {showTitle && !!contact && contact.title && (
            <div className="contact-card--details--title text-small">
              {contact.title}
            </div>
          )}

          {!showTitle &&
            !!contact &&
            isRep(contact) &&
            contact.roleCategory && (
              <div className="contact-card--details--role text-small">
                {t(`roleCategory.${toCamelCase(contact.roleCategory)}`)}
              </div>
            )}

          {!!contact && isRep(contact) && contact.group && showGroup && (
            <div className="contact-card--details--group text-small">
              {contact.group.name}
            </div>
          )}

          {showContactStatus &&
            !!contact &&
            !contact.isContact &&
            hasCommonHCPs(contact) && (
              <div className="contact-card--details--shared-connections">
                <div className="contact-card--details--status">
                  <StarIcon />
                </div>
                <span className="text-supporting">
                  {t('sharedConnectionsCount', {
                    count: contact.commonHcpIds.length,
                  })}
                </span>
              </div>
            )}

          {!!React.Children.toArray(children).filter(Boolean).length && (
            //workaround because children is technically an array of false when there are no children
            <div className="contact-card--details--children">{children}</div>
          )}
        </div>
      )}

      {showArrow && (
        <div className="contact-card--arrow">
          <CaretIcon color={COLORS.gray} />
        </div>
      )}
    </div>
  )
}

export default ContactCard
