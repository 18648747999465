import React, { useEffect, useState, useContext } from 'react'
import './Offices.scss'
import { Office, ContactOffice } from '@web/_types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, matchRoutes } from 'react-router-dom'
import { OfficesService } from '@web/_services'
import { I18N, ROUTES } from '@web/_constants'
import _ from 'lodash'
import Button from '@web/js/components/Button'
import OfficeCard from '@web/js/components/OfficeCard'
import CardList from '@web/js/components/CardList'
import { ToastContext } from '@web/js/context/ToastContext'
import { AuthContext } from '@web/js/context/AuthContext'

interface ContactOfficeData {
  officeId: string
  contactCount: number
}

interface OfficesProps {
  hcpId?: string //if hcpId is not present, display current user offices/functionality
}

const CONTACT_OFFICES_LIMIT = 2

const Offices: React.FC<OfficesProps> = ({ hcpId }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { addToast } = useContext(ToastContext)
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [offices, setOffices] = useState<Office[]>([])
  const [contactOffices, setContactOffices] = useState<ContactOffice[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [viewAll, setViewAll] = useState(false)
  const profileMatch = matchRoutes([{ path: ROUTES.profile }], location)

  useEffect(() => {
    init()

    return () => {
      if (!isLoading) setIsLoading(true)
    }
  }, [hcpId])

  const init = async () => {
    try {
      const getUserOfficesResponse = hcpId
        ? await OfficesService.getOfficesByHcpId(hcpId)
        : await OfficesService.getUserOffices()

      const data = getUserOfficesResponse.data
      const officeIds = getUserOfficesResponse.data.officeIds
      const officeData = data.dossier.offices
      const offices = officeIds.map((id: string) => officeData[id])
      const sortedOffices = _.orderBy(
        offices,
        [(office) => office.name.toLowerCase()],
        ['asc']
      )
      setOffices(sortedOffices)

      if (!hcpId) {
        const contactOfficeData = data.contactOffices
        const contactOffices = contactOfficeData.map(
          (contactOffice: ContactOfficeData) => {
            return {
              office: officeData[contactOffice.officeId],
              contactCount: contactOffice.contactCount,
            }
          }
        )
        const sortedContactOffices = _.orderBy(
          contactOffices,
          [(contactOffice) => contactOffice.office.name.toLowerCase()],
          ['asc']
        )
        setContactOffices(sortedContactOffices)
      }

      setIsLoading(false)
    } catch (error) {
      console.log(error)
      addToast(t('errorGeneric'))
      setIsLoading(false)
    }
  }

  const joinOffice = async (
    event: React.MouseEvent<HTMLElement>,
    officeId: string
  ) => {
    event.stopPropagation()

    try {
      await OfficesService.joinOffice(officeId)
      await init()
    } catch (error) {
      console.log(error)
      addToast(t('errorGeneric'))
    }
  }

  const renderNoOffices = () => {
    if (hcpId) {
      return <div id="no-offices">{t('offices.noOffices')}</div>
    } else {
      return (
        <div id="create-office-description">
          {t('offices.createDescription')}
        </div>
      )
    }
  }

  if (isLoading) return null

  return (
    <div id="offices">
      <div id="user-offices">
        <div className="text-headline">
          {profileMatch ? t('offices.myOffices') : t('offices.title')}
        </div>

        {offices.length ? (
          <CardList>
            {_.map(offices, (office) => (
              <OfficeCard office={office} key={office.id} hcpId={hcpId} />
            ))}
          </CardList>
        ) : (
          renderNoOffices()
        )}

        {!hcpId && (
          <Button
            size="sm"
            outline={true}
            onClick={() => navigate(ROUTES.officeCreate)}
          >
            {t('offices.createNew')}
          </Button>
        )}
      </div>

      {!hcpId && !!contactOffices.length && (
        <div id="contact-offices">
          <div className="text-headline">
            {t('offices.joinWithConnections')}
          </div>

          <CardList>
            {_.chain(contactOffices)
              .take(viewAll ? contactOffices.length : CONTACT_OFFICES_LIMIT)
              .map((contactOffice) => (
                <OfficeCard
                  office={contactOffice.office}
                  contactCount={contactOffice.contactCount}
                  key={contactOffice.office.id}
                >
                  {user?.countryCode === contactOffice.office.countryCode && (
                    <Button
                      size="md"
                      onClick={(e) => joinOffice(e, contactOffice.office.id)}
                    >
                      {t('offices.join')}
                    </Button>
                  )}
                </OfficeCard>
              ))
              .value()}
          </CardList>

          {!viewAll && contactOffices.length > CONTACT_OFFICES_LIMIT && (
            <Button size="sm" outline={true} onClick={() => setViewAll(true)}>
              {t('viewNMore', {
                n: contactOffices.length - CONTACT_OFFICES_LIMIT,
              })}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
export default Offices
