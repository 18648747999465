import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { AuthContext } from '@web/js/context/AuthContext'

const SessionExpiredModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)
  const { setHasExpired } = useContext(AuthContext)

  const expireSessionAndClose = () => {
    setHasExpired(false)
    hideModal()
  }

  return (
    <Modal title={t('sessionExpiredTitle')} closeable={false}>
      <ModalBody>
        <p>{t('sessionExpiredDescription')}</p>
      </ModalBody>
      <ModalFooter onAccept={expireSessionAndClose} onAcceptText={t('close')} />
    </Modal>
  )
}

export default SessionExpiredModal
