import React, { useState, useCallback, MutableRefObject } from 'react'
import './ProfileImage.scss'
import { useTranslation } from 'react-i18next'
import { cropImage } from '@web/_utils'
import Slider from '@mui/material/Slider'
import Cropper from 'react-easy-crop'
import Button from '@web/js/components/Button'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import { I18N } from '@web/_constants'
import { Area } from 'react-easy-crop/types'

const marks = [
  {
    value: 1,
    label: '1x',
  },
  {
    value: 3,
    label: '3x',
  },
]

interface ProfileImageCropperProps {
  src: string | null
  croppedSrcRef: MutableRefObject<string | null>
}

const ProfileImageCropper: React.FC<ProfileImageCropperProps> = ({
  src,
  croppedSrcRef,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<number>(1)
  const [rotation, setRotation] = useState<number>(0)

  const onCropComplete = useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) => {
      if (src) getCroppedImage(src, croppedAreaPixels, rotation)
    },
    [src, rotation]
  )

  const getCroppedImage = async (
    imageSrc: string,
    pixelCrop: {
      width: number
      height: number
      x: number
      y: number
    },
    rotate: number
  ) => {
    const croppedImage = await cropImage(imageSrc, pixelCrop, rotate)
    croppedSrcRef.current = croppedImage
  }

  const rotateLeft = () => {
    setRotation(rotation - 90)
  }

  const rotateRight = () => {
    setRotation(rotation + 90)
  }

  const reset = () => {
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setRotation(0)
  }

  return (
    <div id="profile-image--cropper">
      <div className="crop-container">
        <Cropper
          image={src as string}
          crop={crop}
          cropShape="round"
          zoom={zoom}
          rotation={rotation}
          aspect={3 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <div className="controls">
        <div className="control control--zoom">
          <h6>{t('zoom')}</h6>
          <Slider
            value={zoom}
            marks={marks}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby={t('zoom')}
            onChange={(e, zoom) => setZoom(zoom as number)}
            classes={{ root: 'slider' }}
          />
        </div>
        <div className="control control--rotate">
          <Button
            size="md"
            style="info"
            iconFront={<RotateLeftIcon />}
            onClick={rotateLeft}
          >
            {t('rotate')} -90&deg;
          </Button>

          <Button size="md" style="info" onClick={reset}>
            {t('reset')}
          </Button>

          <Button
            size="md"
            style="info"
            icon={<RotateRightIcon />}
            onClick={rotateRight}
          >
            {t('rotate')} 90&deg;
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProfileImageCropper
