import * as React from 'react'
import { IconProps } from '@web/_types'

const ConnectionsIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-connections',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6364 12C20.6364 16.7697 16.7697 20.6364 12 20.6364C7.23027 20.6364 3.36364 16.7697 3.36364 12C3.36364 7.23027 7.23027 3.36364 12 3.36364C16.7697 3.36364 20.6364 7.23027 20.6364 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13.2251 10.3145L12.0004 6.54549L10.7758 10.3145H6.81286L10.019 12.6439L8.79434 16.4129L12.0004 14.0835L15.2065 16.4129L13.9819 12.6439L17.188 10.3145H13.2251Z"
      fill={color}
    />
  </svg>
)

export default ConnectionsIcon
