import { Meeting, NotificationRequest, RemoteCLMSession } from '@web/_types'
import _ from 'lodash'

export const isMeeting = (
  event: Meeting | NotificationRequest | RemoteCLMSession
): event is Meeting => {
  return _.has(event, 'attendeeId')
}

export const isRemoteCLMSession = (
  event: Meeting | NotificationRequest | RemoteCLMSession
): event is RemoteCLMSession => {
  return _.has(event, 'presenterId') && _.has(event, 'participantIds')
}

export const isNotificationRequest = (
  event: Meeting | NotificationRequest | RemoteCLMSession
): event is NotificationRequest => {
  return _.has(event, 'notificationId')
}
