import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { AuthContext } from '@web/js/context/AuthContext'
import { UserService } from '@web/_services'
import { ToastContext } from '@web/js/context/ToastContext'

const DeletePhotoModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)
  const { setAuthenticatedUser } = useContext(AuthContext)
  const { addToast } = useContext(ToastContext)

  const removePhoto = async () => {
    try {
      const removePhotoResponse = await UserService.removePhoto()
      setAuthenticatedUser(removePhotoResponse.data)
      hideModal()
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  return (
    <Modal closeable={false} title={t('removePhoto')}>
      <ModalBody>
        <p>{t('confirmDeletePhoto')}</p>
      </ModalBody>
      <ModalFooter
        cancellable={true}
        onAcceptText={t('remove')}
        onAccept={removePhoto}
        onCancelText={t('keepPhoto')}
        onCancel={hideModal}
      />
    </Modal>
  )
}

export default DeletePhotoModal
