import React, { useEffect, useContext, useRef } from 'react'
import './PresentationStartingModal.scss'
import { useLocalStorage } from '@web/common/hooks'
import { useNavigate } from 'react-router-dom'
import PresentationIcon from '@web/common/img/PresentationIcon.svg'
import { useTranslation } from 'react-i18next'
import { I18N, LOCALSTORAGE, LOGGING, ROUTES } from '@web/_constants'
import { RemoteClmStartedSocketData } from '@web/_types'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

interface PresentationStartingModalProps {
  eventData: RemoteClmStartedSocketData
}

const PresentationStartingModal: React.FC<PresentationStartingModalProps> = ({
  eventData,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [, setJoinType] = useLocalStorage<string | null>(
    LOCALSTORAGE.joinType,
    null
  )

  useEffect(() => {
    timer.current = setTimeout(() => hideModal(), 300000)
  }, [])

  const joinMeeting = () => {
    if (eventData?.pin) {
      setJoinType(LOGGING.JOIN_TYPE.POPUP)
      navigate(`${ROUTES.presentation}?pin=${eventData.pin}`, {
        state: { overrideLeavingGuard: true },
      })
    }
    dismissModal()
  }

  const dismissModal = () => {
    if (timer.current) clearTimeout(timer.current)
    hideModal()
  }

  return (
    <Modal closeable={false} size="sm" blur={true}>
      <ModalBody id="presentation-starting-modal">
        <div id="presentation-icon">
          <img src={PresentationIcon} />
        </div>
        <div className="text-headline">{t('presentationInvite')}</div>

        <p className="text-small">
          {t('hostedBy', {
            repName: eventData?.repDisplayName,
          })}
        </p>
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onAccept={joinMeeting}
        onCancel={dismissModal}
        onAcceptText={t('joinNow')}
        onCancelText={t('dismiss')}
        stacked={true}
      />
    </Modal>
  )
}

export default PresentationStartingModal
