import {
  OfficeHours,
  PartitionedHours,
  PartitionedHoursGrouped,
} from '@web/_types'
import _ from 'lodash'
import dayjs from 'dayjs'

export const generateOfficeHours = (
  officeHours: OfficeHours[]
): PartitionedHoursGrouped => {
  const hours = _.chain(officeHours)
    .map((hours) => createBlock(hours))
    .flatten()
    .orderBy(['day', 'startTime'])
    .groupBy('day')
    .value()

  return hours
}

export const createBlock = (hours: OfficeHours): PartitionedHours => {
  const startTimeUTC = dayjs('2000-01-03')
    .isoWeekday(hours.startDay + 1)
    .format(`YYYY-MM-DD[T${hours.startTime}Z]`)

  const endTimeUTC = dayjs(startTimeUTC)
    .add(hours.duration, 'minutes')
    .utc()
    .format('YYYY-MM-DDTHH:mm[Z]')

  const block = {
    day: hours.startDay,
    startTime: startTimeUTC,
    endTime: endTimeUTC,
  }

  return block
}

export const generateOfficeHourDurations = (
  officeHours: PartitionedHoursGrouped
): OfficeHours[] => {
  const hours = _.chain(officeHours)
    .map()
    .flatten()
    .map((block) => {
      const startTime = dayjs(block.startTime).utc().format('HH:mm')
      let endTime = block.endTime
      const isBefore = dayjs(block.startTime).isBefore(dayjs(block.endTime))

      if (!isBefore) {
        endTime = dayjs(block.endTime)
          .utc()
          .add(1, 'day')
          .format('YYYY-MM-DDTHH:mm[Z]')
      }

      const duration = dayjs(endTime).diff(block.startTime, 'minute')

      return {
        startDay: block.day,
        startTime,
        duration,
      }
    })
    .value()

  return hours
}
