const Constants = {
  appStoreLink: 'itms-apps://apple.com/app/id1155146582',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=com.veeva.engage',
  androidCN: 'https://engage.veevasfa.cn/android/veeva-engage-hcp-app.apk',
  BREAKPOINTS: {
    xs: '320px',
    sm: '576px',
    md: '960px',
    lg: '1280px',
    xl: '1800px',
  },
  BREAKPOINTS_MAX: {
    xs: '319px',
    sm: '575px',
    md: '959px',
    lg: '1279px',
    xl: '1799px',
  },
  COOKIE: {
    setInstalledCookie: 'veevaEngage=true;',
    removeInstalledCookie: 'veevaEngage=;',
    setCookieExpiration: 'expires=Fri, 31 Dec 9999 23:59:59 GMT;',
    expireCookie: 'expires=Thu, 01 Jan 1970 00:00:00 GMT;',
    setMeetingId: 'meetingID={0};',
    removeMeetingId: 'meetingID=;',
    setPassword: 'meetingPwd={1}',
    removePassword: 'meetingPwd=;',
  },
  i18n: {
    namespaces: {
      landing: 'landing',
    },
  },
  openWindowsApp: 'veevaengage1://?meetingID={0}&pwd={1}',
  ROUTES: {
    home: '/',
    welcome: '/welcome',
    goodbye: '/goodbye',
  },
  SpeakerRole: 'SPEAKER',
  iOSLaunchLink: 'veevaengage://?meetingID={0}&pwd={1}',
  androidLaunchLink:
    'veevaengage://com.veeva.engage/?meetingID={0}&pwd={1}#Intent;scheme=veevaengage;package=com.veeva.engage;end',
  androidChinaLaunchLink:
    'veevaengage://com.veeva.engage/?meetingID={0}&pwd={1}',
  baseName: '/engage',
}

export default Constants
