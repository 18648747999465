import { v4 as uuidv4 } from 'uuid'

let clientId: string | null = null

export const ClientIdService = {
  getClientId: (): string => {
    clientId = clientId || uuidv4()
    return clientId
  },
}
