import React, { useContext, useEffect, useState } from 'react'
import './ExperienceRatingModal.scss'
import { EngageContext, EngageProvider } from '@web/js/context/EngageContext'
import { useLocalStorage } from '@web/common/hooks'
import {
  ExperienceRating,
  FeedbackInfo,
  Feedback,
  FeedbackOption,
  SurveyData,
} from '@web/_types/engageApi'
import { EngageService } from '@web/_services/EngageService'
import { useTranslation } from 'react-i18next'
import { I18N, LOCALSTORAGE } from '@web/_constants'
import _ from 'lodash'
import StarIcon from '@web/common/img/StarIcon'
import Button from '@web/js/components/Button'
import LoadingIndicator from '@web/js/components/LoadingIndicator'
import { ZoomService } from '@web/_services/ZoomService'
import { ToastContext } from '@web/js/context/ToastContext'
import Modal, { ModalBody } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { useSearchParams } from 'react-router-dom'

interface ExperienceRatingModalInnerProps {
  experienceId: string
}

const ExperienceRatingModalInner: React.FC<ExperienceRatingModalInnerProps> = ({
  experienceId,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const [searchParams, setSearchParams] = useSearchParams()
  const { getToken } = useContext(EngageContext)
  const { addToast } = useContext(ToastContext)
  const { hideModal } = useContext(ModalContext)

  const [feedbackInfo, setFeedbackInfo] = useState<FeedbackInfo | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [overallExperience, setOverallExperience] = useState<number>(0)
  const [activeFeedback, setActiveFeedback] = useState<Feedback | null>(null)
  const [showSurveyFooter, setShowSurveyFooter] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<FeedbackOption[]>([])

  const [, setExperience] = useLocalStorage<string | null>(
    LOCALSTORAGE.experience,
    null
  )
  const [surveyData, setSurveyData] = useLocalStorage<SurveyData | null>(
    LOCALSTORAGE.surveyData,
    null
  )

  useEffect(() => {
    if (experienceId) {
      loadFeedback()
      if (searchParams.size) setSearchParams({}, { replace: true })
    }
  }, [experienceId])

  useEffect(() => {
    if (overallExperience === 0) return
    if (surveyData) return setShowSurveyFooter(!!surveyData.integratedSurveyUrl)

    const activeFeedback = _.filter(feedbackInfo?.feedback, (fb) => {
      return _.includes(fb.condition, overallExperience)
    })[0]

    setActiveFeedback(activeFeedback)
    setSelectedOptions([])
  }, [overallExperience])

  const submitFeedback = async (showToast = false) => {
    const feedbackOptions = _.map(selectedOptions, (option) => ({
      field: option.field,
      id: option.id.toString(),
    }))
    const experienceRating: ExperienceRating = {
      overallExperience,
      device: 'WEB_CLIENT',
      feedbackOptions,
    }

    try {
      const token = await getToken()

      await EngageService.submitFeedback(
        token.participantId,
        token.token,
        experienceRating,
        experienceId
      )

      setExperience(null)
      setSurveyData(null)
      hideModal()

      if (showToast)
        addToast(
          t('experienceRatingSuccess'),
          'success',
          true,
          5000,
          t('thankYou')
        )
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const goToSurvey = () => {
    if (surveyData?.integratedSurveyUrl) {
      let url = surveyData.integratedSurveyUrl

      if (surveyData?.meetingRatingField) {
        const appendMeetingRating = `${
          surveyData.meetingRatingField
        }=${overallExperience.toString()}`
        if (url.includes('?')) {
          url += `&${appendMeetingRating}`
        } else {
          url += `?${appendMeetingRating}`
        }
      }

      window.open(url, '_blank')
    }
    submitFeedback()
  }

  const closeModal = () => {
    if (overallExperience === 0) {
      setExperience(null)
      setSurveyData(null)
      hideModal()
    } else {
      submitFeedback()
    }
  }

  const selectFeedbackOption = (option: FeedbackOption) => {
    const selected = _.includes(selectedOptions, option)
    let options
    if (selected) {
      options = _.filter(selectedOptions, (o) => o !== option)
    } else {
      options = [...selectedOptions, option]
    }
    setSelectedOptions(options)
  }

  const loadFeedback = async () => {
    try {
      const token = await getToken()
      const language = ZoomService.getZoomLanguage()

      const feedback = await EngageService.getFeedback(
        experienceId,
        token.token,
        language
      )
      setError(null)
      setFeedbackInfo(feedback)
    } catch (e) {
      console.error(e)
      setFeedbackInfo(null)
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal onClose={closeModal} title={t('meetingEnded')} size="fixed">
      <ModalBody>
        {!!feedbackInfo && (
          <>
            {feedbackInfo && (
              <div className="feedback">
                <div className="text-headline">
                  {feedbackInfo.mainQuestion.question}
                </div>
                <div className="feedback--rating">
                  {_.times(5, (i) => {
                    const starValue = i + 1
                    const highlighted = starValue <= overallExperience
                    return (
                      <div
                        className={`feedback--rating-star ${
                          highlighted ? 'highlighted' : ''
                        }`}
                        key={`star_${starValue}`}
                        onClick={() => setOverallExperience(starValue)}
                      >
                        <StarIcon
                          className={`feedback--rating-star ${
                            highlighted ? 'highlighted' : ''
                          }`}
                        />
                      </div>
                    )
                  })}
                </div>

                {showSurveyFooter && (
                  <div className="feedback--survey-url">
                    <div className="text-headline">
                      {t('thankYouForYourFeedback')}
                    </div>

                    <p className="text-small">{t('doYouHaveAFewMinutes')}</p>

                    <div className="feedback--survery-url--go">
                      <Button onClick={goToSurvey} size="lg">
                        {t('tellUsMore')}
                      </Button>
                    </div>
                  </div>
                )}

                {!!activeFeedback && (
                  <>
                    <div className="feedback--question">
                      <div className="text-headline">
                        {activeFeedback.question}
                      </div>
                      <div className="feedback--question-options">
                        {_.map(activeFeedback.feedbackOptions, (option) => {
                          const selected = _.includes(selectedOptions, option)
                          return (
                            <Button
                              key={option.id}
                              size="lg"
                              outline={true}
                              style="select"
                              onClick={() => selectFeedbackOption(option)}
                              selected={selected}
                              showCheck={false}
                            >
                              {option.text}
                            </Button>
                          )
                        })}
                      </div>
                    </div>
                    <div className="feedback--submit">
                      <Button onClick={() => submitFeedback(true)} size="lg">
                        {feedbackInfo.buttonSubmit}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}

        {error && <div>{error}</div>}

        {isLoading && (
          <div className="feedback-loading">
            <LoadingIndicator />
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

interface ExperienceRatingModalProps {
  experienceId: string
}

const ExperienceRatingModal: React.FC<ExperienceRatingModalProps> = ({
  experienceId,
}) => {
  return (
    <EngageProvider participantId={experienceId}>
      <ExperienceRatingModalInner experienceId={experienceId} />
    </EngageProvider>
  )
}

export default ExperienceRatingModal
