import React, { useContext, useEffect, useRef, useState } from 'react'
import './OfficeHoursEditorModal.scss'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import _ from 'lodash'
import { OfficesService } from '@web/_services'
import { Office } from '@web/_types'
import { ToastContext } from '@web/js/context/ToastContext'
import {
  classNames,
  createBlock,
  generateOfficeHourDurations,
  generateOfficeHours,
} from '@web/_utils'
import { WEEK, I18N, COLORS } from '@web/_constants'
import dayjs from 'dayjs'
import TimePicker from '@web/js/components/TimePicker'
import EditIcon from '@web/common/img/EditIcon'
import PlusIcon from '@web/common/img/PlusIcon.svg'
import TrashIcon from '@web/common/img/TrashIcon.svg'
import ErrorIcon from '@web/common/img/ErrorIcon'

interface OfficeHoursEditorModalProps {
  office: Office
  onSave?: () => void
}

interface Errors {
  day: number
  index: number
}

const OfficeHoursEditorModal: React.FC<OfficeHoursEditorModalProps> = ({
  office,
  onSave,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)
  const { addToast } = useContext(ToastContext)

  const [isDirty, setIsDirty] = useState(true)
  const [hours, setHours] = useState(generateOfficeHours(office.hours))
  const [errors, setErrors] = useState<Errors[]>([])

  const editOfficeHoursRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setHours(generateOfficeHours(office.hours))
  }, [office])

  useEffect(() => {
    const dirty = !_.isEqual(generateOfficeHours(office.hours), hours)
    if (isDirty !== dirty) setIsDirty(dirty)
    if (errors.length) validateOfficeHours()
  }, [hours])

  const handleOnChange = (
    day: number,
    index: number,
    property: string,
    time: string
  ) => {
    const hoursClone = _.cloneDeep(hours)
    const currentHoursBlock = hoursClone[day][index]
    currentHoursBlock[property as 'endTime' | 'startTime'] = time
    setHours(hoursClone)
  }

  const validateOfficeHours = () => {
    const dayErrors: Errors[] = []
    for (const day in hours) {
      _.each(hours[day], (block, index) => {
        if (dayjs(block.endTime).isSameOrBefore(block.startTime)) {
          dayErrors.push({
            day: block.day,
            index,
          })
        }
      })
    }

    setErrors(dayErrors)
    return !!dayErrors.length
  }

  const saveOfficeHours = async () => {
    if (isDirty) {
      const officeHours = generateOfficeHourDurations(hours)
      const hasErrors = validateOfficeHours()
      if (hasErrors) return

      try {
        await OfficesService.updateOffice(office.id, { hours: officeHours })
        if (onSave) onSave()
        hideModal()
      } catch (error) {
        console.log(error)
        addToast(t('errorGeneric'))
      }
    }
  }

  const addHours = (day: number) => {
    const block = createBlock({
      startTime: '09:00',
      startDay: day,
      duration: 480,
    })
    const dayHours = hours[day] || []
    setHours({ ...hours, [day.toString()]: [...dayHours, block] })
  }

  const deleteRange = (dayIndex: number, rangeIndex: number) => {
    const hoursClone = _.cloneDeep(hours)
    hoursClone[dayIndex].splice(rangeIndex, 1)
    setHours(hoursClone)
  }

  return (
    <Modal
      title={t('offices.editRepHours')}
      size="lg"
      closeable={true}
      onClose={hideModal}
    >
      <ModalBody id="office-hours-editor" bodyRef={editOfficeHoursRef}>
        {_.map(WEEK, (day, dayIdx) => {
          const dayOfficeHours = hours[dayIdx] || []

          return (
            <div
              className={`office-hours-editor--day ${
                dayOfficeHours.length ? '' : 'flex'
              }`}
              key={day}
            >
              <div className="day text-default-semibold">
                {t(`days.${day}`)}
              </div>

              {!dayOfficeHours.length ? (
                <div className="no-hours">
                  {t('offices.noHours')}
                  <a
                    className="no-hours--edit"
                    onClick={() => addHours(dayIdx)}
                  >
                    <EditIcon />
                  </a>
                </div>
              ) : (
                <>
                  {_.map(dayOfficeHours, (officeHours, officeHoursIdx) => {
                    const showError = !!_.filter(
                      errors,
                      (error) =>
                        error.day === dayIdx && error.index === officeHoursIdx
                    ).length

                    const dayRangeClasses = classNames({
                      'office-hours-editor--day-range': true,
                      error: showError,
                      first: officeHoursIdx === 0,
                    })

                    return (
                      <React.Fragment key={`${day}_${officeHoursIdx}`}>
                        <div className={dayRangeClasses}>
                          <div className="office-hours-editor--day-range--time-pickers">
                            <TimePicker
                              onChange={handleOnChange}
                              day={dayIdx}
                              officeHours={officeHours}
                              index={officeHoursIdx}
                              property="startTime"
                            />
                            <div className="hyphen">-</div>
                            <TimePicker
                              onChange={handleOnChange}
                              day={dayIdx}
                              officeHours={officeHours}
                              index={officeHoursIdx}
                              property="endTime"
                            />
                          </div>
                          <div
                            tabIndex={1}
                            className="delete-range"
                            onClick={() => deleteRange(dayIdx, officeHoursIdx)}
                          >
                            <img src={TrashIcon} alt="Delete" />
                          </div>
                        </div>
                        {!!showError && (
                          <div className="text-small day-range--error">
                            <ErrorIcon
                              height="18px"
                              width="18px"
                              color={COLORS.red}
                            />
                            {t('errorEndTime')}
                          </div>
                        )}
                      </React.Fragment>
                    )
                  })}

                  <a className="add-hours" onClick={() => addHours(dayIdx)}>
                    <img src={PlusIcon} alt={t('offices.addHours')} />
                    <span>{t('offices.addHours')}</span>
                  </a>
                </>
              )}
            </div>
          )
        })}
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onAcceptText={t('save')}
        onAccept={saveOfficeHours}
        onCancel={hideModal}
        disabled={!isDirty || !!errors.length}
      />
    </Modal>
  )
}

export default OfficeHoursEditorModal
