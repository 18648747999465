import React, { useEffect, useState } from 'react'
import './Timer.scss'
import { formatTime } from '@web/_utils'

interface TimerProps {
  seconds?: number
  format?: boolean
  onTimeOut?: () => void
}

const Timer: React.FC<TimerProps> = ({
  seconds = 60,
  format = false,
  onTimeOut,
}) => {
  const [time, setTime] = useState<number>(seconds)

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (time > 0) {
      timeout = setTimeout(() => {
        const sec = time - 1
        setTime(sec)
      }, 1000)
    } else {
      if (onTimeOut) onTimeOut()
    }

    return () => {
      clearTimeout(timeout)
    }
  })

  return <span id="timer">{format ? formatTime(time) : time}</span>
}

export default Timer
