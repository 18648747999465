import * as React from 'react'
import { IconProps } from '@web/_types'

const InfoIcon: React.FC<IconProps> = ({
  height = '32px',
  width = '32px',
  color = '#ffffff',
  className = 'icon-info',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.001 4.66656C9.74189 4.66656 4.66724 9.74263 4.66724 16.0051C4.66724 22.2676 9.74189 27.3436 16.001 27.3436C22.26 27.3436 27.3347 22.2676 27.3347 16.0051C27.3347 9.74263 22.26 4.66656 16.001 4.66656ZM2.66724 16.0051C2.66724 8.6388 8.63658 2.66656 16.001 2.66656C23.3653 2.66656 29.3347 8.6388 29.3347 16.0051C29.3347 23.3714 23.3653 29.3436 16.001 29.3436C8.63658 29.3436 2.66724 23.3714 2.66724 16.0051ZM16.0006 15.005C16.5529 15.005 17.0006 15.4527 17.0006 16.005V20.9404C17.0006 21.4927 16.5529 21.9404 16.0006 21.9404C15.4483 21.9404 15.0006 21.4927 15.0006 20.9404V16.005C15.0006 15.4527 15.4483 15.005 16.0006 15.005ZM16 12.3038C16.6815 12.3038 17.2339 11.7514 17.2339 11.07C17.2339 10.3885 16.6815 9.83611 16 9.83611C15.3186 9.83611 14.7662 10.3885 14.7662 11.07C14.7662 11.7514 15.3186 12.3038 16 12.3038Z"
      fill={color}
    />
  </svg>
)

export default InfoIcon
