import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

interface ConfirmRouteChangeModalProps {
  setConfirmedNavigation: (confirmedNavigation: boolean) => void
}

const ConfirmRouteChangeModal: React.FC<ConfirmRouteChangeModalProps> = ({
  setConfirmedNavigation,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  const handleConfirmNavigationClick = () => {
    hideModal()
    setConfirmedNavigation(true)
  }

  return (
    <Modal closeable={false} title={t('unsavedChanges')}>
      <ModalBody>
        <p>{t('discardYourChanges')}</p>
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onAcceptText={t('discard')}
        onAccept={handleConfirmNavigationClick}
        onCancelText={t('keepEditing')}
        onCancel={hideModal}
      />
    </Modal>
  )
}

export default ConfirmRouteChangeModal
