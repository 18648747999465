import * as React from 'react'
import { IconProps } from '@web/_types'

const CameraIconSolid: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-camera-solid',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1459 6.46108C14.6098 5.87964 13.8475 5.5 12.9883 5.5H2.77374C2.25639 5.5 1.8036 5.72937 1.49147 6.06786C1.1806 6.40499 1 6.85699 1 7.33908V15.3953C1 16.2481 1.31916 17.0358 1.85407 17.6158C2.39023 18.1973 3.15251 18.5769 4.01173 18.5769H14.2263C14.7436 18.5769 15.1964 18.3476 15.5085 18.0091C15.8194 17.6719 16 17.2199 16 16.7378V8.68162C16 7.82879 15.6808 7.04116 15.1459 6.46108Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.274 7.103L22.0939 7.01444L21.8935 7.00225C21.6601 6.98805 21.3898 7.03951 21.1464 7.20172L17.5089 9.62557C17.2458 9.80093 17 10.1279 17 10.5651V13.4349C17 13.8721 17.2458 14.199 17.5089 14.3744L21.1464 16.7982C21.359 16.9399 21.5977 17 21.8187 17C22.0734 17 22.3393 16.9206 22.5615 16.7474C22.7851 16.5732 23 16.2668 23 15.8587V8.14124C23 8.04878 22.9886 7.89567 22.9151 7.72325C22.8445 7.55789 22.7447 7.43966 22.6619 7.361C22.5217 7.22788 22.362 7.14623 22.274 7.103ZM18.0635 10.4577L21.7009 8.03388C21.7389 8.00853 21.7866 7.99759 21.8328 8.0004C21.9846 8.07501 22 8.10757 22 8.14124V8.14127C22 8.1076 21.9846 8.07504 21.8328 8.00043C21.7866 7.99762 21.7389 8.00855 21.7009 8.03391L18.0635 10.4578C18.0232 10.4846 18 10.5238 18 10.5651V10.5651C18 10.5238 18.0232 10.4846 18.0635 10.4577ZM21.9998 15.866C21.9999 15.8636 22 15.8611 22 15.8587L21.9998 15.866Z"
      fill={color}
    />
  </svg>
)

export default CameraIconSolid
