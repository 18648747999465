import React, { ReactNode } from 'react'
import './Checkbox.scss'
import { generateUuid, classNames } from '@web/_utils'

//Icons
import CheckIcon from '@mui/icons-material/Check'

interface CheckboxProps {
  value: string
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  autoFocus?: boolean
  padded?: boolean
  forRef?: React.Ref<HTMLInputElement>
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  id?: string
  children?: ReactNode
}

const Checkbox: React.FC<CheckboxProps> = ({
  value,
  defaultChecked,
  checked,
  autoFocus = false,
  forRef = null,
  padded,
  onChange,
  id = generateUuid(),
  disabled,
  children,
}) => {
  const cx = {
    checkbox: true,
    checked: checked,
    disabled: disabled,
    padded: padded,
  }

  const checkboxClasses = classNames(cx)

  return (
    <div className={checkboxClasses}>
      <input
        ref={forRef}
        id={id}
        type="checkbox"
        name={value}
        {...(disabled && { disabled })}
        {...(autoFocus && { autoFocus })}
        {...(defaultChecked && { defaultChecked })}
        {...(onChange && { onChange })}
        {...(checked && { checked })}
      />

      <label htmlFor={id}>
        <div className="checkbox--check">
          <CheckIcon />
        </div>
        <div className="checkbox--label">{children}</div>
      </label>
    </div>
  )
}

export default Checkbox
