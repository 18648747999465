import { Errors, FormFieldValue, Rules } from '@web/_types'
import _ from 'lodash'
import i18n from '@web/common/js/i18n'
import { I18N } from '@web/_constants'
import isEmail from 'validator/lib/isEmail'

export const validateForm = (fields: FormFieldValue, rules: Rules): Errors => {
  const errors: Errors = {}

  _.each(fields, (value, key) => {
    const fieldRules = rules[key]
    if (!fieldRules) return

    const fieldRulesKeys = Object.keys(fieldRules)

    for (let r = 0; r < fieldRulesKeys.length; r++) {
      if (fieldRules.required && !value) {
        errors[key] = i18n.t('errorRequiredField', { ns: I18N.namespaces.web })
        continue
      }

      if (
        !!fieldRules.requiredIf &&
        !value &&
        _.chain(fieldRules.requiredIf as string[])
          .map((f) => !!fields[f])
          .includes(true)
          .value()
      ) {
        errors[key] = i18n.t('errorRequiredField', { ns: I18N.namespaces.web })
        continue
      }

      if (
        !!fieldRules.valid &&
        fieldRules.valid === 'email' &&
        !isEmail(value)
      ) {
        errors[key] = i18n.t('errorInvalidEmail', {
          ns: I18N.namespaces.web,
        })
        continue
      }

      if (fieldRules.length && value.length !== fieldRules.length) {
        errors[key] = i18n.t('errorNumericDigits', {
          num: fieldRules.length,
          ns: I18N.namespaces.web,
        })
        continue
      }

      const hasError = !!errors[key]
      if (hasError) break
    }
  })

  return errors
}
