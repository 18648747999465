import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N, MODALS } from '@web/_constants'
import { AuthContext } from '@web/js/context/AuthContext'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import FormField from '@web/js/components/FormField'
import './AccounDeletetModal.scss'

const DeleteAccountModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal, showModal } = useContext(ModalContext)
  const { email } = useContext(AuthContext)
  const [error, setError] = useState('')
  const confirmEmailValueRef = useRef('')
  const confirmEmaiInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!error) confirmEmaiInputRef.current?.focus()
  })

  const deleteMyAccount = () => {
    if (
      confirmEmailValueRef.current.toLowerCase().trim() !==
      (email as string).toLowerCase()
    ) {
      setError(t('errorEmailMustMatch'))
      return
    } else {
      showModal({
        name: MODALS.CONFIRM_ACCOUNT_DELETE_MODAL,
      })
    }
  }

  const clearDeleteAccount = () => {
    hideModal()
    confirmEmailValueRef.current = ''
    setError('')
  }

  return (
    <Modal
      closeable={true}
      size="lg"
      title={`${t('deleteAccount.title')}`}
      onClose={clearDeleteAccount}
    >
      <ModalBody id="account-delete-modal">
        <div className="text-headline">{t('deleteAccount.subtitle')}</div>
        <p className="description">{t('deleteAccount.description')}</p>
        <p className="confirm">
          <strong>{t('deleteAccount.confirmEmail')}</strong>
          <br />
          {email}
        </p>

        <div className="col-xs-8 col-md-4">
          <FormField
            forRef={confirmEmaiInputRef}
            type="text"
            name="enter-email"
            placeholder={t('enterYourEmail')}
            spellCheck={false}
            error={error}
            value={confirmEmailValueRef.current}
            onChange={(e) => {
              confirmEmailValueRef.current = e.target.value
              if (error) setError('')
            }}
          />
        </div>
      </ModalBody>

      <ModalFooter
        cancellable={true}
        dangerous={true}
        onAcceptText={t('deleteAccount.title')}
        onAccept={deleteMyAccount}
        onCancel={clearDeleteAccount}
      />
    </Modal>
  )
}

export default DeleteAccountModal
