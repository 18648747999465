import React, { useEffect, useRef, useState } from 'react'
import './ConnectionNotification.scss'
import { COLORS, I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import FriendlyDate from '../FriendlyDate'
import { Connection, NotificationRequest } from '@web/_types'
import { CHANNEL_TYPE, ROUTES, NOTIFICATION } from '@web/_constants'
import { canCommunicate, hasProfile, isHCP, isRep } from '@web/_guards'
import { classNames, formatText } from '@web/_utils'
import Button from '@web/js/components/Button'
import ContactCard from '../ContactCard'
import ThumbsDown from '@web/common/img/ThumbsDown.svg'
import ThumbsUp from '@web/common/img/ThumbsUp.svg'
import ExpandIcon from '@web/common/img/ExpandIcon.svg'
import ContractIcon from '@web/common/img/ContractIcon.svg'
import ChatIcon from '@web/common/img/ChatIcon'
import PingIcon from '@web/common/img/PingIcon'
import InfoIcon from '@web/common/img/InfoIcon'
import { useNavigate } from 'react-router-dom'

interface ConnectionNotificationProps {
  active: boolean
  approved: boolean
  notification: NotificationRequest
  openContactDetails: (connectionRequest: NotificationRequest) => void
  approveConnectionRequest: (connection: Connection) => void
  declineConnectionRequest: (connectionRequest: NotificationRequest) => void
}

const ConnectionNotification: React.FC<ConnectionNotificationProps> = ({
  active,
  notification,
  approved,
  openContactDetails,
  approveConnectionRequest,
  declineConnectionRequest,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const inviteMessageBodyRef = useRef<HTMLDivElement>(null)
  const isRequest = notification.type === NOTIFICATION.connectionRequest
  const [declined, setDeclined] = useState<boolean>(false)
  const [expandable, setExpandable] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(false)
  const clickable = hasProfile(notification.contact)
  const cx = {
    'connection-notification': true,
    clickable,
    active,
  }
  const notificationClasses = classNames(cx)

  const toggleInviteMessage = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (inviteMessageBodyRef.current) {
      setExpandable(
        inviteMessageBodyRef.current.clientHeight <
          inviteMessageBodyRef.current.scrollHeight
      )
    }
  }, [inviteMessageBodyRef])

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        if (clickable) openContactDetails(notification)
      }}
      className={notificationClasses}
    >
      <div className="connection-notification--title">
        <span className="text-small">
          {isRequest ? t('newConnectionRequests') : t('newConnection')}
        </span>
        <FriendlyDate date={notification.date} classes="text-small" />
      </div>

      <div className="connection--body">
        <div className="connection--info">
          <ContactCard
            contact={notification.contact}
            showContactStatus={!notification.contact.isContact}
            showTitle={isHCP(notification.contact)}
          >
            {isRequest && !declined && !approved && (
              <>
                {!!notification.inviteMessage && (
                  <div
                    className="connection--invite-message"
                    onClick={toggleInviteMessage}
                  >
                    <div
                      ref={inviteMessageBodyRef}
                      className={`connection--invite-message--body ${
                        expanded ? 'expanded' : 'contracted'
                      }`}
                    >
                      {formatText(notification.inviteMessage)}
                    </div>
                    {expandable && (
                      <div className="connection--invite-message--actions">
                        <div className="connection--invite-message--expand-contract">
                          <img src={expanded ? ContractIcon : ExpandIcon} />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {notification.unreadMessages && (
                  <div className="connection--pending-message">
                    <InfoIcon color={COLORS.darkBlue} />
                    <span>{t('pendingMessage')}</span>
                  </div>
                )}

                <div className="connection--accept-decline">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      approveConnectionRequest(notification.contact)
                    }}
                    size="sm"
                    style="success"
                    iconFront={<img src={ThumbsUp} />}
                  >
                    {t('connect')}
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      setDeclined(true)
                      declineConnectionRequest(notification)
                    }}
                    size="sm"
                    style="danger"
                    iconFront={<img src={ThumbsDown} />}
                  />
                </div>
              </>
            )}

            {declined && (
              <div className="connection--request-response text-bold declined">
                {t('connectionDenied')}
              </div>
            )}

            {approved && (
              <div className="connection--request-response text-bold accepted">
                {t('nowConnected')}
              </div>
            )}

            {!isRequest && canCommunicate(notification.contact) && (
              <div className="connection--chat-ping">
                <Button
                  style="primary"
                  size="sm"
                  iconFront={
                    isHCP(notification.contact) ? (
                      <ChatIcon />
                    ) : notification.contact.channelType ===
                      CHANNEL_TYPE.chat ? (
                      <ChatIcon />
                    ) : (
                      <PingIcon />
                    )
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`${ROUTES.chat}/${notification.contact.channelId}`)
                  }}
                >
                  {notification.unreadMessages
                    ? t('viewMessage')
                    : isHCP(notification.contact) ||
                        (isRep(notification.contact) &&
                          notification.contact.channelType ===
                            CHANNEL_TYPE.chat)
                      ? t('chatNow')
                      : t('ping')}
                </Button>
              </div>
            )}
          </ContactCard>
        </div>
      </div>
    </div>
  )
}

export default ConnectionNotification
