import React, { useEffect, useContext, useState } from 'react'
import ChatList from '@web/js/components/ChatList'
import ChatMessenger from '@web/js/components/ChatMessenger'
import { useResponsive } from '@farfetch/react-context-responsive'
import { ROUTES } from '@web/_constants'
import { useNavigate, useParams } from 'react-router-dom'
import './Chat.scss'
import { Channel } from '@web/_types'
import { ChatContext } from '@web/js/context/ChatContext'
import _ from 'lodash'

const ChatRoute: React.FC = () => {
  const navigate = useNavigate()
  const { chatChannels, activateChannel, activeChannel, isLoadingChat } =
    useContext(ChatContext)
  const { channel } = useParams<{ channel: string | undefined }>()
  const [isLoading, setIsLoading] = useState(true)
  const { greaterThan, lessThan } = useResponsive()

  useEffect(() => {
    if (chatChannels.length) {
      const activeChannelId = channel ? channel : ''
      const match = _.find(chatChannels, (cc) => cc.id === activeChannelId)
      if (!match && greaterThan.xs) {
        loadChannel(chatChannels[0], true)
      } else {
        activateChannel(match || null)
      }
    }
  }, [chatChannels, channel, greaterThan])

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 100)

    return () => {
      activateChannel(null)
    }
  }, [])

  const loadChannel = (channel: Channel, redirected?: boolean) => {
    const route = `${ROUTES.chat}/${channel.id}`
    if (redirected) {
      navigate(route, { state: { redirected }, replace: true })
    } else {
      navigate(route, { replace: true })
    }
  }

  if (isLoading || isLoadingChat) return null

  return (
    <div id="chat-route">
      {((lessThan.sm && !activeChannel) || greaterThan.xs) && (
        <ChatList
          loadChannel={loadChannel}
          inDock={false}
        />
      )}
      {!!activeChannel && (
        <ChatMessenger activeChannel={activeChannel} inDock={false} />
      )}
    </div>
  )
}

export default ChatRoute
