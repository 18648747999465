// Read special props embedded on the document
declare global {
  interface Document {
    namespace: string
    staticBase: string
  }
}

const defaultConfigQA = {
  iosLogFlushCount: 100,
  iosLogFlushDelay: 5000,
  androidLogFlushCount: 100,
  androidLogFlushDelay: 5000,
  supportsSignup: false,
  privacyUrl: 'https://veevaengage.com/legal/privacy',
  termsUrl: 'https://veevaengage.com/legal/terms',
  dataUrl: 'https://veevaengage.com/legal/data',
  searchEnabled: false,
  officeSearchEnabled: false,
  engageApiHost: 'https://hcp-dvp-app-us.veevacrmqa.com',
  engageBaseHost: 'https://engage-dvp.veeva.com',
  engageRemoteCLMHost:
    'https://hcp-dvp-app-us.veevacrmqa.com/api/v1/engage/engage-admin/content-player',
  officePostalCodeRequired: false,
  vaultContentPlayerHost: 'https://engage-dvp-player-us.veevacrmqa.com',
}

const defaultConfigProd = {
  ...defaultConfigQA,
  engageApiHost: 'https://hcp-app-us.veevacrm.com',
  engageBaseHost: 'https://engage.veeva.com',
  engageRemoteCLMHost:
    'https://hcp-app-us.veevacrm.com/api/v1/engage/engage-admin/content-player',
  vaultContentPlayerHost: 'https://engage-player-us.veevacrm.com',
}

const BaseConfig = {
  BASE_URL: '/web',
  ROOT_ELEMENT: 'reactRoot',
  SESSION_TIMEOUT: 7200000, //2 hours
  SESSION_TIMEOUT_ALERT: 600000, //10 minutes
  SESSION_TIMEOUT_MAX: 8640000000000000,
  API_URL: '/v1/api',
  OAPI_URL: '/v1/oapi',
  API_URL_V2: '/v2/api',
  OAPI_URL_V2: '/v2/oapi',
  COUNTRY_URL: 'https://cdn.veevaengagedev.com/country/',
  CLM_PLAYER_BASE: 'https://engage-dvp-webplayer-us.veevacrmqa.com',
  ENGAGE_API_BASE: 'https://hcp-dvp-app-us.veevacrmqa.com',
  ENGAGE_SAMPLE_BASE: 'https://engage-dvp.veeva.com/samples',
  INVITE_DEEP_LINK_BASE_DEPRECATED: '/mobile/welcome',
  INVITE_DEEP_LINK_BASE: '/mobile/link',
  STATIC_BASE: window.document.staticBase,
  VERSION: `${process.env.VERSION}`,
  TEST_LOCALE: 'fr-CA',
  USE_TEST_LOCALE: false,
  ENGAGE_WINDOWS_DOWNLOAD_URL: 'https://engage-dvp.veeva.com/windows/Setup.exe',
}

const ClusterConfigs = {
  devCluster: {
    DEFAULT_CONFIG: defaultConfigQA,
    COUNTRY_URL: 'https://cdn.veevaengagedev.com/country/',
    CLM_PLAYER_BASE: 'https://engage-dvp-webplayer-us.veevacrmqa.com',
    ENGAGE_API_BASE: 'https://hcp-dvp-app-us.veevacrmqa.com',
    ENGAGE_SAMPLE_BASE: 'https://engage-dvp.veeva.com/samples',
    USE_TEST_LOCALE: true,
  },
  prodCluster: {
    DEFAULT_CONFIG: defaultConfigProd,
    COUNTRY_URL: 'https://cdn.veevaengage.com/country/',
    CLM_PLAYER_BASE: 'https://engage-webplayer-us.veevacrm.com',
    ENGAGE_API_BASE: 'https://hcp-app-us.veevacrm.com',
    ENGAGE_SAMPLE_BASE: 'https://engage.veeva.com/samples',
    ENGAGE_WINDOWS_DOWNLOAD_URL: 'https://engage.veeva.com/windows/Setup.exe',
  },
}
const ProdConfigs = {
  prod: {},
  demo: {},
  demo2: {},
  sandbox: {
    ENGAGE_API_BASE: 'https://hcp-sbx-app-us.veevacrm.com',
    CLM_PLAYER_BASE: 'https://engage-sbx-webplayer-us.veevacrm.com',
    DEFAULT_CONFIG: {
      ...defaultConfigProd,
      vaultContentPlayerHost: 'https://engage-sbx-player-us.veevacrm.com',
    },
  },
  test: {},
}
type ProdNamespace = keyof typeof ProdConfigs
const DevConfigs = {
  local: {
    COUNTRY_URL: 'https://myveeva-local.com/country/',
    DEFAULT_CONFIG: {
      ...defaultConfigQA,
      vaultContentPlayerHost: 'https://engage-ecv-player-us.veevacrmdi.com',
    },
  },
  dev: {},
  integration: {},
  qa: {},
  di1: {
    ENGAGE_API_BASE: 'https://engage-rcs-app-us.veevacrmdi.com',
    CLM_PLAYER_BASE: 'https://engage-rcs-webplayer-us.veevacrmdi.com',
  },
  di2: {
    ENGAGE_API_BASE: 'https://engage-rcs-app-us.veevacrmdi.com',
    CLM_PLAYER_BASE: 'https://engage-rcs-webplayer-us.veevacrmdi.com',
  },
  staging: {
    DEFAULT_CONFIG: {
      ...defaultConfigQA,
      engageApiHost: 'https://hcp-rel-app-us.veevacrmqa.com',
      engageBaseHost: 'https://engage-rel.veeva.com',
      engageRemoteCLMHost:
        'https://hcp-rel-app-us.veevacrmqa.com/api/v1/engage/engage-admin/content-player',

      vaultContentPlayerHost: 'https://engage-rel-player-us.veevacrmqa.com',
    },
    CLM_PLAYER_BASE: 'https://engage-rel-webplayer-us.veevacrmqa.com',
    ENGAGE_API_BASE: 'https://hcp-rel-app-us.veevacrmqa.com',
    ENGAGE_SAMPLE_BASE: 'https://engage-rel.veeva.com/samples',
    ENGAGE_WINDOWS_DOWNLOAD_URL:
      'https://engage-rel.veeva.com/windows/Setup.exe',
  },
  rgr: {
    DEFAULT_CONFIG: {
      ...defaultConfigQA,
      engageApiHost: 'https://hcp-rgr-app-us.veevacrmqa.com',
      engageBaseHost: 'https://engage-rgr.veeva.com',
      engageRemoteCLMHost:
        'https://hcp-rgr-app-us.veevacrmqa.com/api/v1/engage/engage-admin/content-player',
      vaultContentPlayerHost: 'https://engage-rgr-player-us.veevacrmqa.com',
    },
    CLM_PLAYER_BASE: 'https://engage-rgr-webplayer-us.veevacrmqa.com',
    ENGAGE_API_BASE: 'https://hcp-rgr-app-us.veevacrmqa.com',
    ENGAGE_SAMPLE_BASE: 'https://engage-rgr.veeva.com/samples',
    ENGAGE_WINDOWS_DOWNLOAD_URL:
      'https://engage-rgr.veeva.com/windows/Setup.exe',
  },
}
type DevNamespace = keyof typeof DevConfigs

const namespace = window.document.namespace || 'local'

const isProdCluster = Object.keys(ProdConfigs).indexOf(namespace) >= 0
const clusterConfig =
  ClusterConfigs[isProdCluster ? 'prodCluster' : 'devCluster']
const envConfig =
  (isProdCluster
    ? ProdConfigs[namespace as ProdNamespace]
    : DevConfigs[namespace as DevNamespace]) || {}
const Config = { ...BaseConfig, ...clusterConfig, ...envConfig }

export default Config
