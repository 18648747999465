import { AnyAction } from 'redux'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import { clearLogs, addLog } from '@web/js/redux/logger/loggerSlice'
import { LogEvent } from '@web/_types'
import { LoggingService } from '@web/_services/LoggingService'
import { LOCALSTORAGE, LOGGING } from '@web/_constants'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import { generateUuid, jwtSecToExpire, parseJwt } from '@web/_utils'
import { AuthService } from '@web/_services'

let timer: ReturnType<typeof setTimeout> | null = null

export const initLogger =
  (ns = LOGGING.NS.WEB): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    const loggingId = localStorage.getItem(LOCALSTORAGE.loggingId)
    if (!loggingId) {
      window.localStorage.setItem(
        LOCALSTORAGE.loggingId,
        JSON.stringify(generateUuid())
      )
    }

    axios.interceptors.request.use((config) => {
      dispatch(logApiRequest(config, ns))
      return config
    })

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 500) {
          dispatch(logApiError(error, ns))
        }

        return Promise.reject(error)
      }
    )

    dispatch(startTimer())
  }

export const sendLogs =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { logger } = getState()
    if (!logger.logs.length) return

    const accessToken = JSON.parse(
      localStorage.getItem(LOCALSTORAGE.accessToken) || 'null'
    )

    if (accessToken) {
      const parsedJwt = parseJwt(accessToken)
      const exp = parsedJwt['exp'] as number
      const secondsUntilExpire = jwtSecToExpire(exp)

      if (secondsUntilExpire < 60) {
        await refreshAccessToken()
      }
    }

    try {
      LoggingService.log(logger.logs)
      dispatch(clearLogs())
    } catch (error) {
      console.log(error)
    }
  }

const refreshAccessToken = () => {
  const refreshToken = JSON.parse(
    localStorage.getItem(LOCALSTORAGE.refreshToken) || 'null'
  )

  const clientSecret = JSON.parse(
    localStorage.getItem(LOCALSTORAGE.clientSecret) || 'null'
  )

  return new Promise((resolve, reject) => {
    AuthService.refreshAccessToken(refreshToken.token, clientSecret)
      .then((res) => {
        const token = res.headers['x-myveeva-auth']
        window.localStorage.setItem(
          LOCALSTORAGE.accessToken,
          JSON.stringify(token)
        )

        resolve(null)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const startTimer =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { logger } = getState()

    if (timer) clearInterval(timer)
    timer = setInterval(() => dispatch(sendLogs()), logger.flush.delay)
  }

export const checkFlush =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { logger } = getState()

    if (logger.logs.length === logger.flush.count) {
      await sendLogs()
      dispatch(startTimer())
    }
  }

export const createLog =
  (
    eventType: string,
    eventMessage: string,
    eventMessageOption?: string | string[],
    level = LOGGING.EVENT_LEVELS.INFO,
    loggerNS = LOGGING.NS.WEB
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    const loggingId = JSON.parse(
      localStorage.getItem(LOCALSTORAGE.loggingId) || 'null'
    )
    const timestamp = dayjs().utc().format()
    const device_id = loggingId as string
    const logger = `${loggerNS}.${eventType}`
    const msg = formatLogMessage(eventMessage, eventMessageOption)
    const loc = callingFunctionTrace()

    const logPayload: LogEvent = {
      level,
      msg,
      device_id,
      logger,
      loc,
      timestamp,
    }
    dispatch(addLog(logPayload))
    dispatch(checkFlush())
  }

const logApiRequest =
  (
    config: AxiosRequestConfig,
    ns: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    if (config.url !== '/logs') {
      const method = config.method?.toUpperCase() as string
      const url = `${config.baseURL ? config.baseURL : ''}${
        config.url ? config.url : ''
      }`
      if (method && url)
        dispatch(
          createLog(
            LOGGING.EVENT_TYPES.API,
            LOGGING.EVENT_MESSAGES.API_REQUEST,
            [method, url],
            LOGGING.EVENT_LEVELS.INFO,
            ns
          )
        )
    }
  }

const logApiError =
  (
    error: AxiosError,
    ns: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    const config = error.config
    if (config && config.url !== '/logs') {
      const method = config.method?.toUpperCase() as string
      const url = `${config.baseURL ? config.baseURL : ''}${
        config.url ? config.url : ''
      }`

      if (method && url)
        dispatch(
          createLog(
            LOGGING.EVENT_TYPES.API,
            [error.toString(), '{0}', '{1}'].join(' '),
            [method, url],
            LOGGING.EVENT_LEVELS.ERROR,
            ns
          )
        )
    }
  }

const callingFunctionTrace = (): string => {
  const error = new Error()
  const stack = error.stack
    ?.split('\n')[3]
    .replace(/\s+at\s+/, '')
    .split(' ') as string[]

  const pathNum = stack[stack.length - 1].replace(/([()])/g, '')
  return pathNum
}

const formatLogMessage = (
  eventMessage: string,
  eventMessageOption?: string | string[]
): string => {
  if (eventMessageOption) {
    let msg = eventMessage
    if (_.isArray(eventMessageOption)) {
      _.each(eventMessageOption, (option, i) => {
        msg = msg.replace(`{${i}}`, option)
      })
    }
    msg = msg.replace('{0}', eventMessageOption as string)
    return msg
  }

  return eventMessage
}
