import React, { useContext, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './SignUp.scss'
import { useLocalStorage } from '@web/common/hooks'
import { LOCALSTORAGE } from '@web/_constants'
import * as DOMPurify from 'dompurify'
import { ROUTES, I18N } from '@web/_constants'
import Button from '@web/js/components/Button'
import FormField from '@web/js/components/FormField'
import FormError from '@web/js/components/FormError'
import { AppContext } from '@web/js/context/AppContext'
import { AuthContext } from '@web/js/context/AuthContext'
import { ConfigType } from '@web/_types'

const SignUp: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)

  const { clientSecret, email } = useContext(AuthContext)
  const { config, isSurnameOrdered } = useContext(AppContext)

  const [firstName, setFirstName] = useLocalStorage<string | null>(
    LOCALSTORAGE.firstName,
    null
  )

  const [lastName, setLastName] = useLocalStorage<string | null>(
    LOCALSTORAGE.lastName,
    null
  )

  const [disabled, setDisabled] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    isSurnameOrdered
      ? lastNameRef.current?.focus()
      : firstNameRef.current?.focus()
  }, [isSurnameOrdered])

  useEffect(() => {
    setDisabled(!lastName || !firstName)
    if (error) setError('')
  }, [lastName, firstName])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!!firstName && !!firstName.trim() && !!lastName && !!lastName.trim())
      navigate(ROUTES.consent)
    setError(t('errorAllFieldsRequired'))
  }

  if (!email && !clientSecret) {
    navigate(ROUTES.login, { state: { redirected: true } })
    return null
  }

  return (
    <div id="signup-route">
      <form id="signup-form" noValidate onSubmit={handleSubmit}>
        <div className="text-headline">
          {t('createYourAccount')}
          <div className="text-default">{email}</div>
        </div>

        <div id="ordered-inputs">
          <FormField
            label={t('firstName')}
            forRef={firstNameRef}
            name="firstName"
            value={firstName ?? ''}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            order={isSurnameOrdered ? 2 : 1}
          />

          <FormField
            label={t('lastName')}
            forRef={lastNameRef}
            name="lastName"
            value={lastName ?? ''}
            onChange={(e) => setLastName(e.currentTarget.value)}
            order={isSurnameOrdered ? 1 : 2}
          />
        </div>
        <FormError text={error} align="center" />

        <div
          id="signup-terms"
          className="text-small center"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('agreeToTerms', {
                termsUrl: (config as ConfigType).termsUrl,
                privacyUrl: (config as ConfigType).privacyUrl,
              }),
              { ADD_ATTR: ['target'] }
            ),
          }}
        />

        <Button
          block={true}
          style="primary"
          size="xl"
          type="submit"
          disabled={disabled}
        >
          {t('continue')}
        </Button>
      </form>
    </div>
  )
}

export default SignUp
