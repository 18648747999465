import React, { useContext } from 'react'
import './PresentationEndedModal.scss'
import PresentationIcon from '@web/common/img/PresentationIcon.svg'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import Timer from '@web/js/components/Timer'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

const PresentationEndedModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)

  return (
    <Modal closeable={false} size="sm">
      <ModalBody id="presentation-ended-modal">
        <div id="presentation-icon">
          <img src={PresentationIcon} />
        </div>

        <div className="text-headline">{t('presentationEnded')}</div>

        <p className="text-small">
          {t('dismissingIn')}
          <Timer seconds={5} onTimeOut={hideModal} />
          ...
        </p>
      </ModalBody>

      <ModalFooter onAccept={hideModal} cancellable={false} />
    </Modal>
  )
}

export default PresentationEndedModal
