import React, { useEffect, useState } from 'react'
import { classNames } from '@web/_utils'
import './Option.scss'
import _ from 'lodash'
import OptionCheckedIcon from '@web/common/img/OptionCheckedIcon.svg'
import OptionUncheckedIcon from '@web/common/img/OptionUncheckedIcon.svg'

interface IOptionProps {
  checkedDefault: boolean
  checked?: boolean //controlled component
  isLoading?: boolean
  onClick?: (checked: boolean) => void
}

const Option: React.FC<IOptionProps> = ({
  checkedDefault,
  checked,
  isLoading,
  onClick,
}) => {
  const [toggleChecked, setToggleChecked] = useState(checkedDefault)

  useEffect(() => {
    if (!_.isUndefined(checked)) setToggleChecked(checked)
  }, [checked])

  const cx = {
    ['form-control-option']: true,
    checked: toggleChecked,
    loading: !!isLoading,
  }

  const toggleClasses = classNames(cx)

  const handleToggle = () => {
    if (isLoading) return
    if (_.isUndefined(checked)) setToggleChecked(!toggleChecked)
    if (onClick) onClick(!toggleChecked)
  }

  return (
    <div className={toggleClasses} onClick={handleToggle}>
      <img src={toggleChecked ? OptionCheckedIcon : OptionUncheckedIcon} />
    </div>
  )
}

export default Option
