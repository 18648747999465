export const formatTime = (sec: number): string => {
  //hours, minutes and seconds
  const hrs = ~~(sec / 3600)
  const mins = ~~((sec % 3600) / 60)
  const secs = ~~sec % 60

  //output like "1:01" or "4:03:59" or "123:03:59"
  let ret = ''
  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }
  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}
