import axios from 'axios'
import Config from '@web/_config'

const v2apiUrl = Config.API_URL_V2

export const MeetingRequestService = {
  respondToMeetingRequest: async (
    notificationId: string,
    accept: boolean
  ): Promise<void> => {
    await axios({
      baseURL: v2apiUrl,
      method: 'post',
      url: '/schedule',
      data: { notificationId, accept },
    })
  },
}
