import React, { useContext, useRef } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { AuthContext } from '@web/js/context/AuthContext'
import { UserService } from '@web/_services'
import { ToastContext } from '@web/js/context/ToastContext'
import ProfileImageCropper from '@web/js/components/ProfileImage/ProfileImageCropper'
import { b64toBlob } from '@web/_utils'

interface EditPhotoModalProps {
  src: string
  setSrc: (src: string | null) => void
}

const EditPhotoModal: React.FC<EditPhotoModalProps> = ({ src, setSrc }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)
  const { setAuthenticatedUser } = useContext(AuthContext)
  const { addToast } = useContext(ToastContext)

  const croppedSrcRef = useRef<string | null>(null)

  const savePhoto = async () => {
    if (!croppedSrcRef.current) return

    try {
      const blob = b64toBlob(croppedSrcRef.current)
      const setPhotoResponse = await UserService.setPhoto(blob)
      resetEdit()
      setAuthenticatedUser(setPhotoResponse.data)
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const resetEdit = () => {
    setSrc(null)
    hideModal()
  }

  return (
    <Modal
      size="lg"
      closeable={true}
      title={t('cropPhoto')}
      onClose={resetEdit}
    >
      <ModalBody>
        <ProfileImageCropper src={src} croppedSrcRef={croppedSrcRef} />
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onCancel={resetEdit}
        onAcceptText={t('done')}
        onAccept={savePhoto}
      />
    </Modal>
  )
}

export default EditPhotoModal
