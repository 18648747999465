import { useState, useEffect, useRef } from 'react'
import _ from 'lodash'

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>
    msExitFullscreen?: () => Promise<void>
    webkitExitFullscreen?: () => Promise<void>
    mozFullScreenElement?: Element
    msFullscreenElement?: Element
    webkitFullscreenElement?: Element
    mozFullScreenEnabled?: boolean
    msFullscreenEnabled?: boolean
    webkitFullscreenEnabled?: boolean
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>
    mozRequestFullscreen?: () => Promise<void>
    webkitRequestFullscreen?: () => Promise<void>
  }
}

type useFullScreenReturn = {
  toggleFullScreen: () => void
  isFullScreen: boolean
  fullScreenEnabled: boolean
}

export const useFullScreen = (
  elemRef: React.RefObject<HTMLElement>,
  initialState = false,
  onFullScreenOpen?: () => void,
  onFullScreenClose?: () => void
): useFullScreenReturn => {
  const [isFullScreen, setIsFullScreen] = useState(initialState)
  const isFullScreenRef = useRef(initialState)

  const isfullScreenEnabled = () => {
    let fullScreenEnabled = false
    if (document.fullscreenEnabled) {
      fullScreenEnabled = document.fullscreenEnabled
    } else if (document.mozFullScreenEnabled) {
      fullScreenEnabled = document.mozFullScreenEnabled
    } else if (document.webkitFullscreenEnabled) {
      fullScreenEnabled = document.webkitFullscreenEnabled
    } else if (document.msFullscreenEnabled) {
      fullScreenEnabled = document.msFullscreenEnabled
    }
    return fullScreenEnabled
  }

  const [fullScreenEnabled] = useState(isfullScreenEnabled())

  useEffect(() => {
    if (isFullScreenRef.current === isFullScreen) return
    if (isFullScreen && onFullScreenOpen) onFullScreenOpen()
    if (!isFullScreen && onFullScreenClose) onFullScreenClose()
    isFullScreenRef.current = isFullScreen
  }, [isFullScreen])

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleScreenChange)
    document.addEventListener('webkitfullscreenchange', handleScreenChange)
    document.addEventListener('mozfullscreenchange', handleScreenChange)
    document.addEventListener('MSFullscreenChange', handleScreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleScreenChange)
      document.removeEventListener('webkitfullscreenchange', handleScreenChange)
      document.removeEventListener('mozfullscreenchange', handleScreenChange)
      document.removeEventListener('MSFullscreenChange', handleScreenChange)
    }
  }, [])

  const toggleFullScreen = () => {
    if (isFullScreen) {
      closeFullScreen()
    } else {
      openFullScreen()
    }
  }

  const handleScreenChange = () => {
    let isFullscreen = false
    if (document.fullscreenElement) {
      isFullscreen = !_.isNull(document.fullscreenElement)
    } else if (document.mozFullScreenElement) {
      isFullscreen = !_.isNull(document.mozFullScreenElement)
    } else if (document.webkitFullscreenElement) {
      isFullscreen = !_.isNull(document.webkitFullscreenElement)
    } else if (document.msFullscreenElement) {
      isFullscreen = !_.isNull(document.msFullscreenElement)
    }
    setIsFullScreen(isFullscreen)
  }

  const openFullScreen = () => {
    const elem = elemRef.current as HTMLElement
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.mozRequestFullscreen) {
      elem.mozRequestFullscreen()
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen()
    }
  }

  const closeFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }

  return { toggleFullScreen, fullScreenEnabled, isFullScreen }
}
