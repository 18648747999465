import React, { useState, useEffect, useContext, useRef } from 'react'
import './Meetings.scss'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useTranslation } from 'react-i18next'
import { I18N, DAYS, LOGGING, ROUTES, ROLES } from '@web/_constants'
import { Meeting, NotificationRequest, RemoteCLMSession } from '@web/_types'
import {
  getNotificationStartTime,
  groupMeetings,
  sortGroupedMeetingsKeys,
} from '@web/_utils'
import { isIPad, isMobile } from '@admin/common/js/IsMobile'
import _ from 'lodash'
import dayjs from 'dayjs'
import JoinMeetingForm from '@web/js/components/JoinMeetingForm'
import MeetingCard from '@web/js/components/MeetingCard'
import Sidebar from '@web/js/components/Sidebar'
import MeetingDetails from '@web/js/components/MeetingDetails'
import ConnectionDetails from '@web/js/components/ConnectionDetails'
import { MeetingsContext } from '@web/js/context/MeetingsContext'
import Button from '@web/js/components/Button'
import EmptyMeetings from '@web/common/img/EmptyMeetings.svg'
import { isMeeting, isNotificationRequest, isRep } from '@web/_guards'
import { MeetingRequestService } from '@web/_services/MeetingRequestService'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import { useNavigate, useParams } from 'react-router-dom'

interface RouterParams {
  id: string
}

const MeetingsRoute: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { lessThan, mediaType } = useResponsive()
  const { t, i18n } = useTranslation(I18N.namespaces.web)
  const { id } = useParams<keyof RouterParams>() as RouterParams

  const {
    getMeetings,
    previousMeetings,
    upcomingMeetings,
    isLoadingMeetings,
    loadMore,
    nextPreviousMeetings,
    nextUpcomingMeetings,
    loadUpdatedRequestRecord,
  } = useContext(MeetingsContext)

  const [open, setOpen] = useState(false)
  const [isOpenProfile, setIsOpenProfile] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [activeMeeting, setActiveMeeting] = useState<
    Meeting | NotificationRequest | RemoteCLMSession | null | undefined
  >(null)

  const isInitializing = useRef(true)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (!isLoading) setOpen(false)
  }, [mediaType])

  useEffect(() => {
    if (isOpenProfile && activeMeeting) {
      logMeetingProfileEvent(activeMeeting)
    }
  }, [isOpenProfile, activeMeeting])

  useEffect(() => {
    if (
      !isLoading &&
      !_.isNull(previousMeetings) &&
      !_.isNull(upcomingMeetings)
    ) {
      handleOnChangeId(id)
    }
  }, [id])

  useEffect(() => {
    if (
      isInitializing.current &&
      !_.isNull(previousMeetings) &&
      !_.isNull(upcomingMeetings)
    ) {
      handleOnChangeId(id)
      isInitializing.current = false
    }
  }, [id, previousMeetings, upcomingMeetings])

  //update url when active meeting selected
  useEffect(() => {
    if (!isLoading) {
      if (activeMeeting) {
        const meetingId = isNotificationRequest(activeMeeting)
          ? activeMeeting.notificationId
          : activeMeeting.id

        navigate(`${ROUTES.meetings}/${meetingId}`, { replace: true })
        setIsOpenProfile(false)
      } else {
        navigate(ROUTES.meetings, { replace: true })
      }
    }
  }, [activeMeeting])

  const init = async () => {
    await getMeetings()
    setIsLoading(false)
  }

  const handleOnChangeId = (id?: string) => {
    if (id && !_.isNull(previousMeetings) && !_.isNull(upcomingMeetings)) {
      const match = [...previousMeetings, ...upcomingMeetings].find(
        (meeting) => {
          const meetingId = isNotificationRequest(meeting)
            ? meeting.notificationId
            : meeting.id
          return meetingId === id
        }
      )

      if (match) {
        setActiveMeeting(match)
        if (!open) setOpen(true)
      } else {
        navigate(ROUTES.meetings, { replace: true })
        if (open) setOpen(false)
      }
    } else {
      if (open) setOpen(false)
    }
  }

  const logMeetingProfileEvent = (
    activeMeeting: Meeting | NotificationRequest | RemoteCLMSession
  ) => {
    const profileId = isNotificationRequest(activeMeeting)
      ? activeMeeting.contact.id
      : activeMeeting.connection.id

    const referenceId = isNotificationRequest(activeMeeting)
      ? activeMeeting.notificationId
      : activeMeeting.id

    const eventMessage = {
      profileId,
      actionType: LOGGING.ACTION_TYPE.VIEW_PROFILE,
      actionDetail: LOGGING.ACTION_DETAIL.MEETING_DETAIL,
      referenceId,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  const acceptRequest = async (meeting: NotificationRequest) => {
    try {
      await MeetingRequestService.respondToMeetingRequest(
        meeting.notificationId,
        true
      )
      const acceptedRequest = await loadUpdatedRequestRecord(
        meeting.notificationId
      )
      setActiveMeeting(acceptedRequest)
    } catch (error) {
      console.log(error)
    }
  }

  const declineRequest = async (meeting: NotificationRequest) => {
    try {
      await MeetingRequestService.respondToMeetingRequest(
        meeting.notificationId,
        false
      )
      const declinedRequest = await loadUpdatedRequestRecord(
        meeting.notificationId
      )
      setActiveMeeting(declinedRequest)
    } catch (error) {
      console.log(error)
    }
  }

  const openMeetingDetails = (
    meeting: Meeting | NotificationRequest | RemoteCLMSession
  ) => {
    if (meeting === activeMeeting) {
      setOpen(false)
    } else {
      setActiveMeeting(meeting)
      if (!open) setOpen(true)
    }
  }

  const generateHeadline = (meetingKey: string, previousMeeting = false) => {
    let headline = ''
    const isToday = meetingKey === DAYS.today
    const isTomorrow = meetingKey === DAYS.tomorrow

    if (isToday || isTomorrow) {
      headline = t(`days.${meetingKey.toLowerCase()}`)
    } else {
      const sameYear = dayjs().isSame(meetingKey, 'year')
      const dateOptions: Intl.DateTimeFormatOptions = {
        month: 'long',
      }
      if (!sameYear || previousMeeting) dateOptions.year = 'numeric'
      headline = new Date(meetingKey).toLocaleDateString(
        i18n.language,
        dateOptions
      )
    }

    return headline
  }

  const generateMeetingsList = (
    meetings: (Meeting | NotificationRequest | RemoteCLMSession)[],
    isPreviousMeetings = false
  ) => {
    if (!meetings.length) return null
    const groupedMeetings = groupMeetings(meetings)
    const group = isPreviousMeetings ? 'previous' : 'upcoming'

    return (
      <div id={`${group}-meetings`} className="container">
        {isPreviousMeetings && (
          <div className="text-headline">{t('previousMeetings')}</div>
        )}

        {_.map(
          sortGroupedMeetingsKeys(groupedMeetings, !isPreviousMeetings),
          (meetingKey) => {
            const meetingsList = _.orderBy(
              groupedMeetings[meetingKey],
              [
                (mtg: Meeting | NotificationRequest | RemoteCLMSession) => {
                  const meetingDate = isNotificationRequest(mtg)
                    ? getNotificationStartTime(mtg)
                    : mtg.start
                  return dayjs(meetingDate)
                },
                (mtg: Meeting | NotificationRequest | RemoteCLMSession) => {
                  const lastName = isNotificationRequest(mtg)
                    ? mtg.contact.lastName
                    : mtg.connection.lastName
                  return lastName
                },
                (mtg: Meeting | NotificationRequest | RemoteCLMSession) => {
                  const type = isMeeting(mtg) ? 1 : 0
                  return type
                },
                (mtg: Meeting | NotificationRequest) => {
                  const cardTitle = isMeeting(mtg) ? mtg.title || '' : mtg.type
                  return cardTitle
                },
                (mtg: Meeting | NotificationRequest | RemoteCLMSession) => {
                  const id = isNotificationRequest(mtg)
                    ? mtg.notificationId
                    : mtg.id

                  return id
                },
              ],
              [!isPreviousMeetings ? 'asc' : 'desc', 'asc', 'asc', 'asc', 'asc']
            )

            const headline = generateHeadline(meetingKey, isPreviousMeetings)

            return (
              <div
                className={`${group}-meetings--group`}
                key={`${group}_${meetingKey}`}
              >
                <div
                  className={
                    isPreviousMeetings ? 'text-subhead' : 'text-headline'
                  }
                >
                  {headline}
                </div>
                {_.map(meetingsList, (meeting) => {
                  const meetingId = isNotificationRequest(meeting)
                    ? meeting.notificationId
                    : meeting.id

                  const isActive = activeMeeting
                    ? isNotificationRequest(activeMeeting)
                      ? meetingId === activeMeeting?.notificationId
                      : meetingId === activeMeeting?.id
                    : false

                  return (
                    <MeetingCard
                      meeting={meeting}
                      key={meetingId}
                      onClick={() => openMeetingDetails(meeting)}
                      active={isActive}
                    />
                  )
                })}
              </div>
            )
          }
        )}
      </div>
    )
  }

  const openProfile = () => {
    if (!activeMeeting) return

    if (lessThan.md) {
      const connection = isNotificationRequest(activeMeeting)
        ? activeMeeting.contact
        : activeMeeting.connection

      const path = connection && isRep(connection) ? ROLES.rep : ROLES.hcp
      return navigate(`/${path.toLowerCase()}/${connection.id}`)
    } else {
      setIsOpenProfile(true)
    }
  }

  if (isLoading && isLoadingMeetings) return null

  return (
    <div id="meetings-route">
      <div className="row gutter-md">
        <div className="col-md-5 col-xs-8">
          {!isLoading &&
            ((upcomingMeetings && !!upcomingMeetings.length) ||
              (previousMeetings && !!previousMeetings.length)) && (
              <>
                {upcomingMeetings &&
                  !!upcomingMeetings.length &&
                  generateMeetingsList(upcomingMeetings)}

                {!!nextUpcomingMeetings && (
                  <div className="load-more">
                    <Button
                      size="sm"
                      outline={true}
                      onClick={() => loadMore(nextUpcomingMeetings)}
                    >
                      {t('loadMore')}
                    </Button>
                  </div>
                )}

                {previousMeetings &&
                  !!previousMeetings.length &&
                  generateMeetingsList(previousMeetings, true)}

                {!!nextPreviousMeetings && (
                  <div className="load-more">
                    <Button
                      size="sm"
                      outline={true}
                      onClick={() => loadMore(nextPreviousMeetings)}
                    >
                      {t('loadMore')}
                    </Button>
                  </div>
                )}
              </>
            )}

          {!!upcomingMeetings &&
            !upcomingMeetings.length &&
            !!previousMeetings &&
            !previousMeetings.length && (
              <div id="no-meetings" className="container">
                <img src={EmptyMeetings} />
                <div className="text-headline center">{t('noMeetings')}</div>
                <div className="text-subhead center">{t('findMeetings')}</div>
              </div>
            )}
        </div>

        {!lessThan.md && !isMobile && !isIPad && (
          <div className="col-md-3 col-xs-8">
            <div className="container">
              <JoinMeetingForm />
            </div>
          </div>
        )}
      </div>

      <Sidebar
        classes={isMobile ? 'mobile-sidebar' : 'web-sidebar'}
        side="right"
        open={open}
        id="meeting-details-sidebar"
        closeFunc={() => setOpen(false)}
        onClosedFunc={() => {
          setActiveMeeting(null)
        }}
        width="calc(50% - 125px)"
        fullScreen={lessThan.md}
      >
        {activeMeeting && (
          <MeetingDetails
            meeting={activeMeeting}
            acceptRequest={acceptRequest}
            declineRequest={declineRequest}
            openProfile={openProfile}
          />
        )}
      </Sidebar>

      <Sidebar
        side="right"
        open={isOpenProfile}
        id="connection-details-sidebar"
        closeFunc={() => setIsOpenProfile(false)}
        width="calc(50% - 125px)"
        fullScreen={lessThan.md}
      >
        {activeMeeting && (
          <ConnectionDetails
            connection={
              isNotificationRequest(activeMeeting)
                ? activeMeeting.contact
                : activeMeeting.connection
            }
          />
        )}
      </Sidebar>
    </div>
  )
}

export default MeetingsRoute
