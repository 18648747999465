import * as React from 'react'
import './Avatar.scss'
import DeletedUser from '@web/common/img/DeletedUser.svg'
import { Connection, DeeplinkInviter } from '@web/_types'
import { isHCP, isRep } from '@web/_guards'
import { COLORS } from '@web/_constants'
import BriefcaseIcon from '@web/common/img/BriefcaseIcon.svg'
import EmailIcon from '@web/common/img/EmailIcon'

type AvatarSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface AvatarProps {
  size?: AvatarSize
  user?: Connection
  inviter?: DeeplinkInviter
  email?: string
  showRepIcon?: boolean
  invert?: boolean
}

const Avatar: React.FC<AvatarProps> = ({
  size = 'sm',
  user,
  inviter,
  email,
  invert = false,
  showRepIcon = false,
}) => {
  let src = null
  let initial = null
  let color = COLORS.blue

  if (user) {
    src = isHCP(user) && user.deleted ? DeletedUser : user.photoUrl
    initial = Array.from(user.lastName)[0].toUpperCase()
    color = isHCP(user) ? COLORS.midnight : (user.group.primaryColor as string)
  } else if (inviter) {
    src = inviter.deleted ? DeletedUser : inviter.photoUrl
    initial = Array.from(inviter.lastName)[0].toUpperCase()
    color =
      inviter.type === 'HCP'
        ? COLORS.midnight
        : (inviter.primaryColor as string)
  }

  const initialStyle = invert
    ? { background: COLORS.white, color }
    : { background: color }

  return (
    <div className={`avatar avatar-${size}`}>
      {src && <img className="avatar--photo" src={src} />}

      {!src && !!initial && (
        <div className="avatar--initial" style={initialStyle}>
          {initial}
        </div>
      )}

      {!user && !!email && (
        <div className="avatar--email-icon" style={initialStyle}>
          <EmailIcon />
        </div>
      )}

      {!!user && isRep(user) && showRepIcon && (
        <div className="avatar--rep-icon" style={{ background: color }}>
          <img src={BriefcaseIcon} />
        </div>
      )}
    </div>
  )
}

export default Avatar
