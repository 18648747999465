import React, { createContext, useCallback, useContext, useMemo } from 'react'
import Bowser from 'bowser'
import { EngageService } from '@web/_services/EngageService'
import { EngageMeetingContext } from '@web/js/context/EngageMeetingContext'
import Config from '@web/_config'
import { ZoomService } from '@web/_services/ZoomService'
import { MeetingActivity } from '@web/_types/engageApi'
import { ClientIdService } from '@web/_services/ClientIdService'

const browser = Bowser.detect(window.navigator.userAgent)

type EngageLoggingProviderProps = {
  children?: React.ReactNode
}

export type IEngageLoggingContext = {
  logClientEvent: (
    eventType: string,
    zoomCode: number | string | null,
    zoomMessage: string | null
  ) => Promise<void>
  createActivity: (activityType: string, message?: string) => MeetingActivity
  logMeetingActivity: (
    activities: Array<MeetingActivity>,
    tenantId: string
  ) => Promise<void>
  logMeetingInstance: (
    activities: Array<MeetingActivity>,
    zoomInstanceId: string,
    zoomUserId: string,
    tenantId: string
  ) => Promise<void>
}

export const EngageLoggingContext = createContext<IEngageLoggingContext>({
  createActivity: () => {
    throw 'Not Implemented'
  },
  logClientEvent: async () => {
    /* */
  },
  logMeetingActivity: async () => {
    /* */
  },
  logMeetingInstance: async () => {
    /* */
  },
})

const ENGAGE_INFO_NOT_LOADED = 'Engage meeting info failed to load'

export const EngageLoggingProvider: React.FC<EngageLoggingProviderProps> = ({
  children,
}) => {
  const {
    info: engageInfo,
    participantId,
    displayName,
    getToken,
  } = useContext(EngageMeetingContext)
  const clientId = ClientIdService.getClientId()

  const connection = (
    navigator as unknown as { connection?: { effectiveType?: string } }
  )?.connection

  const deviceInfo = useMemo(
    () => ({
      clientAppVersion: Config.VERSION,
      clientAppName: 'WebClient',
      clientLanguage: ZoomService.getZoomLanguage(),
      clientUUID: clientId,
      clientOS: `${browser.osname} ${browser.osversion}`,
      clientNetworkType: connection?.effectiveType || '',
      viewportBrowserRes: `${window.innerWidth} x ${window.innerHeight} (${window.outerWidth} x ${window.outerHeight})`,
    }),
    []
  )

  const logClientEvent = useCallback(
    async (
      eventType: string,
      zoomCode: number | string | null,
      zoomMessage: string | null
    ) => {
      if (!engageInfo) throw ENGAGE_INFO_NOT_LOADED
      const event = {
        engage_meeting_id: participantId,
        zoom_signature: engageInfo.token.signature,
        client_app_name: deviceInfo.clientAppName,
        client_app_version: deviceInfo.clientAppVersion,
        client_language: deviceInfo.clientLanguage,
        client_uuid: deviceInfo.clientUUID,
        viewport_browser_res: deviceInfo.viewportBrowserRes,
        event_type: eventType,
        zoom_code: zoomCode === null ? '' : zoomCode, // Avoid overwriting zoom code of 0
        zoom_message: zoomMessage || '',
        client_timestamp: Date.now(),
      }
      await EngageService.logClientEvent(event, await getToken())
    },
    [engageInfo, deviceInfo, getToken]
  )

  const createActivity = useCallback(
    (activityType: string, message?: string) => ({
      activityType: activityType,
      message: message,
      activityTime: Date.now(),
    }),
    []
  )

  const formMeetingSession = useCallback(
    (activities: Array<MeetingActivity>) => {
      const now = Date.now()
      const bulkActivities = activities.map(
        ({ activityTime, activityType, message }) => ({
          activityType,
          message,
          activityOffset: now - activityTime,
        })
      )
      return {
        session: {
          veevaMeetingId: participantId,
          participantName: displayName,
          participantId: engageInfo?.token.participantId,
          appType: deviceInfo.clientAppName,
          appVersion: deviceInfo.clientAppVersion,
          osVersion: deviceInfo.clientOS,
          networkType: deviceInfo.clientNetworkType,
          activities: bulkActivities,
        },
      }
    },
    [engageInfo, deviceInfo]
  )

  const logMeetingActivity = useCallback(
    async (activities: Array<MeetingActivity>, tenantId: string) => {
      const logData = formMeetingSession(activities)
      const token = await getToken()
      await EngageService.logMeetingActivity(
        logData,
        engageInfo?.token.meetingId || null,
        clientId,
        tenantId,
        token
      )
    },
    [engageInfo, deviceInfo, getToken]
  )

  const logMeetingInstance = useCallback(
    async (
      activities: Array<MeetingActivity>,
      zoomInstanceId: string,
      zoomUserId: string,
      tenantId: string
    ) => {
      const { session } = formMeetingSession(activities)
      const token = await getToken()
      const logData = {
        session: Object.assign(session, { zoomUserId }),
        zoomInstanceId,
      }
      await EngageService.logMeetingInstance(
        logData,
        engageInfo?.token.meetingId || null,
        clientId,
        tenantId,
        token
      )
    },
    [engageInfo, deviceInfo, getToken]
  )

  const contextValue = useMemo(
    () => ({
      logClientEvent,
      createActivity,
      logMeetingActivity,
      logMeetingInstance,
    }),
    [
      engageInfo,
      deviceInfo,
      logClientEvent,
      logMeetingActivity,
      logMeetingInstance,
    ]
  )
  return (
    <EngageLoggingContext.Provider value={contextValue}>
      {children}
    </EngageLoggingContext.Provider>
  )
}
