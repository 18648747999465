import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  HCP,
  Meeting,
  MeetingRef,
  NotificationRequest,
  RemoteCLMSession,
} from '@web/_types'
import { MeetingService, NotificationsService } from '@web/_services'
import { parseDossier, getQueryStringParams } from '@web/_utils'
import { I18N, NOTIFICATION } from '@web/_constants'
import _ from 'lodash'
import { ToastContext } from './ToastContext'
import { AuthContext } from './AuthContext'
import dayjs from 'dayjs'
import { isNotificationRequest } from '@web/_guards'

export type IMeetingsContext = {
  upcomingMeetings: (Meeting | NotificationRequest | RemoteCLMSession)[] | null
  previousMeetings: (Meeting | NotificationRequest | RemoteCLMSession)[] | null
  getMeetings: (includePrevious?: boolean) => void
  loadMore: (next: string) => void
  loadUpdatedRequestRecord: (id: string) => Promise<NotificationRequest> | null
  isLoadingMeetings: boolean
  nextUpcomingMeetings: string | null | undefined
  nextPreviousMeetings: string | null | undefined
}

interface IMeetingsProvider {
  children?: ReactNode
}

const noop = () => null

export const MeetingsContext = createContext<IMeetingsContext>({
  upcomingMeetings: null,
  previousMeetings: null,
  getMeetings: noop,
  loadMore: noop,
  loadUpdatedRequestRecord: noop,
  isLoadingMeetings: true,
  nextUpcomingMeetings: null,
  nextPreviousMeetings: null,
})

export const MeetingsProvider: React.FC<IMeetingsProvider> = ({ children }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { addToast } = useContext(ToastContext)
  const { user } = useContext(AuthContext)
  const [upcomingMeetings, setUpcomingMeetings] = useState<
    (Meeting | NotificationRequest | RemoteCLMSession)[] | null
  >(null)
  const [previousMeetings, setPreviousMeetings] = useState<
    (Meeting | NotificationRequest | RemoteCLMSession)[] | null
  >(null)
  const [nextUpcomingMeetings, setNextUpcomingMeetings] = useState<
    string | null | undefined
  >(null)
  const [nextPreviousMeetings, setNextPreviousMeetings] = useState<
    string | null | undefined
  >(null)
  const [isLoadingMeetings, setIsLoadingMeetings] = useState(true)
  const count = 50

  const loadUpdatedRequestRecord = async (
    id: string
  ): Promise<NotificationRequest> => {
    const updatedNotificationResponse =
      await NotificationsService.getNotification(id)
    const dossier = parseDossier(
      updatedNotificationResponse.data,
      (user as HCP).id
    )

    const updatedNotification = dossier.notificationRequests[id]
    const isDeleted =
      updatedNotification.type === NOTIFICATION.meetingAdvDeclined

    let updatedUpcomingMeetings
    if (isDeleted) {
      updatedUpcomingMeetings = _.filter(
        upcomingMeetings,
        (mtg) =>
          !isNotificationRequest(mtg) ||
          (isNotificationRequest(mtg) && mtg.notificationId !== id)
      )
    } else {
      updatedUpcomingMeetings = _.map(upcomingMeetings, (mtg) => {
        if (isNotificationRequest(mtg) && mtg.notificationId === id) {
          return updatedNotification
        } else {
          return mtg
        }
      })
    }

    setUpcomingMeetings(updatedUpcomingMeetings)
    return updatedNotification
  }

  const getMeetings = async (includePreviousMeetings = true) => {
    try {
      if (!isLoadingMeetings) setIsLoadingMeetings(true)
      const directions = ['asc']
      if (includePreviousMeetings) directions.push('desc')

      _.each(directions, async (direction) => {
        const params: Record<string, string | number> = {
          datetime: dayjs().startOf('day').utc().format(),
          count,
          direction,
        }
        const getMeetingsResponse = await MeetingService.getMeetings(params)
        const dossier = parseDossier(getMeetingsResponse.data, (user as HCP).id)

        const meetings = _.map(dossier.meetingRefs as MeetingRef[], (ref) => {
          switch (ref.type) {
            case 'MEETING':
              return dossier.meetings[ref.id]
            case 'NOTIFICATION':
              return dossier.notificationRequests[ref.id]
            case 'REMOTE_CLM':
              return dossier.remoteCLMSessions[ref.id]
          }
        }) as (Meeting | NotificationRequest | RemoteCLMSession)[]

        const next = dossier.next

        if (direction === 'asc') {
          setNextUpcomingMeetings(next)
          setUpcomingMeetings(meetings)
        } else {
          setNextPreviousMeetings(next)
          setPreviousMeetings(meetings)
        }

        if (direction === _.last(directions)) setIsLoadingMeetings(false)
      })
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const loadMore = async (nextQuery: string) => {
    const params = getQueryStringParams(nextQuery)
    const dir = params.direction

    try {
      const getMeetingsResponse = await MeetingService.getMeetings(params)
      const dossier = parseDossier(getMeetingsResponse.data, (user as HCP).id)
      const meetings: Meeting[] = Object.values(dossier.meetings)
      const next = dossier.next

      if (dir === 'asc') {
        setNextUpcomingMeetings(next)
        setUpcomingMeetings([...(upcomingMeetings || []), ...meetings])
      } else {
        setNextPreviousMeetings(next)
        setPreviousMeetings([...(previousMeetings || []), ...meetings])
      }
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const contextValue = useMemo(
    () => ({
      getMeetings,
      loadMore,
      loadUpdatedRequestRecord,
      isLoadingMeetings,
      nextPreviousMeetings,
      nextUpcomingMeetings,
      previousMeetings,
      upcomingMeetings,
    }),
    [
      isLoadingMeetings,
      nextPreviousMeetings,
      nextUpcomingMeetings,
      previousMeetings,
      upcomingMeetings,
    ]
  )

  return (
    <MeetingsContext.Provider value={contextValue}>
      {children}
    </MeetingsContext.Provider>
  )
}
