import React, { useState, useEffect } from 'react'
import './FormError.scss'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

type AlignOptions = 'left' | 'center' | 'right'

interface FormErrorProps {
  text: string
  icon?: React.ReactNode
  align?: AlignOptions
  name?: string
}

const FormError: React.FC<FormErrorProps> = ({
  text = '',
  icon = <ErrorOutlineIcon />,
  align = 'left',
  name = '',
}) => {
  const [justifyContent, setJustifyContent] = useState('flex-start')
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(!!text)
  })

  useEffect(() => {
    switch (align) {
      case 'center':
        setJustifyContent('center')
        break
      case 'right':
        setJustifyContent('flex-end')
        break
      default:
        setJustifyContent('flex-start')
    }
  }, [align])

  if (!isVisible) return null

  return (
    <div className="form-error" style={{ justifyContent }} data-name={name}>
      {icon}
      {text}
    </div>
  )
}

export default FormError
