import React, { useContext } from 'react'
import { I18N, LOGGING } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { ConnectionContext } from '@web/js/context/ConnectionContext'
import { Connection } from '@web/_types'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'

interface RemoveConnectionModalProps {
  connection: Connection
  removeConnectionCallback?: () => void
}

const RemoveConnectionModal: React.FC<RemoveConnectionModalProps> = ({
  connection,
  removeConnectionCallback,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { hideModal } = useContext(ModalContext)
  const dispatch = useDispatch()
  const { removeConnection } = useContext(ConnectionContext)

  const handleOnAccept = () => {
    removeConnection(connection, () => {
      if (removeConnectionCallback) removeConnectionCallback()
      hideModal()
    })

    const eventMessage = {
      profileId: connection.id,
      actionType: LOGGING.ACTION_TYPE.CLICK_REMOVE_CONNECTION,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  return (
    <Modal closeable={false} title={t('removeConnection')}>
      <ModalBody>
        <p>{t('pleaseConfirmDisconnect', { name: connection.displayName })}</p>
      </ModalBody>

      <ModalFooter
        onAcceptText={t('remove')}
        cancellable={true}
        onCancel={hideModal}
        onAccept={handleOnAccept}
      />
    </Modal>
  )
}

export default RemoveConnectionModal
