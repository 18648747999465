import React from 'react'
import './LoadingIndicator.scss'
import Spinner from '@web/common/img/Spinner.png'
import SpinnerSmall from '@web/common/img/SpinnerSmall.svg'
import EngageLogoIcon from '@web/common/img/EngageLogoIcon.svg'
import { classNames } from '@web/_utils'

interface LoadingIndicatorProps {
  engage?: boolean
  fullPage?: boolean
  fadeIn?: boolean
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  engage = false,
  fullPage = false,
  fadeIn = false,
}) => {
  const spinnerClasses = classNames({
    engage,
  })

  const loadingIndicatorClasses = classNames({
    'fade-in': fadeIn,
  })

  const renderLoadingIndicator = () => {
    return (
      <div id="loading-indicator" className={loadingIndicatorClasses}>
        {engage && <img id="engage-logo" src={EngageLogoIcon} />}

        <img
          id="spinner"
          className={spinnerClasses}
          src={engage ? SpinnerSmall : Spinner}
        />
      </div>
    )
  }

  return fullPage ? (
    <div id="loading-indicator--full-page-wrapper">
      {renderLoadingIndicator()}
    </div>
  ) : (
    renderLoadingIndicator()
  )
}

export default LoadingIndicator
