import React, { createContext, useCallback, useMemo, useState } from 'react'
import { EngageService } from '@web/_services/EngageService'
import { EngageMeetingToken } from '@web/_types/engageApi'

export type IEngageContext = {
  participantId: string
  getToken: () => Promise<EngageMeetingToken>
}

export const EngageContext = createContext<IEngageContext>({
  participantId: '',
  getToken: () => Promise.reject(),
})

type EngageContextProps = {
  participantId: string
  children?: React.ReactNode
}

type CachedToken = {
  token: EngageMeetingToken | null
  issued: number
}

const TOKEN_TIMEOUT = 1000 * 60 * 8 // 8 minutes in ms

export const EngageProvider: React.FC<EngageContextProps> = ({
  participantId,
  children,
}) => {
  const [cachedToken, setCachedToken] = useState<CachedToken>({
    token: null,
    issued: 0,
  })

  const getToken = useCallback(async (): Promise<EngageMeetingToken> => {
    const { token, issued } = cachedToken
    const now = Date.now()
    if (!token || now - issued > TOKEN_TIMEOUT) {
      const newToken = await EngageService.getMeetingToken(participantId)
      setCachedToken({ token: newToken, issued: now })
      return newToken
    } else {
      return token
    }
  }, [cachedToken])

  const contextValue = useMemo<IEngageContext>(
    () => ({
      participantId,
      getToken,
    }),
    [getToken]
  )

  return (
    <EngageContext.Provider value={contextValue}>
      {children}
    </EngageContext.Provider>
  )
}
