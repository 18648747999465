import React, { useContext } from 'react'
import './Toasts.scss'
import { classNames } from '@web/_utils'
import { ToastContext } from '@web/js/context/ToastContext'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import { Toast } from '@web/_types'

//Icons
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

interface ToastProps {
  toast: Toast
  dismiss?: () => void
}

const Toast: React.FC<ToastProps> = ({ toast, dismiss }) => {
  const { removeToast } = useContext(ToastContext)
  const { t } = useTranslation(I18N.namespaces.web)

  const titles = {
    danger: t('error'),
    warning: t('warning'),
    success: '',
    info: t('info'),
  }

  const title = toast.title !== null ? toast.title : titles[toast.type]
  const icon =
    toast.type === 'success' ? (
      <CheckCircleOutlineIcon className="toast-success-icon" />
    ) : (
      <ErrorOutlineIcon className="toast-error-icon" />
    )

  const cx = {
    toast: true,
    [`toast-${toast.type}`]: true,
  }

  const toastClasses = classNames(cx)

  const closeToast = () => {
    if (dismiss) {
      dismiss()
    } else {
      removeToast(toast.id)
    }
  }

  return (
    <div className={toastClasses} data-toast-id={toast.id}>
      <div className="toast--details--icon">{icon}</div>
      <div className="toast--details">
        {title && <span className="toast--details--title">{title}:</span>}
        <span className="toast--details--message">{toast.message}</span>
      </div>
      {toast.dismissable && (
        <div className="toast--close" onClick={closeToast}>
          <CloseIcon />
        </div>
      )}
    </div>
  )
}

export default Toast
