import React, { useEffect, useState, useRef, useContext } from 'react'
import './OfficeForm.scss'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTES, I18N } from '@web/_constants'
import { OfficesService, TimezoneService } from '@web/_services'
import {
  ConfigType,
  Errors,
  Office,
  OfficeCRUD,
  Rules,
  SelectOption,
} from '@web/_types'
import { validateForm } from '@web/_utils'
import _ from 'lodash'
import Button from '@web/js/components/Button'
import FormField from '@web/js/components/FormField'
import Select from 'react-select'
import { ToastContext } from '@web/js/context/ToastContext'
import { AppContext } from '@web/js/context/AppContext'
import { genericSelectStyles } from '@web/_config/select'
import { useRouteGuard } from '@web/common/hooks'

interface TimezoneData {
  name: string
  label: string
}

const OfficeForm: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { id } = useParams()
  const navigate = useNavigate()
  const { addToast } = useContext(ToastContext)
  const { config } = useContext(AppContext)

  const [isEditing] = useState(!!id)
  const [isLoading, setIsLoading] = useState<boolean>(isEditing)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [office, setOffice] = useState<Office | null>(null)

  //Form Fields
  const [officeName, setOfficeName] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [description, setDescription] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneLabel, setPhoneLabel] = useState('')
  const [phoneExtension, setPhoneExtension] = useState('')
  const [timezone, setTimezone] = useState('')

  const [errors, setErrors] = useState<Errors>({})
  const officeNameRef = useRef<HTMLInputElement>(null)
  const timezonesRef = useRef<SelectOption[]>([])

  useRouteGuard(isDirty)

  const formRef = useRef({
    officeName,
    postalCode,
    description,
    phoneNumber,
    phoneLabel,
    phoneExtension,
    timezone,
  })

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (office) {
      const postalCode = office.postalCode as string
      const officeName = office.name as string
      const timezone = office.timezone as string
      const description = office.description || ''
      let phoneNumber = '',
        phoneLabel = '',
        phoneExtension = ''

      if (office.phones.length) {
        phoneExtension = office.phones[0].extension || ''
        phoneLabel = office.phones[0].label || ''
        phoneNumber = office.phones[0].number || ''
      }

      formRef.current = {
        officeName,
        postalCode,
        description,
        phoneExtension,
        phoneLabel,
        phoneNumber,
        timezone,
      }

      setOfficeName(officeName || '')
      setPostalCode(postalCode || '')
      setDescription(description || '')
      setPhoneNumber(phoneNumber || '')
      setPhoneLabel(phoneLabel || '')
      setPhoneExtension(phoneExtension || '')
      setTimezone(timezone || 'UTC')
    }
  }, [office])

  useEffect(() => {
    const form = formRef.current

    const clean =
      officeName === form.officeName &&
      postalCode === form.postalCode &&
      phoneNumber === form.phoneNumber &&
      phoneLabel === form.phoneLabel &&
      phoneExtension === form.phoneExtension &&
      timezone === form.timezone &&
      description === form.description

    if (isDirty === clean) setIsDirty(!clean)
  }, [
    officeName,
    postalCode,
    description,
    phoneNumber,
    phoneLabel,
    phoneExtension,
    timezone,
  ])

  const init = async () => {
    const getTimezoneResponse = await TimezoneService.getTimezones()
    timezonesRef.current = getTimezoneResponse.data.timezones.map(
      (tz: TimezoneData) => ({
        label: tz.label,
        value: tz.name,
      })
    )

    if (!isEditing) {
      officeNameRef.current?.focus()
      setTimezone(getTimezoneResponse.data.currentTz)
      return
    }

    try {
      const getOfficeResponse = await OfficesService.getOffice(id as string)
      const office = getOfficeResponse.data.dossier.offices[id as string]
      setOffice(office)
      setIsLoading(false)
      officeNameRef.current?.focus()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectTimezone = (timezone: SelectOption) => {
    setTimezone(timezone.value)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    const fields = {
      timezone,
      officeName: officeName.trim(),
      description: description.trim(),
      phoneNumber: phoneNumber.trim(),
      phoneLabel: phoneLabel.trim(),
      phoneExtension: phoneExtension.trim(),
      ...((config as ConfigType).officePostalCodeRequired && {
        postalCode: postalCode.trim(),
      }),
    }

    const rules: Rules = {
      officeName: {
        required: true,
      },
      timezone: {
        required: true,
      },
      phoneNumber: {
        requiredIf: ['phoneLabel', 'phoneExtension'],
      },
      phoneLabel: {
        requiredIf: ['phoneNumber', 'phoneExtension'],
      },
    }

    if ((config as ConfigType).officePostalCodeRequired) {
      rules.postalCode = {
        required: true,
        length: 5,
      }
    }

    const errors = validateForm(fields, rules)
    setErrors(errors)
    if (!_.isEmpty(errors)) return

    try {
      const office = _.mapKeys(fields, (v, key) => {
        if (key === 'officeName') key = 'name'
        return key
      })

      const officeResponse = isEditing
        ? await OfficesService.updateOffice(id as string, office as OfficeCRUD)
        : await OfficesService.createOffice(office as OfficeCRUD)

      setIsDirty(false)
      setTimeout(() => {
        const officeId = officeResponse.data.officeId
        goToOffice(officeId)
      }, 0)
    } catch (error) {
      console.log(error)
      addToast(t('errorGeneric'))
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  const goToOffice = (officeId: string) => {
    navigate(`${ROUTES.office}/${officeId}`)
  }

  const clearErrors = (errorKey: string) => {
    const updatedErrors = _.clone(errors)
    if (errorKey.includes('phone')) {
      updatedErrors.phoneLabel = ''
      updatedErrors.phoneNumber = ''
      updatedErrors.phoneExtension = ''
    } else {
      updatedErrors[errorKey] = ''
    }

    setErrors(updatedErrors)
  }

  if (isLoading) return null

  return (
    <div id="office-form-route">
      <div className="row">
        <div className="col-xs-8 col-md-6 col-lg-5" id="profile-form-column">
          <div id="page-title" className="text-large-semibold">
            {isEditing ? t('offices.edit') : t('offices.createNew')}
          </div>

          <form className="office-form" noValidate onSubmit={handleSubmit}>
            <FormField
              label={t('offices.name')}
              forRef={officeNameRef}
              name="officeName"
              value={officeName}
              error={errors.officeName}
              maxLength={150}
              placeholder={t('required')}
              onChange={(e) => {
                setOfficeName(e.currentTarget.value)
                clearErrors('officeName')
              }}
            />

            <div id="office-form-phone-fields">
              <FormField
                label={t('offices.phoneLabel')}
                name="phoneLabel"
                value={phoneLabel}
                id="office-form-phone-label"
                error={errors.phoneLabel}
                maxLength={30}
                placeholder={t('offices.phoneLabelPlaceholder')}
                onChange={(e) => {
                  setPhoneLabel(e.currentTarget.value)
                  clearErrors('phoneLabel')
                }}
              />

              <FormField
                label={t('phoneNumber')}
                name="phoneNumber"
                value={phoneNumber}
                id="office-form-phone-number"
                maxLength={15}
                error={errors.phoneNumber}
                type="tel"
                onChange={(e) => {
                  setPhoneNumber(e.currentTarget.value)
                  clearErrors('phoneNumber')
                }}
              />

              <FormField
                label={t('phoneExtension')}
                name="phoneExtension"
                value={phoneExtension}
                id="office-form-phone-ext"
                maxLength={10}
                type="number"
                error={errors.phoneExtension}
                onChange={(e) => {
                  setPhoneExtension(e.currentTarget.value)
                  clearErrors('phoneExtension')
                }}
              />
            </div>

            <div id="office-form-timezone" className="form-control">
              <label className="label-default-semibold">{t('timeZone')}</label>
              <Select
                className="react-select"
                classNamePrefix="timezone"
                options={timezonesRef.current}
                value={timezonesRef.current.find((tz) => tz.value === timezone)}
                styles={genericSelectStyles}
                onChange={handleSelectTimezone}
              />
            </div>

            {(config as ConfigType).officePostalCodeRequired && (
              <FormField
                label={t('offices.postalCodeTitle')}
                name="postalCode"
                id="office-form-postal-code"
                value={postalCode}
                maxLength={5}
                type="number"
                error={errors.postalCode}
                placeholder={t('required')}
                onChange={(e) => {
                  setPostalCode(e.currentTarget.value)
                  clearErrors('postalCode')
                }}
              />
            )}

            <FormField
              label={t('offices.info')}
              name="description"
              value={description}
              textarea={true}
              error={errors.description}
              maxLength={4000}
              placeholder={t('offices.additionalInfo')}
              onChange={(e) => setDescription(e.currentTarget.value)}
            />

            <div id="office-form--actions">
              <Button
                id="cancel"
                outline={true}
                style="primary"
                size="xl"
                onClick={goBack}
              >
                {t('cancel')}
              </Button>
              <Button id="save" style="primary" size="xl" type="submit">
                {isEditing ? t('saveChanges') : t('save')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default OfficeForm
