import React, { useContext, useEffect } from 'react'
import { DeeplinkContext } from '@web/js/context/DeeplinkContext'
import Config from '@web/_config'
import LoadingIndicator from '@web/js/components/LoadingIndicator'

const LinkRoute: React.FC = () => {
  const { getDeeplinkActions } = useContext(DeeplinkContext)

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    const isMobileLink = window.location.pathname.includes(
      Config.INVITE_DEEP_LINK_BASE
    )
    const linkBase = isMobileLink
      ? Config.INVITE_DEEP_LINK_BASE
      : Config.INVITE_DEEP_LINK_BASE_DEPRECATED
    const pathname = `${window.location.origin}${linkBase}${window.location.search}`
    getDeeplinkActions(pathname)
  }

  return <LoadingIndicator engage={true} fullPage={true} fadeIn={true} />
}

export default LinkRoute
