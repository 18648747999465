import React, { useState, useEffect, useContext, ReactNode } from 'react'
import './MainLayout.scss'
import { useLocation, matchRoutes } from 'react-router-dom'
import { useResponsive, useIsMobile } from '@farfetch/react-context-responsive'
import { useTranslation } from 'react-i18next'
import { classNames } from '@web/_utils'
import dayjs from 'dayjs'
import _ from 'lodash'
import { AppContext } from '@web/js/context/AppContext'
import VeevaLogo from '@web/common/img/VeevaLogo_Greyscale.svg'
import { ROUTES, I18N } from '@web/_constants'
import Sidebar from '@web/js/components/Sidebar'
import Nav from '@web/js/components/Nav'
import ChatDock from '@web/js/components/ChatDock'
import Header from '@web/js/components/Header'
import DownloadAppBanner from '@web/js/components/DownloadAppBanner'
import { AuthContext } from '@web/js/context/AuthContext'
import { isMobile as isMobileDevice } from '@admin/common/js/IsMobile'

interface IMainLayout {
  children?: ReactNode
}

const MainLayout: React.FC<IMainLayout> = ({ children }) => {
  const location = useLocation()
  const chatRouteMatch = matchRoutes(
    [{ path: ROUTES.chat }, { path: ROUTES.chatChannel }],
    location
  )
  const { mediaType, lessThan } = useResponsive()
  const { isMobile } = useIsMobile()
  const [menuOpen, setMenuOpen] = useState(false)
  const { t } = useTranslation(I18N.namespaces.web)
  const { config } = useContext(AppContext)
  const { authenticated } = useContext(AuthContext)

  useEffect(() => {
    const shouldOpen = !_.includes(['_initial', 'xs', 'sm'], mediaType)
    setMenuOpen(shouldOpen)
  }, [mediaType])

  useEffect(() => {
    if (lessThan.md) setMenuOpen(false)
  }, [location])

  const cx = {
    [mediaType as string]: mediaType !== '_initial',
    xs: lessThan.sm,
    mobile: isMobile,
    banner: authenticated && isMobileDevice,
  }

  const mainLayoutClasses = classNames(cx)

  const toggleMenu = () => {
    if (lessThan.md) setMenuOpen(!menuOpen)
  }

  return (
    <>
      {authenticated && isMobileDevice && <DownloadAppBanner />}

      <main id="main-layout" className={mainLayoutClasses}>
        <Header toggleMenu={toggleMenu} />

        <div className="content">
          <Sidebar open={menuOpen} overlay={lessThan.md}>
            <div id="menu-wrapper">
              <Nav />
              <div id="footer">
                <img id="logo-image" src={VeevaLogo} />
                <div id="privacy-terms">
                  <a href={config?.privacyUrl} target="_blank">
                    {t('privacyPolicy')}
                  </a>
                  <span>
                    {'  '}|{'  '}
                  </span>
                  <a href={config?.termsUrl} target="_blank">
                    {t('termsOfUse')}
                  </a>
                </div>
                <div id="copyright">
                  {t('copyright', { year: dayjs().get('year') })}
                </div>
              </div>
            </div>
          </Sidebar>
          <div id="content-body">{children}</div>
        </div>

        {!chatRouteMatch && !lessThan.md && <ChatDock />}
      </main>
    </>
  )
}

export default MainLayout
