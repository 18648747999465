import { Tuple, configureStore } from '@reduxjs/toolkit'
import reducer from './rootReducer'
import thunk from 'redux-thunk'

import { createLogger } from 'redux-logger'

const logger = createLogger({
  collapsed: true,
})

const store = configureStore({
  reducer,
  middleware: () =>
    process.env.NODE_ENV === 'development'
      ? new Tuple(thunk, logger)
      : new Tuple(thunk),
})

export default store
