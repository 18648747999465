import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'
import { Connection } from '@web/_types'
import { parseDossier } from '@web/_utils'

const apiUrl = Config.API_URL
const apiUrlV2 = Config.API_URL_V2

export const ContactService = {
  connectRep: async (repId: string, source: string): Promise<Connection> => {
    const url = `/reps/${repId}/contact`
    const data = { source }
    const response = await axios({ baseURL: apiUrl, method: 'post', url, data })
    const dossier = parseDossier(response.data)
    return dossier.reps[dossier.repId as string]
  },

  connectFromNotification: async (
    notificationId: string
  ): Promise<Connection> => {
    const response = await axios({
      baseURL: apiUrlV2,
      method: 'post',
      url: '/contacts',
      data: {
        notificationId,
      },
    })
    const dossier = parseDossier(response.data)
    if (dossier.repId) {
      return dossier.reps[dossier.repId as string]
    } else {
      return dossier.hcps[dossier.hcpId as string]
    }
  },

  deleteHcpConnection: (hcpId: string): Promise<AxiosResponse> => {
    const url = `/hcps/${hcpId}/contact`
    return axios({ baseURL: apiUrl, method: 'delete', url })
  },

  deleteRepConnection: (repId: string): Promise<AxiosResponse> => {
    const url = `/reps/${repId}/contact`
    return axios({ baseURL: apiUrl, method: 'delete', url })
  },

  addRepNotes: (repId: string, notes: string): Promise<AxiosResponse> => {
    const url = `/reps/${repId}/notes`
    return axios({ baseURL: apiUrl, method: 'post', url, data: { notes } })
  },
}
