import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'
import './ChatMessages.scss'
import { Message, CachedMessage, HCP, Channel } from '@web/_types'
import { useTranslation } from 'react-i18next'
import { CHANNEL_TYPES, I18N, LOGGING } from '@web/_constants'
import dayjs from 'dayjs'
import _ from 'lodash'
import { Components, Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { Star } from '@mui/icons-material'
import ChatMessage from './ChatMessage'
import TimeDivider from '../TimeDivider'
import Button from '@web/js/components/Button'
import { AuthContext } from '@web/js/context/AuthContext'
import { isHCP, isMessage, isRep, hasProfile } from '@web/_guards'

interface ChatMessagesProps {
  messages: (Message | CachedMessage)[]
  onScrollTop: () => void
  reconnect: (channel: Channel, log?: string) => void
  inDock: boolean
  loadingTop: boolean
  loadingBottom: boolean
  showTyping: boolean
  channel: Channel
}

interface ListContext {
  loadingTop: boolean
  isTestChannel: boolean
  messageCount: number
}

const List: Components['List'] = React.forwardRef((props, ref) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { loadingTop, messageCount, isTestChannel } =
    props.context as ListContext

  return (
    <div className="chat-messages" {...props} ref={ref}>
      {messageCount < 40 && (
        <>
          {isTestChannel && (
            <div className="demo-user text-extra-small center">
              {t('youAreSignedInHCPDemoUser')}
            </div>
          )}

          {loadingTop && (
            <div className="loading text-extra-small center">
              {t('loading')}
            </div>
          )}
        </>
      )}

      {props.children}
    </div>
  )
})

const Scroller: Components['Scroller'] = React.forwardRef((props, ref) => {
  return <div id="chat-scroller" {...props} ref={ref} />
})

const ChatMessages: React.FC<ChatMessagesProps> = ({
  channel,
  inDock,
  loadingBottom,
  loadingTop,
  messages,
  onScrollTop,
  showTyping,
  reconnect,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { user } = useContext(AuthContext)

  const [initIndex, setInitIndex] = useState<number | null>(null)
  const [atBottom, setAtBottom] = useState(true)
  const [atStart, setAtStart] = useState(false)
  const [isTestChannel, setIsTestChannel] = useState(false)

  const prevMessageLenRef = useRef<number>(messages.length)
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const contactChangeRef = useRef(channel.connection?.isContact)

  useEffect(() => {
    if (messages.length) {
      const currentUserLastMessage = _.findLast(messages, (message) => {
        return !!message.user && message.user.id === (user as HCP).id
      })

      setInitIndex(
        !currentUserLastMessage ? null : currentUserLastMessage.index
      )

      if (loadingTop) {
        const goToIndex = messages.length - prevMessageLenRef.current
        virtuosoRef.current?.scrollToIndex({
          index: goToIndex,
          align: 'start',
        })
      }

      prevMessageLenRef.current = messages.length
    }
  }, [messages])

  useEffect(() => {
    if (atStart) {
      onScrollTop()
      setAtStart(false)
    }
  }, [atStart])

  useEffect(() => {
    if (showTyping && atBottom) {
      setTimeout(() => {
        virtuosoRef.current?.scrollBy({
          left: 0,
          top: 100,
          behavior: 'smooth',
        })
      }, 0)
    }
  }, [showTyping])

  useEffect(() => {
    if (loadingBottom || !!initIndex) {
      scrollToBottom()
    }
  }, [loadingBottom, initIndex])

  useEffect(() => {
    setIsTestChannel(channel.isTestChannel)

    if (
      channel.connection &&
      channel.connection.isContact !== contactChangeRef.current
    ) {
      contactChangeRef.current = channel.connection?.isContact
      scrollToBottom()
    }
  }, [channel])

  const scrollToBottom = useCallback(() => {
    if (!messages.length) return

    setTimeout(() => {
      virtuosoRef.current?.scrollToIndex({
        index: messages.length,
        align: 'start',
        offset: 1000,
      })
    }, 0)
  }, [messages])

  const renderTyping = () => {
    if (!channel.connection) return

    return (
      <div className="typing">
        {t('typing', { name: channel.connection.displayName })}
      </div>
    )
  }

  const renderTimeDivider = (date: string) => {
    return <TimeDivider date={date} />
  }

  const renderSignedInHCPDemoUser = () => {
    if (!isTestChannel) return null

    return (
      <div className="demo-user text-extra-small center">
        {t('youAreSignedInHCPDemoUser')}
      </div>
    )
  }

  const renderConnectionInviteSent = () => {
    return (
      <div className="connection-invite-sent center">
        <div>{t('connectionInviteSentTo')}</div>
        <div>
          {channel.connection
            ? channel.connection.displayName
            : channel.partialEmail}
        </div>
        <br />
        <div>{t('chatEnabledWhenAcceptedInvite')}</div>
      </div>
    )
  }

  const renderInactiveChannelStatus = () => {
    if (!channel.connection) return null

    if (isRep(channel.connection) && !hasProfile(channel.connection)) {
      return (
        <div className="no-longer-connected text-small center">
          {t('noLongerConnectedTo', {
            name: channel.connection.displayName,
          })}
        </div>
      )
    }

    return (
      <div className="no-longer-connected text-small center">
        <div>
          {!!channel.connection &&
          isHCP(channel.connection) &&
          !!channel.connection.deleted
            ? t('accountNoLongerExists')
            : t('noLongerConnectedConnectToMsg', {
                name: channel.connection.displayName,
              })}
        </div>

        {!!channel.connection &&
          !(isHCP(channel.connection) && !!channel.connection.deleted) && (
            <Button
              style="warning"
              size="sm"
              outline={true}
              iconFront={<Star />}
              onClick={() =>
                reconnect(
                  channel as Channel,
                  LOGGING.ACTION_TYPE.CHAT_THREAD_CONNECT
                )
              }
            >
              {channel.connection.invitePending
                ? t('inviteSentPlainCap')
                : t('connect')}
            </Button>
          )}
      </div>
    )
  }

  return (
    <>
      {!messages.length && (
        <div id="no-messages">
          {renderSignedInHCPDemoUser()}
          {!channel.active && renderInactiveChannelStatus()}
        </div>
      )}

      {!!messages.length && (
        <Virtuoso
          style={{ height: '100%' }}
          ref={virtuosoRef}
          initialTopMostItemIndex={messages.length}
          overscan={{ main: 300, reverse: 300 }}
          data={messages}
          atTopStateChange={(top) => setAtStart(top)}
          atBottomStateChange={(bottom: boolean) => setAtBottom(bottom)}
          context={{
            loadingTop,
            isTestChannel,
            messageCount: messages.length,
          }}
          components={{
            Scroller,
            List,
            ...((!channel.active ||
              showTyping ||
              channel.type === CHANNEL_TYPES.partial) && {
              Footer: () => {
                return (
                  <>
                    {showTyping && renderTyping()}
                    {!channel.active && renderInactiveChannelStatus()}
                    {channel.type === CHANNEL_TYPES.partial &&
                      renderConnectionInviteSent()}
                  </>
                )
              },
            }),
            ...(messages.length >= 40 &&
              (channel.active || loadingTop) && {
                Header: () => {
                  return (
                    <>
                      {channel.active && renderSignedInHCPDemoUser()}

                      {loadingTop && (
                        <div className="loading text-extra-small center">
                          {t('loading')}
                        </div>
                      )}
                    </>
                  )
                },
              }),
          }}
          itemContent={(index, message: Message | CachedMessage) => {
            const me = !!message.user && message.user.id === user?.id
            let shouldRenderTimeDivider = true

            //time dividers
            const currMessageIndex = _.findIndex(
              messages,
              (m) => m.id === message.id
            )
            const prevMessage = messages[currMessageIndex - 1]
            const currDate = dayjs(message.created)
            const previousDate = prevMessage ? dayjs(prevMessage.created) : null

            if (previousDate) {
              shouldRenderTimeDivider =
                currDate.diff(previousDate, 'minute') > 60
            }

            const messageActionsEnabled =
              isMessage(message) && channel.messageActionsEnabled

            //render message
            return (
              <React.Fragment key={index}>
                {shouldRenderTimeDivider && renderTimeDivider(message.created)}

                <ChatMessage
                  inDock={inDock}
                  initIndex={initIndex as number}
                  me={me}
                  message={message}
                  messageActionsEnabled={messageActionsEnabled}
                />
              </React.Fragment>
            )
          }}
        />
      )}
    </>
  )
}

export default ChatMessages
