import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'
import { LogEvent } from '@web/_types'

const baseURL = Config.OAPI_URL

export const LoggingService = {
  log: (logs: LogEvent[]): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      baseURL,
      url: `/logs`,
      data: {
        logs,
      },
    })
  },
}
