import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const apiUrl = Config.API_URL

export const InvitationsService = {
  getInvitations: (): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, url: '/invites' })
  },

  invite: ({
    email = null,
    hcpId = null,
    inviteMessage = null,
    source = null,
  }: {
    email?: string | null
    hcpId?: string | null
    inviteMessage?: string | null
    source?: string | null
  }): Promise<AxiosResponse> => {
    const data = {
      ...(email && { email }),
      ...(hcpId && { hcpId }),
      ...(inviteMessage && { inviteMessage }),
      ...(source && { source }),
    }

    return axios({
      baseURL: apiUrl,
      method: 'post',
      url: '/invite',
      data,
    })
  },
}
