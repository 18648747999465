import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import _ from 'lodash'
import {
  Message,
  Channel,
  SocketEventData,
  CachedMessage,
  Rep,
} from '@web/_types'
import {
  SOCKET_EVENT_TYPE,
  CHAT,
  I18N,
  CHANNEL_TYPE,
  SOCKET_EVENT,
  LOGGING,
  ERROR,
  ROLES,
  MODALS,
} from '@web/_constants'
import { useTranslation } from 'react-i18next'
import './ChatMessenger.scss'
import { isRep } from '@web/_guards'
import * as DOMPurify from 'dompurify'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ChatForm from '@web/js/components/ChatForm'
import ChatMessages from '@web/js/components/ChatMessages'
import ChatHeader from '@web/js/components/ChatHeader'
import { ChatContext } from '@web/js/context/ChatContext'
import { SocketContext } from '@web/js/context/SocketContext'
import { ContactService, InvitationsService } from '@web/_services'
import { ConnectionContext } from '@web/js/context/ConnectionContext'
import { ToastContext } from '@web/js/context/ToastContext'
import { ModalContext } from '@web/js/context/ModalContext'

type ChatMessengerProps = {
  activeChannel: Channel
  showHeader?: boolean
  inDock: boolean
}

const ChatMessenger: React.FC<ChatMessengerProps> = ({
  activeChannel,
  showHeader = true,
  inDock,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)

  const { chatMessages, getMessages, getChannels } = useContext(ChatContext)
  const { socket } = useContext(SocketContext)
  const { getConnections } = useContext(ConnectionContext)
  const { addToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)

  const [messages, setMessages] = useState<(Message | CachedMessage)[]>([])
  const [showTyping, setShowTyping] = useState(false)
  const [loadingTop, setLoadingTop] = useState(false)
  const [loadingBottom, setLoadingBottom] = useState(false)

  //used to present messages after form is loaded
  //to ensure proper layout/scroll func
  const [formLoaded, setFormLoaded] = useState(false)

  //use refs because of stale state data in closures
  const chatMessagesRef = useRef(chatMessages)
  const activeChannelRef = useRef(activeChannel)

  const socketEventListener = useCallback(
    (event: SocketEventData) => {
      if (
        !document.hidden &&
        event.type === SOCKET_EVENT_TYPE.msg &&
        activeChannelRef.current.id === event.data.channelId
      ) {
        loadNewMessages()
      }

      //typing event on the active channel
      if (
        event.type === SOCKET_EVENT_TYPE.typing &&
        activeChannelRef.current.id === event.data.channelId
      ) {
        const status = event.data.status
        setShowTyping(status)
      }
    },
    [socket]
  )

  useEffect(() => {
    if (socket) socket.on(SOCKET_EVENT.message, socketEventListener)

    return () => {
      if (socket) socket.off(SOCKET_EVENT.message, socketEventListener)
    }
  }, [socketEventListener])

  useEffect(() => {
    if (activeChannel) {
      if (activeChannel.id !== activeChannelRef.current.id) {
        activeChannelRef.current = activeChannel
        setMessages([])
      }

      init(activeChannel)
    }
  }, [activeChannel])

  useEffect(() => {
    chatMessagesRef.current = chatMessages
    if (chatMessages[activeChannelRef.current.id]) {
      setMessages(chatMessages[activeChannelRef.current.id].messages)
    }
  }, [chatMessages])

  useEffect(() => {
    if (!showTyping) return

    const timer = setTimeout(() => {
      setShowTyping(false)
    }, 10000)

    return () => {
      clearTimeout(timer)
    }
  }, [showTyping])

  const init = (activeChannel: Channel) => {
    const messagesCached = !!chatMessagesRef.current[activeChannel.id]

    let unloadedLatestMessages = false
    if (messagesCached && activeChannel.latestMessage) {
      const latestMessageId = activeChannel.latestMessage.id
      const messageIds = _.map(
        chatMessagesRef.current[activeChannel.id].messages,
        'id'
      )
      unloadedLatestMessages = !_.includes(messageIds, latestMessageId)
    }

    const isUnread =
      !!activeChannel.unreadCount || _.isNull(activeChannel.unreadCount)

    if (!messagesCached) {
      //no cached messages for channel - load messages
      loadMessages(undefined, CHAT.desc, isUnread)
    } else if (isUnread || unloadedLatestMessages) {
      //unread and previously cached - get new messages
      loadNewMessages()
    } else {
      //previously cached chat messages - load stored data
      setMessages(chatMessagesRef.current[activeChannel.id].messages)
    }
  }

  const loadNewMessages = () => {
    const messages =
      chatMessagesRef.current[activeChannelRef.current.id].messages
    const lastMessage = _.last(messages)
    const index = lastMessage ? lastMessage.index : undefined
    loadMessages(index, CHAT.asc) //get new messages
  }

  const handleOnScrollTop = async () => {
    if (
      !chatMessagesRef.current[activeChannelRef.current.id] ||
      !messages.length
    )
      return
    const msgs = chatMessagesRef.current[activeChannelRef.current.id].messages
    const index = _.first(msgs)?.index
    await loadMessages(index, CHAT.desc, false)
  }

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const loadMessages = async (
    index: number | undefined = undefined,
    dir = CHAT.desc,
    reloadChannels = true
  ) => {
    const id = activeChannelRef.current.id

    if (
      chatMessagesRef.current[id] &&
      !chatMessagesRef.current[id].next &&
      dir === CHAT.desc
    ) {
      return
    }

    setLoadingTop(dir === CHAT.desc)
    setLoadingBottom(dir === CHAT.asc)

    await timeout(100)
    await getMessages(id, index, CHAT.count, dir)

    if (reloadChannels) getChannels()

    setLoadingTop(false)
    setLoadingBottom(false)
  }

  const renderPingDisclaimer = (connection: Rep) => {
    return (
      <div className="ping-disclaimer">
        <ErrorOutlineOutlinedIcon />
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('pingDisclaimer', {
                name: connection.displayName,
              })
            ),
          }}
        />
      </div>
    )
  }

  const reconnect = async (channel: Channel, log: string) => {
    if (!channel.connection || channel.connection.invitePending) return

    if (isRep(channel.connection)) {
      if (!log) log = LOGGING.CONTACT_SOURCE_TYPE.OTHER
      await ContactService.connectRep(channel.connection.id, log)
      getConnections()
    } else {
      try {
        const inviteResponse = await InvitationsService.invite({
          hcpId: channel.connection.id,
          ...(log && { source: log }),
        })
        const autoConnected = inviteResponse.data.result === ROLES.hcp
        if (!autoConnected) {
          getChannels()
          if (channel.connection)
            showModal({
              name: MODALS.REINVITE_HCP_MODAL,
              data: {
                displayName: channel.connection.displayName,
              },
            })
        }
      } catch (error) {
        const errorResponse = error.response
        const errorData = errorResponse.data

        if (errorData.detail.length) {
          switch (errorData.detail[0].reason) {
            case ERROR.duplicate:
              addToast(t('errorDuplicateInvitation'))
              break
            default:
              addToast(t('errorGeneric'))
          }
        }
      }
    }
  }

  return (
    <div className="chat-messenger">
      {activeChannel && (
        <>
          {showHeader && (
            <ChatHeader
              reconnect={reconnect}
              channel={activeChannel}
              {...(activeChannel.connection && {
                connection: activeChannel.connection,
              })}
              {...(activeChannel.partialEmail && {
                email: activeChannel.partialEmail,
              })}
            />
          )}
          <div id="chat-messages-wrapper">
            {formLoaded && (
              <ChatMessages
                reconnect={reconnect}
                inDock={inDock}
                messages={messages}
                loadingTop={loadingTop}
                loadingBottom={loadingBottom}
                onScrollTop={handleOnScrollTop}
                channel={activeChannel}
                showTyping={showTyping}
              />
            )}
          </div>

          {!!activeChannel.connection &&
            isRep(activeChannel.connection) &&
            activeChannel.connection.channelType === CHANNEL_TYPE.ping &&
            renderPingDisclaimer(activeChannel.connection)}

          <ChatForm
            channel={activeChannel}
            key={activeChannel.id}
            setFormLoaded={setFormLoaded}
          />
        </>
      )}
    </div>
  )
}

export default ChatMessenger
