import React, { useContext, useEffect, useRef, useState } from 'react'
import { I18N, ROUTES } from '@web/_constants'
import './EnterName.scss'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '@web/common/hooks'
import { useNavigate } from 'react-router-dom'
import { LOCALSTORAGE } from '@web/_constants'
import Button from '@web/js/components/Button'
import FormError from '@web/js/components/FormError'
import { AppContext } from '@web/js/context/AppContext'
import FormField from '@web/js/components/FormField'
import { buildName } from '@web/_utils/buildName'

const EnterName: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)
  const [error, setError] = useState('')
  const { countryCode, isSurnameOrdered } = useContext(AppContext)
  const isChina = countryCode?.toLowerCase() === 'cn'

  const [meetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )
  const [meetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )
  const [firstName, setFirstName] = useLocalStorage<string | null>(
    LOCALSTORAGE.firstName,
    null
  )
  const [lastName, setLastName] = useLocalStorage<string | null>(
    LOCALSTORAGE.lastName,
    null
  )
  const [fullName, setFullName] = useLocalStorage<string | null>(
    LOCALSTORAGE.fullName,
    null
  )

  const isPresentation = meetingId && meetingId.length === 6

  const fullNameRef = useRef<HTMLInputElement>(null)
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isChina) {
      fullNameRef.current?.focus()
    } else {
      isSurnameOrdered
        ? lastNameRef.current?.focus()
        : firstNameRef.current?.focus()
    }
  }, [isSurnameOrdered])

  useEffect(() => {
    if (isChina) {
      const autoFilledName = [lastName, firstName]
        .filter((s) => s?.trim())
        .join('')

      if (autoFilledName) setFullName(autoFilledName)
    }
  }, [isChina])

  useEffect(() => {
    if (error) setError('')
  }, [lastName, firstName, fullName])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!firstName && !lastName && !isChina) {
      setError(t('enterFirstAndLastName'))
    } else if (!firstName && !isChina) {
      setError(t('enterFirstName'))
    } else if (!lastName && !isChina) {
      setError(t('enterLastName'))
    } else if (!fullName && isChina) {
      setError(t('enterName'))
    } else {
      let name = ''
      if (isChina) {
        name = fullName as string
      } else {
        name = buildName({
          ...(!!firstName && { firstName }),
          ...(!!lastName && { lastName }),
          isSurnameOrdered,
        })
      }

      if (isPresentation) {
        navigate(`${ROUTES.presentation}?pin=${meetingId}`, {
          state: { verified: true },
        })
      } else {
        //hard load meetings route!!
        window.location.assign(
          `/web${ROUTES.meeting}?m=${meetingId}&p=${meetingPwd}&name=${name}`
        )
      }
    }
  }

  if (!meetingId && !meetingPwd) {
    navigate(ROUTES.join, { state: { redirected: true } })
    return null
  }

  const inputFields = isChina ? (
    <div id="ordered-inputs">
      <FormField
        label={t('fullName')}
        forRef={fullNameRef}
        name="fullName"
        value={fullName ?? ''}
        hasError={!!error && !fullName}
        onChange={(e) => setFullName(e.currentTarget.value)}
      />
    </div>
  ) : (
    <div id="ordered-inputs">
      <FormField
        label={t('firstName')}
        forRef={firstNameRef}
        name="firstName"
        value={firstName ?? ''}
        hasError={!!error && !firstName}
        onChange={(e) => setFirstName(e.currentTarget.value)}
        order={isSurnameOrdered ? 2 : 1}
      />

      <FormField
        label={t('lastName')}
        forRef={lastNameRef}
        name="lastName"
        value={lastName ?? ''}
        hasError={!!error && !lastName}
        onChange={(e) => setLastName(e.currentTarget.value)}
        order={isSurnameOrdered ? 1 : 2}
      />
    </div>
  )

  return (
    <div id="entername-route">
      <form id="name-form" onSubmit={handleSubmit}>
        <div id="registration-label" className="text-headline">
          {isPresentation ? t('registration') : t('meetingRegistration')}
        </div>
        <div id="id-label" className="text-subhead">
          {t('meetingIdWColon')} {meetingId}
        </div>
        {inputFields}
        <FormError text={error} align="center" />
        <Button block={true} size="xl" type="submit">
          {isPresentation ? t('joinNow') : t('joinMeeting')}
        </Button>
      </form>
    </div>
  )
}

export default EnterName
