import React, { useState, useEffect, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import {
  ERROR,
  I18N,
  ROLES,
  MODALS,
  LOGGING,
} from '@web/_constants'
import isEmail from 'validator/lib/isEmail'
import { Invite } from '@web/_types'
import './Invite.scss'
import { ToastContext } from '@web/js/context/ToastContext'
import { ConnectionContext } from '@web/js/context/ConnectionContext'
import { InvitationsService } from '@web/_services'
import Button from '@web/js/components/Button'
import FormField from '@web/js/components/FormField'
import InviteIcon from '@web/common/img/InviteIcon'
import FriendlyDate from '@web/js/components/FriendlyDate'
import { ModalContext } from '@web/js/context/ModalContext'

const Invite: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { addToast } = useContext(ToastContext)
  const { getConnections } = useContext(ConnectionContext)
  const { showModal } = useContext(ModalContext)

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [invites, setInvites] = useState<Invite[]>([])

  const inviteEmailRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inviteEmailRef.current?.focus()
    getInvitations()
  }, [])

  useEffect(() => {
    if (error && !email) setError('')
  }, [email])

  const getInvitations = async () => {
    const getInvitationsResponse = await InvitationsService.getInvitations()
    const invitesData = getInvitationsResponse.data.invites
    setInvites(_.orderBy(invitesData, ['sent'], ['desc']))
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!isEmail(email)) {
      setError(t('errorInvalidEmail'))
      return
    } else {
      setError('')
    }

    try {
      const inviteResponse = await InvitationsService.invite({
        email,
        source: LOGGING.ACTION_TYPE.ENTER_EMAIL,
      })

      const invites = inviteResponse.data.invites
      const autoConnected = _.includes(
        [ROLES.rep, ROLES.hcp],
        inviteResponse.data.result
      )

      if (autoConnected) getConnections()

      let title = autoConnected ? t('connectionCreated') : t('inviteSent')
      if (invites.length > 1) {
        title = t('successExclaim')
      }

      getInvitations()

      showModal({
        name: MODALS.INVITE_SUCCESS_MODAL,
        data: {
          email,
          setEmail,
          title,
          invites,
        },
      })
    } catch (error) {
      const errorResponse = error.response
      const errorData = errorResponse.data

      if (errorData?.detail?.length) {
        switch (errorData.detail[0].reason) {
          case ERROR.invalid:
            setError(t('errorInvalidEmail'))
            break
          case ERROR.self:
            showModal({
              name: MODALS.INVITE_YOURSELF_MODAL,
            })
            break
          case ERROR.crossCompany:
            addToast(t('errorUnableToInviteUser'), 'danger', true, 0, '')
            break
          case ERROR.blockedDomain:
            showModal({
              name: MODALS.INVITE_BLOCKED_DOMAIN_ERROR_MODAL,
            })
            break
          case ERROR.duplicate:
            addToast(t('errorDuplicateInvitation'))
            break
          default:
            addToast(t('errorGeneric'))
        }
      } else {
        addToast(t('errorGeneric'))
      }
    }
  }

  return (
    <div id="invite-route">
      <div className="row gutter-md">
        <div className="col-auto invite-icon">
          <div id="invite-coworkers--icon">
            <InviteIcon />
          </div>
        </div>
        <div className="col-xs-8 col-md-5 col-lg-4">
          <div className="text-headline-border">{t('inviteCoworkers')}</div>
          <p className="text-default">{t('inviteCoworkersDescription')}</p>

          <form className="invite-form" noValidate onSubmit={handleSubmit}>
            <FormField
              label={t('emailAddress')}
              forRef={inviteEmailRef}
              name="email"
              placeholder={t('nameCompany')}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              error={error}
            />

            <Button block={true} style="primary" size="xl" type="submit">
              {t('sendConnectionInvite')}
            </Button>
          </form>

          {!!invites.length && (
            <>
              <div className="text-headline-border">{t('sentInvites')}</div>
              <ul id="invites">
                {invites.map((invite, index) => (
                  <li className="invite" key={index}>
                    <div className="invite--email text-default-semibold">
                      {invite.email || invite.name}
                    </div>
                    <div className="invite--date text-small">
                      {t('inviteSent')} <FriendlyDate date={invite.sent} />
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Invite
