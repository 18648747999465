import React, { useContext, useEffect, useState } from 'react'
import './Login.scss'
import { useTranslation } from 'react-i18next'
import { ROUTES, I18N, LOCALSTORAGE, LOGIN_SOURCE } from '@web/_constants'
import isEmail from 'validator/lib/isEmail'
import { useNavigate, useLocation } from 'react-router-dom'
import { classNames } from '@web/_utils'
import { useLocalStorage } from '@web/common/hooks'
import { AuthContext } from '@web/js/context/AuthContext'
import { ToastContext } from '@web/js/context/ToastContext'
import Button from '@web/js/components/Button'
import FormError from '@web/js/components/FormError'
import { AppContext } from '@web/js/context/AppContext'
import { ConfigType } from '@web/_types'
import { DeeplinkContext } from '@web/js/context/DeeplinkContext'
import InviterCard from '@web/js/components/InviterCard'

export interface LoginHistoryState {
  email?: string
}

export const Login: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const { state } = useLocation()

  const { addToast } = useContext(ToastContext)
  const { hcpVerification, clearSession } = useContext(AuthContext)
  const { config } = useContext(AppContext)
  const { connectionCard, loginEmail } = useContext(DeeplinkContext)

  const [email, setEmail] = useState('')
  const [error, setError] = useState<string>('')

  const [, setLoginSource] = useLocalStorage<string | null>(
    LOCALSTORAGE.loginSource,
    null
  )

  useEffect(() => {
    //ensure cleared session from previous login attempts
    clearSession()

    //state is set if rep enters email and receives a 403
    if (!!state?.email || !!loginEmail) {
      const email = state?.email || loginEmail
      setEmail(email as string)
    }
  }, [])

  useEffect(() => {
    if (error) setError('')
  }, [email])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (isEmail(email)) {
      try {
        setLoginSource(LOGIN_SOURCE.ENTER_EMAIL)
        await hcpVerification(email)
        navigate(`${ROUTES.verify}${window.location.search}`)
      } catch (error) {
        const errorResponse = error.response
        switch (errorResponse.status) {
          case 400:
            setError(t('errorInvalidEmail'))
            break
          case 403:
            //update history state before navigating away
            navigate(ROUTES.login, {
              state: { ...state, email },
              replace: true,
            })
            navigate(ROUTES.loginRep)
            break
          case 429:
            addToast(t('errorTooManyRequests'))
            break
        }
      }
    } else {
      setError(t('errorInvalidEmail'))
    }
  }

  const emailInputClasses = classNames({
    error: !!error,
  })

  if (!(config as ConfigType)?.supportsSignup) {
    navigate(ROUTES.join, { state: { redirected: true } })
    return null
  }

  return (
    <div id="login-route">
      <form className="login-form" onSubmit={handleSubmit} noValidate>
        <label className="text-default-medium center">
          {t('signUpSignIn')}
        </label>
        <input
          className={emailInputClasses}
          type="email"
          value={email}
          placeholder={t('enterYourEmail')}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormError text={error} align="center" />

        <Button block={true} type="submit" size="xl">
          {t('continue')}
        </Button>
      </form>

      {connectionCard && (
        <div className="invited-by">
          <div className="invited-by--title">{t('connectWith')}</div>
          <div className="container">
            <InviterCard inviter={connectionCard} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Login
