import * as React from 'react'
import { IconProps } from '@web/_types'

const CaretIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-caret',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9.02877C4 9.28434 4.09318 9.49404 4.26622 9.66443L11.2512 16.666C11.4775 16.8888 11.7105 17.0002 11.9967 17.0002C12.2829 17.0002 12.5225 16.8954 12.7421 16.666L19.7338 9.66443C19.9068 9.48749 20 9.27779 20 9.02877C20 8.52418 19.5874 8.11133 19.0749 8.11133C18.822 8.11133 18.5824 8.21618 18.396 8.39967L11.9967 14.8311L5.60399 8.39967C5.41764 8.21618 5.17804 8.11133 4.92513 8.11133C4.41265 8.11133 4 8.52418 4 9.02877Z"
      fill={color}
    />
  </svg>
)

export default CaretIcon
