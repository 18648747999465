import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './TimeDivider.scss'
import { I18N } from '@web/_constants'
import * as DOMPurify from 'dompurify'

//DayJs
import dayjs from 'dayjs'

interface TimeDividerProps {
  date: string
}

const TimeDivider: React.FC<TimeDividerProps> = ({
  date = new Date().toString(),
}) => {
  const { t, i18n } = useTranslation(I18N.namespaces.web)
  const [friendlyDate, setFriendlyDate] = useState('')

  useEffect(() => {
    const isToday = dayjs(date).isToday()
    const timeDisplay = dayjs(date).format('LT')

    if (isToday) {
      const dateTimeDisplay =
        '<span class="text-extra-small-bold">' +
        t('days.today') +
        '</span> <span class="text-extra-small">' +
        timeDisplay +
        '</span>'
      setFriendlyDate(dateTimeDisplay)
      return
    }

    const weekAgo = dayjs().subtract(6, 'day').startOf('day').format()
    const isPastWeek = dayjs(date).isSameOrAfter(weekAgo)

    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
    }

    if (!isPastWeek) {
      const sameYear = dayjs().isSame(date, 'year')
      dateOptions.month = 'long'
      dateOptions.day = 'numeric'
      if (!sameYear) dateOptions.year = 'numeric'
    }

    const dateDisplay = new Date(date).toLocaleDateString(
      i18n.language,
      dateOptions
    )

    const dateTimeDisplay =
      '<span class="text-extra-small-bold">' +
      dateDisplay +
      '</span> <span class="text-extra-small">' +
      timeDisplay +
      '</span>'
    setFriendlyDate(dateTimeDisplay)
  }, [date])

  return (
    <div
      className="time-divider center"
      key={date}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(friendlyDate) }}
    />
  )
}

export default TimeDivider
