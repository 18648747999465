import * as React from 'react'
import { IconProps } from '@web/_types'

const InviteIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-connect',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.63796 5.02752C8.11044 5.02752 6.87214 6.28714 6.87214 7.84096C6.87214 9.39478 8.11044 10.6544 9.63796 10.6544C11.1655 10.6544 12.4038 9.39478 12.4038 7.84096C12.4038 6.28714 11.1655 5.02752 9.63796 5.02752ZM5.86202 7.84096C5.86202 5.71965 7.55257 4 9.63796 4C11.7234 4 13.4139 5.71965 13.4139 7.84096C13.4139 9.96226 11.7234 11.6819 9.63796 11.6819C7.55257 11.6819 5.86202 9.96226 5.86202 7.84096ZM19.0418 8.15905C19.3207 8.15905 19.5468 8.38906 19.5468 8.6728V10.6545H21.4949C21.7739 10.6545 22 10.8845 22 11.1683C22 11.452 21.7739 11.682 21.4949 11.682H19.5468V13.6636C19.5468 13.9473 19.3207 14.1774 19.0418 14.1774C18.7628 14.1774 18.5367 13.9473 18.5367 13.6636V11.682H16.5886C16.3097 11.682 16.0835 11.452 16.0835 11.1683C16.0835 10.8845 16.3097 10.6545 16.5886 10.6545H18.5367V8.6728C18.5367 8.38906 18.7628 8.15905 19.0418 8.15905ZM4.10595 15.1067C4.81408 14.3864 5.7745 13.9817 6.77595 13.9817H12.5C13.5014 13.9817 14.4619 14.3864 15.17 15.1067C15.8781 15.827 16.2759 16.804 16.2759 17.8226V19.4862C16.2759 19.77 16.0498 20 15.7709 20C15.4919 20 15.2658 19.77 15.2658 19.4862V17.8226C15.2658 17.0765 14.9744 16.3609 14.4557 15.8332C13.937 15.3056 13.2335 15.0092 12.5 15.0092H6.77595C6.04241 15.0092 5.33891 15.3056 4.82022 15.8332C4.30152 16.3609 4.01013 17.0765 4.01013 17.8226V19.4862C4.01013 19.77 3.784 20 3.50506 20C3.22612 20 3 19.77 3 19.4862V17.8226C3 16.804 3.39782 15.827 4.10595 15.1067Z"
      fill={color}
    />
  </svg>
)

export default InviteIcon
