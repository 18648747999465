import React, { useContext, useEffect, useState } from 'react'
import './Meeting.scss'
import { getQueryStringParams } from '@web/_utils'
import { EngageMeetingProvider } from '@web/js/context/EngageMeetingContext'
import { EngageLoggingProvider } from '@web/js/context/EngageLoggingContext'
import { AuthContext } from '@web/js/context/AuthContext'
import ZoomMeeting from '@web/js/components/ZoomMeeting'
import { LOCALSTORAGE, ROUTES } from '@web/_constants'
import { useLocalStorage } from '@web/common/hooks'
import { EngageService } from '@web/_services/EngageService'
import { OrgService } from '@web/_services/OrgService'
import { EngageAttendeeDetails } from '@web/_types/engageApi'
import { AppContext } from '@web/js/context/AppContext'
import { buildName } from '@web/_utils/buildName'
import LoadingIndicator from '@web/js/components/LoadingIndicator'

const ZoomMeetingRoute: React.FC = () => {
  const queryArgs = getQueryStringParams(window.location.search)
  const meetingId = queryArgs.m
  const password = queryArgs.p
  const name = queryArgs.name
  const { authenticated, user } = useContext(AuthContext)
  const { countryCode, isSurnameOrdered } = useContext(AppContext)

  const [lsFirstName, setLsFirstName] = useLocalStorage<string | null>(
    LOCALSTORAGE.firstName,
    null
  )
  const [lsLastName, setLsLastName] = useLocalStorage<string | null>(
    LOCALSTORAGE.lastName,
    null
  )
  const [lsFullName] = useLocalStorage<string | null>(
    LOCALSTORAGE.fullName,
    null
  )
  const [, setMeetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )
  const [, setMeetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )
  const [, setAccountId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingAccountId,
    null
  )

  const [nameLoading, setNameLoading] = useState<boolean>(!name)
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)
  const [orgRedirect, setOrgRedirect] = useState<boolean>(false)
  let fetchedAttendee: EngageAttendeeDetails

  const firstName = authenticated ? user?.firstName : lsFirstName
  const lastName = authenticated ? user?.lastName : lsLastName

  useEffect(() => {
    if (!(meetingId && password)) {
      setMeetingId(null)
      setMeetingPwd(null)
      //using <Redirect /> causes bootstrap to break our page styling
      window.location.assign(`/web${ROUTES.join}`)
    } else {
      checkOrgRedirectAndName()
    }
  }, [])

  const checkMeetingInfoAndRoute = async () => {
    if (fetchedAttendee.firstName) setLsFirstName(fetchedAttendee.firstName)
    if (fetchedAttendee.lastName) setLsLastName(fetchedAttendee.lastName)
    setAccountId(fetchedAttendee.accountId)
    if (!authenticated) {
      window.location.assign(`/web${ROUTES.name}`)
      setShouldRedirect(true)
      setNameLoading(false)
      return
    }
    setShouldRedirect(false)
    setNameLoading(false)
  }

  const checkOrgRedirect = async (): Promise<boolean> => {
    setMeetingId(meetingId)
    setMeetingPwd(password)
    try {
      const [, , attendee] = await EngageService.getMeetingInfoWithAttendee(
        meetingId,
        password
      )
      fetchedAttendee = attendee
    } catch (error) {
      console.log(error)
      window.location.assign(
        authenticated ? `/web${ROUTES.meetings}` : `/web${ROUTES.join}`
      )
      setShouldRedirect(true)
      setNameLoading(false)
      return true // returning true to prevent redirect in meeting info routing
    }

    try {
      const redirect = await OrgService.getRedirectUrl(
        (fetchedAttendee?.orgId || fetchedAttendee?.tenantId) as string
      )
      if (redirect.data.redirectTo) {
        let redirectRoute = `${redirect.data.redirectTo}/web/meeting?m=${meetingId}&p=${password}`
        if (!/^https?:\/\//i.test(redirectRoute)) {
          redirectRoute = 'https://' + redirectRoute
        }
        setOrgRedirect(true)
        window.location.assign(redirectRoute)
        return true
      }
      return false
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const checkOrgRedirectAndName = async () => {
    // check if org redirect is needed and render nothing if so
    // if no redirect, check if meeting info and route appropriately
    // only checking redirect if no name because it is already checked if user manually inputs params
    if (!name) {
      const redirected = await checkOrgRedirect()
      if (!redirected) {
        checkMeetingInfoAndRoute()
      }
    }
  }

  if (orgRedirect || nameLoading || shouldRedirect) {
    return <LoadingIndicator engage={true} fullPage={true} />
  }

  let displayName: string | null = null
  if (countryCode === 'CN') {
    displayName = lsFullName
  } else if (authenticated && user?.displayName) {
    displayName = user?.displayName
  } else if (!!firstName && !!lastName) {
    displayName = buildName({ firstName, lastName, isSurnameOrdered })
  }

  if (!displayName) {
    window.location.assign(`/web${ROUTES.join}?m=${meetingId}&p=${password}`)
    return null
  }

  return (
    <EngageMeetingProvider
      participantId={meetingId}
      password={password}
      displayName={displayName}
    >
      <EngageLoggingProvider>
        <ZoomMeeting displayName={displayName} />
      </EngageLoggingProvider>
    </EngageMeetingProvider>
  )
}

export default ZoomMeetingRoute
