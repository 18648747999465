import React, { useContext, useRef, useState } from 'react'
import { ERROR, I18N, MODALS, ROUTES, SUPPORT_LINK } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import { ChatContext } from '@web/js/context/ChatContext'
import { Connection, HCP, Message, RecipientEmail } from '@web/_types'
import { useNavigate } from 'react-router-dom'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import RecipientSelector from '@web/js/components/RecipientSelector'
import OptionalMessagePreview from '@web/js/components/OptionalMessagePreview'
import { AuthContext } from '@web/js/context/AuthContext'
import { ChatService } from '@web/_services'

interface SendMessageModallProps {
  shouldRoute: boolean
}

const SendMessageModal: React.FC<SendMessageModallProps> = ({
  shouldRoute,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)

  const { user } = useContext(AuthContext)
  const {
    queueMessage,
    getMessages,
    chatMessages,
    getChannels,
    setOpenMiniChatTo,
  } = useContext(ChatContext)
  const { hideModal, showModal } = useContext(ModalContext)

  const isSendingRef = useRef(false)
  const optionalMessageRef = useRef<HTMLTextAreaElement>(null)

  const [hasMessage, setHasMessage] = useState(false)
  const [recipient, setRecipient] = useState<Connection | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [emailStatus, setEmailStatus] = useState<string | null>(null)
  const [message] = useState<Message>({
    status: 'DELIVERED',
    type: 'BRAND_RESOURCE',
    user: user as HCP,
  } as Message)

  const handleAccept = async () => {
    if (isSendingRef.current) return

    //using ref because setting state is too slow
    isSendingRef.current = true

    let channelId = recipient?.channelId || null
    const body = optionalMessageRef.current?.value.trim()
      ? optionalMessageRef.current?.value.trim()
      : null

    if (!body || (!channelId && !email)) return

    try {
      if (channelId) {
        if (!chatMessages[channelId]) {
          await getMessages(channelId)
        }

        queueMessage({ channelId, body })
      } else if (email) {
        const sendMessageViaEmailResponse =
          await ChatService.sendMessageViaEmail(email, body)
        channelId = sendMessageViaEmailResponse.data.channelId
        await getChannels()
      }
      setTimeout(() => routeToThread(channelId as string), 0)
    } catch (error) {
      hideModal()

      let errorResponse = null
      let name = email

      if (error.response.data.code) errorResponse = error.response.data.code
      if (error.response.data.autoConnectedName)
        name = error.response.data.autoConnectedName

      //if error code exists without name/email present, trigger default error
      if (
        (!!errorResponse && !name) ||
        (errorResponse === ERROR.crossCompany &&
          !error.response.data.autoConnectedName)
      ) {
        errorResponse = null
      }

      let messageError
      switch (errorResponse) {
        case ERROR.disconnected:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorMustBeConnected', {
              name,
            }),
          }
          break
        default:
          messageError = {
            title: t('errorSomethingWentWrongTitle'),
            body: t('errorNotAllowed', {
              support: SUPPORT_LINK,
            }),
          }
          break
      }

      if (messageError) {
        showModal({
          name: MODALS.GENERIC_ERROR_MODAL,
          data: {
            error: messageError,
          },
        })
      }
    } finally {
      isSendingRef.current = false
    }
  }

  const setRecipientEmail = ({ email, emailStatus }: RecipientEmail) => {
    setEmail(email)
    setEmailStatus(emailStatus)
  }

  const clearRecipientEmail = () => {
    setRecipient(null)
    setEmail(null)
    setEmailStatus(null)
  }

  const routeToThread = (channelId: string) => {
    if (shouldRoute) {
      const route = `${ROUTES.chat}/${channelId}`
      navigate(route)
    } else {
      setOpenMiniChatTo(channelId)
    }

    hideModal()
  }

  const selectRecipient = (recipient: Connection) => {
    if (recipient.channelId) {
      routeToThread(recipient.channelId)
    } else {
      setRecipient(recipient)
    }
  }

  const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHasMessage(!!event.target.value.trim())
  }

  const loadUsers = (q: string, signal: AbortSignal) =>
    ChatService.getRecipients(q, signal)

  return (
    <Modal size="lg" title={t('sendMessage')} onClose={hideModal}>
      <ModalBody>
        {!email && !recipient && (
          <RecipientSelector
            loadData={loadUsers}
            setRecipient={selectRecipient}
            setEmail={setRecipientEmail}
          />
        )}

        {!!message && (!!email || !!recipient) && (
          <OptionalMessagePreview
            ref={optionalMessageRef}
            placeholder={t('typeSomething')}
            message={message}
            recipient={recipient}
            email={email}
            emailStatus={emailStatus}
            comment={false}
            clearRecipientData={clearRecipientEmail}
            useViewTextBanner={false}
            onChange={onMessageChange}
          />
        )}
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onCancel={hideModal}
        onCancelText={t('cancel')}
        onAcceptText={t('send')}
        onAccept={handleAccept}
        disabled={(!email && !recipient) || !hasMessage}
      />
    </Modal>
  )
}
export default SendMessageModal
