import * as React from 'react'
import { IconProps } from '@web/_types'

const LeaveIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-location',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.35928 7.59326C1.13952 7.82153 1.13952 8.17791 1.35928 8.40617L1.78224 8.84549L4.22269 11.3805C4.44742 11.6139 4.81178 11.6139 5.03651 11.3805L5.08438 11.3242L5.12427 11.2635C5.25592 11.034 5.22667 10.7327 5.03651 10.5352L3.16713 8.59319L11.4355 8.59263C11.6706 8.59263 11.8611 8.32746 11.8611 8.00037L11.8578 7.92608C11.8315 7.63405 11.6525 7.40811 11.4355 7.40811L3.16592 7.40801L5.03651 5.46484C5.26124 5.23142 5.26124 4.85293 5.03651 4.61951C4.81178 4.38609 4.44742 4.38609 4.22269 4.61951L1.78224 7.15394L1.35928 7.59326ZM8.92708 14.6346C8.58383 14.6346 8.30557 14.9402 8.30557 15.3173C8.30557 15.6943 8.58383 16 8.92708 16H13.3892C14.1444 16 14.852 15.7487 15.3848 15.3097C15.9178 14.8706 16.3056 14.2118 16.3056 13.4267V2.5733C16.3056 1.78816 15.9178 1.12941 15.3848 0.690282C14.852 0.251318 14.1444 0 13.3892 0H11.1582H8.92708C8.58383 0 8.30557 0.305662 8.30557 0.682714C8.30557 1.05977 8.58383 1.36543 8.92708 1.36543H13.3892C13.9015 1.36543 14.3413 1.53733 14.639 1.78263C14.9366 2.02776 15.0625 2.3143 15.0625 2.5733L15.0625 13.4267C15.0625 13.6857 14.9366 13.9722 14.639 14.2174C14.3413 14.4627 13.9015 14.6346 13.3892 14.6346H8.92708Z"
      fill={color}
    />
  </svg>
)

export default LeaveIcon
