import * as React from 'react'
import { IconProps } from '@web/_types'

const ProfileIcon: React.FC<IconProps> = ({
  height = '17px',
  width = '16px',
  color = '#ffffff',
  className = 'icon-profile',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2464 2.56885H2.74963C2.33542 2.56885 1.99963 2.90463 1.99963 3.31885C1.99963 3.73306 2.33542 4.06885 2.74963 4.06885H11.4358L2.2193 13.2853C1.92641 13.5782 1.92641 14.0531 2.2193 14.346C2.5122 14.6389 2.98707 14.6389 3.27996 14.346L12.4964 5.12951V13.8156C12.4964 14.2299 12.8322 14.5656 13.2464 14.5656C13.6606 14.5656 13.9964 14.2299 13.9964 13.8156V3.31885C13.9964 3.11255 13.9131 2.92571 13.7783 2.79011C13.7778 2.78958 13.7773 2.78905 13.7768 2.78852C13.7762 2.78799 13.7757 2.78746 13.7752 2.78693C13.7036 2.71581 13.6213 2.66209 13.5335 2.62576C13.4451 2.58909 13.3481 2.56885 13.2464 2.56885Z"
      fill={color}
    />
  </svg>
)

export default ProfileIcon
