import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const apiUrl = Config.API_URL

export const NotificationsService = {
  getNotifications: (): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, url: '/user/notifications' })
  },

  getNotification: (id: string): Promise<AxiosResponse> => {
    return axios({ baseURL: apiUrl, url: `/user/notifications/${id}` })
  },

  delineConnectionRequest: (notificationId: string): Promise<AxiosResponse> => {
    return axios({
      baseURL: apiUrl,
      method: 'delete',
      url: `/user/notifications/${notificationId}`,
    })
  },
}
