export const formatPhoneNumber = (phoneNum: string): string | null => {
  const cleaned = phoneNum.replace(/\D/g, '')
  if (cleaned.length <= 10 || (cleaned.length == 11 && cleaned.startsWith('1'))) {
    const match = cleaned.match(/^(1|)?(\d{3})?(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      const areaCode = match[2] ? '(' + match[2] + ') ' : ''
      return [intlCode, areaCode, match[3], '-', match[4]].join('')
    }
    return phoneNum
  } else {
    return '+' + cleaned
  }
}
