/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { Office } from '@web/_types'
import { OfficesService } from '@web/_services'
import { AxiosResponse } from 'axios'
import { ToastContext } from '@web/js/context/ToastContext'

interface ConfirmLeaveOfficeModalProps {
  parseOffice: (resonse: AxiosResponse<any>) => void
  office: Office
}

const ConfirmLeaveOfficeModal: React.FC<ConfirmLeaveOfficeModalProps> = ({
  parseOffice,
  office,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { addToast } = useContext(ToastContext)
  const { hideModal } = useContext(ModalContext)

  const leaveOffice = async () => {
    try {
      const leaveOfficeResponse = await OfficesService.leaveOffice(office.id)
      parseOffice(leaveOfficeResponse)
      hideModal()
    } catch (error) {
      console.log(error)
      addToast(t('errorGeneric'))
    }
  }

  return (
    <Modal closeable={true} onClose={hideModal} title={t('offices.leaveTitle')}>
      <ModalBody>
        <p>
          {t('offices.confirmLeave', {
            office: office.name,
          })}
        </p>
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onCancel={hideModal}
        onAcceptText={t('offices.leave')}
        onAccept={leaveOffice}
      />
    </Modal>
  )
}

export default ConfirmLeaveOfficeModal
