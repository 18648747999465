import { CACHE_EVENT } from '@web/_constants'
import { Message, CachedMessage } from '@web/_types'
import _ from 'lodash'

export const isMessage = (
  message: Message | CachedMessage
): message is Message => {
  return (message as Message).status === CACHE_EVENT.delivered
}

export const isCachedMessage = (
  message: Message | CachedMessage
): message is CachedMessage => {
  return (message as CachedMessage).status !== CACHE_EVENT.delivered
}

export const isSent = (
  message: Message | CachedMessage
): message is Message => {
  return _.has(message as Message, 'external')
}
