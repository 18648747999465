import React from 'react'
import { findPhoneNumbersInText, CountryCode } from 'libphonenumber-js/max'
import { LOCALSTORAGE } from '@web/_constants'
import _ from 'lodash'

export const telifyArray = (
  origText: string,
  index: number
): (string | JSX.Element)[] => {
  const countryCode =
    JSON.parse(localStorage.getItem(LOCALSTORAGE.countryCode) as string) || 'US'
  const getPhoneNum = findPhoneNumbersInText(
    origText,
    countryCode as CountryCode
  )

  if (getPhoneNum.length) {
    const getPhoneNumOrdered = _.orderBy(getPhoneNum, 'startsAt')
    const arr = []
    const len = origText.length

    for (let i = 0; i < getPhoneNumOrdered.length; i++) {
      const currNumber = getPhoneNumOrdered[i]

      //grab text in front of phone number
      if (currNumber.startsAt > 0) {
        const startAt = i === 0 ? 0 : getPhoneNumOrdered[i - 1].endsAt
        arr.push(origText.substring(startAt, currNumber.startsAt))
      }

      //add phone number
      arr.push(
        React.createElement(
          'a',
          { href: `tel:${currNumber.number.number}`, key: `tel_${index}_${i}` },
          origText.substring(currNumber.startsAt, currNumber.endsAt)
        )
      )

      //grab text after last phone number
      if (i === getPhoneNumOrdered.length - 1 && currNumber.endsAt !== len) {
        arr.push(origText.substring(currNumber.endsAt, len))
      }
    }

    return arr
  } else {
    return [origText]
  }
}

export const formatText = (body = ''): JSX.Element[] => {
  body = body.replace(
    // eslint-disable-next-line no-control-regex
    new RegExp('\r?\n', 'g'),
    '<br>'
  )

  const bodyArr = _.chain(body.split('<br>'))
    .map((b: string, i) => {
      const brEl = '<br>'
      return b ? [...telifyArray(b, i), brEl] : [brEl]
    })
    .flatten()
    .map((b: string, i) => {
      if (b === '<br>') {
        return React.createElement('br', { key: i })
      } else if (typeof b === 'string') {
        return React.createElement('span', { key: i, children: b })
      } else {
        return b
      }
    })
    .value()

  return bodyArr
}
