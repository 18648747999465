/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { COLORS } from '@web/_constants'

export const genericSelectStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided: Record<string, unknown>, state: Record<string, any>) => {
    const background = state.isDisabled ? COLORS.grayLight : COLORS.white

    return {
      ...provided,
      borderRadius: '10px',
      borderColor: COLORS.gray,
      minHeight: 44,
      background,
      '&:hover': {
        borderColor: COLORS.gray,
      },
      ...(state.isFocused && {
        boxShadow: `0px 0px 3px 1px ${COLORS.blue}`,
        borderColor: COLORS.blue,
        '&:hover': {
          borderColor: COLORS.blue,
        },
      }),
    }
  },
  dropdownIndicator: (
    provided: Record<string, unknown>,
    state: Record<string, any>
  ) => {
    const transform = state.selectProps.menuIsOpen
      ? 'rotate(180deg)'
      : 'rotate(0deg)'
    return { ...provided, transform, color: COLORS.gray }
  },
  input: (provided: Record<string, unknown>) => ({
    ...provided,
    fontSize: '17px',
  }),
  singleValue: (provided: Record<string, unknown>) => {
    return {
      ...provided,
      fontSize: '17px',
      margin: 0,
      color: COLORS.black,
    }
  },
  placeholder: (provided: Record<string, unknown>) => ({
    ...provided,
    fontSize: '17px',
  }),
  valueContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    padding: '4px 16px',
  }),
}

export const timeSelectStyles = {
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'max-content',
    minWidth: '60px',
  }),
  control: (provided: Record<string, unknown>, state: Record<string, any>) => {
    const background = state.isDisabled ? COLORS.grayLight : 'transparent'

    return {
      ...provided,
      border: 'none',
      height: 34,
      minHeight: 34,
      background,
      '&:hover': {
        background: COLORS.grayLighter,
      },
      ...(state.isFocused && {
        boxShadow: `0px 0px 3px 1px ${COLORS.blue}`,
        background: COLORS.lightBlue,
        borderColor: COLORS.blue,
        '&:hover': {
          borderColor: COLORS.blue,
        },
      }),
      ...(state.isDisabled && {
        background: 'transparent',
      }),
    }
  },
  input: (provided: Record<string, unknown>) => ({
    ...provided,
    fontSize: '16px',
    height: 26,
    lineHeight: '26px',
    margin: '0 0 0 2px',
    padding: 0,
  }),
  option: (provided: Record<string, unknown>) => ({
    ...provided,
    padding: '0 4px',
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'center',
  }),
  singleValue: (provided: Record<string, unknown>) => {
    return {
      ...provided,
      fontSize: '16px',
      margin: 0,
      width: '100%',
      color: COLORS.black,
      height: 26,
      lineHeight: '26px',
    }
  },
  valueContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    padding: '4px',
    alignItems: 'center',
    width: 34,
    height: 34,
  }),
}
