/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useContext, useRef } from 'react'
import { I18N, SUPPORT_EMAIL, MARKETING_SITE_URL } from '@web/_constants'
import { classNames, getQueryStringParams, isIOSSafari } from '@web/_utils'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import './Content.scss'
import dayjs from 'dayjs'
import { AppContext } from '@web/js/context/AppContext'
import EngageLogo from '@web/common/img/Engage_Logo.svg'
import Button from '@web/js/components/Button'
import Config from '@web/_config'
import { AuthContext, AuthProvider } from '@web/js/context/AuthContext'
import { useResponsive } from '@farfetch/react-context-responsive'

type QueryParams = Record<string, string>

const ContentRoute: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { lessThan } = useResponsive()
  const { config } = useContext(AppContext)
  const { authenticated } = useContext(AuthContext)
  const [src, setSrc] = useState<string>('')
  const [isLoading, setIsLoading] = useState(true)
  const [useLegacyPlayer, setUseLeagacyPlayer] = useState(false)

  const iFrameRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    init()

    //only applies for mobile Safari (?)
    const handleVeevaDownloadMessage = (message: any) => {
      let data
      try {
        data = JSON.parse(message.data)
      } catch {
        data = message.data
      }

      if (data.type === 'veevaDownload') {
        location.href = data.url
      }
    }

    if (isIOSSafari(window.navigator.userAgent)) {
      window.addEventListener('message', handleVeevaDownloadMessage)
    }

    return () => {
      window.removeEventListener('message', handleVeevaDownloadMessage)
    }
  }, [])

  const focusIFrame = () => {
    if (iFrameRef.current) iFrameRef.current.focus()
  }

  const init = () => {
    const queryParams = getQueryStringParams(window.location.search)

    if (queryParams.externalName || queryParams.docName) {
      document.title = queryParams.externalName || queryParams.docName
    }

    const queryParamsKeys = _.keys(queryParams)
    const legacyPlayer = _.includes(queryParamsKeys, 'dns')
    const src = formatUrl(queryParams, legacyPlayer)
    setUseLeagacyPlayer(legacyPlayer)
    setSrc(src)
    setIsLoading(false)
  }

  const formatUrl = (queryParams: QueryParams, legacyPlayer: boolean) => {
    if (legacyPlayer) {
      let src = `https://${queryParams.dns}/ui/approved_viewer?api=true`
      const queryParamKeys = _.keys(queryParams)
      _.each(queryParamKeys, (key) => {
        if (key === 'dns') return
        src += `&${key}=${encodeURIComponent(queryParams[key])}`
      })

      if (!queryParams.error) {
        src +=
          '&error=' + encodeURIComponent(t('errorDocNotAvailable') as string)
      }
      return src
    } else {
      const apiHost = config?.engageApiHost
        ? config.engageApiHost
        : Config.ENGAGE_API_BASE

      return (
        apiHost +
        '/api/v1/engage/engage-admin/content-player' +
        window.location.search
      )
    }
  }

  const contentRouteClasses = classNames({
    condensed: lessThan.md,
  })

  if (isLoading) return null

  return (
    <AuthProvider>
      <div id="content-route" className={contentRouteClasses}>
        {useLegacyPlayer && (
          <div id="content-route--header">
            <div id="logo-wrapper">
              <img
                onClick={() => window.open(Config.BASE_URL, '_blank')}
                id="engage-logo"
                src={EngageLogo}
              />
            </div>
            <div id="actions">
              {!authenticated && !lessThan.md && (
                <Button
                  outline={true}
                  onClick={() => window.open(Config.BASE_URL, '_blank')}
                >
                  {t(['signIn'])}
                </Button>
              )}
              <Button
                size={lessThan.md ? 'sm' : 'lg'}
                id="about-engage"
                onClick={() => window.open(MARKETING_SITE_URL, '_blank')}
              >
                {t('aboutEngage')}
              </Button>
            </div>
          </div>
        )}

        {!!src && (
          <iframe
            allow="fullscreen"
            src={src}
            scrolling="no"
            ref={iFrameRef}
            onLoad={focusIFrame}
          />
        )}

        {useLegacyPlayer && (
          <div id="content-route--footer">
            <div id="copyright">
              {t('copyright', { year: dayjs().get('year') })}
            </div>
            <div id="links">
              <a href={config?.privacyUrl} target="_blank">
                {t('privacyPolicy')}
              </a>
              <a href={config?.termsUrl} target="_blank">
                {t('termsOfUse')}
              </a>
              <a href={`mailto:${SUPPORT_EMAIL}`}>{t('help')}</a>
            </div>
          </div>
        )}
      </div>
    </AuthProvider>
  )
}

export default ContentRoute
