import * as React from 'react'
import { IconProps } from '@web/_types'

const JoinIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-join',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6963 7.59326C11.9161 7.82153 11.9161 8.17791 11.6963 8.40617L11.2733 8.84549L8.83289 11.3805C8.60816 11.6139 8.2438 11.6139 8.01907 11.3805L7.9712 11.3242L7.93131 11.2635C7.79966 11.034 7.82891 10.7327 8.01907 10.5352L9.88845 8.59319L1.62011 8.59263C1.38501 8.59263 1.19446 8.32746 1.19446 8.00037L1.19778 7.92608C1.22404 7.63405 1.40309 7.40811 1.62011 7.40811L9.88966 7.40801L8.01907 5.46484C7.79434 5.23142 7.79434 4.85293 8.01907 4.61951C8.2438 4.38609 8.60816 4.38609 8.83289 4.61951L11.2733 7.15394L11.6963 7.59326ZM8.92708 14.6346C8.58383 14.6346 8.30557 14.9402 8.30557 15.3173C8.30557 15.6943 8.58383 16 8.92708 16H13.3892C14.1444 16 14.852 15.7487 15.3848 15.3097C15.9178 14.8706 16.3056 14.2118 16.3056 13.4267V2.5733C16.3056 1.78816 15.9178 1.12941 15.3848 0.690283C14.852 0.251319 14.1444 0 13.3892 0H11.1582H8.92708C8.58383 0 8.30557 0.305661 8.30557 0.682714C8.30557 1.05976 8.58383 1.36543 8.92708 1.36543H13.3892C13.9015 1.36543 14.3413 1.53733 14.639 1.78263C14.9366 2.02776 15.0625 2.3143 15.0625 2.5733L15.0625 13.4267C15.0625 13.6857 14.9366 13.9722 14.639 14.2174C14.3413 14.4627 13.9015 14.6346 13.3892 14.6346H8.92708Z"
      fill={color}
    />
  </svg>
)

export default JoinIcon
