import React, { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AuthContext } from '@web/js/context/AuthContext'
import AuthenticationLayout from '@web/js/layouts/AuthenticationLayout'
import { ROUTES } from '@web/_constants'
import ModalRoot from '@web/js/modals/ModalRoot'

interface UnauthenticatedRouteProps {
  logoClickRedirect?: string
  hideConnectionLabel?: boolean
  autoRedirect?: boolean
}

const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = ({
  logoClickRedirect = null,
  hideConnectionLabel = false,
  autoRedirect = true,
}) => {
  const { authenticated } = useContext(AuthContext)
  const navigate = useNavigate()

  if (authenticated && autoRedirect) {
    navigate(ROUTES.home, { state: { redirected: true } })
    return null
  }

  return (
    <>
      <ModalRoot />
      <AuthenticationLayout
        logoClickRedirect={logoClickRedirect}
        hideConnectionLabel={hideConnectionLabel}
      >
        <Outlet />
      </AuthenticationLayout>
    </>
  )
}

export default UnauthenticatedRoute
