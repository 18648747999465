import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import { AuthContext } from '@web/js/context/AuthContext'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'

const DeletedAccountModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { setShowAccountDeleted } = useContext(AuthContext)
  const { hideModal } = useContext(ModalContext)

  const hideAccountDeleted = () => {
    setShowAccountDeleted(false)
    hideModal()
  }

  return (
    <Modal
      title={t('deleteAccount.accountDeletedTitle')}
      closeable={true}
      onClose={hideAccountDeleted}
    >
      <ModalBody>
        <p>{t('deleteAccount.accountDeletedDescription')}</p>
      </ModalBody>
      <ModalFooter onAcceptText={t('ok')} onAccept={hideAccountDeleted} />
    </Modal>
  )
}

export default DeletedAccountModal
