import React, { useContext, useEffect, useState, useRef } from 'react'
import './DataConsent.scss'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '@web/common/hooks'
import { LOCALSTORAGE, ROUTES, I18N, ERROR } from '@web/_constants'
import { useNavigate } from 'react-router-dom'
import { AuthService } from '@web/_services'
import * as DOMPurify from 'dompurify'
import { ToastContext } from '@web/js/context/ToastContext'
import { AuthContext } from '@web/js/context/AuthContext'
import { DeeplinkContext } from '@web/js/context/DeeplinkContext'
import { AppContext } from '@web/js/context/AppContext'
import Button from '@web/js/components/Button'
import FormError from '@web/js/components/FormError'
import Checkbox from '@web/js/components/Checkbox'
import { buildName } from '@web/_utils/buildName'

interface ConsentData {
  label?: string
  message?: string
  title?: string
}

const DataConsent: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)

  const [error, setError] = useState<string>('')
  const [dataConsent, setDataConsent] = useState<boolean>(false)
  const [consentText, setConsentText] = useState<ConsentData>({})

  const { isSurnameOrdered } = useContext(AppContext)
  const { addToast } = useContext(ToastContext)
  const { clientSecret, email, signup, clearSession } = useContext(AuthContext)
  const { actionableDeeplink } = useContext(DeeplinkContext)

  const checkboxRef = useRef<HTMLInputElement>(null)

  const [, setShowWelcome] = useLocalStorage<boolean | null>(
    LOCALSTORAGE.welcome,
    null
  )

  const [, setIsInitialSessionManual] = useLocalStorage<boolean | null>(
    LOCALSTORAGE.isInitialSessionManual,
    null
  )

  const [firstName, setFirstName] = useLocalStorage<string | null>(
    LOCALSTORAGE.firstName,
    null
  )

  const [lastName, setLastName] = useLocalStorage<string | null>(
    LOCALSTORAGE.lastName,
    null
  )

  const [meetingId] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingId,
    null
  )

  const [meetingPwd] = useLocalStorage<string | null>(
    LOCALSTORAGE.meetingPwd,
    null
  )

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      const dataConsentResponse = await AuthService.dataConsent()
      setConsentText(dataConsentResponse.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    try {
      setShowWelcome(true)
      await signup(firstName ?? '', lastName ?? '', dataConsent)
      setFirstName(null)
      setLastName(null)

      if (meetingId && meetingPwd) {
        navigate(ROUTES.meetings)
      } else if (!actionableDeeplink) {
        setIsInitialSessionManual(true)
        navigate(ROUTES.home)
      }
    } catch (error) {
      const errorResponse = error.response
      const errorData = errorResponse.data

      switch (errorResponse.status) {
        case 400:
          setError(t('errorAllFieldsRequired'))
          break
        case 401:
          addToast(t('errorGeneric'))
          clearSession()
          break
        case 403:
          if (errorData.code === ERROR.dataConsentRequired) {
            setError(errorData.translations.msg)
          } else if (!errorData.invertedInviteDisplayName) {
            addToast(t('errorEmailInUse'))
          }
          break
      }
    }
  }

  const handleOnConsent = () => {
    if (checkboxRef.current) setDataConsent(checkboxRef.current.checked)
  }

  if (!email && !clientSecret) {
    navigate(ROUTES.login, { state: { redirected: true } })
    return null
  }

  return (
    <div id="data-consent-route">
      <form className="data-consent-form" noValidate onSubmit={handleSubmit}>
        <div id="data-consent-form--title" className="text-headline">
          {buildName({
            firstName: firstName || '',
            lastName: lastName || '',
            isSurnameOrdered,
          })}
          <span>{email}</span>
        </div>

        <div className="text-headline">{consentText.title}</div>

        {consentText.message && (
          <div
            className="consent-message"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(consentText.message, {
                ADD_ATTR: ['target'],
              }),
            }}
          />
        )}

        <Checkbox
          forRef={checkboxRef}
          padded={true}
          defaultChecked={false}
          value="consent"
          onChange={handleOnConsent}
        >
          {consentText.label}
        </Checkbox>

        <FormError text={error} align="center" />

        <Button block={true} style="primary" size="xl" type="submit">
          {t('completeRegistration')}
        </Button>
      </form>
    </div>
  )
}

export default DataConsent
