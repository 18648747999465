/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModalRootProps } from '@web/_types'
import React, { createContext, useState, useMemo, ReactNode } from 'react'

interface IModalContext {
  showModal: (data: ModalRootProps) => void
  hideModal: () => void
  modalProps: ModalRootProps | null
}

interface IModalProvider {
  children?: ReactNode
}

export const ModalContext = createContext<IModalContext>({
  hideModal: () => null,
  showModal: () => null,
  modalProps: null,
})

export const ModalProvider: React.FC<IModalProvider> = ({ children }) => {
  const [modalProps, setModalProps] = useState<ModalRootProps | null>(null)

  const showModal = (data: ModalRootProps) => {
    if (data) setModalProps(data)
  }

  const hideModal = () => {
    setModalProps(null)
  }

  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
      modalProps,
    }),
    [modalProps]
  )

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  )
}
