import {
  Meeting,
  GroupedMeetings,
  NotificationRequest,
  ScheduleAdvDetails,
  RemoteCLMSession,
} from '@web/_types'
import { DAYS } from '@web/_constants'
import dayjs from 'dayjs'
import _ from 'lodash'
import { isMeeting, isRemoteCLMSession } from '@web/_guards'

export const sortGroupedMeetingsKeys = (
  groupedMeetings: GroupedMeetings,
  asc = true
): string[] => {
  const groupedMeetingKeys = _.keys(groupedMeetings)
  const hasToday = _.includes(groupedMeetingKeys, DAYS.today)
  const hasTomorrow = _.includes(groupedMeetingKeys, DAYS.tomorrow)

  const filteredMeetingKeys = _.without(
    _.keys(groupedMeetings),
    DAYS.today,
    DAYS.tomorrow
  )

  const orderedFilteredMeetingKeys = _.orderBy(
    filteredMeetingKeys,
    (mtgKey: string) => {
      return dayjs(mtgKey)
    },
    [asc ? 'asc' : 'desc']
  )

  const orderedMeetingKeys = [
    ...(hasToday ? [DAYS.today] : []),
    ...(hasTomorrow ? [DAYS.tomorrow] : []),
    ...orderedFilteredMeetingKeys,
  ]

  return orderedMeetingKeys
}

export const getNotificationStartTime = (
  notification: NotificationRequest
): string => {
  if (notification.scheduleAdvDetails) {
    return notification.scheduleAdvDetails.dateTime
  } else {
    return (notification.scheduleCrmDetails as ScheduleAdvDetails).dateTime
  }
}

export const groupMeetings = (
  meetings: (Meeting | NotificationRequest | RemoteCLMSession)[]
): GroupedMeetings => {
  const groupedMeetings = _.groupBy(meetings, (meeting) => {
    const meetingDate =
      isMeeting(meeting) || isRemoteCLMSession(meeting)
        ? meeting.start
        : getNotificationStartTime(meeting)

    const isToday = dayjs(meetingDate).isToday()
    const isTomorrow = dayjs(meetingDate).isTomorrow()
    if (isToday) {
      return DAYS.today
    } else if (isTomorrow) {
      return DAYS.tomorrow
    }

    return dayjs(meetingDate).startOf('month').format()
  })

  return groupedMeetings
}
