import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Config from '@web/_config'
import { Flush, LogEvent } from '@web/_types'

interface LoggerState {
  flush: Flush
  logs: LogEvent[]
}

// Define the initial state using that type
const initialState: LoggerState = {
  flush: {
    count: Config.DEFAULT_CONFIG.androidLogFlushCount,
    delay: Config.DEFAULT_CONFIG.androidLogFlushDelay,
  },
  logs: [],
}

export const loggerSlice = createSlice({
  name: 'logger',

  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setFlush: (state, action: PayloadAction<Flush>) => {
      state.flush = action.payload
    },
    addLog: (state, action: PayloadAction<LogEvent>) => {
      state.logs = [...state.logs, action.payload]
    },
    clearLogs: (state) => {
      state.logs = []
    },
  },
})

export const { setFlush, addLog, clearLogs } = loggerSlice.actions

export default loggerSlice.reducer
