import Config from '@web/_config'
import { ENGAGE, LOCALSTORAGE, ROUTES } from '@web/_constants'

export type ZoomInfo = {
  meetingNumber: number
  password: string | undefined
  userName: string
  userEmail: string | undefined
  signature: string
  sdkKey: string
  participantId: string | undefined
}

const zoomInitDefaultSettings = {
  debug: true,
  leaveUrl: '',
  showMeetingHeader: true,
  disableInvite: true,
  disableRecord: true,
  disableCallOut: false,
  disableJoinAudio: false,
  audioPanelAlwaysOpen: true,
  showPureSharingContent: true,
  isLockBottom: false,
  isSupportAV: true,
  isSupportChat: true,
  screenShare: false,
  videoDrag: false,
  sharingMode: 'fit',
  videoHeader: false,
  isShowJoiningErrorDialog: false,
  inviteUrlFormat: '',
  meetingInfo: [],
}

const zoomSupportedLanguages = [
  ENGAGE.NAME.LANGUAGE.DUTCH,
  ENGAGE.NAME.LANGUAGE.ENGLISH,
  ENGAGE.NAME.LANGUAGE.FRENCH,
  ENGAGE.NAME.LANGUAGE.GERMAN,
  ENGAGE.NAME.LANGUAGE.JAPANESE,
  ENGAGE.NAME.LANGUAGE.INDONESIAN,
  ENGAGE.NAME.LANGUAGE.ITALIAN,
  ENGAGE.NAME.LANGUAGE.KOREAN,
  ENGAGE.NAME.LANGUAGE.POLISH,
  ENGAGE.NAME.LANGUAGE.PORTUGUESE,
  ENGAGE.NAME.LANGUAGE.RUSSIAN,
  ENGAGE.NAME.LANGUAGE.SPANISH,
  ENGAGE.NAME.LANGUAGE.TURKISH,
  ENGAGE.NAME.LANGUAGE.VIETNAMESE,
  ENGAGE.NAME.LANGUAGE.CHINESE,
  ENGAGE.NAME.LANGUAGE.TRADITIONAL_CHINESE,
]
const languageUpMapper: Record<string, string> = {
  nl: ENGAGE.NAME.LANGUAGE.DUTCH,
  de: ENGAGE.NAME.LANGUAGE.GERMAN,
  en: ENGAGE.NAME.LANGUAGE.ENGLISH,
  es: ENGAGE.NAME.LANGUAGE.SPANISH,
  fr: ENGAGE.NAME.LANGUAGE.FRENCH,
  id: ENGAGE.NAME.LANGUAGE.INDONESIAN,
  it: ENGAGE.NAME.LANGUAGE.ITALIAN,
  ja: ENGAGE.NAME.LANGUAGE.JAPANESE,
  ko: ENGAGE.NAME.LANGUAGE.KOREAN,
  pl: ENGAGE.NAME.LANGUAGE.POLISH,
  pt: ENGAGE.NAME.LANGUAGE.PORTUGUESE,
  ru: ENGAGE.NAME.LANGUAGE.RUSSIAN,
  tr: ENGAGE.NAME.LANGUAGE.TURKISH,
  vi: ENGAGE.NAME.LANGUAGE.VIETNAMESE,
  zh: ENGAGE.NAME.LANGUAGE.CHINESE,
}

let zoomServiceInitialized = false

export const ZoomService = {
  init: async (
    leaveUrl: string,
    screenShareEnabled: boolean
  ): Promise<typeof ZoomMtg> => {
    const { ZoomMtg } = await import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "zoom-websdk" */
      '@zoom/meetingsdk'
    )

    console.log('WebSDK Version:', ZoomMtg.getWebSDKVersion({}))

    if (!zoomServiceInitialized) {
      zoomServiceInitialized = true
      ZoomMtg.preLoadWasm()
      ZoomMtg.prepareWebSDK()

      // Handle Translations
      const i18n = ZoomMtg.i18n
      let language = ZoomService.getZoomLanguage()
      // if the web client is built in production, disable localisation test strings for the zoom client.
      if (!Config.USE_TEST_LOCALE && language === Config.TEST_LOCALE) {
        language = language.split('-')[0]
      }

      console.log('Zoom language:', language)

      let formattedLanguageCode = languageUpMapper[language] || language
      const isZoomSupportedLang = zoomSupportedLanguages.includes(
        formattedLanguageCode
      )
      const isVeevaSupportedLang =
        ENGAGE.VEEVA_ADDL_SUPPORTED_LANGUAGES.includes(formattedLanguageCode)
      let isZoomSupportedLangTruncated = false
      let isVeevaSupportedLangTruncated = false
      let truncatedLangCode = ''

      //check for short Zoom/Veeva matches
      if (
        !isZoomSupportedLang &&
        !isVeevaSupportedLang &&
        formattedLanguageCode.includes('-')
      ) {
        truncatedLangCode = formattedLanguageCode.split('-')[0]
        isZoomSupportedLangTruncated = !!languageUpMapper[truncatedLangCode]
        isVeevaSupportedLangTruncated =
          ENGAGE.VEEVA_ADDL_SUPPORTED_LANGUAGES.includes(truncatedLangCode)

        if (isZoomSupportedLangTruncated) {
          formattedLanguageCode = languageUpMapper[truncatedLangCode]
        } else if (isVeevaSupportedLangTruncated) {
          formattedLanguageCode = truncatedLangCode
        }
      }

      console.log('formatted language code:', formattedLanguageCode)

      if (isZoomSupportedLang || isZoomSupportedLangTruncated) {
        const log = `loading Zoom language${
          isZoomSupportedLangTruncated ? ' (truncated): ' : ': '
        }`

        console.log(
          log,
          isZoomSupportedLangTruncated
            ? language +
                ' -> ' +
                truncatedLangCode +
                ' -> ' +
                formattedLanguageCode
            : language !== formattedLanguageCode
            ? language + ' -> ' + formattedLanguageCode
            : formattedLanguageCode
        )

        // Fetch for translation files delivered by Zoom out-of-the-box
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        i18n.load(formattedLanguageCode as any)

        // Override
        i18n.load(
          `${Config.STATIC_BASE}zoom-dependencies/zoomi18n/overrides/${formattedLanguageCode}.json`,
          formattedLanguageCode
        )
      } else if (isVeevaSupportedLang || isVeevaSupportedLangTruncated) {
        const log = `loading Veeva language${
          isVeevaSupportedLangTruncated ? ' (truncated): ' : ': '
        }`
        // If this language is not supported by Zoom, go fetch from our translation files
        console.log(
          log,
          isVeevaSupportedLangTruncated
            ? language + ' -> ' + formattedLanguageCode
            : formattedLanguageCode
        )

        i18n.load(
          `${Config.STATIC_BASE}zoom-dependencies/zoomi18n/${formattedLanguageCode}.json`,
          formattedLanguageCode
        )
      } else {
        console.log('language not supported', formattedLanguageCode)
      }
    }

    const countryCode = localStorage.getItem(LOCALSTORAGE.countryCode)

    const zoomInitSettings = Object.assign({}, zoomInitDefaultSettings, {
      leaveUrl,
      screenShare: screenShareEnabled,
      externalLinkPage: `/web${ROUTES.redirect}`,
      ...((!countryCode || countryCode === 'CN') && {
        webEndpoint: 'zoom.com.cn',
      }),
    })

    await ZoomMtg.init(zoomInitSettings)
    return ZoomMtg
  },

  getZoomMtg: async (): Promise<typeof ZoomMtg> => {
    const { ZoomMtg } = await import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "zoom-websdk" */
      '@zoom/meetingsdk'
    )
    return await ZoomMtg
  },

  getUserId: async (): Promise<string> => {
    const { ZoomMtg } = await import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "zoom-websdk" */
      '@zoom/meetingsdk'
    )
    return await new Promise<string>((resolve, reject) => {
      ZoomMtg.getCurrentUser({
        success: (resp: { result: { currentUser: { userId: string } } }) =>
          resolve(resp.result.currentUser.userId),
        error: reject,
      })
    })
  },

  getInstanceId: async (): Promise<string> => {
    const { ZoomMtg } = await import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "zoom-websdk" */
      '@zoom/meetingsdk'
    )
    return await new Promise<string>((resolve, reject) => {
      ZoomMtg.getCurrentMeetingInfo({
        success: (resp: { result: { mid: string } }) =>
          resolve(resp.result.mid),
        error: reject,
      })
    })
  },

  getZoomLanguage: (): string => {
    const language = navigator.language
    let appLanguage = language.replace('_', '-')
    const parts = appLanguage.split('-')
    if (parts.length === 2) {
      appLanguage = parts[0] + '-' + parts[1].toUpperCase()
    }
    return appLanguage
  },
}
