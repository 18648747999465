import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import { AuthContext } from '@web/js/context/AuthContext'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ToastContext } from '@web/js/context/ToastContext'
import './ConfirmAccountDeleteModal.scss'

const ConfirmAccountDeleteModal: React.FC = () => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { deleteAccount } = useContext(AuthContext)
  const { hideModal } = useContext(ModalContext)
  const { addToast } = useContext(ToastContext)

  const confirmDeleteAccount = async () => {
    hideModal()

    try {
      await deleteAccount()
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  return (
    <Modal
      closeable={true}
      size="sm"
      onClose={hideModal}
      title={t('areYouSure')}
    >
      <ModalBody id="confirm-account-delete-modal">
        <div>{t('deleteAccount.cannotBeUndone')}</div>
      </ModalBody>

      <ModalFooter
        dangerous={true}
        cancellable={true}
        onAcceptText={t('deleteAccount.yesDeleteAccount')}
        block={true}
        onAccept={confirmDeleteAccount}
        onCancel={hideModal}
      />
    </Modal>
  )
}

export default ConfirmAccountDeleteModal
