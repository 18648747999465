import axios, { AxiosResponse } from 'axios'
import Config from '@web/_config'

const baseURL = Config.API_URL
const apiUrlV2 = Config.API_URL_V2

export const BrandService = {
  getBrand: (id: string): Promise<AxiosResponse> => {
    return axios({
      method: 'get',
      baseURL,
      url: `/brands/${id}`,
      data: {
        id,
      },
    })
  },

  getBrandShareableContacts: (
    brandId: string,
    brandResourceId: string,
    q: string | null,
    signal: AbortSignal
  ): Promise<AxiosResponse> => {
    const url = `/messages/brands/${brandId}/resources/${brandResourceId}/contacts?&q=${
      q ? encodeURIComponent(q) : ''
    }`

    return axios({
      baseURL: apiUrlV2,
      url,
      signal,
    })
  },
}
