import React, { useContext, useState } from 'react'
import './ConnectionDetails.scss'
import { Connection, HCP } from '@web/_types'
import { isRep, isHCP } from '@web/_guards'
import { useTranslation } from 'react-i18next'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'
import {
  classNames,
  formatPhoneNumber,
  getQueryStringParams,
  toCamelCase,
} from '@web/_utils'
import { useLocalStorage } from '@web/common/hooks'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import {
  ROUTES,
  I18N,
  ROLES,
  CHANNEL_TYPE,
  LOGO_EXT,
  LOGGING,
  LOCALSTORAGE,
  LOGIN_SOURCE,
  MODALS,
  ERROR,
} from '@web/_constants'
import Avatar from '@web/js/components/Avatar'
import Button from '@web/js/components/Button'
import BrandCard from '@web/js/components/BrandCard'
import ContactCard from '@web/js/components/ContactCard'
import Notes from '@web/js/components/Notes'
import DetailsHeader from '@web/js/components/DetailsHeader'
import DetailsBody from '@web/js/components/DetailsBody'
import ThumbsUp from '@web/common/img/ThumbsUp.svg'
import Offices from '@web/js/components/Offices'
import CardList from '@web/js/components/CardList'
import DetailsWrapper from '@web/js/components/DetailsWrapper'
import PhoneIcon from '@web/common/img/PhoneIcon'
import EmailIcon from '@web/common/img/EmailIcon'
import ProfileIcon from '@web/common/img/ProfileIcon'
import Star from '@mui/icons-material/Star'
import ProfilePingIcon from '@web/common/img/ProfilePingIcon.svg'
import ProfileChatIcon from '@web/common/img/ProfileChatIcon.svg'
import ScheduleMeetingIcon from '@web/common/img/ScheduleMeetingIcon'
import ProfileSaveIcon from '@web/common/img/ProfileSaveIcon.svg'
import { useResponsive } from '@farfetch/react-context-responsive'
import SignUpBanner from '../SignUpBanner'
import { ContactService, InvitationsService } from '@web/_services'
import { ConnectionContext } from '@web/js/context/ConnectionContext'
import { ToastContext } from '@web/js/context/ToastContext'
import { ModalContext } from '@web/js/context/ModalContext'

interface ConnectionDetailsProps {
  connection?: Connection | null
  isPublicDBC?: boolean
  approveConnectionRequest?: (connection: Connection) => void
  removeConnectionCallback?: () => void
  createConnectionCallback?: () => void
}

const ConnectionDetails: React.FC<ConnectionDetailsProps> = ({
  connection,
  isPublicDBC = false,
  approveConnectionRequest,
  removeConnectionCallback,
  createConnectionCallback,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { lessThan } = useResponsive()
  const queryArgs = getQueryStringParams(window.location.search)
  const isGlassMode = !!queryArgs.vo && queryArgs.vo === '1'

  const isFullProfile =
    !!matchRoutes([{ path: ROUTES.hcpId }, { path: ROUTES.repId }], location) ||
    isPublicDBC

  const { connect, getConnections } = useContext(ConnectionContext)
  const { addToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)

  const [sentInvite, setSentInvite] = useState(false)
  const [showAllBrands, setShowAllBrands] = useState(isGlassMode)
  const [showAllSharedConnections, setShowAllSharedConnections] =
    useState(false)

  const [, setLoginSource] = useLocalStorage<string | null>(
    LOCALSTORAGE.loginSource,
    null
  )
  const viewLimit = 2

  if (!connection) return null

  const saveContact = () => {
    logEvent(LOGGING.ACTION_TYPE.CLICK_SAVE_CONTACT)

    // create a vcard file
    let vcard = `BEGIN:VCARD\nVERSION:3.0\nFN:${connection.displayName}`

    if (connection.firstName && connection.lastName) {
      vcard += `\nN:${connection.lastName};${connection.firstName};;;`
    }

    if (isRep(connection) && connection.phoneNumber) {
      vcard += `\nTEL;type=WORK:${connection.phoneNumber}`
    }

    if (isRep(connection) && connection.dbcLink) {
      vcard += `\nURL;type=WORK:${connection.dbcLink}`
    }

    if (connection.email) {
      vcard += `\nEMAIL;type=WORK:${connection.email}`
    }

    if (isRep(connection) && connection.group) {
      vcard += `\nORG:${connection.group.name};`
    }

    vcard += '\nEND:VCARD'

    //force download file
    const blob = new Blob([vcard], { type: 'text/vcard' })
    const url = URL.createObjectURL(blob)
    const newLink = document.createElement('a')
    newLink.download = connection.displayName + '.vcf'
    newLink.textContent = connection.displayName
    newLink.href = url
    newLink.click()
  }

  const goToChat = () => {
    let logActionType: string
    let loginSource: string

    if (isHCP(connection) || connection.channelType === CHANNEL_TYPE.chat) {
      logActionType = LOGGING.ACTION_TYPE.CLICK_CHAT
      loginSource = LOGIN_SOURCE.PROFILE_CHAT
    } else {
      logActionType = LOGGING.ACTION_TYPE.CLICK_PING
      loginSource = LOGIN_SOURCE.PROFILE_PING
    }
    logEvent(logActionType)

    if (isPublicDBC) {
      goToDBCSignup(loginSource)
    } else if (isRep(connection) && !connection.isContact) {
      connect(connection, LOGGING.CONTACT_SOURCE_TYPE.REP_PROFILE_PHONE, () =>
        navigate(`${ROUTES.chat}/${connection.channelId}`)
      )
    } else {
      navigate(`${ROUTES.chat}/${connection.channelId}`)
    }
  }

  const scheduleMeeting = (hcpInitiatedSchLink: string) => {
    logEvent(LOGGING.ACTION_TYPE.CLICK_SCHEDULE)

    if (isPublicDBC) {
      goToDBCSignup(LOGIN_SOURCE.PROFILE_SCHEDULE)
    } else if (connection.isContact) {
      window.open(hcpInitiatedSchLink)
    } else {
      connect(
        connection,
        LOGGING.CONTACT_SOURCE_TYPE.REP_PROFILE_SCHEDULE,
        () => window.open(hcpInitiatedSchLink)
      )
    }
  }

  const openLink = (link: string, contactSourceType: string) => {
    if (connection.isContact || isPublicDBC) {
      window.open(link)
    } else if (connect) {
      connect(connection, contactSourceType, () => window.open(link))
    }
  }

  const goToProfile = (connection: Connection) => {
    const path = connection && isRep(connection) ? ROLES.rep : ROLES.hcp
    navigate(`/${path.toLowerCase()}/${connection.id}`)
  }

  const goToDBCSignup = (loginSource: string) => {
    setLoginSource(loginSource)

    navigate(ROUTES.dbcSignup, {
      state: { repName: connection.displayName },
    })
  }

  const disconnect = () => {
    showModal({
      name: MODALS.REMOVE_CONNECTION_MODAL,
      data: {
        connection,
        ...(removeConnectionCallback && { removeConnectionCallback }),
      },
    })
    logEvent(LOGGING.ACTION_TYPE.CLICK_CONNECTED)
  }

  const createConnection = async () => {
    if (connection.invitePending) return

    if (connection.channelId && isHCP(connection)) {
      try {
        const inviteResponse = await InvitationsService.invite({
          hcpId: connection.id,
        })
        const autoConnected = inviteResponse.data.result === ROLES.hcp
        if (autoConnected) {
          if (createConnectionCallback) createConnectionCallback()
        } else {
          connection.invitePending = true
          setSentInvite(true)
          showModal({
            name: MODALS.INVITE_SENT_MODAL,
            data: {
              displayName: connection.displayName,
            },
          })
        }
      } catch (error) {
        const errorResponse = error.response
        const errorData = errorResponse.data

        if (errorData.detail.length) {
          switch (errorData.detail[0].reason) {
            case ERROR.duplicate:
              addToast(t('errorDuplicateInvitation'))
              break
            default:
              addToast(t('errorGeneric'))
          }
        }
      }
    } else if (isRep(connection)) {
      connect(
        connection,
        LOGGING.CONTACT_SOURCE_TYPE.REP_PROFILE_CONNECT,
        () => {
          if (createConnectionCallback) createConnectionCallback()
        }
      )
    } else {
      showModal({
        name: MODALS.INVITE_TO_CONNECT_MODAL,
        data: {
          connection,
          setSentInvite,
          ...(createConnectionCallback && { createConnectionCallback }),
        },
      })
    }

    logEvent(LOGGING.ACTION_TYPE.CLICK_CONNECT)
  }

  const approveRequest = async (notificationId: string) => {
    try {
      await ContactService.connectFromNotification(notificationId)
      getConnections()
    } catch (error) {
      addToast(t('errorGeneric'))
    }
  }

  const logEvent = (actionType: string, referenceId?: string) => {
    const eventMessage = {
      profileId: connection.id,
      actionType,
      ...(referenceId && { referenceId }),
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  const renderConnectionButton = () => {
    if (
      !connection.isContact &&
      (connection.invitedNotificationId || approveConnectionRequest)
    ) {
      return (
        <Button
          onClick={() => {
            if (approveConnectionRequest) {
              approveConnectionRequest(connection)
            } else {
              approveRequest(connection.invitedNotificationId as string)
            }

            logEvent(LOGGING.ACTION_TYPE.CLICK_CONNECT)
          }}
          size="sm"
          style="success"
          iconFront={<img src={ThumbsUp} />}
        >
          {t('connect')}
        </Button>
      )
    } else if (connection.isContact) {
      return (
        <Button
          style="warning"
          size="sm"
          iconFront={<Star />}
          onClick={disconnect}
        >
          {t('connected')}
        </Button>
      )
    } else if (!connection.isContact) {
      return (
        <Button
          outline={true}
          style="warning"
          size="sm"
          iconFront={<Star />}
          onClick={createConnection}
        >
          {connection.invitePending || sentInvite
            ? t('inviteSentPlainCap')
            : t('connect')}
        </Button>
      )
    }
  }

  const connectionDetailsClasses = classNames({
    'connection-details--profile': true,
    mini: !isFullProfile,
  })

  return (
    <DetailsWrapper id="connection-details" classes={connectionDetailsClasses}>
      <DetailsHeader
        id="connection-details--header"
        {...(isRep(connection) && {
          primaryColor: connection.group.primaryColor as string,
        })}
        {...(isRep(connection) && {
          secondaryColor: connection.group.secondaryColor as string,
        })}
      >
        <>
          <Avatar
            invert={true}
            user={connection}
            size={isFullProfile ? 'xxl' : 'xl'}
          />

          <div className="connection-details--header-details">
            <div className="connection-details--header-details--contact-wrapper">
              <div className="connection-details--header-details--contact-info">
                <div className="connection-details--header-details--name">
                  <span>{connection.displayName}</span>

                  {!lessThan.md &&
                    isFullProfile &&
                    isRep(connection) &&
                    connection.group.photoUrl && (
                      <div className="connection-details--header--logo">
                        <img
                          src={connection.group.photoUrl + LOGO_EXT}
                          alt="logo"
                        />
                      </div>
                    )}
                </div>

                {isRep(connection) && (
                  <div className="connection-details--header-details--title">
                    {`${t(
                      `roleCategory.${toCamelCase(connection.roleCategory)}`
                    )}${connection.title ? ' • ' + connection.title : ''}`}
                  </div>
                )}

                {isHCP(connection) && !!connection.title && (
                  <div className="connection-details--header-details--title">
                    {connection.title}
                  </div>
                )}

                {isRep(connection) && connection && (
                  <div className="connection-details--header-details--group">
                    {connection.group.name}
                  </div>
                )}

                {!isPublicDBC && (
                  <div className="connection-details--header-details--connect">
                    {renderConnectionButton()}
                  </div>
                )}
              </div>
            </div>

            {isRep(connection) &&
              (connection.phoneNumber || connection.email) && (
                <div className="connection-details--header-details--contact">
                  {connection.phoneNumber && (
                    <div className="connection-details--header-details--phone">
                      <PhoneIcon />{' '}
                      <a
                        href="#"
                        onClick={() => {
                          openLink(
                            `tel:${connection.phoneNumber}`,
                            LOGGING.CONTACT_SOURCE_TYPE.REP_PROFILE_PHONE
                          )
                          logEvent(LOGGING.ACTION_TYPE.CLICK_PHONE)
                        }}
                      >
                        {formatPhoneNumber(connection.phoneNumber)}
                      </a>
                    </div>
                  )}

                  {connection.email && (
                    <div className="connection-details--header-details--email">
                      <EmailIcon />{' '}
                      <a
                        href="#"
                        onClick={() => {
                          openLink(
                            `mailto:${connection.email}`,
                            LOGGING.CONTACT_SOURCE_TYPE.REP_PROFILE_EMAIL
                          )
                          logEvent(LOGGING.ACTION_TYPE.CLICK_EMAIL)
                        }}
                      >
                        {connection.email}
                      </a>
                    </div>
                  )}
                </div>
              )}

            <div className="connection-details--header-details--interact">
              <div className="round-btn-list">
                {((isRep(connection) &&
                  connection.channelType !== CHANNEL_TYPE.off) ||
                  (isHCP(connection) && connection.isContact)) && (
                  <div className="round-btn" onClick={goToChat}>
                    <div className="round-btn--img">
                      <img
                        src={
                          isHCP(connection) ||
                          connection.channelType === CHANNEL_TYPE.chat
                            ? ProfileChatIcon
                            : ProfilePingIcon
                        }
                      />
                    </div>
                    <div className="round-btn--text">
                      {t(
                        `${
                          isHCP(connection)
                            ? 'chat'
                            : connection.channelType.toLowerCase()
                        }`
                      )}
                    </div>
                  </div>
                )}

                {isRep(connection) && connection.hcpInitiatedSchLink && (
                  <div
                    className="round-btn"
                    onClick={() =>
                      scheduleMeeting(connection.hcpInitiatedSchLink as string)
                    }
                  >
                    <div className="round-btn--img">
                      <ScheduleMeetingIcon width="28px" height="28px" />
                    </div>
                    <div className="round-btn--text">
                      {!isFullProfile || lessThan.md
                        ? t('schedule')
                        : t('scheduleMeeting')}
                    </div>
                  </div>
                )}

                {isRep(connection) &&
                  (connection.email || connection.phoneNumber) && (
                    <div className="round-btn" onClick={saveContact}>
                      <div className="round-btn--img">
                        <img src={ProfileSaveIcon} />
                      </div>
                      <div className="round-btn--text">
                        {!isFullProfile || lessThan.md
                          ? t('save')
                          : t('saveContact')}
                      </div>
                    </div>
                  )}

                {!isFullProfile && (
                  <div
                    className="round-btn"
                    onClick={() => goToProfile(connection)}
                  >
                    <div className="round-btn--img">
                      <ProfileIcon width="24px" height="24px" />
                    </div>
                    <div className="round-btn--text">{t('fullProfile')}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {(lessThan.md || !isFullProfile) &&
            isRep(connection) &&
            connection.group.photoUrl && (
              <div className="connection-details--header--logo">
                <img src={connection.group.photoUrl + LOGO_EXT} alt="logo" />
              </div>
            )}
        </>
      </DetailsHeader>

      {isPublicDBC && (
        <SignUpBanner
          goToDBCSignup={() => {
            logEvent(LOGGING.ACTION_TYPE.CLICK_SIGN_UP_TO_CONNECT)
            goToDBCSignup(LOGIN_SOURCE.PROFILE_SIGN_UP_TO_CONNECT)
          }}
        />
      )}

      <DetailsBody id="connection-details--body">
        {!isPublicDBC && isRep(connection) && connection.isContact && (
          <Notes rep={connection} isFullProfile={isFullProfile} />
        )}

        <div className="connection-details--brands">
          {isRep(connection) && !connection.brands.length && (
            <div className="no-items">{t('noMedications')}</div>
          )}

          {isRep(connection) && !!connection.brands.length && (
            <>
              <div className="text-headline">{t('medications')}</div>
              <CardList>
                {_.chain(connection.brands)
                  .take(showAllBrands ? connection.brands.length : viewLimit)
                  .map((brand) => (
                    <div
                      className="connection-details--brands--list-item"
                      key={brand.id}
                    >
                      <BrandCard
                        brand={brand}
                        connectionId={connection.id}
                        category="REP_PROFILE"
                        {...(isPublicDBC && {
                          redirect: () =>
                            goToDBCSignup(LOGIN_SOURCE.PROFILE_BRAND_CARD),
                        })}
                      />
                    </div>
                  ))
                  .value()}
              </CardList>

              {!showAllBrands && connection.brands.length > viewLimit && (
                <div className="view-more">
                  <Button
                    size="sm"
                    outline={true}
                    onClick={() => setShowAllBrands(true)}
                  >
                    {t('viewNMore', {
                      n: connection.brands.length - viewLimit,
                    })}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>

        {!isPublicDBC && (
          <div className="connection-details--shared-connections">
            <div className="text-headline">{t('sharedConnections')}</div>
            {connection.commonHcps?.length ? (
              <CardList>
                {_.chain(connection.commonHcps)
                  .orderBy(
                    [
                      (hcp) => hcp.lastName.toLowerCase(),
                      (hcp) => hcp.firstName.toLowerCase(),
                    ],
                    ['asc', 'asc']
                  )
                  .take(
                    showAllSharedConnections
                      ? connection.commonHcps.length
                      : viewLimit
                  )
                  .map((hcp: HCP) => {
                    return (
                      <ContactCard
                        contact={hcp}
                        key={hcp.id}
                        showTitle={true}
                      />
                    )
                  })
                  .value()}
              </CardList>
            ) : (
              <div className="no-items">{t('noSharedConnections')}</div>
            )}

            {!showAllSharedConnections &&
              connection.commonHcps?.length > viewLimit && (
                <div className="view-more">
                  <Button
                    size="sm"
                    outline={true}
                    onClick={() => setShowAllSharedConnections(true)}
                  >
                    {t('viewNMore', {
                      n: connection.commonHcps.length - viewLimit,
                    })}
                  </Button>
                </div>
              )}
          </div>
        )}

        {isHCP(connection) && connection.isContact && (
          <div className="connection-details--offices">
            <Offices hcpId={connection.id} />
          </div>
        )}
      </DetailsBody>
    </DetailsWrapper>
  )
}

export default ConnectionDetails
