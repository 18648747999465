import * as React from 'react'
import { IconProps } from '@web/_types'

const DocumentIcon: React.FC<IconProps> = ({
  height = '24px',
  width = '24px',
  color = '#ffffff',
  className = 'icon-document',
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64437 3.67434C6.05695 3.24257 6.61652 3 7.2 3H13.6C13.7591 3 13.9117 3.06615 14.0243 3.18391L14.0246 3.18422L18.8243 8.20717L18.8307 8.21397C18.9355 8.32702 19 8.48121 19 8.65116V18.6977C19 19.3083 18.7682 19.8939 18.3556 20.3257C17.9431 20.7574 17.3835 21 16.8 21H7.2C6.61652 21 6.05694 20.7574 5.64437 20.3257C5.23179 19.8939 5 19.3083 5 18.6977V5.30233C5 4.69171 5.23178 4.10611 5.64437 3.67434ZM7.2 4.25581H13V8.65116C13 8.99795 13.2686 9.27907 13.6 9.27907H17.8V18.6977C17.8 18.9752 17.6946 19.2414 17.5071 19.4377C17.3196 19.6339 17.0652 19.7442 16.8 19.7442H7.2C6.93478 19.7442 6.68043 19.6339 6.49289 19.4377C6.30536 19.2414 6.2 18.9752 6.2 18.6977V5.30233C6.2 5.02477 6.30536 4.75859 6.49289 4.56233C6.68043 4.36607 6.93478 4.25581 7.2 4.25581ZM14.2 5.14381L16.9515 8.02326H14.2V5.14381ZM8.8 12.2093C8.46863 12.2093 8.2 12.4904 8.2 12.8372C8.2 13.184 8.46863 13.4651 8.8 13.4651H15.2C15.5314 13.4651 15.8 13.184 15.8 12.8372C15.8 12.4904 15.5314 12.2093 15.2 12.2093H8.8ZM8.2 16.186C8.2 15.8393 8.46863 15.5581 8.8 15.5581H15.2C15.5314 15.5581 15.8 15.8393 15.8 16.186C15.8 16.5328 15.5314 16.814 15.2 16.814H8.8C8.46863 16.814 8.2 16.5328 8.2 16.186ZM8.8 8.86047C8.46863 8.86047 8.2 9.14159 8.2 9.48837C8.2 9.83516 8.46863 10.1163 8.8 10.1163H9.6H10.4C10.7314 10.1163 11 9.83516 11 9.48837C11 9.14159 10.7314 8.86047 10.4 8.86047H9.6H8.8Z"
      fill={color}
    />
  </svg>
)

export default DocumentIcon
