import { isRep, isHCP } from '.'
import { HCP, Rep } from '@web/_types'
import { CHANNEL_TYPE } from '@web/_constants'

export const canCommunicate = (contact: HCP | Rep): contact is Rep | HCP => {
  return (
    (isRep(contact) &&
      contact.isContact &&
      contact.channelType !== CHANNEL_TYPE.off) ||
    (isHCP(contact) && contact.isContact)
  )
}
