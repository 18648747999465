import React, { useContext } from 'react'
import './ChatList.scss'
import _ from 'lodash'
import { classNames } from '@web/_utils'
import { Channel, Message } from '@web/_types'
import { useTranslation } from 'react-i18next'
import {
  CHANNEL_TYPES,
  I18N,
  MESSAGE_TYPES,
  MODALS,
  ROUTES,
} from '@web/_constants'
import { useNavigate } from 'react-router-dom'
import ContactCard from '@web/js/components/ContactCard'
import Button from '@web/js/components/Button'
import EmptyChatList from '@web/common/img/EmptyChatList.svg'
import { ChatContext } from '@web/js/context/ChatContext'
import { isHCP } from '@web/_guards'
import ChatIcon from '@web/common/img/ChatIcon'
import { ModalContext } from '@web/js/context/ModalContext'

interface ChatListProps {
  loadChannel: (channel: Channel, redirected?: boolean) => void
  inDock?: boolean
  showActive?: boolean
}

const ChatList: React.FC<ChatListProps> = ({
  inDock = false,
  loadChannel,
  showActive = true,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)

  const { chatChannels, activeChannel } = useContext(ChatContext)
  const { showModal } = useContext(ModalContext)

  const renderLatestMessagePreview = (channel: Channel) => {
    let latestMessagePreview = null
    const isPartialChannel = channel.type === CHANNEL_TYPES.partial
    const isBrandResource =
      channel.latestMessage?.type === MESSAGE_TYPES.brandResource
    const { contactId, body, content, forwardedComment } =
      channel.latestMessage as Message

    if (isPartialChannel) {
      latestMessagePreview = t('connectionInviteSent')
    } else if (forwardedComment) {
      latestMessagePreview = forwardedComment
    } else if (isBrandResource || contactId) {
      //if sender id does not equal connection id, user is in sender view
      const isSender =
        channel.connection?.id !== channel.latestMessage?.sender.id
      if (isSender) {
        latestMessagePreview = isBrandResource
          ? t('youSentResource')
          : t('youSharedContact')
      } else {
        const name = channel.connection
          ? channel.connection.displayName
          : channel.partialEmail
        latestMessagePreview = t(
          `${isBrandResource ? 'sentResource' : 'sharedContact'}`,
          {
            name,
          }
        )
      }
    } else if (body) {
      latestMessagePreview = body
    } else if (content) {
      latestMessagePreview = content.message
    }

    return (
      <div className="chat-list--latest-message">{latestMessagePreview}</div>
    )
  }

  return (
    <div className="chat-list">
      {!inDock && (
        <div className="chat-list--header">
          <Button
            iconFront={<ChatIcon />}
            size="sm"
            onClick={() =>
              showModal({
                name: MODALS.SEND_MESSAGE_MODAL,
                data: {
                  shouldRoute: true,
                },
              })
            }
          >
            {t('new')}
          </Button>
        </div>
      )}

      <div className="chat-list--items">
        {!chatChannels.length && (
          <div className="chat-list--empty">
            <div className="chat-list--empty--icon">
              <img src={EmptyChatList} />
            </div>
            <div className="text-headline center">{t('emptyChatsTitle')}</div>
            <div className="text-subhead center">
              {t('emptyChatsDescription1')}
            </div>
            <div className="chat-list--empty--action">
              <Button size="sm" onClick={() => navigate(ROUTES.invite)}>
                {t('sendInvitation')}
              </Button>
            </div>
          </div>
        )}

        {!!chatChannels.length &&
          chatChannels.map((channel: Channel) => {
            const isDeletedHCP =
              channel.connection &&
              isHCP(channel.connection) &&
              channel.connection.deleted

            const cx = {
              'chat-list--item': true,
              read: !channel.unreadCount,
              unread: !!channel.unreadCount || _.isNull(channel.unreadCount),
              active:
                showActive &&
                !!activeChannel &&
                channel.id === activeChannel.id,
            }

            const chatListItemClasses = classNames(cx)
            const date = channel.latestMessage
              ? channel.latestMessage.created
              : channel.created

            return (
              <div
                key={channel.id}
                className={chatListItemClasses}
                onClick={() => loadChannel(channel)}
              >
                <ContactCard
                  {...(channel.connection && { contact: channel.connection })}
                  {...(channel.connection &&
                    isHCP(channel.connection) && {
                      showTitle:
                        channel.connection && isHCP(channel.connection),
                    })}
                  {...(channel.partialEmail &&
                    !channel.connection && { email: channel.partialEmail })}
                  date={date}
                  showRepIcon={true}
                >
                  {(channel.active || isDeletedHCP) &&
                    channel.latestMessage &&
                    renderLatestMessagePreview(channel)}

                  {((!channel.active && !isDeletedHCP) ||
                    (isDeletedHCP && !channel.latestMessage)) && (
                    <div className="chat-list--not-connected">
                      {t('notConnected')}
                    </div>
                  )}

                  {channel.active &&
                    channel.connection &&
                    !channel.latestMessage && (
                      <div className="chat-list--now-connected">
                        {t('nowConnectedTo', {
                          name: channel.connection.displayName,
                        })}
                      </div>
                    )}
                </ContactCard>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default ChatList
